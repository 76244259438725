var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DatePipe } from '@angular/common';
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as firebase from 'firebase';
import { TicketServiceService } from '../ticket-service.service';
export class TicketComponent {
    constructor(formBuilder, ticketService) {
        this.formBuilder = formBuilder;
        this.ticketService = ticketService;
        this.tickets = [];
        this.fileUploading = false;
        this.fileUploaded = false;
        this.fileAdded = [];
        this.pipe = new DatePipe('fr-FR');
        this.now = Date.now();
        this.myFormattedDate = this.pipe.transform(this.now, 'shortDate');
        this.idPro = [];
        this.Prof = [];
        this.ticketList = Array();
        this.ticketId = Array();
        this.ticketData = Array();
    }
    ngOnInit() {
        // tslint:disable-next-line:no-shadowed-variable
        this.getCurrentUserData().then(array => this.Prof = array);
        this.initSendTicket();
        this.getAllTicket().then(array => this.ticketList = array);
        // console.log(this.ticketList);
    }
    initSendTicket() {
        this.sendTicket = this.formBuilder.group({
            objet: ['', Validators.required],
            questions: ['', Validators.required]
        });
    }
    // get the current user data
    getCurrentUserData() {
        const currentUser = firebase.auth().currentUser;
        const docRef = firebase.firestore().collection('particuliers').where('uid', '==', currentUser.uid);
        return docRef.get().then((snapshot) => {
            return snapshot.docs.map(doc => {
                this.idPro.push(doc.id);
                // console.log(this.idPro);
                this.Prof.push(doc.data());
                // console.log(this.Prof);
                return this.Prof;
            });
        });
    }
    onSendTicket() {
        const currentUser = firebase.auth().currentUser;
        const newTicket = this.sendTicket.value;
        newTicket.objet = this.sendTicket.get('objet').value;
        newTicket.questions = this.sendTicket.get('questions').value;
        newTicket.today = this.myFormattedDate;
        newTicket.numero = Math.floor((Math.random() * 1000000) + 1);
        newTicket.uid = currentUser.uid;
        // newTicket.state = this.status.t;
        // @ts-ignore return undefined
        // newTicket.state = this.status.encours;
        // convert the array into an object so that firebase accepts it
        newTicket.user = this.Prof.map((obj) => {
            return Object.assign({}, obj);
        });
        // console.log(newTicket.user);
        firebase.firestore().collection('tickets').add(newTicket);
        this.sendTicket.reset();
    }
    onUploadFile(event) {
        this.filename = event.srcElement.files[0].name;
        this.fileUploading = true;
        this.ticketService.uploadFile(event.target.files[0]).then((url) => {
            this.fileAdded.push(url);
            this.fileUploading = false;
            this.fileUploaded = true;
            setTimeout(() => {
                this.fileUploaded = false;
            }, 5000);
        });
    }
    getAllTicket() {
        return __awaiter(this, void 0, void 0, function* () {
            const db = firebase.firestore().collection('tickets').orderBy('today', 'desc');
            const snapshot = yield db.get();
            return snapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    data: doc.data()
                };
            });
        });
    }
}
