import { Routes } from "@angular/router";
import { LoggedInGuard } from "./guards/loggedin.guard";
import { AccountComponent } from "./routes/account/account.component";
import { SignupComponent } from "./routes/account/signup/signup.component";
import { AppBaseComponent } from "./routes/app/appbase/appbase.component";
import { ProfileComponent } from "./routes/app/profile/profile.component";
import { SupportComponent } from "./routes/app/support/support.component";
import { ProfessionnelComponent } from "./routes/app/professionnel/professionnel.component";
import { BaseComponent } from "./routes/base/base.component";
import { NotFoundComponent } from "./routes/not_found/not_found.component";
import { ProfileCheckComponent } from "./routes/verify/profile/profilecheck.component";
import { VerifyBaseComponent } from "./routes/verify/verifybase/verifybase.component";
import { ResetPasswordComponent } from "./routes/account/reset-password/reset-password.component";
import { CommandesComponent } from "./routes/app/commandes/commandes.component";
import { MaintenanceComponent } from "./routes/maintenance/maintenance.component";
import { QuestionsComponent } from "./routes/app/questions/questions.component";
import { TicketComponent } from "./routes/app/ticket/ticket.component";
import { SingleTicketComponent } from "./routes/app/single-ticket/single-ticket.component";
import { SingleCommandeComponent } from "./routes/app/single-commande/single-commande.component";
import { FacturesComponent } from "./routes/app/factures/factures.component";
import { FactureDetailComponent } from "./routes/app/facture-detail/facture-detail.component";
import { TutoSalonComponent } from "./routes/app/tuto-salon/tuto-salon.component";
import { MessagesComponent } from "./routes/app/messages/messages.component";
import { RegistrationComponent } from "./routes/account/science-accueil/registration/registration.component";
import { ConnectComponent } from "./routes/account/science-accueil/connect/connect.component";
import { EmailVerfiyComponent } from "./routes/account/email-verify/email-verify.component";
import { CardComponent } from "./routes/app/card/card.component";
import { DefaultLoginComponent } from "./routes/account/defaultLogin/defaultLogin.component";
import { AccountExpatComponent } from "./routes/account/expat/account-expat.component";
const ɵ0 = { title: "section_account_title" }, ɵ1 = { title: "section_agency_title" }, ɵ2 = { title: "section_support_title" }, ɵ3 = { title: "section_support_title" }, ɵ4 = { title: "section_order_title" }, ɵ5 = { title: "section_order_title" }, ɵ6 = { title: "main_menu_left_section_navigation_factures" };
const appRoutes = [
    // Pages for logging in
    {
        path: "compte",
        component: AccountComponent,
        children: [
            {
                path: "connexion",
                component: AccountExpatComponent,
            },
            {
                path: "emailVerify/:id",
                component: EmailVerfiyComponent,
            },
            {
                path: "inscription",
                component: SignupComponent,
            },
            {
                path: "resetPassword",
                component: ResetPasswordComponent,
            },
            {
                path: "registration",
                component: RegistrationComponent,
            },
            {
                path: "connect",
                component: ConnectComponent,
            },
            {
                path: "default",
                component: DefaultLoginComponent,
            },
        ],
    },
    // Intermediate steps before getting to app section
    {
        path: "verify",
        canActivate: [LoggedInGuard],
        component: VerifyBaseComponent,
        children: [
            {
                path: "profile",
                component: ProfileCheckComponent,
            },
            { path: "**", component: ProfileCheckComponent },
            { path: "", redirectTo: "profile", pathMatch: "full" },
        ],
    },
    // TODO: chercher une autre solution si possible | brin:18/09/2020
    {
        path: "verify/:id",
        canActivate: [LoggedInGuard],
        component: VerifyBaseComponent,
        children: [
            {
                path: "profile",
                component: ProfileCheckComponent,
            },
            { path: "**", component: ProfileCheckComponent },
            { path: "", redirectTo: "profile", pathMatch: "full" },
        ],
    },
    // Validated section - user is connected
    {
        path: "app",
        canActivate: [LoggedInGuard],
        component: AppBaseComponent,
        children: [
            {
                path: "profile",
                component: ProfileComponent,
                data: ɵ0,
            },
            {
                path: "professionnel",
                component: ProfessionnelComponent,
                data: ɵ1,
            },
            {
                path: "support",
                component: SupportComponent,
                data: ɵ2,
            },
            {
                path: "card",
                component: CardComponent,
                data: ɵ3,
            },
            {
                // brin
                path: "commandes",
                component: CommandesComponent,
                data: ɵ4,
            },
            // mamadou
            {
                path: "commandes/:id",
                component: SingleCommandeComponent,
                data: ɵ5,
            },
            { path: "", redirectTo: "commandes", pathMatch: "full" },
            {
                // mamadou
                path: "factures",
                component: FacturesComponent,
                data: ɵ6,
            },
            {
                path: "factures/:id",
                component: FactureDetailComponent,
            },
            {
                // mamadou
                path: "questions",
                component: QuestionsComponent,
            },
            {
                // mamadou
                path: "ticket",
                component: TicketComponent,
            },
            {
                // mamadou
                path: "ticket/:id",
                component: SingleTicketComponent,
            },
            {
                // mamadou
                path: "tuto-salon",
                component: TutoSalonComponent,
            },
            {
                // mamadou
                path: "messages",
                component: MessagesComponent,
            },
        ],
    },
    // Logged out page(s)
    {
        path: "",
        component: BaseComponent,
        children: [],
    },
    // maintenance
    {
        // brin
        path: "maintenance",
        component: MaintenanceComponent,
    },
    /// Fallbacks
    { path: "", redirectTo: "BaseComponent", pathMatch: "full" },
    { path: "**", component: NotFoundComponent },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
