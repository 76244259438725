import { Component, OnInit } from '@angular/core';
import { HieroBDD } from '../../../services/hierobdd.service';
import * as firebase from 'firebase';


@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  particulierData;

  command;


  constructor( private hiero:HieroBDD) { }

  ngOnInit(): void {

    this.commandWaitingPaiement().then(value=> {
      this.command = value.filter(doc => doc.prestationInfo.state === "waiting-for-payment")
    } )
  
    
    
   }



  async commandWaitingPaiement () {
    const currentUser = this.hiero.Auth.User;
    const docRef = this.hiero.DB.collection("particuliers").where(
      "uid",
      "==",
      currentUser.Id
    );
    return docRef.get().then((snapshot) => {
      const particulierId = snapshot.docs[0].id;
      this.particulierData = snapshot.docs[0].data();

      const request = this.hiero.DB.collection("particuliers_commandes")
        .where("particulierId", "==", particulierId)
        .orderBy("createdAt", "desc")
       

      return request.get().then(
        // tslint:disable-next-line:no-shadowed-variable
        (snapshot) => {
          return snapshot.docs.map((doc) => {
            
              return doc.data()
 
            
            
          });
        }
      );
    });
  }

  async paid (prix) {  

    let db = firebase.firestore();
    
    const docRef = await db
    .collection('customers')
    .doc(firebase.auth().currentUser.uid)
    .collection('checkout_sessions')
    .add({
      line_items: [
        {
          price_data: {
            currency: 'eur',
            product_data: {
              name: 'T-shirt',
              description: 'grrrrrrrrrrrrrrr'
            },
            unit_amount: prix,
          },
          quantity: 1,
        },
      ],
      mode: "payment",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });
  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot((snap) => {
    const { error, url }:any = snap.data();
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(url);
    }
  });

  }

}

