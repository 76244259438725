<div class="my-bg">
  <div class="container">
    <div class="row mt-5">
      <div class="col-10 col-md-10 mx-auto">
        <div class="banner">
          <img
            src="../assets/img/logo/logoexpat.png"
            alt="partenaire"
            class="img-fluid w-25"
            width="100px"
          />
          <div class="d-flex align-items-center p-20">
            <div class="offset-md-3">
              <h5>
                {{ "signup_main_text" | localise }}
              </h5>
            </div>
            <div class="ml-auto p-2">
              <app-language-dropdown class="lang btn"></app-language-dropdown>
            </div>
          </div>
        </div>
        <div class="content mb-3 ml-3">
          <div class="row">
            <div class="col-md-6 login-form-1">
              <h3 class="pull-left mt-3 ml-3 mb-4">
                {{ "signup_main_title" | localise }}
              </h3>
              <p class="pull-left mt-3 ml-3 txt">
                {{ "signup_login_text" | localise }}
              </p>
              <form
                [formGroup]="signupForm.Group"
                novalidate
                class="margin-bottom-0"
                data-parsley-validate="true"
              >
                <app-form-input
                  [parameters]="signupForm.GetInputElement('email')"
                ></app-form-input>
                <app-form-input
                  [parameters]="signupForm.GetInputElement('password')"
                ></app-form-input>
                <app-form-input
                  [parameters]="signupForm.GetInputElement('confirm')"
                ></app-form-input>
                <app-form-input
                  [parameters]="signupForm.GetInputElement('familyName')"
                ></app-form-input>
                <app-form-input
                  [parameters]="signupForm.GetInputElement('givenName')"
                ></app-form-input>
                <app-form-input
                  [parameters]="signupForm.GetInputElement('telephone')"
                ></app-form-input>
                <app-form-general-error
                  [submitForm]="signupForm"
                ></app-form-general-error>
                <div class="checkbox checkbox-css m-b-30">
                  <div class="checkbox checkbox-css m-b-30">
                    <input type="checkbox" id="agreement_checkbox1" value="" />
                    <label for="agreement_checkbox1">
                      {{ "newsletter" | localise }}
                    </label>
                  </div>
                </div>
                <!--
              <div class="checkbox checkbox-css m-b-30">
                <div class="checkbox checkbox-css m-b-30">
                  <input type="checkbox" id="agreement_checkbox" value="" required>
                  <label for="agreement_checkbox">
                    {{'cdu' | localise }} <a href="#">{{'cdu1' | localise }}</a> {{'cdu3' | localise }}
                    <a href="#">{{'cdu2' | localise }}</a>
                  </label>
                </div>
              </div>
              -->
                <button
                  (click)="signupForm.Submit()"
                  [disabled]="!signupForm.Valid"
                  class="btn pull-right myColor"
                >
                  {{ "signup_main_button" | localise }}
                </button>
              </form>
            </div>

            <div class="col-md-6 login-form-2">
              <div class="login-logo">
                <img src="../assets/img/logo/origami.png" alt="logohiero" />
              </div>
              <h3 class="pull-left mt-3 ml-3">
                {{ "reset_password_login" | localise }}
              </h3>
              <p class="pull-left mt-3 ml-3 txt-white">
                {{ "login_text" | localise }}
              </p>
              <form
                [formGroup]="loginForm.Group"
                novalidate
                class="margin-bottom-0"
                data-parsley-validate="true"
              >
                <app-form-input
                  [parameters]="loginForm.GetInputElement('email')"
                ></app-form-input>
                <app-form-input
                  [parameters]="loginForm.GetInputElement('password')"
                ></app-form-input>
                <app-form-general-error
                  [submitForm]="loginForm"
                ></app-form-general-error>
                <div class="checkbox checkbox-css m-b-5">
                  <input type="checkbox" id="remember_me_checkbox" value="" />
                  <label for="remember_me_checkbox">
                    {{ "online" | localise }}
                  </label>
                </div>
                <div class="d-flex flex-column my-div">
                  <div class="login-buttons pull-right">
                    <button
                      (click)="loginForm.Submit()"
                      [disabled]="!loginForm.Valid"
                      class="btn myWhiteColor"
                    >
                      {{ "login_main_button" | localise }}
                    </button>
                  </div>
                  <!-- <div class="m-t-20 p-b-20 text-inverse">
                    <a [routerLink]="['..', 'inscription']" class="text-success">{{ 'login_main_to_signup' | localise }}</a>
                  </div> -->
                  <div class="m-t-20 m-t-20 p-b-20 text-inverse">
                    <a
                      [routerLink]="['..', 'resetPassword']"
                      class="text-primary"
                      >{{ "login_forgot_password" | localise }}</a
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <h5 class="txt-white">
              {{ "login_main_copyright" | localise }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
