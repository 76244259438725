var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { EmptyParticulierProfile } from '../../../../../common/src/bdd/interfaces/IProfessionnel';
import { Config } from '../../../../../common/src/services/config.service';
import { GoogleGeo } from '../../../services/google.services';
import { HieroBDD } from '../../../services/hierobdd.service';
import { SubmitForm } from '../../../../../common/src/utility/forms/submitform.class';
import { haveValideAddressValidator } from '../../verify/profile/profilecheck.component';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppModalManualAddressComponent } from '../../../utility/components/modal-manual-address/modal-manual-address.component';
export class ProfessionnelComponent {
    constructor(fb, hiero, config, router, geo, localisation, modalService) {
        this.fb = fb;
        this.hiero = hiero;
        this.config = config;
        this.router = router;
        this.geo = geo;
        this.localisation = localisation;
        this.modalService = modalService;
        this.busy = true;
        this.formatter = (loc) => (loc && loc.address) ? loc.address.formatted : '';
        this.search = (text$) => text$.pipe(debounceTime(100), distinctUntilChanged(), switchMap(term => term.length < 3
            ? []
            : this.geo.geocode(term)));
        this.createForm(this.profile || EmptyParticulierProfile);
    }
    ngOnInit() {
        this.profSubs = this.hiero.WatchParticulierProfile({
            next: (profile) => {
                if (profile) {
                    this.profile = profile;
                    this.createForm(this.profile);
                }
                this.busy = false;
            }
        });
    }
    ngOnDestroy() {
        if (this.profSubs) {
            this.profSubs.unsubscribe();
        }
    }
    createForm(profile) {
        let compliment = '';
        if (profile && profile.address && profile.address.extra) {
            compliment = profile.address.extra;
        }
        const fullAddr = {
            address: profile.address,
            coords: profile.coords
        };
        this.profileForm = new SubmitForm(this.fb, [
            {
                name: 'businessName',
                value: profile.businessName,
                validators: [Validators.required],
                type: 'text',
                title: this.localisation.localise('agency_setup_companyname'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_companyname_placeholder'),
                help: this.localisation.localise('agency_setup_companyname_help'),
                errors: [
                    {
                        code: 'required',
                        message: this.localisation.localise('agency_setup_error_required_field'),
                    },
                ]
            },
            {
                name: 'fulladdr',
                value: fullAddr,
                validators: [haveValideAddressValidator('fulladdr')],
                type: 'text',
                title: this.localisation.localise('agency_setup_address'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_address_placeholder'),
                help: this.localisation.localise('agency_setup_address_help'),
                errors: [
                    {
                        code: 'required',
                        message: this.localisation.localise('agency_setup_error_required_field'),
                    },
                    {
                        code: 'validAddress',
                        message: this.localisation.localise('agency_setup_error_valid_address'),
                    },
                ]
            },
            /*
            {
              name: 'siret',
              value: profile.telephone,
              validators: [],
              type: 'text',
              title: this.localisation.localise('agency_setup_siret'),
              autocomplete: '',
              placeholder: this.localisation.localise('agency_setup_siret_placeholder'),
              help: this.localisation.localise('agency_setup_siret_help'),
              errors: [
                <ISubmitFormInputErrors>{
                  code: 'required',
                  message: this.localisation.localise('agency_setup_error_required_field'),
                },
              ]
            },
             */
            {
                name: 'extra',
                value: compliment,
                validators: [],
                type: 'text',
                title: this.localisation.localise('agency_setup_addresscompl'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_addresscompl_placeholder'),
                help: this.localisation.localise('agency_setup_addresscompl_help'),
                errors: []
            },
            {
                name: 'telephone',
                value: profile.telephone,
                validators: [Validators.required],
                type: 'text',
                title: this.localisation.localise('agency_setup_tel'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_tel_placeholder'),
                help: this.localisation.localise('agency_setup_tel_help'),
                errors: [
                    {
                        code: 'required',
                        message: this.localisation.localise('agency_setup_error_required_field'),
                    },
                ]
            },
            {
                name: 'email',
                value: profile.email,
                validators: [Validators.required],
                type: 'email',
                title: this.localisation.localise('agency_setup_email'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_email_placeholder'),
                help: this.localisation.localise('agency_setup_email_help'),
                errors: [
                    {
                        code: 'required',
                        message: this.localisation.localise('agency_setup_error_required_field'),
                    },
                    {
                        code: 'email',
                        message: this.localisation.localise('agency_setup_error_invalid_email'),
                    },
                ]
            },
            /*
            {
              name: 'budget',
              value: profile.budget,
              validators: [Validators.required, Validators.min(0.1)], // Validators.pattern (/ ^ [+]? ([0-9] + (?: [\.] [0-9] *)? | \. [0- 9] +) $ /)
              type: 'number',
              title: this.localisation.localise('account_budget'),
              autocomplete: 'off',
              placeholder: this.localisation.localise('account_budget_placeholder'),
              help: this.localisation.localise('account_budget_help'),
              disabled: true,
              errors: [
                <ISubmitFormInputErrors>{
                  code: 'required',
                  message: this.localisation.localise('agency_setup_error_required_field'),
                },
                <ISubmitFormInputErrors>{
                  code: 'min',
                  message: 'Minimum de 0.1€ obligatoire',
                },
              ]
            },
             */
            // {
            //   name: 'subscription',
            //   value: profile.subscription,
            //   validators: [Validators.required], //Validators.pattern (/ ^ [+]? ([0-9] + (?: [\.] [0-9] *)? | \. [0- 9] +) $ /)
            //   type: 'text',
            //   title: this.localisation.localise('sub_subscription'),
            //   autocomplete: 'off',
            //   placeholder: this.localisation.localise('account_budget_placeholder'),
            //   help: this.localisation.localise('account_budget_help'),
            //   disabled: true,
            //   errors: [
            //     <ISubmitFormInputErrors>{
            //       code: 'required',
            //       message: this.localisation.localise('agency_setup_error_required_field'),
            //     },
            //     <ISubmitFormInputErrors>{
            //       // code: 'min',
            //       // message: 'Minimum de 0.1€ obligatoire',
            //     },
            //   ]
            // },
            {
                name: 'coords',
                value: profile.coords,
                validators: [Validators.required],
                type: 'text',
                title: '',
                autocomplete: '',
                placeholder: '',
                help: '',
                errors: []
            },
            {
                name: 'address',
                value: profile.address,
                validators: [Validators.required],
                type: 'text',
                title: '',
                autocomplete: '',
                placeholder: '',
                help: '',
                errors: []
            }
        ], 
        // Submit callback
        (changes) => {
            this.busy = true;
            const pro = this.updateData();
            return this.hiero.Particulier.UpdateProfile(pro);
        }, 
        // Success callback
        () => {
            this.busy = false;
        }, 
        // Fail callback
        err => {
            // What to do with login failuer
            console.error(err);
            this.busy = false;
        }, 
        // Changes callback
        null);
    }
    onAddressSelected(address) {
        const oldAddress = this.profileForm.GetValue('address');
        if (oldAddress && oldAddress.extra) {
            address.address.extra = oldAddress.extra;
        }
        this.profileForm.SetValue('coords', address.coords);
        this.profileForm.SetValue('address', address.address);
    }
    updateData() {
        const professionnel = {
            uid: this.profile.uid,
            businessName: this.profileForm.GetValue('businessName'),
            email: this.profileForm.GetValue('email'),
            telephone: this.profileForm.GetValue('telephone'),
            address: this.profileForm.GetValue('address'),
            coords: this.profileForm.GetValue('coords'),
            partenaire: this.profile.partenaire,
        };
        professionnel.address.extra = this.profileForm.GetValue('extra');
        return professionnel;
    }
    manualAddress() {
        return __awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modalService.open(AppModalManualAddressComponent, {
                centered: true,
                backdrop: 'static',
                keyboard: false
            });
            modalRef.componentInstance.address = this.profileForm.GetValue('address');
            modalRef.componentInstance.coords = this.profileForm.GetValue('coords');
            let result = false;
            try {
                result = yield modalRef.result;
            }
            catch (_a) { }
            if (result) {
                this.busy = true;
                try {
                    const trad = this.updateData();
                    const extra = trad.address.extra;
                    trad.address = result.address;
                    trad.address.extra = extra;
                    trad.coords = result.coords;
                    this.createForm(trad);
                    this.profileForm.CheckValidity();
                }
                catch (err) {
                    console.error(err.message);
                }
                finally {
                    this.busy = false;
                }
            }
        });
    }
}
