<p class="ml-3">{{ 'agency_ticket_head' | localise }}</p>
<div class="d-flex">
  <div class="col-md-6">
    <form class="mt-3 container" [formGroup]="sendTicket" (ngSubmit)="onSendTicket()">
     <p class="mt-3">{{ 'main_menu_left_section_navigation_ticket' | localise}}</p>
      <div class="row">
        <div class="col">
          <button disabled>{{ 'agency_ticket_objet' | localise}}</button>
          <input type="text" class="form-control" formControlName="objet">
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <button disabled class="monBtn">{{ 'agency_ticket_question' | localise}}</button>
          <textarea rows="10" cols="33" class="form-control" formControlName="questions">

          </textarea>
        </div>
      </div>
      <div class="m-3 d-flex justify-content-between">
        <div style="color:#f4f4f4">
          <label for="file-upload" class="custom-file-upload btn" >
            <i class="fa fa-paperclip"></i> &nbsp; <span style="color: black">{{'add_file' | localise }}</span>
            <input id="file-upload" type="file"  (change)="onUploadFile($event)" name="file"/>
          </label>
          <p style="color: #1b1f24">{{ filename }}</p>
        </div>
        <div>
          <button class="sendBtn btn text-white" type="submit" [disabled]="sendTicket.invalid" (click)="getCurrentUserData()">Envoyer</button>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-6 mt-3">
    <div class="col-md-3">
      <div class="card" style="width: 32.2rem;">
        <div class="card-body">
          <h5 class="card-title">{{ 'agency_support' | localise}}</h5>
          <div class="text-right">
            <h6 class="card-subtitle mb-2 txt">{{ 'agency_support_service_client' | localise}}</h6>
            <p class="card-text"><strong>{{'support_tel' | localise }}</strong></p>
            <p>{{ 'agency_support_ouverture' | localise}}</p>
          </div>
          <p>{{ 'agency_support_questions' | localise }} <a href="mailto:contact@hiero-support.com">contact@hiero-support.com</a></p>
        </div>
      </div>
    </div>

    <div class="col-md-3 mt-4">
      <div class="card" style="width: 32.2rem;">
        <div class="card-body">
          <h5 class="card-title">{{ 'agency_support_guide_pro' | localise}}</h5>
          <!-- <h6 class="card-subtitle mb-2 ">Numéro de téléphone- Services Clients</h6> -->
          <div class="text-right text-justify">
            <p class="card-text text-justify">{{ 'agency_support_guide_p' | localise}}</p>
            <p> <a href="#">{{ 'agency_support_download_guide' | localise}}</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row m-3">
  <div class="col-8 mytab">
    <h3>{{ 'agency_ticket_title' | localise}}</h3>
    <table class="table table-striped">
    <thead>
      <tr>
        <!--<th scope="col">Ticket n°</th>-->
        <th scope="col">{{ 'section_date' | localise }}</th>
        <th scope="col">{{'agency_ticket_state' | localise}}</th>
        <th scope="col">{{ 'agency_ticket_objet' | localise}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ticket of ticketList ; let i = index">
        <!--<td>#{{ticket.numero}}</td>-->
        <td class="align-middle badge" style="background-color: #2d4054;">{{ticket.data.today}}</td>
        <td class="align-middle">
          <span *ngIf="!ticket.state">{{'loading' | localise }}</span>
          <span *ngIf="ticket.state">{{'terminer' | localise }}</span>
        </td>
        <td class="align-middle">{{ticket.data.objet}}</td>
        <td>
          <button type="button" class="btn"  style="background-color:black; color:white" [routerLink]="[ticket.id]">
            {{ 'section_consulter' | localise}}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</div>



<!-- Button trigger data-bs-toggle="modal" data-bs-target="#exampleModal" modal  [routerLink]="['/ticket', i]" -->


