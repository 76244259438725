import {Component, OnInit} from '@angular/core';
import * as firebase from 'firebase';
import {ActivatedRoute, Router} from '@angular/router';
import {TicketServiceService} from '../ticket-service.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-single-ticket',
  templateUrl: './single-ticket.component.html',
  styleUrls: ['./single-ticket.component.scss']
})
export class SingleTicketComponent implements OnInit {
  msgForm: FormGroup;
  singleTicket: any;
  loadTicket = [];

  fileUploading = false;
  fileUploaded = false;
  fileAdded: any[] = [];
  filename: string;

  constructor(private route: ActivatedRoute, private ticketService: TicketServiceService, private router: Router) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.has('id');
    this.route.paramMap.subscribe(async paramMap => {
      if (!paramMap.has('id')) {
        // Redirect
        this.router.navigate(['/ticket']);
        return;
      }
      const docId = paramMap.get('id');
      this.loadTicket = await this.getSingleTicket(docId);
    });
   // this.getSingleTicket(id).then(array => this.loadTicket = array );

    this.msgForm = new FormGroup({
      message: new FormControl(null, {
        validators: [Validators.required]
      })
    });
  }

  async getSingleTicket(documentID: string) {
    const db = firebase.firestore().collection('tickets').doc(documentID);
    const data = await db.get();
    const ticketData = [];
    ticketData.push(data.data());
    return ticketData;
  }

  onUploadFile(event) {
    this.filename = event.srcElement.files[0].name;
    this.fileUploading = true;
    this.ticketService.uploadFile(event.target.files[0]).then(
      (url: string) => {
        this.fileAdded.push(url);
        this.fileUploading = false;
        this.fileUploaded = true;
        setTimeout(() => {
          this.fileUploaded = false;
        }, 5000);

      }
    );
  }

  onSendMsg() {
    if (this.msgForm.invalid) {
      return ;
    }
    // console.log(this.msgForm.value.message);
    this.msgForm.reset();
  }
}
