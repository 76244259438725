var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketServiceService } from '../ticket-service.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
export class SingleTicketComponent {
    constructor(route, ticketService, router) {
        this.route = route;
        this.ticketService = ticketService;
        this.router = router;
        this.loadTicket = [];
        this.fileUploading = false;
        this.fileUploaded = false;
        this.fileAdded = [];
    }
    ngOnInit() {
        const id = this.route.snapshot.paramMap.has('id');
        this.route.paramMap.subscribe((paramMap) => __awaiter(this, void 0, void 0, function* () {
            if (!paramMap.has('id')) {
                // Redirect
                this.router.navigate(['/ticket']);
                return;
            }
            const docId = paramMap.get('id');
            this.loadTicket = yield this.getSingleTicket(docId);
        }));
        // this.getSingleTicket(id).then(array => this.loadTicket = array );
        this.msgForm = new FormGroup({
            message: new FormControl(null, {
                validators: [Validators.required]
            })
        });
    }
    getSingleTicket(documentID) {
        return __awaiter(this, void 0, void 0, function* () {
            const db = firebase.firestore().collection('tickets').doc(documentID);
            const data = yield db.get();
            const ticketData = [];
            ticketData.push(data.data());
            return ticketData;
        });
    }
    onUploadFile(event) {
        this.filename = event.srcElement.files[0].name;
        this.fileUploading = true;
        this.ticketService.uploadFile(event.target.files[0]).then((url) => {
            this.fileAdded.push(url);
            this.fileUploading = false;
            this.fileUploaded = true;
            setTimeout(() => {
                this.fileUploaded = false;
            }, 5000);
        });
    }
    onSendMsg() {
        if (this.msgForm.invalid) {
            return;
        }
        // console.log(this.msgForm.value.message);
        this.msgForm.reset();
    }
}
