/**
 * this component can be improve, it's a copy from commande component.
 * only different is getTraducteur function when assermentation is true
 */
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { NgbActiveModal, } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgModel } from '@angular/forms';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { Particulier } from '../../../../../../common/src/bdd/professionnel/Professionnel';
import { ParticulierServiceList } from '../../../../../../common/src/bdd/professionnel/ProfessionnelService';
import { IPrestation, EmptyPrestation } from '../../../../../../common/src/bdd/interfaces/IPrestation';
import { IDocument } from '../../../../../../common/src/bdd/interfaces/IDocument';
import { Prestation } from '../../../../../../common/src/bdd/prestation/Prestation';
import { IFile } from '../../../../../../common/src/bdd/interfaces/IFile';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { LocalisationService } from '../../../../../../common/src/modules/localisation/localisation.service';
import { IParticulier } from '../../../../../../common/src/bdd/interfaces/IProfessionnel';

interface IDeviceObeject {
  deviceStorageId: string;
  domwnlaodUrl?: string;
  name?: string;

}
export enum EnumPrestationState {
  Defining = 'defining',
  WaitingForTranslator = 'waiting-for-translator',
  CancelledByClient = 'cancelled-by-client',
  WaitingForPayment = 'waiting-for-payment',
  RefusedByTranslator = 'refused-by-translator',
  Translating = 'translating',
  WaitingForValidationFromClient = 'waiting-for-client-to-validate',
  Validated = 'validated'
}

@Component({
  selector: 'app-commande-assermente',
  templateUrl: './commande-assermente.component.html',
  styleUrls: ['./commande-assermente.component.scss']
})

export class CommandeAssermenteComponent implements OnInit {

  EmptyPrestation: IPrestation = {
    uid: '',
    state: EnumPrestationState.WaitingForTranslator,
    srcLanguageIso639: '',
    destLanguageIso639: '',
    srcCountryCode: '',
    documents: [],
    traducteurId: null,
    traducteur: null,
    price: {
      traducteurHT: 0,
      hieroMarginPercent: 0,
      hieroMarginValue: 0,
      subtotalHT: 0,
      tva: 0,
      tvaValue: 0,
      ttc: 0
    },

    deviceStorageId: '',

    sentToTranslatorAt: 0,
    cancelledByClientAt: 0,
    acceptedByTranslatorAt: 0,
    refusedByTranslatorAt: 0,
    dueAt: 0,
    paidAt: 0,
    completedAt: 0,
    validatedByClientAt: 0,
    lastModifiedAt: 0,
    createdAt: 0,
    isProfessionalPrestation: false,

  };

  EmptyDocument: IDocument = {
    documentTypeId: '',
    deviceStorageId: '',
    original: [],
    translated: []
  };
  EmptyIFile: IFile = {
    name: '',
    deviceStorageId: '',
    downloadURL: ''
  };

  mydeviceStorageId: string;
  deviceLastPathId = [];
  storage: firebase.storage.Storage;

  currentUser: any;
  professionnelId: any;
  professionelData: IParticulier;

  commandeForm: FormGroup;
  submitted: boolean;
  show = false;
  closeModalEvent = new EventEmitter<boolean>();
  showLoading = false;


  selectedFiles: FileList;
  priceTTC: any;
  FORFAIT: any;
  TVA = 1.2;
  TVAvaleur: any;
  priceHT: any;
  templateId: any;

  origin = undefined;
  originCountry = undefined;
  originDocument = undefined;
  fileDocument = undefined;
  traducteur: any;
  traducteurName: string;
  traducteurs = [];
  tradId: string;
  tradOut: any;
  progress: any;
  firebaseDownloadUrl: string;

  formValue: any;
  storageRef: firebase.storage.Reference;

  dbDocSend = [];
  countriesFiltered = [];
  destFiltered = [];
  documentFiltered = [];
  documentTypeMap: DocumentType;
  lang = [];

  tab: Array<IFile> = [];
  documentsFileUpload: any = [];
  documentsCast: IDeviceObeject;

  response: string;
  isChecked = false;
  @Input()
  pagesNumber: number = null;
  @Input()
  urgency: any = null;


  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private hiero: HieroBDD, private localisation: LocalisationService
  ) { }
  ngOnInit() {
    this.storage = this.hiero.Storage;
    this.createForm();
    this.getTranslatorServices();
    DocumentType.Init(this.hiero.Auth.User).then(
      (types: DocumentType) => {
        this.documentTypeMap = types;
      }
    );
  }

  createForm() {
    this.commandeForm = this.formBuilder.group({
      srcLang: ['', Validators.required],
      originLang: ['', Validators.required],
      destLang: ['', Validators.required],
      doctype: ['', Validators.required],
      description: [''],
      docFile: [[], Validators.required],
      docFilePageNumber: ['', Validators.min(0)],
      urgency: ['', ],

    });
  }


  async getTraducteurs({ srcName, destName }: { srcName: string; destName: string; }): Promise<firebase.firestore.DocumentData[]> {

    const traducteurServiceRef = await this.hiero.DB.collection('traducteur_services')
      .where('srcLanguageIso639', '==', srcName)
      .where('destLanguageIso639', '==', destName)
      .get();
    console.log('pays', this.originCountry);
    const datas = await traducteurServiceRef.docs.filter(doc => doc.data().exceptions) // qs[]
      .map(doc => doc.data());

    const templateIdRef = await this.hiero.DB.collection('templates')
      .where('documentTypeId', '==', this.fileDocument)
      .where('srcLanguageIso639', '==', srcName)
      .where('srcCountryCode', '==', this.originCountry)
      .where('destLanguageIso639', '==', destName)
      .get();
    const templateDataId = templateIdRef.docs.map(doc => doc.id);

    const templateData = templateIdRef.docs.map(doc => doc.data());
    // console.log( 'nom du template templatedata', templateData)
    // console.log( 'nom du template templatedataid', templateDataId) // tableau d'es id

    const isTemplateOk = async (exception) => {
      console.log('exception.templateId', exception.templateId); // id du document

      templateDataId.forEach(_ => {
        if (_ === exception.templateId) {
          // console.log('le id',id)
          this.templateId = exception.templateId;
          // console.log('traducteur avec le bon template', exception)
          // console.log('this.templateId', this.templateId)
          this.TVAvaleur = (exception.priceHT / 100) * 20;
          /**
           * when the document have mamy page
           */
          if (this.isChecked && this.pagesNumber != null && this.pagesNumber > 0) {
            this.priceTTC = (exception.priceHT + this.TVAvaleur + 0.33) * this.pagesNumber;
            this.priceHT = exception.priceHT * this.pagesNumber;

          } else {
            this.priceTTC = (exception.priceHT + this.TVAvaleur + 0.33) * this.selectedFiles.length;
            this.priceHT = exception.priceHT * this.selectedFiles.length;
          }

          // console.log('prix hors taxe', this.priceHT)
          // console.log('valeur tva =>', this.TVAvaleur)
          // console.log('nombe de fichiers =>', this.selectedFiles.length)
           console.log('Prix TTC', this.priceTTC);
          exception.priceHT = this.priceTTC;
          // return exception
        }
      });

    };

    const keepData = async (data) => {
      // data.exceptions = data.exceptions.filter(isPriceOK)
      // console.log('DATA from keepdata', data)
      // console.log('DATA.EXCEPTIONS from keepdata', data.exceptions)
      if (data.exceptions === 0 ) {
        // get default data from template document
        // console.log('templateData si le tableau est vide', data)
        const arr = [];
        arr.push({
          priceHT: templateData[0].priceHT,
          templateId: templateDataId[0]
        });
        data.exceptions = arr;
        // console.log('DATA.EXCEPTIONS dans mon condiftion', data)
      }
      if (data.exceptions.length > 0) {
        const found = data.exceptions.some(el => el.templateId === templateDataId);
        console.log('FOUUND ', found);
        if (!found) {
          // console.log('templateData si le tableau est vide', data)
          const arr = [];
          arr.push({
            priceHT: templateData[0].priceHT,
            templateId: templateDataId[0]
          });
          data.exceptions = arr;
           console.log('DATA.EXCEPTIONS dans mon condiftion', data);
        }
      }
      data.exceptions = data.exceptions.filter(isTemplateOk);
      // if(data.exceptions.length > 0) return data

    };

    const res = datas.filter(keepData); // .reduce((acc, val) => acc.concat(val), []);
    // console.table('tab', res);
    // console.table(res)
    return res;

  }
  /*
  async getTraducteursProfile() {
    //console.log('list des traducteurs => ', this.traducteurs)
    await this.hiero.DB.collection('traducteurs').get().then( snapshot => {
        this.tradOut = this.traducteurs
        this.traducteurs = this.tradOut.map( trad => snapshot.docs.find(
        elt => elt.id == trad.traducteurId
        )
        ).map(_ => _.data() )
      /*
      let acc = []
      for (let i = 0; i < tradOut.length; i++) {
          for (let j = 0; j < snapshot.docs.length; j++) {
            if(snapshot.docs[j].id == tradOut[i]){
              acc.push(snapshot.docs[j].data())
            }
          }
      }
      this.traducteurs = acc

      })

  }
  */

  getTraducteurId(): Promise<string> {
    const docRefTrad = this.hiero.DB.collection('traducteurs').where('businessName', '==', this.traducteurName);
    return docRefTrad.get().then(
      (snapshot) => {
        this.tradId = snapshot.docs[0].id;
        this.traducteur = snapshot.docs.find(elt => elt.id === this.tradId).data();
        return this.tradId;
      }
    );
  }

  async sendCommande() {
    this.showLoading = true;
    this.progress = 0;
    const pro: Particulier = new Particulier(this.currentUser, this.professionnelId);
    const commandes = new ParticulierServiceList(pro);
    this.EmptyDocument.documentTypeId = this.formValue.doctype.documentTypeId;
    // send data files to firestorage

    Object.keys(this.selectedFiles).map(obj => {
      const EmptyIFiles: IFile = {
        name: '',
        deviceStorageId: '',
        downloadURL: ''
      };
      EmptyIFiles.deviceStorageId = this.selectedFiles[obj].name;
      const id = this.currentUser.Id + '/' + this.currentUser.Id + '/' + this.currentUser.Id + '/' + EmptyIFiles.deviceStorageId;
      // this.storageRef = this.hiero.Storage.ref().child(this.currentUser.Id).child(this.currentUser.Id).child(this.traducteurName.replace(' ', '_'))
      this.storageRef = this.hiero.Storage.ref(id);
      console.log('STORAGE ', this.storageRef);
      console.log('OBJECT ', this.selectedFiles[obj]);

      this.storageRef.put(this.selectedFiles[obj])
        .on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            console.log('', );
            const float = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progress = float.toFixed();
            console.log('Upload is ' + this.progress + '% done');
            // console.log('id traducteur', this.tradId)
            this.storageRef.getDownloadURL().then(snap => {
              // console.log('Les liens de telechargement==>', snap)
              EmptyIFiles.downloadURL = snap;
            });
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                // this.loadingFile(this.progress)
                break;
            }
          }, (error) => {

          }, () => {
            this.loadingFile();
          }

        );
      EmptyIFiles.name = this.selectedFiles[obj].name;
      EmptyIFiles.type = this.selectedFiles[obj].type;
      // tslint:disable-next-line:no-unused-expression
      EmptyIFiles.deviceStorageId.replace(' ', '_') + obj;
      this.tab.push(EmptyIFiles);

    });

    // console.log('versionn', this.traducteurName)

    const data = this.traducteurs.filter(
      // tslint:disable-next-line:no-shadowed-variable
      (data) => {
        if (data.traducteur.businessName === this.traducteurName) {
          // console.log('versionn', data.exception.exceptions[0].priceHT)
          data.exception.exceptions.forEach((_) => {
            if (_.templateId === this.templateId ) {
              //   console.log('_.templateId', _.templateId)
              //   console.log('this.templateId',this.templateId)
              this.priceTTC = _.priceHT;
                console.log('price ttc new ', this.priceTTC);
            }
          });

          return data;
        }
      }
    );
    // data.filter((_) => { console.log('prix',_.exception.exceptions[0].priceHT)

    // })

    this.EmptyDocument.original = this.tab;

    const arrayDocuments = [];
    arrayDocuments[0] = this.EmptyDocument;
    // console.log('EN DEHORS DE MA BOUCLE  ACC =>', arrayDocuments)
    // console.log('Les traducteurs  =>', this.traducteurs)
    // console.log('Les traducteurs toto  =>', data)
    // console.log('prix ttc =>', this.priceTTC)
    this.EmptyPrestation.srcLanguageIso639 = this.formValue.srcLang.iso639;
    this.EmptyPrestation.price.ttc = this.priceTTC;
    this.EmptyPrestation.price.traducteurHT = this.priceHT;
    this.EmptyPrestation.price.tva = this.TVA;
    this.EmptyPrestation.price.tvaValue = this.TVAvaleur;
    this.EmptyPrestation.srcCountryCode = this.formValue.originLang.code;
    this.EmptyPrestation.destLanguageIso639 = this.formValue.destLang.iso639;
    this.EmptyPrestation.traducteurId = this.traducteurName;
    this.EmptyPrestation.documents = arrayDocuments;
    this.EmptyPrestation.traducteurId = this.tradId;
    this.EmptyPrestation.uid = this.tradId; // Id traductor
    this.EmptyPrestation.deviceStorageId = this.currentUser.Id; // id prestation device
    this.EmptyPrestation.traducteur = this.traducteur;
    this.EmptyPrestation.isProfessionalPrestation = true;



    // this.loadingFile()
    const newPrestation = await Prestation.Create(this.currentUser, this.EmptyPrestation);
    // console.log(' \t ID de la Prestation \t', newPrestation.Id)

    // data of facture
    await firebase.firestore().collection('facture_particuliers').add({
      numero: Math.floor((Math.random() * 1000000) + 1),
      date: new Date(),
      srcLang: this.formValue.srcLang.iso639,
      destLang: this.formValue.destLang,
      priceTTC: this.priceTTC,
      priceHT: this.priceHT,
      priceTVA: this.TVA,
      priceTVAVal: this.TVAvaleur,
      traducteur: this.tradId,
      traducteurName: this.traducteurName,
      pagesNumber: this.pagesNumber,
      prestation: newPrestation.Id,
      particulierId: this.professionnelId,
      typePrestation: this.localisation.localise('menu_label_traductor_asser')

    });

    // add information form professional command
    await commandes.addService(
      {
        srcLangISO639: this.formValue.srcLang.iso639,
        originLangISO369: this.formValue.originLang.code,
        destLangISO369: this.formValue.destLang.iso639,
        documentType: this.formValue.doctype.documentTypeId,
        docFile: this.documentsFileUpload,
        tradName: this.traducteurName,
        tradId: this.tradId,
        date: new Date(),
        prestaId: newPrestation.Id,
        presta: this.EmptyPrestation,
        pagesNumber: this.pagesNumber,
        urgency: this.urgency,
        description: ''

      });
  }

  public getTraducteurNameOnClickBunton(event: any) {
    // research traductor
    this.traducteurName = event.target.value;
    this.currentUser = this.hiero.Auth.User;
    this.getTraducteurId();
    const docRef = this.hiero.DB.collection('particuliers').where('uid', '==', this.currentUser.Id);
    docRef.get().then(
      (snapshot) => {
        this.professionnelId = snapshot.docs[0].id;
        this.professionelData = snapshot.docs[0].data() as IParticulier;
        // this.professionelData = snapshot.docs[0].data()
        if (this.traducteurName != null) {
          if (this.selectedFiles.length > 0) {
            // console.log('selected files =>', this.selectedFiles)
            // tslint:disable-next-line:forin
            for (const indexe in this.selectedFiles) {
              this.EmptyDocument.deviceStorageId = this.currentUser.Id;
              if (indexe === 'length') {
                // console.log('sort de la boucle et affiche indexe =>', indexe)
                this.sendCommande();
              }
            }
          }
        }
      }
    ).catch();

  }

  async onSubmitForm() {
    this.submitted = true;
    this.formValue = this.commandeForm.value;
    // this.formValue.docFilePageNumber = this.pagesNumber
    // console.log('', this.formValue)
    const traducteurListFilter = this.getTraducteurs({ srcName: this.formValue.srcLang.iso639, destName: this.formValue.destLang.iso639 });
    const traducteurRef = await this.hiero.DB.collection('traducteurs')
      .where('assermentation', '==', 'true')
      .get();

    const traductorsFound = await traducteurListFilter.then(data => data.map(TraductorsFoundWithExecption => {
        // console.log('les ids datas de l exception', TraductorsFoundWithExecption )
        return TraductorsFoundWithExecption;
      }
    ));
    traducteurRef.docs.map(_ => {

        traductorsFound.forEach(exception => {
          if (exception.traducteurId === _.id && exception.exceptions.length > 0) {
            console.log('_.data', _.data());
            console.log('element', exception);
            const traducteur = _.data();
            // console.log('trad =>', traducteur);
            const dataMerge = { traducteur, exception };
            // console.log('data merge =>', dataMerge);
            console.log('MON OBJET GLOBAL ===>', dataMerge);
            this.traducteurs.push(dataMerge);
             console.table('translators => ', this.traducteurs);
          }
        });
      }
    );

    // this.getTraducteursProfile()
    this.showHtml();
  }

  detectFiles(files: FileList) {
    /*
    this.priceTTC = files.length * this.FORFAIT
    this.priceHT = (this.priceTTC / this.TVA).toFixed(2)
    this.TVAvaleur = (this.priceTTC - this.priceHT).toFixed(2)
    */
    /*
    this.priceHT = files.length * this.FORFAIT
    this.TVAvaleur = (this.priceHT /100) * 20
    this.priceTTC = this.priceHT + this.TVAvaleur
    */
    if (files.length > 0) {
      this.selectedFiles = files;
      // console.log('LES FILES SELECTIONNEES', this.selectedFiles)
      /*
      for(let i = 0; i < files.length; i++){
        this.selectedFiles[i] = files[i];
        */
    }
  }

  public getTranslatorServices() {

    const docRef = this.hiero.DB.collection('app_indexes').doc('services');

    return docRef.get().then(
      (snapshot) => {
        const data = snapshot.get('languages');
        this.lang = data;
      }
    ).catch(
    );
  }

  public getSelectedOrigin(event: any) {
    this.origin = event.iso639;
    this.countriesFiltered = this.lang.filter((l) => (l as any).iso639 === this.origin)
      .map(_ => (_ as any).countries)
      .reduce((acc, val) => acc.concat(val), []);
    // .map(_ =>  _.code)

    // console.log('countriesFiltered = >', this.countriesFiltered)
    return event;
  }

  public getSelectedOriginCountry(event: any) {

    this.originCountry = event.code;
    this.destFiltered = this.countriesFiltered.filter(l => l.code === this.originCountry)
      .map(_ => (_ as any).to)
      .reduce((acc, val) => acc.concat(val), []);
    // console.log("destFiltered => ", this.destFiltered)
    return event;
  }

  public getSelectedOriginDocument(event: any) {
    this.originDocument = event.iso639;
    this.documentFiltered = this.destFiltered.filter(_ => _.iso639 === this.originDocument)
      .map(_ => _.documents)
      .reduce((acc, val) => acc.concat(val), []);
    // console.log("list des templates => ", this.documentFiltered)
    // this.toto = this.documentFiltered

    return event;
  }

  public getSelectedListDocument(event: any) {
    // show button if filelistdocument it's not emplty
    this.fileDocument = event.documentTypeId;
    console.log('lol', this.fileDocument );
  }

  public documentPagesNumbers(event) {
    // console.log('Event =>', event.target.checked)
    const element = <HTMLInputElement>document.getElementById('docFilePageNumber');
    const multipleChoose = document.getElementById('docFile');
    const divHide = document.getElementById('hiddenId');
    if (event.target.checked) {
      // console.log('Value Multi=>', multipleChoose)
      this.isChecked = event.target.checked;
      multipleChoose.removeAttribute('multiple');
      divHide.classList.remove('my_hidden');

      // console.log('Value =>', element)

      this.pagesNumber = +element.value;
      // console.log('dans le if du if =>', this.pagesNumber)
      // console.log('dans le if =>', this.pagesNumber)

    } else if (!event.target.checked) {
      this.isChecked = false;
      divHide.classList.add('my_hidden');
      multipleChoose.setAttribute('multiple', '');
      this.pagesNumber = null;
      // console.log('dans le else this.pagesnumber=>', this.pagesNumber)
    }
    // console.log('dehors this.pagesnumber=>', this.pagesNumber)

  }

  public getUrgency(event) {
    this.urgency = event;
    return event;
  }

  public showHtml() {
    if (this.traducteurs != null) {
      this.show = true;
    }
  }

  public loadingFile() {
    this.show = false;
    this.showLoading = true;
    Swal.fire({
      title: this.localisation.localise('reset_password_send')
    }).then(() => {
      this.activeModal.close();
    });

  }

  getDocumentName(docIdType: string) {
    if (this.documentTypeMap) {
      return this.documentTypeMap.GetDocumentName(this.localisation.CurrentLanguageISO639, docIdType);
    } else {
      return '';
    }
  }

}
