<div class="row ml-4 d-flex justify-content-between container-fluid">
  <div class="col-md-3 card text-center">
    <div class="card-body">
      <div class="mt-3">
        <!--<input type="file" id="inputGroupFile01" (change)="onUploadFile($event)" accept="image/*"> -->
        <input
          type="file"
          class="file-input"
          (change)="onFileSelected($event)"
          #fileUpload
          accept="image/x-png,image/gif,image/jpeg"
        />
        <img
          *ngIf="photo == null"
          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
          alt="imageProfile"
          class="img-fluid border"
          (click)="fileUpload.click()"
        />
        <img
          class="img-fluid border"
          *ngIf="photo"
          [src]="photo"
          alt="imageProfile"
          (click)="fileUpload.click()"
        />
      </div>
      <div>
        <p *ngIf="errorImg" class="text-danger mt-2">
          {{ "modif_profile_failed_pfp" | localise }}
        </p>
      </div>
      <div class="mt-5">
        <h3>
          {{ profileData.givenName }} {{ profileData.familyName | uppercase }}
        </h3>
      </div>
    </div>
    <div class="card-footer">
      <p class="text-center">
        {{ "inscription_date" | localise }}
        {{ user.metadata.creationTime | date: "dd/MM/yyyy" }}
      </p>
    </div>
  </div>

  <div class="col-md-8 card mr-5">
    <div class="m-3">
      <div class="d-flex justify-content-between">
        <div>
          <h5>{{ "my_info" | localise }}</h5>
          <form [formGroup]="profileForm" (ngSubmit)="submit()">
            <div class="d-flex">
              <div class="form-group mr-2">
                <label class="sr-only" for="familyName">{{
                  "account_surname" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_surname" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="familyName"
                    formControlName="familyName"
                    [placeholder]="'account_surname_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('familyName').hasError('required') &&
                    profileForm.get('familyName').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
              <div class="form-group">
                <label class="sr-only" for="givenName">{{
                  "account_name" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_name" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="givenName"
                    formControlName="givenName"
                    [placeholder]="'account_name_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('givenName').hasError('required') &&
                    profileForm.get('givenName').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="sr-only" for="address">{{
                "account_adresse" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_adresse" | localise }}
                  </div>
                </div>
                <input
                  id="address"
                  type="text"
                  class="form-control"
                  [ngbTypeahead]="search"
                  [inputFormatter]="formatter"
                  [resultFormatter]="formatter"
                  [editable]="false"
                  formControlName="address"
                  [placeholder]="'account_adresse_placeholder' | localise"
                />
                <small id="address" class="form-text text-muted">
                  {{ "agency_setup_address_help" | localise }}
                </small>
              </div>
              <div
                *ngIf="
                  profileForm.get('address').hasError('required') &&
                  profileForm.get('address').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="sr-only" for="address2">{{
                "account_complement_adr" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_complement_adr" | localise }}
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="address2"
                  formControlName="address2"
                  [placeholder]="
                    'account_complement_adr_placeholder' | localise
                  "
                />
              </div>
            </div>
            <div class="form-group">
              <label class="sr-only" for="email">{{
                "account_email" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_email" | localise }}
                  </div>
                </div>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  [placeholder]="'account_email_placeholder' | localise"
                />
              </div>
              <div
                *ngIf="
                  profileForm.get('email').hasError('required') &&
                  profileForm.get('email').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
              <div
                *ngIf="
                  profileForm.get('email').hasError('email') &&
                  profileForm.get('email').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_invalid_email" | localise }}
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="sr-only" for="tel">{{
                "account_telephone" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_telephone" | localise }}
                  </div>
                </div>
                <input
                  type="number"
                  class="form-control"
                  id="tel"
                  formControlName="tel"
                  [placeholder]="'account_telephone_placeholder' | localise"
                />
              </div>
              <div
                *ngIf="
                  profileForm.get('tel').hasError('required') &&
                  profileForm.get('tel').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
            </div>
            <div>
              <button
                class="btn my-btn"
                [disabled]="profileForm.invalid || !active"
              >
                {{ "account_save" | localise }}
              </button>
            </div>
            <div>
              <p *ngIf="updated" class="text-success mt-2">
                {{ "modif_profile_success" | localise }}
              </p>
              <p *ngIf="error" class="text-danger mt-2">
                {{ "modif_profile_failed" | localise }}
              </p>
              <p *ngIf="errorLogin" class="text-danger mt-2">
                {{ modif_profile_failed_login_attempts | localise }}
              </p>
            </div>
          </form>
        </div>

        <div class="col-md-6">
          <h6 class="text-right">
            {{ "agency_expl_2_title" | localise }}
            <i class="fa fa-map-marker"> </i>
          </h6>
          <small class="mt-1 mb-3 form-text text-muted text-right">
            {{ "geolocation_paragraphe" | localise }}
          </small>
          <h6 class="text-right">
            {{ "update_password" | localise }}
            <i class="fa fa-key"> </i>
          </h6>
          <small class="mt-1 mb-3 form-text text-muted text-right">
            {{ "account_upt" | localise }}
          </small>
          <form [formGroup]="passwordForm" (ngSubmit)="submitPwd()">
            <div class="form-group">
              <label class="sr-only" for="password">{{
                "account_password" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_password" | localise }}
                  </div>
                </div>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  formControlName="password"
                />
              </div>
              <div
                *ngIf="
                  passwordForm.get('password').hasError('required') &&
                  passwordForm.get('password').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="sr-only" for="newPassword">{{
                "account_new_password" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_new_password" | localise }}
                  </div>
                </div>
                <input
                  type="password"
                  class="form-control"
                  id="newPassword"
                  formControlName="newPassword"
                />
              </div>
              <div
                *ngIf="
                  passwordForm.get('newPassword').hasError('required') &&
                  passwordForm.get('newPassword').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
              <div
                *ngIf="
                  passwordForm.get('newPassword').hasError('minlength') &&
                  passwordForm.get('newPassword').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_min_password" | localise }}
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="sr-only" for="newPassword2">{{
                "account_confirm" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_confirm" | localise }}
                  </div>
                </div>
                <input
                  type="password"
                  class="form-control"
                  id="newPassword2"
                  formControlName="newPassword2"
                />
              </div>
              <div
                *ngIf="
                  passwordForm.get('newPassword2').hasError('required') &&
                  passwordForm.get('newPassword2').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
              <div
                *ngIf="
                  passwordForm.get('newPassword2').hasError('mustMatch') &&
                  passwordForm.get('newPassword2').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_passwords_do_not_match" | localise }}
                </small>
              </div>
            </div>
            <div>
              <button class="btn my-btn" [disabled]="passwordForm.invalid">
                {{ "account_save" | localise }}
              </button>
            </div>
            <div>
              <p *ngIf="updatedPwd" class="text-success mt-2">
                {{ "modif_profile_success_pwd" | localise }}
              </p>
              <p *ngIf="errorPwd" class="text-danger mt-2">
                {{ "modif_profile_failed_pwd" | localise }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row ml-4 container-fluid mt-3">
  <div class="col-md-3 card p-2">
    <div class="mx-auto">
      <h6 class="mt-3 text-center">{{ "delete_account" | localise }}</h6>
      <p class="m-1 text-justify">{{ "account_paragraphe1" | localise }}</p>
      <p class="m-1 text-justify">{{ "account_paragraphe2" | localise }}</p>
      <p class="m-1 text-justify">{{ "account_paragraphe3" | localise }}</p>
      <div class="text-center">
        <button class="btn mb-1 my-btn" (click)="deleteAccount()">
          {{ "account_delete" | localise }}
        </button>
      </div>
    </div>
  </div>
</div>
