import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalisationService} from '../../../../../common/src/modules/localisation/localisation.service';


@Component({
  selector: 'app-tuto-salon',
  templateUrl: './tuto-salon.component.html',
  styleUrls: ['./tuto-salon.component.scss']
})
export class TutoSalonComponent implements OnInit {

  video_url = 'https://video-app-7531-1291-dev.twil.io?passcode=16318775311291';
  show = false;
  showLoading = false;

  constructor(public activeModal: NgbActiveModal,  private localisation: LocalisationService) { }

  ngOnInit() {
  }

  copyContent(val: string) {
    const selectBox = document.createElement('textarea');
    selectBox.style.position = 'fixed';
    selectBox.style.left = '0';
    selectBox.style.top = '0';
    selectBox.style.opacity = '0';
    selectBox.value = val;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);
    this.loadingFile();
  }

  getRoom() {
    window.open(this.video_url);
  }

  public loadingFile() {
    this.show = false;
    this.showLoading = true;
    Swal.fire({
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500,
      title: this.localisation.localise('copy_alert')
    });
    /*
    .then(() => {

     setTimeout(() => {
       // console.log('okkkkkkkkkkkkkkkkkkkkkkkkkk');
     }, 1000);
    })
     */

  }
}
