<div *ngIf="commande" class="mb-1">
  <div class="my-div border">
    <p class="clickable m-2" [routerLink]="['/app/commandes']">
      {{ "section_order_title" | localise }} /
      <span class="txt">
        {{ "commande_numero" | localise }} {{ commande.prestationId }}
      </span>
    </p>
  </div>
  <div class="row container-fluid mt-3 justify-content-between">
    <div class="col-md-7">
      <div class="row border">
        <div class="my-p p-3">
          <h4 class="mb-3">
            {{ "progress_commande" | localise }}
          </h4>
          <button class="btn my-btn btn-default mr-3">
            {{ "status_orders" | localise }}
          </button>
          <span class="my-txt">{{
            commande.prestationInfo.state | prestationStateName
          }}</span>
          <p class="my-text mt-3">
            {{ "single_commande_paragraphe" | localise }}
          </p>
          <div
            *ngIf="commande.prestationInfo.state === 'waiting-for-payment'"
            class="m-1 d-flex flex-row-reverse"
          >
            <button
              class="btn my-btn1"
              (click)="
                pay(
                  commande.prestationInfo.price.ttc * 100,
                  commande.documentType,
                  commande.prestationId
                )
              "
            >
              {{ "finaliser_pay" | localise }}
            </button>
          </div>
          <div
            *ngIf="
              commande.prestationInfo.state === 'waiting-for-client-to-validate'
            "
            class="m-1 d-flex flex-row-reverse"
          >
            <button class="btn my-btn1">
              {{ "finaliser_commande" | localise }}
            </button>
          </div>
        </div>
      </div>
      <div class="row border mt-3">
        <div class="my-p p-3">
          <h4 class="mb-3">
            {{ "intervention_details" | localise }}
          </h4>
          <p>
            <span>{{ " commande_receive" | localise }} </span> :
            <span
              >{{ commande.createdAt.toDate() | date: "dd/MM/yyyy" }}
              {{ commande.createdAt.toDate() | date: "hh:mm" }}
            </span>
          </p>
          <p>
            <span>{{ "table_header_type_de_document" | localise }}</span> :
            <span>{{ commande.documentType }}</span>
          </p>
          <p>
            <span>{{ "doc_a_traduire" | localise }}</span>
            <span>
              {{ commande.prestationInfo.documents[0].original.length }}</span
            >
          </p>
          <p>
            <span>{{ "table_header_nb_pages" | localise }} </span>
            <span *ngIf="commande.pagesNumber">
              {{ commande.pagesNumber }}</span
            >
            <span *ngIf="!commande.pagesNumber"> 1 </span>
          </p>
        </div>
        <div class="m-auto">
          <p>
            <span>{{ "trad_asser" | localise }} : </span>
            <span
              *ngIf="
                commande.prestationInfo.traducteur.assermentation === true ||
                commande.prestationInfo.traducteur.assermentation === 'true'
              "
            >
              {{ "prestation_document_confirm_oui" | localise }}
            </span>
            <span *ngIf="!commande.prestationInfo.traducteur.assermentation">{{
              "prestation_document_confirm_non" | localise
            }}</span>
          </p>
          <p *ngIf="commande.originLanguage != 'classic'">
            <span>{{ "pays_origine" | localise }} : </span>
            <span>{{ commande.prestationInfo.srcCountryCode | country }} </span>
          </p>
          <p>
            <span>{{ "menu_langue" | localise }} : </span>
            <span
              >{{ commande.srcLanguageIso639 | iso639ToNative }}
              {{ "services_dst_lang" | localise }}
              {{ commande.destLanguageIso639 | iso639ToNative }}</span
            >
          </p>
          <p>
            <span>{{ "total_ht" | localise }} : </span>
            <span>{{ commande.prestationInfo.price.traducteurHT }} €</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4 border">
      <div class="my-p p-3">
        <div class="mb-3">
          <h4>{{ "prestation_order_messaging_title" | localise }}</h4>
        </div>
        <p class="discuss">{{ "commande_discuss" | localise }}</p>
        <form class="mt-2" [formGroup]="sendmessage" (ngSubmit)="sendMsg()">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Tapez votre message"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              formControlName="message"
            />
            <button class="btn my-btn" type="button" id="button-addn">
              {{ "send" | localise }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="row container-fluid mt-3">
    <div class="col border p-3">
      <div class="mb-3">
        <h4>{{ "visioner_doc" | localise }}</h4>
      </div>
      <p>
        <span>{{ "auth_doc" | localise }}</span> <br />
        <span>{{ "doc_bug" | localise }}</span>
      </p>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              {{ "prestation_order_document_type" | localise }}
            </th>
            <th scope="col">{{ "visioner_doc" | localise }}</th>
            <th scope="col">{{ "progress_commande" | localise }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="myTD">{{ commande.documentType }}</td>
            <td class="downloadUrl">
              <div
                class="btn-group ml-1"
                *ngFor="
                  let doc of commande.prestationInfo.documents[0].original
                "
              >
                <button
                  class="btn btn-outline-primary"
                  (click)="getFile(doc.deviceStorageId)"
                >
                  <i class="fa fa-file-pdf-o"></i>
                  <!-- {{ "section_consulter" | localise }} -->
                  {{ doc.deviceStorageId }}
                </button>
              </div>
            </td>
            <td
              class="my-td"
              *ngIf="
                commande.prestationInfo.state ===
                'waiting-for-client-to-validate'
              "
            >
              {{ "state_translated_order" | localise }}
              <div
                class="btn-group ml-1"
                *ngFor="
                  let doc of commande.prestationInfo.documents[0].translated
                "
              >
                <button
                  class="btn btn-outline-primary"
                  (click)="getFile(doc.deviceStorageId)"
                >
                  <i class="fa fa-file-pdf-o"></i>
                  <!-- {{ "section_consulter" | localise }} -->
                  {{ doc.deviceStorageId }}
                </button>
              </div>
            </td>
            <td
              class="my-td"
              *ngIf="
                commande.prestationInfo.state !=
                'waiting-for-client-to-validate'
              "
            >
              {{ commande.prestationInfo.state | prestationStateName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
