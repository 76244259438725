import { Injectable } from "@angular/core";
import * as firebase from 'firebase';
import { BehaviorSubject } from "rxjs";



@Injectable() export class SubscriptionProfessionnal {

    // subscription: BehaviorSubject<any>
    private user = firebase.auth().currentUser
    private dataService = new BehaviorSubject<any>(null)
    observer = this.dataService.asObservable()

    firebaseMyTranslatorSub = firebase.functions().httpsCallable('myProfessionnalSubsciption')
    constructor() {
        // // this.subscription = new BehaviorSubject(null)
        // console.log('user', this.user.email)
        // this.firebaseMyTranslatorSub(this.user.email).then((reponse) => {
        //     console.log('AA =>', reponse.data)
        //     return reponse.data
        // })
    }

    public GetSubscription() {
        console.log('user', this.user.email)
        this.firebaseMyTranslatorSub(this.user.email).then((reponse) => {
            console.log('AA =>', reponse.data)
            this.dataService.next(reponse.data) //reponse.data
        })
    }


}