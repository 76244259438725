var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
const FIREBASE_COLLECTION = 'particuliers_commandes';
export class ParticulierService {
    constructor(particulier, docId, data) {
        this._particulier = particulier;
        this._docId = docId;
        this._data = data;
    }
    get Id() {
        return this._docId;
    }
    get data() {
        return this._data;
    }
    get Particulier() {
        return this._particulier;
    }
}
export class ParticulierServiceList {
    constructor(particulier) {
        this._particulier = particulier;
        this._services = [];
        this._servicesSubject = new BehaviorSubject(this._services);
        const query = particulier.User.DB.collection(FIREBASE_COLLECTION).where('particulierId', '==', particulier.Id);
        query.onSnapshot({
            next: (snapshort) => {
                this._services = [];
                snapshort.forEach((result) => {
                    const data = result.data();
                    const cmd = new ParticulierService(particulier, result.id, data);
                    this._services.push(cmd);
                });
            }
        });
        // tslint:disable-next-line:label-position no-unused-expression
        error: (error) => {
        };
        try {
            query.get();
        }
        catch (err) {
            console.warn(err);
        }
    }
    // list commande
    WatchParticulierServiceList(observer) {
        return this._servicesSubject.subscribe(observer);
    }
    // Add commande
    addService({ srcLangISO639, originLangISO369, destLangISO369, documentType, docFile, tradName, tradId, date, prestaId, presta, pagesNumber, urgency, description }) {
        return __awaiter(this, void 0, void 0, function* () {
            const commande = {
                particulierId: this._particulier.Id,
                srcLanguageIso639: srcLangISO639,
                originLanguage: originLangISO369,
                destLanguageIso639: destLangISO369,
                documentType: documentType,
                docFile: docFile,
                traducteurName: tradName,
                traducteurId: tradId,
                createdAt: date,
                prestationId: prestaId,
                prestationInfo: presta,
                pagesNumber: pagesNumber,
                urgency: urgency,
                description: description,
            };
            const exist = this._services.find((service) => {
                return (service.data.srcLanguageIso639 === srcLangISO639 &&
                    service.data.destLanguageIso639 === destLangISO369 &&
                    service.data.originLanguage === originLangISO369 &&
                    service.data.documentType === documentType &&
                    service.data.docFile === docFile &&
                    service.data.traducteurName === tradName);
            });
            if (exist) {
                throw Error('This order service already exist');
            }
            yield this._particulier.User.DB.collection(FIREBASE_COLLECTION).add(commande);
        });
    }
    removeService(prestationId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._particulier.User.DB.collection(FIREBASE_COLLECTION).doc(prestationId).delete();
        });
    }
    getSrcLanguages(particulier) {
        const documentTypeRef = particulier.User.DB.collection('app_indexes').doc('services');
        documentTypeRef.get().then((snapshot) => {
            const lang = snapshot.get('languages');
            // console.log(lang);
            /*
            for(const key in data){
              const value = data[key];
              return (value['iso639']);
            }
            */
        }).catch();
    }
    // brin test
    getList(particulier) {
        return __awaiter(this, void 0, void 0, function* () {
            {
                const query = particulier.User.DB.collection(FIREBASE_COLLECTION).where('particulierId', '==', particulier.Id);
                const results = yield query.get();
                if (results) {
                    return (results.size > 0);
                }
                else {
                    return false;
                }
            }
        });
    }
    static Init(particulier) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(new ParticulierServiceList(particulier));
        });
    }
    static HasService(particulier) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = particulier.User.DB.collection(FIREBASE_COLLECTION).where('particulierId', '==', particulier.Id);
            const results = yield query.get();
            if (results) {
                return (results.size > 0);
            }
            else {
                return false;
            }
        });
    }
}
