import {User} from '../user/User';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';
import {PartialObserver} from 'rxjs';
import {EmptyParticulierProfile, IParticulier} from '../interfaces/IProfessionnel';
import { ParticulierServiceList } from './ProfessionnelService';

const FIREBASE_COLLECTION_PARTICULIER = 'particuliers';



export class Particulier {

    constructor( user: User, docId: string) {
        this._user = user;
        this._docId = docId;

        this._docRef = user.DB.collection(FIREBASE_COLLECTION_PARTICULIER).doc(docId);
        this._profileSubject = new BehaviorSubject<IParticulier>(null);

        this._docRef.onSnapshot({
            next: (snapshort: firebase.firestore.DocumentSnapshot) => {
                this._profileSubject.next(snapshort.data() as IParticulier);
            },
            error: (err) => {}
        });
    }

    public get User(): User {
        return this._user;
    }
    public get Id(): string {
        return this._docId;
    }

    private _user: User;
    private _docId: string;
    private _docRef: firebase.firestore.DocumentReference;
    private _particulierServiceList: null;
    private _profileSubject: BehaviorSubject<IParticulier>;

    public async sync() {
        await this._docRef.get();
      }

    // public async getProfessionnelService(): Promise<professionnelService> {}

  /** Watch for changes to the professionnal profile. */
    public WatchProfile(observer: PartialObserver<IParticulier>): Subscription {
        return this._profileSubject.subscribe(observer);
  }

    /** Update the professionnal profile */
    public async UpdateProfile(profile: IParticulier) {
        // let validatedProfile: ITraducteur;
        try {
          /* validatedProfile = await TraducteurProfileSchema.validate(profile,
            {
              strict: false,
              abortEarly: false,
              stripUnknown: true
            }
          );
          */

          await this._docRef.update(profile);

        } catch (err) {
          return Promise.reject(err);
        }

      }

    public async hasServicesOrders(): Promise<boolean> {
      return await ParticulierServiceList.HasService(this);

     return true;
    }

        /** Instantiate a new Translator class */
    public static async Init(user: User): Promise<Particulier> {
        const query = user.DB.collection(FIREBASE_COLLECTION_PARTICULIER).where('uid', '==', user.Id);

        try {
        const snapshot: firebase.firestore.QuerySnapshot = await query.get();
        let docId: string = null;
        if (snapshot.empty) {
            // No translator of this type, create one
            const particulier: IParticulier = EmptyParticulierProfile;
          particulier.uid = user.Id;

            const docRef: firebase.firestore.DocumentReference = await user.DB.collection(FIREBASE_COLLECTION_PARTICULIER).add(particulier);

            await user.GetIDToken();
            docId = docRef.id;

        } else {
            docId = snapshot.docs[0].id;
        }

        const pro: Particulier = new Particulier(user, docId);
        await pro.sync();

        return pro;

        } catch (err) {
        console.error(err);
        throw err;
        }
    }

}
