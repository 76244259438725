<div class="modal-body">
  <div class="container-fluid">
    <form [formGroup]="commandeForm" (ngSubmit)="onSubmitForm()">

      <div class="d-inline-flex">
        <!-- choose document origin language-->
        <div class="form-group mr-1">
          <label for="srcLang"> {{ 'label_origin_lang' | localise }} </label>
          <select class="form-group custom-select my-1 mr-sm-2" (ngModelChange)="getSelectedOrigin($event)"
                  formControlName="srcLang">
            <option value="" disabled>
              {{ 'select_choose' | localise}}
            </option>
            <option *ngFor="let l of lang; let i = index" [ngValue]="l">
              {{ l.iso639 | iso639ToNative }}
            </option>
          </select>
        </div>
        <!-- end -->

        <!-- choose document country language-->
        <div class="form-group">
          <label for="originLang">{{ 'tasks_src_country' | localise }}</label>
          <select class="form-group custom-select my-1 mr-sm-2" (ngModelChange)="getSelectedOriginCountry($event)"
                  formControlName="originLang">
            <option value="" disabled>
              {{ 'select_choose' | localise}}
            </option>
            <option *ngFor="let l of countriesFiltered" [ngValue]="l">
              {{ l.code | country }}
            </option>
          </select>
        </div>
        <!-- end -->
      </div>

      <div class="d-inline-flex">
        <!-- choose document destination language-->
        <div class="form-group mr-1">
          <label class="my-1 mr-2" for="destLang"> {{ 'tasks_dst_country' | localise}} </label>
          <select class="form-group custom-select my-1 mr-sm-2" id="destLang" formControlName="destLang"
                  (ngModelChange)="getSelectedOriginDocument($event)">
            <option value="" disabled>
              {{ 'select_choose' | localise}}
            </option>
            <option *ngFor="let lang of destFiltered" [ngValue]="lang">
              {{ lang.iso639 | iso639ToNative }}
            </option>
          </select>
        </div>
        <!-- end -->
        <!-- choose document name -->
        <div class="form-group">
          <label class="my-1 mr-2" for="doctype"> {{ 'order_no_asser_document_name' | localise }} </label>
          <select class="form-group custom-select my-1 mr-sm-2" id="doctype" formControlName="doctype"
                  (ngModelChange)="getSelectedListDocument($event)">
            <option value="" disabled>{{ 'select_choose' | localise}}</option>
            <option *ngFor="let lang of documentFiltered" [ngValue]="lang">
              {{getDocumentName(lang.documentTypeId)}}
            </option>
          </select>
        </div>
        <!-- end document name -->
      </div>
      <!-- end d-inline-flex -->

      <!-- description -->
      <div class="form-group">
        <label class="my-1 mr-2" for="description"> {{ 'description' | localise}} </label>
        <textarea class="form-control" id="description" rows="3" formControlName="description"></textarea>
      </div>
      <!-- end -->

      <!-- urgency -->
      <div class="form-group">
        <label class="my-1 mr-2" for="doctype"> {{ 'urgency' | localise}} </label>
        <input type="date" class="form-group" id="urgency" formControlName="urgency" data-date=""
               data-date-format="DD MMMM YYYY" value="date(now)" (ngModelChange)="getUrgency($event)">

      </div>
      <!-- end urgency -->


      <!-- document pages numbers -->
      <div class="form-group">
        <label class="my-1 mr-2">{{ 'order_no_asser_query_pdf_page_number' | localise }} </label>
        <input type="checkbox" id="checkboxId" (change)="documentPagesNumbers($event)">
      </div>

      <div class="form-group my_hidden" id="hiddenId">
        <label class="my-1 mr-2">{{ 'order_no_asser_query_pdf_page_number_yes' | localise }}</label>
        <input type="number" id="docFilePageNumber" [(ngModel)]="pagesNumber"
               formControlName="docFilePageNumber" min="0">
        <div *ngIf="commandeForm.controls['docFilePageNumber'].hasError('min')">
          {{ 'alert_form_error_pages_number' | localise}}
        </div>
      </div>

      <div class="form-group">
        <input type="file" (change)="detectFiles($event.target.files)" [disabled]="!fileDocument" id="docFile"
               formControlName="docFile" multiple>
      </div>
      <!-- end -->
      <div>
        <div class="form-group">
          <button class="btn my-color white btn-s"
                  [disabled]="!selectedFiles">{{ 'find_traductors' | localise}}</button>
        </div>
      </div>
    </form>
    <table class="table-responsive table-body" *ngIf="show">
      <thead>
      <tr>
        <th width="35%">{{ 'order_traductor_delegate' | localise}}</th>
        <th>{{'agency_address_city' | localise}}</th>
        <th>{{ 'tasks_price_ttc' | localise }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="traducteurs?.length === 0">
        {{ 'no_traductor' | localise}} <i>{{'no_traductor_message' | localise}}</i>
      </tr>

      <tr *ngFor="let traducteur of traducteurs; let i = index">
        <!--<td>
          {{ i }}
        </td> -->
        <td nowrap>
          <a (click)="getTraducteurNameOnClickBunton($event)">
            <input type="button" class="btn btn-outline-dark"
                   value="{{ traducteur.traducteur.businessName }}">
          </a>
        </td>

        <td>
          {{ traducteur.traducteur.address.city }}

        </td>

        <ng-container *ngFor="let item of traducteur.exception.exceptions">
          <td *ngIf=" item.templateId == templateId && selectedFiles.length > 0">
            <ng-container>{{ item.priceHT }} €</ng-container>
            <!--
             <span *ngIf="traducteur.exception.exceptions.length > 0"> {{ item.priceHT }} € </span>
            <span *ngIf="traducteur.exception.exceptions.length === 0"> {{ traducteur.traducteur.defaultException}} € </span>
            -->
          </td>
        </ng-container>
      </tr>

      </tbody>
    </table>
    <div class="load-wrapp" *ngIf="showLoading">
      <div class="load-5">
        <p>{{ 'loading' | localise }} - {{ progress }} %</p>

        <div class="progress">
          <div class="progress-bar w-{{ progress }}" role="progressbar" aria-valuenow="75" aria-valuemin="0"
               aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">{{'close_modal' | localise }}</button>
</div>
