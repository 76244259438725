var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import * as firebase from "firebase";
import { ActivatedRoute, Router } from "@angular/router";
import { HieroBDD } from "../../../services/hierobdd.service";
import { EnumPrestationState } from "../../../../../common/src/bdd/interfaces/IPrestation";
import { environment } from "../../../../environments/environment";
import { Location } from "@angular/common";
export class SingleCommandeComponent {
    constructor(route, router, hiero, _location) {
        this.route = route;
        this.router = router;
        this.hiero = hiero;
        this._location = _location;
        this.loadInterpretation = [];
    }
    ngOnInit() {
        const id = this.route.snapshot.paramMap.has("id");
        this.route.paramMap.subscribe((paramMap) => __awaiter(this, void 0, void 0, function* () {
            if (!paramMap.has("id")) {
                this.router.navigate(["/commandes"]);
            }
            const docId = paramMap.get("id");
            this.getSingleCommande(docId).then((data) => {
                this.commande = data[0];
            });
            const req = this.hiero.DB.collection("customers")
                .doc(firebase.auth().currentUser.uid)
                .collection("payments")
                .where("metadata.item", "==", docId)
                .where("status", "==", "succeeded");
            req.get().then((snapshot) => {
                if (snapshot.docs[0]) {
                    const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", docId);
                    const req2 = this.hiero.DB.collection("prestations").doc(docId);
                    req.get().then((snapshot) => {
                        if (snapshot.docs[0].data().prestationInfo.state ===
                            "waiting-for-payment") {
                            snapshot.docs[0].ref
                                .update({
                                "prestationInfo.state": EnumPrestationState.Translating,
                                "prestationInfo.paidAt": Date.now(),
                            })
                                .then(() => {
                                this.router
                                    .navigateByUrl("/refresh", { skipLocationChange: true })
                                    .then(() => {
                                    console.log(decodeURI(this._location.path()));
                                    this.router.navigate([decodeURI(this._location.path())]);
                                });
                            });
                        }
                    });
                    req2.get().then((snapshot) => {
                        if (snapshot.data().state === "waiting-for-payment") {
                            snapshot.ref.update({
                                state: EnumPrestationState.Translating,
                                paidAt: Date.now(),
                            });
                        }
                    });
                }
            });
        }));
    }
    getSingleCommande(prestationID) {
        const docRef = this.hiero.DB.collection("particuliers_commandes")
            .where("prestationId", "==", prestationID)
            .get();
        return docRef.then((snapshot) => {
            return snapshot.docs.map((doc) => {
                return doc.data();
            });
        });
    }
    getSingleInterpretation(docId) {
        const docRef = this.hiero.DB.collection("interpretations_commandes").get();
        return docRef.then((snaphot) => {
            return snaphot.docs.map((doc) => {
                return {
                    id: doc.id,
                    data: doc.data(),
                };
            });
        });
    }
    sendMsg() {
        const msg = {
            uid: firebase.auth().currentUser.uid,
            message: this.sendmessage.value.message,
            sendAt: Date.now(),
        };
        // firebase.firestore().collection('messages').add(msg);
        // console.log(this.sendmessage.value.message);
        this.sendmessage.reset();
    }
    test() {
        const monTd = document.getElementById("myTd").innerHTML;
        console.log("IDdddddddddddd", monTd);
    }
    pay(price, type, prestation) {
        return __awaiter(this, void 0, void 0, function* () {
            let db = firebase.firestore();
            const docRef = yield db
                .collection("customers")
                .doc(firebase.auth().currentUser.uid)
                .collection("checkout_sessions")
                .add({
                line_items: [
                    {
                        price_data: {
                            currency: "eur",
                            product_data: {
                                name: "Commande de traduction",
                                description: "Nom du document: " + type,
                            },
                            unit_amount: parseInt(price),
                        },
                        quantity: 1,
                    },
                ],
                mode: "payment",
                allow_promotion_codes: true,
                success_url: environment.resetRedirectUrl + "/app/commandes/" + prestation,
                cancel_url: window.location.origin,
                metadata: {
                    item: prestation,
                },
            });
            // Wait for the CheckoutSession to get attached by the extension
            docRef.onSnapshot((snap) => {
                const { error, url } = snap.data();
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`An error occured: ${error.message}`);
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                }
            });
        });
    }
    getFile(deviceStorageId) {
        console.log(deviceStorageId);
        let uidUser = firebase.auth().currentUser.uid;
        const file = uidUser + "/" + uidUser + "/" + uidUser + "/" + deviceStorageId;
        let newref = this.hiero.Storage.ref(file);
        let url = newref.getDownloadURL().then((data) => {
            window.open(data, "_blank");
        });
    }
}
