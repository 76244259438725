module.exports = {
  fr: {
    /************************* Bloc pour les nouvelles phrases *************************/
    // part
    signup_login_text:
      "Pour utiliser nos services, veuillez renseigner une adresse mail valide. Celle-ci vous permettra de valider votre compte et de sécuriser vos données.",
    /********************** fin du bloc pour les nouvelles phrases *********************/

    /************ Bloc pour les nouvelles phrases envoyées pour vérification ***********/
    /******** fin du bloc pour les nouvelles phrases envoyées pour vérification ********/

    /************** Bloc pour les phrases utilisées que sur le particulier *************/
    modif_profile_failed_pfp:
      "Nous n’avons pas pu mettre à jour votre photo de profil.",
    modif_profile_failed_login_attempts:
      "3ème tentative : identifiant ou mot de passe erroné. La mise à jour de votre adresse mail n’a pas pu être effectuée. Veuillez réessayer",
    agency_support_guide_part: "Le guide d'utilisation",
    agency_setup_title_part: "À propos de vous",
    finaliser_pay: "Procéder au paiement de la commande",
    new_orders_traduction: "Passer une commande de traduction",
    new_orders_interprestation: "Passer une commande d'interprétariat",
    main_header_title_particulier:
      "Demandez vos traductions assermentées avec Hierø ",
    main_left_menu_section_compte: "Compte",
    main_menu_left_section_navigation_messages: "Mes messages",
    add_file: "Ajouter une pièce jointe",
    head_particulier:
      "Les réponses à vos questions sont peut être sur cette page. Dans le cas contraire, n'hésitez pas à ouvrir un ticket.",
    partuclier_trad_doc_head1:
      "Le traducteur n'a pas livré mon document. Que faire ?",
    partuclier_trad_doc1:
      "Vous pouvez tenter de le contacter en utilisant la messagerie ou bien passer par le support technique si le traducteur ne répond pas à vos requêtes. Le traducteur doit vous donner une raison valable pour laquelle il n'a pas pu livrer votre document dans les délais demandés.",
    partuclier_trad_doc_head2:
      "J'ai déjà la version téléchargeable de la traduction assermentée... Pourquoi récupérer l'original ?",
    partuclier_trad_doc2:
      "La version téléchargeable de la traduction n'a aucune valeur juridique et ne peut donc pas être utilisée pour les procedures qui nécessitent une traduction rélisée par un traducteur assermenté. La version originale de la traduction est la seule acceptée par les autorités compétentes.",
    partuclier_trad_doc_head3:
      "Que se passe t-il si le traducteur que j'ai choisi ne répond pas ?",
    partuclier_trad_doc3:
      "En théorie les traducteurs qui s'affichent sont ceux qui sont disponibles pour s'occuper de votre commande. Il peut arriver que certains prennent du temps à répondre. Dans ce cas, là notre logiciel vous dirigera vers un traducteur proche de chez vous qui pourra prendre en charge votre demande de traduction/interpretation",
    partuclier_trad_doc_head4:
      "Je ne peux pas me déplacer pour récuperer mon document auprès du traducteur.",
    partuclier_trad_doc4:
      "Si vous ne pouvez pas vous déplacer pour récupérer l'original, le traducteur a toujours la possibilité de vous faire parvenir les documents par courrier.",
    partuclier_trad_doc_head5:
      "La traduction que j'ai reçue me semble être de mauvaise qualité, voire erronée ou imcomplète.",
    partuclier_trad_doc5:
      "Gardez le document comme preuve, contactez le traducteur pour obtenir des explications. Si le traducteur refuse de faire des changements, contactez directement le support technique pour qu'il intervienne.",
    partuclier_trad_doc_head6:
      "Que faire si le logiciel ne détecte aucun traducteur à proximité ?",
    partuclier_trad_doc6:
      "Il peut arriver que nous n'ayons pas de traducteur recensé pour la langue qui vous intéresse, ou bien qu'il ne soit pas à proximité. Le cas écheant, contactez le support technique ou bien sélectionnez un traducteur éloigné dans le cas où aucun autre n'aura été suggéré.",
    agency_support_guide_pro: "Le guide pour les professionnels",
    delete_account: "Supprimer mon compte",
    account_paragraphe1:
      "Vous n’avez plus besoin de faire des demandes de traduction ?",
    account_paragraphe2: " Vous ne souhaitez plus utilisez nos services ?",
    account_paragraphe3: "Vous pouvez vous désinscrire à tout moment !",
    account_delete: "Je souhaite supprimer mon compte",
    message_paragraphe:
      "Discutez avec les traducteurs pour toutce qui concerne la mission en cours",
    discuss_encours: "Conversations en cours",
    cdu: "En cliquant sur 'S'inscrire', vous acceptez nos ",
    cdu1: "conditions générales d'utilisation,",
    cdu2: " notre politique de confidentialité",
    cdu3: " ainsi que",
    ticket_numero: "Ticket n°",
    price_unitaire: " Prix unitaire HT",
    menu_head_ticket: "Mes tickets",
    table_header_archives: "Archive des commandes",
    client_facture_detail: "Client",
    salle_de_conference: "Salle de conférence",
    /********** fin du bloc pour les phrases utilisées que sur le particulier **********/

    /************ Bloc pour les phrases utilisées sur le pro et particulier ************/
    state_translated_order: "Document traduit",
    newsletter:
      "J'accepte de recevoir par e-mail les mises à jour, les offres spéciales et les nouveautés sur la plateforme et/ou les autres produits développés par Hierø ",
    online: "Rester connecté",
    login_text:
      "Avec l'espace Hierø, faites vos demandes de traduction et sélectionnez votre traducteur assermenté en un clic.",
    signup_main_text: "Demandez vos traductions assermentées avec HIERØ",
    select: "Sélectionner",
    traduction_type: "Type de la traduction",
    find_interpertor: "Trouver un interprète",
    page_number: "Nombre de pages",
    login_caption_title_pro: "Hierø - Espace professionnel",
    login_main_title_pro: "Espace professionnel",
    login_main_subtitle_pro: "Accédez à votre espace professionnel Hiero !",
    signup_caption_title_pro: "Hierø - Espace professionnel",
    signup_main_subtitle_pro:
      "Pour nous rejoindre, veuillez remplir ce formulaire !",
    agency_setup_explanation_pro:
      "Trouvez un traducteur assermenté rapidement grâce à notre solution. Vous pourrez ainsi faire traduire vos documents dans les plus brefs délais !",
    main_header_subtitle_pro: "Espace professionnel",
    main_left_menu_section_navigation_order: "Mes commandes",
    order_id: "Id",
    order_language_from: "De",
    order_status: "Etat",
    status_orders: "Statut de votre commande",
    finaliser_commande: "Terminer la commande",
    order_traductor_delegate: "Traducteurs",
    new_order: "Commander une traduction",
    new_order_ass: "Commander une traduction assermentée",
    section_order_title: "Mes commandes",
    section_card_title: "Abonnement",
    account_budget: "Budget ",
    account_budget_placeholder: "",
    account_budget_help: "",
    label_origin_lang: "Langue d'origine",
    find_traductors: "Trouver des traducteurs",
    tasks_price_ttc: "Prix (TTC)",
    order_no_asser_query_pdf_page_number:
      "Votre document contient-il plus d'une page ?",
    order_no_asser_query_pdf_page_number_yes:
      "Entrer le nombre de pages du document",
    no_traductor: "Il n'y a pas de traducteur disponible. ",
    no_traductor_message: "Fermer la fenêtre puis essayez à nouveau.",
    loading: "En cours ...",
    menu: "Afficher les commandes de",
    alert_form_error_pages_number: "Le nombre de pages ne peut être négatif.",
    urgency: "Délai de livraison demandé pour cette traduction",
    message_no_budget: "Vous disposez actuellement d'un budget de ",
    message_contact: "Veuillez nous contacter si vous souhaitez le modifier.",
    assermente: "Assermenté(e)",
    description: "Description",
    main_left_menu_title_asser: "Traduction assermentée",
    main_left_menu_section_parameters_card: "Mon Abonnement",
    main_menu_left_section_navigation_assistance: "Assistance",
    main_menu_left_section_navigation_ticket: "Ouvrir un ticket",
    main_menu_left_section_navigation_question: "Foire aux questions",
    date_commande: "Date de la commande",
    single_commande_paragraphe:
      "Le traducteur a accepté votre commande. Une fois la traduction terminée, vous devrez la valider et confirmer. En cas de refus de la part du traducteur choisi, notre logiciel soumettra automatiquement votre commande à un autre traducteur.",
    commande_discuss: "Discuter directement avec votre traducteur",
    intervention_details: "Détails de l'intervention",
    commande_receive: "Commande reçue le",
    doc_a_traduire: " Document à traduire",
    trad_asser: "Traducteur assermenté",
    pays_origine: "Pays d'origine",
    visioner_doc: "Visionner le document",
    auth_doc:
      "Le traducteur vérifie l'authencité du document avant de débuter la traduction.",
    doc_bug:
      "En cas de problèmes, le traducteur peut vous contacter directement par messagerie.",
    section_questions_frequentes: "Questions les plus fréquentes",
    section_questions_contact:
      "Est-il possible de contacter le traducteur pour des questions ou des détails sur la prestation ?",
    section_questions_paragraphe_six:
      "Absolument! Vous avez accès à la messagerie lorsque vous accédez au détail de la commande, ou bien en allant dans l'onglet 'Service de messagerie'. La messagerie vous sert à convenir d'un lieu de rendez vous pour la remise des documents.",
    section_questions_traductions:
      "Pourquoi la prestation de traduction classique diffère de celle dite 'assermentée ou certifiée' ?",
    section_questions_paragraphe_seven:
      "Les documents classiques étant plus aléatoires, un modèle ne peut pas être généré par l'algorithme. Le client transmet le document que vous pouvez télécharger lorsque vous accédez au détail de la commande. Une fois traduit, le traducteur le charge et l'envoie au client pour validation.",
    agency_support: "Support technique",
    agency_support_service_client: "Numéro de téléphone - Service Client",
    agency_support_ouverture: "Ouvert du lundi au vendredi de 9h00 à 17h30",
    agency_support_questions:
      "Vous avez des questions à nous soumettre, des bugs à nous rapporter? Contactez-nous par mail",
    agency_support_guide: "Le guide Hierø",
    agency_support_guide_p:
      "Retrouvez notre guide d'utilisation ! Vous avez raté une étape ? Vous souhaitez savoir comment fonctionne la platforme Hierø ? ",
    agency_support_download_guide: "Téléchargez notre guide",
    agency_ticket_title: "Mes anciens tickets",
    agency_ticket_objet: "Objet",
    agency_ticket_question: "Votre question",
    agency_ticket_state: "Statut",
    agency_ticket_head:
      "Besoin d'aide ? Vous n'avez pas trouvé la réponse dans le FAQ? Posez-nous toutes vos questions, nous répondons aussi vite que possible !",
    consult_orders: "Consulter mes commandes",
    menu_label_intervention: "L'intervention doit se dérouler sur",
    menu_label_event_name: "Nom de l'événement",
    nb_heures_estimee: "Nombre d'heures estimées",
    menu_langue: "Langues",
    table_header_type_de_document: "Type de document",
    table_header_nb_pages: "Nombre de pages",
    table_hader_details: "Détails",
    joindre_salon: " Rejoindre la salle",
    interpretation_paragraphe_9:
      "Pour inviter des participants, cliquez sur le bouton ci-dessous, et copiez le lien d'invitation à envoyer dans votre message, tout en respectant le nom de la salle. ",
    interpretation_paragraphe_10:
      "Cliquez ici pour copier le lien d'invitation",
    interpretation_paragraphe_11:
      "Cliquez ici pour inviter les participants ou les intervenants",
    room_inter_pro: "Votre nom d'utilisateur",
    copy_alert: "Copié!",
    commander_interprete: "Commander un interprète",
    /********* fin du bloc pour les phrases utilisées sur le pro et particulier ********/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    modal_supp_title: "Confirmer la suppression de votre compte",
    facture_part: "Particulier",
    tasks_filters: "Filtres",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    modif_profile_success_pwd:
      "Votre mot de passe a été mis à jour avec succès.",
    modif_profile_failed_pwd:
      "Une erreur s'est produite lors de la modification de votre mot de passe. Veuillez vérifier que vous avez saisi le bon mot de passe.",
    modal_modif_title: "Confirmer la modification de votre adresse mail",
    modif_profile_failed_login:
      "Adresse mail ou mot de passe erroné. Veuillez réessayer.",
    augmente_budget:
      "Pour augmentter votre solde, vous pouvez nous adresser un chèque ou bien procéder à un virement.",
    no_budget: "Vous n'avez aucun budget pour l'instant",
    validation_mail_registration:
      "HIERØ vous souhaite la bienvenue ! Pour pouvoir commencer à utiliser nos services, vous devez d’abord confirmer votre adresse mail.",
    validation_modif_mail:
      "La modification de votre adresse email s'est faite avec succès. Pour pouvoir utiliser nos services, vous devez à nouveau confirmer votre adresse mail.",
    validation_question:
      "Vous n’avez pas reçu le mail de confirmation ? Cliquez sur le bouton pour renvoyer le mail de confirmation.",
    validation_mail_resend: "Renvoyer le mail de confirmation",
    message_verification:
      "Veuillez vérifier votre boite mail pour confirmer votre adresse mail.",
    non_valid_address: "Adresse mail non validée",
    validation_mail_success: "Mail de confirmation envoyé avec succès.",
    account_error_invalid_email: "L'adresse saisie n'est pas valide.",
    account_error_min_password: "8 carctères minimum",
    account_error_passwords_do_not_match:
      "Les mots de passe ne correspondent pas",
    account_confirm: "Confirmez votre mot de passe",
    modif_profile_success: "Votre profil a été mis à jour avec succès.",
    modif_profile_failed:
      "Une erreur s'est produite lors de la modification de votre profil. Veuillez vérifier vos informations et réessayer.",
    facture_conditions: "Conditions",
    facture_comptant: "Comptant",
    facture_echeance: "Échéance",
    facture_adress: "adresse de facturation",
    facture_qte: "Qté",
    facture_price: "Prix",
    facture_unitaire: "unitaire",
    facture_montant: "Montant",
    facute_solde: "Solde à payer",
    facture_tva_fr: "20 % TVA FR",
    order_language_to: "Vers",
    account_hist_pro_date: "Date d'intervention",
    select_choose: "Choisir...",
    tasks_price_tva: "TVA",
    order_no_asser_document_name: "Nom du document",
    menu_label_traductor_asser: "Traduction assermentée",
    menu_label_traductor_classic: "Traduction classique",
    menu_label_interprestation: "Interprétariat",
    menu_label_all: "Tout afficher",
    login_caption_title: "Hierø - Espace traducteur",
    login_caption_subtitle:
      "Un logiciel pensé par des traducteurs pour des traducteurs.",
    login_main_logo: "Hierø",
    login_main_connect: "Se connecter",
    login_main_email: "E-mail",
    login_main_email_placeholder: "Renseignez votre adresse mail",
    login_main_email_help: "Veuillez vous identifier avec votre adresse mail.",
    login_main_password: "Mot de passe",
    login_main_password_placeholder: "Indiquez votre mot de passe",
    login_main_password_help: "",
    login_main_button: "Se connecter",
    login_main_to_signup: "Pas encore inscrit ? Inscrivez-vous !",
    login_main_copyright: "© Hierø - HIERØ 2022",
    login_error_required_field: "Champ obligatoire",
    login_error_invalid_email: "L'adresse saisie n'est pas valide.",
    login_error_email_not_found: "L'adresse mail n'existe pas.",
    signup_caption_subtitle: "Choisissez Hierø et gagnez en visibilité !",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscription",
    signup_main_email: "Adresse mail",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Mot de passe",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmer votre mot de passe",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Nom",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Prénom",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Téléphone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "S'inscrire",
    signup_main_to_signup: "Déjà membre ? Se connecter",
    signup_main_copyright: "© Hierø - HIERØ 2022",
    signup_error_required_field: "Champ obligatoire",
    signup_error_invalid_email: "L'adresse saisie n'est pas valide.",
    signup_error_min_password: "8 caractères minimum",
    signup_error_max_password: "30 caractères maximum",
    signup_error_passwords_do_not_match:
      "Les mots de passe ne correspondent pas",
    login_forgot_password: "Mot de passe oublié ?",
    reset_password_main: "Réinitialiser votre mot de passe",
    reset_password_email_title: "E-mail",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Envoyé",
    reset_password_login: "Connexion",
    reset_password_success:
      "Un e-mail a été envoyé à votre adresse. Cliquez ensuite sur le lien pour modifier votre mot de passe.",
    main_left_menu_section_parameters_agency: "Mon entreprise",
    main_left_menu_section_parameters_services: "Mes services",
    main_left_menu_section_parameters_user: "Mon profil",
    main_left_menu_section_parameters_support: "Support",
    reset_password_error_required: "Champ obligatoire",
    reset_password_error_invalid_email: "L'adresse saisie n'est pas valide.",
    reset_password_error_user_not_found: "L'adresse mail n'existe pas.",
    main_header_user_menu_logout: "Se déconnecter",
    agency_setup_companyname: "Raison sociale",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "Le nom de l’entreprise qui s’affiche pour toutes les commandes et factures, etc.",
    agency_setup_address: "Adresse",
    agency_setup_siret: "N° de SIRET",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help:
      "Entrer votre numéro de SIRET s'il s'agit d'une entreprise",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Commencez à écrire votre adresse, et sélectionnez la bonne adresse dans le menu déroulant.",
    agency_setup_addresscompl: "Complément d'adresse",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Bâtiment, escalier, digicode, etc. Lorsque vos clients viennent chercher leurs documents, ils doivent avoir toutes les informations nécessaires.",
    agency_setup_tel: "Télephone",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "Le numéro de téléphone à utiliser pour toute correspondance avec vos clients.",
    agency_setup_email: "Adresse mail",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "L'adresse mail à utiliser pour toute correspondance avec vos clients. Veuillez en indiquer une autre s'il ne s'agit pas de votre adresse d'inscription sur Hierø.",
    agency_setup_save: "Enregistrer",
    agency_setup_error_required_field: "Champ obligatoire",
    agency_setup_error_invalid_email: "L'adresse saisie n'est pas valide.",
    agency_setup_error_valid_address:
      "Veuillez sélectionner une des adresses proposées dans le menu déroulant.",
    agency_address_not_found: "Votre adresse est introuvable ?",
    agency_address_title: "Saisir manuellement vos coordonnées",
    agency_address_address: "Adresse :",
    agency_address_number: "N°",
    agency_address_street: "Rue",
    agency_address_code: "Code postal",
    agency_address_city: "Ville",
    agency_address_country: "Pays",
    agency_address_coords: "Coordonnées GPS :",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Annuler",
    agency_address_validate: "Valider",
    main_left_menu_section_navigation_dashboard: "Tableau de bord",
    main_menu_left_section_navigation_factures: "Mes factures",
    head_facture: "Facture",
    section_date: "Date",
    section_numero_facture: "Facture n°",
    section_type_prestation: "Type de prestation",
    section_price: "Montant total",
    section_print: "Imprimer",
    section_generate_facture: "Générer la facture en PDF",
    section_consulter: "Consulter",
    prestation: "Prestation",
    commande_qte: "Quantité",
    commande_produit: "Produit",
    commande_desc: "Description",
    total_ht: "Total HT",
    total_tva: "Total TVA",
    total_ttc: "Total TTC",
    retour_btn: "Retour",
    progress_commande: "Progression de la commande",
    commande_numero: "Commande n° ",
    capital_sasu: "SASU au capital social de 7 000,00 €",
    rcs_infos: "RCS EVRY B 843 119 728 ",
    rcs_infos_new: "RCS PARIS B 843 119 728 ",
    siret: " SIRET 84311972800023",
    siret_new: " SIRET 84311972800031",
    tva_fr: "N° de TVA : FR00843119728",
    condition_paragraphe_1: "Pénalités de retard (taux annuel): 10%",
    condition_paragraphe_2: "Pas d'escompte en cas de paiement anticipé",
    condition_paragraphe_3:
      "Indemnité forfaitaire pour frais de recouvrement en cas de retard de paiement : 40€",
    prestataire: "Prestataire",
    my_info: "Mes informations",
    geolocation_paragraphe:
      "Le logiciel a besoin de connaître votre position pour rechercher les traducteurs et interprètes les plus proches de vous avec plus de précision.",
    update_password: "Modifier mon mot de passe",
    account_upt:
      "Si vous le souhaitez, vous pouvez modifier votre mot de passe",
    send: "Envoyer",
    close_modal: "Fermer",
    inscription_date: "Inscrit depuis le ",
    order_translate: "du ",
    montant_total_euro: "Montant total en euros",
    rules_conditions: "Conditions de règlement",
    rules_conditions_p: "Paiement à la réception de la facture",
    section_agency_title: "Mon entreprise",
    section_account_title: "Mon profil",
    section_support_title: "Support",
    notifications_new_task: "Vous avez une nouvelle mission !",
    notifications_task_confirmed:
      "La mission a été confirmée. Vous pouvez débuter la traduction.",
    notifications_task_validated:
      "La mission vient d'être validée par le client",
    tasks_sent_on: "Envoyé le",
    tasks_src_country: "Pays d'origine",
    tasks_dst_country: "Traduire vers",
    tasks_state: "Progression",
    state_cancelled: "Annulé par le client",
    state_waiting_for_translator: "Nouveau",
    state_waiting_for_payment: "En attente de paiement",
    state_refused_by_translator: "Mission refusée",
    state_translating: "En cours de traduction",
    state_waiting_for_client_validation:
      "Finie. En attente de validation par le client.",
    state_validated: "Validée par le client",
    state_validated_pro: "Validé",
    prestation_order_from: "De",
    prestation_order_document_type: "Type de document",
    prestation_order_messaging_title: "Messagerie",
    prestation_order_messaging_send: "Envoyé",
    prestation_order_messaging_client: "Client :",
    prestation_order_messaging_me: "Moi :",
    prestation_document_edit: "Éditeur de document",
    prestation_document_edit_expl:
      "Le texte dans le champ ci-dessus est souligné dans le document ci-dessous. En saisissant le text traduit dans le champ, le document sera automatiquement mis à jour. Utilisez les flèches pour à travers le document.",
    prestation_document_get_browser:
      "Vous ne voyez pas le PDF ? Veuillez utiliser un navigateur récent comme",
    prestation_document_confirm_oui: "Oui",
    prestation_document_confirm_non: "Non",
    account_save: "Enregistrer",
    account_surname: "Nom",
    account_surname_placeholder: "",
    account_name: "Prénom",
    account_name_placeholder: "",
    account_telephone: "Téléphone",
    account_telephone_placeholder: "",
    account_adresse: "Adresse",
    account_adresse_placeholder: "Adresse",
    account_complement_adr: "Complément d’adresse",
    account_complement_adr_placeholder: "Complément d’adresse",
    account_email: "E-mail",
    account_email_placeholder: "e-mail",
    account_password: "Mot de passe actuel",
    account_new_password: "Nouveau mot de passe",
    account_error_required: "Champ obligatoire",
    agency_expl_1_title: "Raison sociale",
    agency_expl_1_expl:
      "Précisez le nom de votre entreprise afin que vos clients puissent vous retrouver.",
    agency_expl_2_title: "Géolocalisation",
    agency_expl_2_expl:
      "L'application nécessite vos coordonnées afin de vous mettre en relation avec les clients les plus proches de vous.",
    agency_expl_3_title: "Activation du compte",
    agency_expl_3_expl:
      "Votre profil sera visible en ligne une fois la sauvegarde des informations effectuée.",
    services_dst_lang: "Vers",
    support_title: "Nos locaux",
    support_company: "Hiero",
    support_addr1: "7 Rue Montespan",
    support_addr1_paris: "83 boulevard de Magenta",
    support_addr2: "91000 Évry-Courcouronnes",
    support_addr2_paris: "75010 Paris",
    support_addr3: "France",
    support_contact: "Contact",
    support_tel: "Tel : (+33) 01 76 38 01 40",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Élement introuvable",
    not_found_subtitle: "La page que vous avez demandé n'existe pas",
    not_found_action: "Contactez-nous si ",
    not_found_home: "Accueil",
    menu_label_sur_place: "Sur place",
    menu_label_visio: "Par visioconférence",
    consulter_facture: "Consulter mes factures",
    facture_numero: "Facture n°",
    recap_tva: "RÉCAPITULATIF DE LA TVA",
    recap_taux: "TAUX",
    recap_mt_tva: "MONTANT TVA",
    recap_base_ht: "BASE HT",
    action: "Action",
    hours: "Heures",
    join_room: "Rejoindre la salle",
    rooms: "Les salles (Rooms)",
    warning: "Attention ! ",
    conf: "La conférence",
    warning_1: "Il faut respecter l'orthographe du nom des salles.",
    interpretation_info: "Explication",
    interpretation_paragraphe_1:
      "Pour participer à la mission d'interprétariat, il vous faut au minimum deux onglets. Dans le premier, vous serez avec l'interprète, dans le second avec tous les participants.",
    interpretation_paragraphe_2:
      "Pour accéder à la salle de conférence, le nom de la salle (Room) à indiquer correspond à ",
    interpretation_paragraphe_3:
      ". C’est là où se trouvent tous les participants.",
    interpretation_paragraphe_4:
      "Pour accéder à la salle d'interprétariat, le nom de la salle (Room) à indiquer correspond à ",
    interpretation_paragraphe_5:
      ". C’est là où se trouvent vos intervenants et l'interprète choisi. ",
    interpretation_paragraphe_6:
      "Pour réaliser correctement la mission d'interprétariat à distance, il est nécessaire que l'intervenant et l'interprète ne parlent pas en même temps dans la même salle.",
    interpretation_paragraphe_7:
      "C'est pourquoi vous êtes dans deux salles différentes. Ainsi, les participants n'entendront que vous dans la salle de conférence et vous n'entendrez que les intervenants dans la salle d'interprétation.",
    interpretation_paragraphe_8:
      "Pour des raisons de performance, la capacité maximale d'une salle est de 50 participants. Vous pouvez créer autant de salles correspondant à votre audience que vous le souhaitez et communiquer les noms de ces salles à votre interprète.",
    room_conf: " Salle de conférence",
    traduction_assermentee: "Traduction assermentée",
    traduction_classic: "Traduction classique",
    interpretation: "Interprétariat",
    AF: "Afghanistan",
    ZA: "Afrique du Sud",
    AL: "Albanie",
    DZ: "Algérie",
    DE: "Allemagne",
    AD: "Andorre",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctique",
    AG: "Antigua-et-Barbuda",
    AN: "Antilles néerlandaises",
    SA: "Arabie saoudite",
    AR: "Argentine",
    AM: "Arménie",
    AW: "Aruba",
    AU: "Australie",
    AT: "Autriche",
    AZ: "Azerbaïdjan",
    BS: "Bahamas",
    BH: "Bahreïn",
    BD: "Bangladesh",
    BB: "Barbade",
    BY: "Bélarus",
    BE: "Belgique",
    BZ: "Belize",
    BJ: "Bénin",
    BM: "Bermudes",
    BT: "Bhoutan",
    BO: "Bolivie",
    BA: "Bosnie-Herzégovine",
    BW: "Botswana",
    BR: "Brésil",
    BN: "Brunéi Darussalam",
    BG: "Bulgarie",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodge",
    CM: "Cameroun",
    CA: "Canada",
    CV: "Cap-Vert",
    EA: "Ceuta et Melilla",
    CL: "Chili",
    CN: "Chine",
    CY: "Chypre",
    CO: "Colombie",
    KM: "Comores",
    CG: "Congo-Brazzaville",
    KP: "Corée du Nord",
    KR: "Corée du Sud",
    CR: "Costa Rica",
    CI: "Côte d’Ivoire",
    HR: "Croatie",
    CU: "Cuba",
    DK: "Danemark",
    DG: "Diego Garcia",
    DJ: "Djibouti",
    DM: "Dominique",
    EG: "Égypte",
    SV: "El Salvador",
    AE: "Émirats arabes unis",
    EC: "Équateur",
    ER: "Érythrée",
    ES: "Espagne",
    EE: "Estonie",
    VA: "État de la Cité du Vatican",
    FM: "États fédérés de Micronésie",
    US: "États-Unis",
    ET: "Éthiopie",
    FJ: "Fidji",
    FI: "Finlande",
    FR: "France",
    GA: "Gabon",
    GM: "Gambie",
    GE: "Géorgie",
    GS: "Géorgie du Sud et les îles Sandwich du Sud",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grèce",
    GD: "Grenade",
    GL: "Groenland",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernesey",
    GN: "Guinée",
    GQ: "Guinée équatoriale",
    GW: "Guinée-Bissau",
    GY: "Guyana",
    GF: "Guyane française",
    HT: "Haïti",
    HN: "Honduras",
    HU: "Hongrie",
    BV: "Île Bouvet",
    CX: "Île Christmas",
    CP: "Île Clipperton",
    AC: "Île de l'Ascension",
    IM: "Île de Man",
    NF: "Île Norfolk",
    AX: "Îles Åland",
    KY: "Îles Caïmans",
    IC: "Îles Canaries",
    CC: "Îles Cocos - Keeling",
    CK: "Îles Cook",
    FO: "Îles Féroé",
    HM: "Îles Heard et MacDonald",
    FK: "Îles Malouines",
    MP: "Îles Mariannes du Nord",
    MH: "Îles Marshall",
    UM: "Îles Mineures Éloignées des États-Unis",
    SB: "Îles Salomon",
    TC: "Îles Turks et Caïques",
    VG: "Îles Vierges britanniques",
    VI: "Îles Vierges des États-Unis",
    IN: "Inde",
    ID: "Indonésie",
    IQ: "Irak",
    IR: "Iran",
    IE: "Irlande",
    IS: "Islande",
    IL: "Israël",
    IT: "Italie",
    JM: "Jamaïque",
    JP: "Japon",
    JE: "Jersey",
    JO: "Jordanie",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KG: "Kirghizistan",
    KI: "Kiribati",
    KW: "Koweït",
    LA: "Laos",
    LS: "Lesotho",
    LV: "Lettonie",
    LB: "Liban",
    LR: "Libéria",
    LY: "Libye",
    LI: "Liechtenstein",
    LT: "Lituanie",
    LU: "Luxembourg",
    MK: "Macédoine",
    MG: "Madagascar",
    MY: "Malaisie",
    MW: "Malawi",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malte",
    MA: "Maroc",
    MQ: "Martinique",
    MU: "Maurice",
    MR: "Mauritanie",
    YT: "Mayotte",
    MX: "Mexique",
    MD: "Moldavie",
    MC: "Monaco",
    MN: "Mongolie",
    ME: "Monténégro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibie",
    NR: "Nauru",
    NP: "Népal",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigéria",
    NU: "Niue",
    NO: "Norvège",
    NC: "Nouvelle-Calédonie",
    NZ: "Nouvelle-Zélande",
    OM: "Oman",
    UG: "Ouganda",
    UZ: "Ouzbékistan",
    PK: "Pakistan",
    PW: "Palaos",
    PA: "Panama",
    PG: "Papouasie-Nouvelle-Guinée",
    PY: "Paraguay",
    NL: "Pays-Bas",
    PE: "Pérou",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Pologne",
    PF: "Polynésie française",
    PR: "Porto Rico",
    PT: "Portugal",
    QA: "Qatar",
    HK: "R.A.S. chinoise de Hong Kong",
    MO: "R.A.S. chinoise de Macao",
    QO: "Régions éloignées de l’Océanie",
    CF: "République centrafricaine",
    CD: "République démocratique du Congo",
    DO: "République dominicaine",
    CZ: "République tchèque",
    RE: "Réunion",
    RO: "Roumanie",
    GB: "Royaume-Uni",
    RU: "Russie",
    RW: "Rwanda",
    EH: "Sahara occidental",
    BL: "Saint-Barthélemy",
    KN: "Saint-Kitts-et-Nevis",
    SM: "Saint-Marin",
    MF: "Saint-Martin",
    PM: "Saint-Pierre-et-Miquelon",
    VC: "Saint-Vincent-et-les Grenadines",
    SH: "Sainte-Hélène",
    LC: "Sainte-Lucie",
    WS: "Samoa",
    AS: "Samoa américaines",
    ST: "Sao Tomé-et-Principe",
    SN: "Sénégal",
    RS: "Serbie",
    CS: "Serbie-et-Monténégro",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapour",
    SK: "Slovaquie",
    SI: "Slovénie",
    SO: "Somalie",
    SD: "Soudan",
    LK: "Sri Lanka",
    SE: "Suède",
    CH: "Suisse",
    SR: "Suriname",
    SJ: "Svalbard et Île Jan Mayen",
    SZ: "Swaziland",
    SY: "Syrie",
    TJ: "Tadjikistan",
    TW: "Taïwan",
    TZ: "Tanzanie",
    TD: "Tchad",
    TF: "Terres australes et antarctiques françaises",
    IO: "Territoire britannique de l'océan Indien",
    PS: "Territoire palestinien",
    TH: "Thaïlande",
    TL: "Timor oriental",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinité-et-Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunisie",
    TM: "Turkménistan",
    TR: "Turquie",
    TV: "Tuvalu",
    UA: "Ukraine",
    EU: "Union européenne",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viêt Nam",
    WF: "Wallis-et-Futuna",
    YE: "Yémen",
    ZM: "Zambie",
    ZW: "Zimbabwe",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "S'agit-il d'une traduction assermentée ?",
    dashboard_pro_command_company_priceHT: "Prix HT:",
    new_orders: "Passer une nouvelle commande",
    state_pro_wait: "En attente",
    account_expl_pro_cheque: "Merci d'adresser un chèque à Hiero",
    account_expl_pro_viremement: "Virements ",
    account_expl_pro_iban: "IBAN FR 000",
    account_hist_pro_debit: "Débit ",
    account_hist_pro_credit: "Crédit ",
    account_hist_pro_way: "Moyen ",
    account_hist_pro_ref: "Référence ",
    agency_pro_budget_help:
      "Le budget que vous souhaitez allouer à vos traductions.",
    sub_training: "Formation d'équipe ",
    sub_search: "Recherche automatique de traducteur / interprète ",
    sub_geo: "Géolocalisation du traducteur et/ou de l'interprète ",
    sub_dem: "Dématérialisation de toutes les commandes ",
    sub_save: "Sauvegarde des documents avec gestionnaire de fichiers ",
    sub_recap: "Récapitulatif des commandes au mois ",
    sub_proj: "Evolution des projets en temps réel ",
    sub_management: "Gestion de budget en temps réel ",
    sub_message: "Messagerie automatique",
    sub_send: "Livraison de vos traductions par courrier ",
    sub_impl: "Implantation du budget voté sur 12 mois ",
    sub_account: "Account manager à vos côtés ",
    sub_orientation:
      "Orientation personnalisée des langues les plus demandées ",
    sub_split:
      "Division du budget en plusieurs départements, selon les utilisateurs",
    sub_event: "Enlèvement par coursier ",
    choose: "Choisir",
    sub_function: "Fonctionnalités",
    sub_standard: "Standard",
    sub_essen: "Essentiel",
    sub_premium: "Premium",
    sub_month: "mois",
    sub_associations_label: "Associations",
    sub_cabinet_label: "Cabinets d'avocats",
    sub_bank_label: "Mobilité internationale",
    sub_academia_label: "Etablissement academiques",
    sub_who_are_you: "Vous êtes ?",
    sub_explanation_academia:
      "Nous préférons travailler sous forme de partenariats avec les établissements académiques, type universités, grandes écoles ...",
    sub_explanation_systeme: "2 systèmes sont disponibles: ",
    sub_system_one:
      "L'utilisation de l'application mobile, s'ils ne sont pas conventionnés.",
    sub_system_two:
      "La négociation d'une mise en place de l'espace professionnel s'ils sont conventionnés.",
    sub_credit_card_message:
      "Activer les services en payant votre abonnement. Dans l'onglet ",
    validate_credit_card_message: "Votre commande a été prise en compte",
    subscriber_choose: "Choisir un abonnement",
    find_out_more: "En savoir plus",
    menu_label_relecture: "Relecture",
    signup_error_email_not_found: "L'adresse mail n'existe pas.",
    main_left_menu_section_accueil: "Accueil",
    agency_setup_email_help_pro:
      "L'adresse mail à utiliser pour toute correspondance avec nos traducteurs et l'équipe Hiero.",
    main_menu_left_section_navigation_entreprise: "Mon entreprise",
    main_menu_left_section_navigation_messagerie: "Service de messagerie",
    main_menu_left_section_navigation_gestion_des_fichiers:
      "Gestion des fichiers",
    montant_total: "Montant total",
    single_commande_p1:
      "Le traducteur télécharge le document pour le traduire ",
    single_commande_p2:
      "Une fois , il l'ajoute à cette commande pour que vous puissiez valider la traduction et confirmer la commande.",
    load_intervention: "Intervention en cours",
    commande_statut: "Statut de votre commande",
    commande_details: "Détails de la commande",
    trad_name: "Nom du traducteur",
    visioner: "Visioner",
    infos_interv: "Informations supplémentaires sur l'intervention",
    intervention_time_date: "Date et heure de l'intervention",
    loading_order: "Mes demandes de traduction en cours",
    view_all_orders: "Voir toutes les commandes",
    last_payment: "Mes derniers paiments effectués",
    view_all_payment: "Voir toutes mes paiements",
    last_send_msg: "Derniers messages envoyés",
    state_accept_order: "Commande acceptée",
    account_expl:
      "Veuillez renseigner vos coordonnées, pour vous contacter si necessaire.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: "Le problème persiste. ",
    menu_label_interpretation: "Demander une prestation d'interprétariat",
    table_header_statut: "Statut",
    head_professionnal:
      "Le logiciel a besoin de connaitre votre position pour rechercher les traducteurs les plus proches de vous avec plus de précision",
    head_change_password:
      "Si vous le souhaitez, vous pouvez changer votre mot de passe",
    recap_tva_fr: "20% TVA FR",
    client_particulier_name: "Raison sociale",
    client_particulier_email: "E-mail",
    client_particulier_address: "Adresse ",
    client_particulier_code_postal: "Ville",
    particulier_name: "Nom du particulier",
    infos_entrep: "",
    infos_commandes: "",
    address_facturation: "Modifier mon addresse de facturation",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
  en: {
    /************** Bloc pour les phrases utilisées que sur le particulier *************/
    modif_profile_failed_pfp: "Your profile picture couldn't be updated.",
    modif_profile_failed_login_attempts:
      "3rd attempt: incorrect username or password. Could not update your email address. Try again.",
    agency_support_guide_part: "User manual",
    agency_setup_title_part: "About you",
    finaliser_pay: "Proceed to payment of the order",
    new_orders_traduction: "Place a translation order ",
    new_orders_interprestation: "Place an interpretation order",
    main_header_title_particulier:
      "Order your sworn/certified translations with Hierø ",
    main_left_menu_section_compte: "Account",
    main_menu_left_section_navigation_messages: "My messages",
    add_file: "Attach a file ",
    head_particulier:
      "You may find the answer you're looking for in this page. If not, please open a ticket.",
    partuclier_trad_doc_head1:
      "The translator still hasn't delivered the translation. What can I do?",
    partuclier_trad_doc1:
      "You can contact your translator by using our messaging service or by contacting our technical support if he/she doesn't answer your messages. A translator must provide a valid reason for not delivering the translation in time.",
    partuclier_trad_doc_head2:
      "I already have the digital version of my sworn/certified translation. Why do I need the original copy?",
    partuclier_trad_doc2:
      "The digital version has no legal value. You can't use it for any legal procedure requiring a sworn/certified translation. The original version of the translation is the only one accepted by the competent authorities.",
    partuclier_trad_doc_head3:
      "What happens if the translator I've selected doesn't answer?",
    partuclier_trad_doc3:
      "In theory, all translators who appear on our system are available to translate your documents. However, they may take some time to repsond. In that case, our software will redirect your request to another translator available to take your order.",
    partuclier_trad_doc_head4:
      "I can't pick up my document from the translator in person.",
    partuclier_trad_doc4:
      "If you can't pick up the original version, the translator can always send you the documents by post.",
    partuclier_trad_doc_head5:
      "The translation I've received seems to be of poor quality, wrong or incomplete.",
    partuclier_trad_doc5:
      "Keep the document as proof and contact your translator to get an explanation. If he/she refuses to make the changes, please contact our technical support for help.",
    partuclier_trad_doc_head6:
      "What if the software doesn't detect any translators nearby?",
    partuclier_trad_doc6:
      "There may not be a translator in the language you need, or the only translator available isn't close to your location. Should this happen, please contact our technical support or select a remote translator if no other is suggested.",
    agency_support_guide_pro: "Our user guide",
    delete_account: "Delete my account",
    account_paragraphe1: "You no longer need to order translations? ",
    account_paragraphe2: " You no longer wish to use our services?",
    account_paragraphe3: "You can unsubscribe at any time.",
    account_delete: "I want to delete my account ",
    message_paragraphe:
      "Send messages directly to your translator for any information regarding your ongoing order.",
    discuss_encours: "Ongoing conversations",
    cdu: "By clicking on 'Register', you agree to our",
    cdu1: "terms and conditions",
    cdu2: "as well as our Privacy Policy",
    cdu3: " as well as",
    ticket_numero: "Ticket no.",
    price_unitaire: "Unit price (excl. VAT)",
    menu_head_ticket: "My tickets",
    table_header_archives: "Order history",
    client_facture_detail: "Customer",
    salle_de_conference: "Conference room",
    /********** fin du bloc pour les phrases utilisées que sur le particulier **********/

    /************ Bloc pour les phrases utilisées sur le pro et particulier ************/
    state_translated_order: "Translated document",
    newsletter:
      "I agree to receive e-mail updates, special offers and news about the platform and/or other products developed by Hierø.",
    online: "Stay logged in",
    login_text:
      "Order your translations using your Hiero account and select your sworn/certified translator in one click.",
    signup_main_text: "Order a sworn/certified translation with HIERØ",
    select: "Choose",
    traduction_type: "Type of translation",
    find_interpertor: "Find an interpreter",
    page_number: "Number of pages",
    login_caption_title_pro: "Hierø - Professional Account",
    login_main_title_pro: "Professional Account",
    login_main_subtitle_pro: "Access your professional Hierø account!",
    signup_caption_title_pro: "Hierø - Professional Account",
    signup_main_subtitle_pro: "To join us, please fill out this form.",
    agency_setup_explanation_pro:
      "Find a sworn/certified translator as soon as possible with our solution. You can then have your documents translated as soon as possible!",
    main_header_subtitle_pro: "Professional Account",
    main_left_menu_section_navigation_order: "My orders",
    order_id: "Id",
    order_language_from: "From",
    order_status: "Status",
    status_orders: "Order status",
    finaliser_commande: "Complete the order",
    order_traductor_delegate: "Translators",
    new_order: "Order a translation",
    new_order_ass: "Order a sworn translation",
    section_order_title: "My orders",
    section_card_title: "Subscription",
    account_budget: "Budget ",
    account_budget_placeholder: "",
    account_budget_help: "",
    label_origin_lang: "Source Language",
    find_traductors: "Find a translator",
    tasks_price_ttc: "Price (incl. VAT)",
    order_no_asser_query_pdf_page_number:
      "Does your document include more than one page ?",
    order_no_asser_query_pdf_page_number_yes:
      "Enter the number of pages in the document",
    no_traductor: "No translator available. ",
    no_traductor_message: "Close the window and try again",
    loading: "In progress...",
    menu: "Choose the type of translation",
    alert_form_error_pages_number: "The number of pages can't be negative.",
    urgency: "Deadline for your translation",
    message_no_budget: "You currently have a budget of ",
    message_contact: "Please contact us if you want to update your budget.",
    assermente: "Sworn / Certified",
    description: "Description",
    main_left_menu_title_asser: "Sworn/certified translation",
    main_left_menu_section_parameters_card: "My subscription",
    main_menu_left_section_navigation_assistance: "Assistance",
    main_menu_left_section_navigation_ticket: "Open a ticket",
    main_menu_left_section_navigation_question: "Frequently asked questions",
    date_commande: "Order date",
    single_commande_paragraphe: " ",
    commande_discuss: "Discuss directly with your translator.",
    intervention_details: "Details of the intervention",
    commande_receive: "Order received on",
    doc_a_traduire: "Document(s) to be translated",
    trad_asser: "Sworn/certified translation",
    pays_origine: "Country of origin",
    visioner_doc: "View the document",
    auth_doc:
      "The translator must check if the document is authentic before beginning the translation.",
    doc_bug:
      "Should there be an issue, the translator can contact you directly by using our messaging service.",
    section_questions_frequentes: "Frequently Asked Questions",
    section_questions_contact:
      "Can I contact the translator if I have any question, or want details on the translation?",
    section_questions_paragraphe_six:
      "Absolutely! We provide a messaging service accessible when clicking on your order, or by clicking on the 'Messaging Service' tab. You can use the chat to discuss where you'd like to receive your original copies for example.",
    section_questions_traductions:
      "Why is there a difference between the services provided for sworn/certified and regular translations?",
    section_questions_paragraphe_seven:
      "Regular documents are quite random in their form, our algorithm therefore can't generate a specific template. The client (you) provides the document, which the translator can download when you access the details of your order. Once the translation is done, the translator sends it back to the client.",
    agency_support: "Technical support",
    agency_support_service_client: "Phone number - Customer Service",
    agency_support_ouverture:
      "Open Monday through Friday, from 9:00AM to 5:30PM",
    agency_support_questions:
      "Do you have any questions, a technical bug to report? Contact us via email",
    agency_support_guide: "Our professional user manual",
    agency_support_guide_p:
      "You've missed a step? You'd like to know exactly how our software works? Take a look at our user manual! ",
    agency_support_download_guide: "Download our user manual",
    agency_ticket_title: "My previous tickets",
    agency_ticket_objet: "Topic",
    agency_ticket_question: "Your question",
    agency_ticket_state: "Status",
    agency_ticket_head:
      "Need help? If you can't find the answer you were looking for in our FAQ, please directly ask us. We'll answer as soon as possible!",
    consult_orders: "View my orders ",
    menu_label_intervention: "The intervention is to be held",
    menu_label_event_name: "Event name",
    nb_heures_estimee: "Estimate time (hours)",
    menu_langue: "Languages",
    table_header_type_de_document: "Document type",
    table_header_nb_pages: "Number of pages",
    table_hader_details: "Details",
    joindre_salon: "Enter the room",
    interpretation_paragraphe_9:
      "To invite new participants, click on the following button to copy the invitation link. Then paste it in your email. Don't forget to check the spelling of the room name before sending your message.",
    interpretation_paragraphe_10: "Click here to copy the invitation link",
    interpretation_paragraphe_11:
      "Click here to invite participants or speakers.",
    room_inter_pro: "Your username",
    copy_alert: "Copied!",
    commander_interprete: "Order an interpreter",
    /********* fin du bloc pour les phrases utilisées sur le pro et particulier ********/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    modal_supp_title: "Confirm the deletion of your account",
    facture_part: "Private individuals",
    tasks_filters: "Filtres",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    modif_profile_success_pwd: "Your password has been successfully updated.",
    modif_profile_failed_pwd:
      "An error occurred while changing your password. Please check that you have entered the correct password.",
    modal_modif_title: "Confirm email change",
    modif_profile_failed_login: "Wrong email address or password. Try again.",
    augmente_budget:
      "To increase your balance, you can send us a check or make a transfer.",
    no_budget: "You don't have a budget for now",
    validation_mail_registration:
      "Welcome to HIERO! To start using our services, you must first confirm your email address.",
    validation_modif_mail:
      "Your email address has been successfully changed. To use our services, you must confirm your email address again.",
    validation_question:
      "Didn't receive the confirmation email? Click the button to send the confirmation email again.",
    validation_mail_resend: "Send confirmation email again",
    message_verification:
      "Please confirm your email address. To do so, please check your inbox.",
    non_valid_address: "Email address not confirmed",
    validation_mail_success: "Confirmation email successfully sent.",
    account_error_invalid_email: "The email you provided is not valid.",
    account_error_min_password: "8 characters minimum",
    account_error_passwords_do_not_match: "Your passwords do not match",
    account_confirm: "Confirm your password",
    modif_profile_success: "Your profile has been successfully updated",
    modif_profile_failed:
      "An error has occurred while updating your profile. Please check the information and try again.",
    facture_echeance: "Due on",
    facture_conditions: "Payment",
    facture_comptant: "Upfront",
    facture_adress: "Billing Address",
    facture_qte: "Qty",
    facture_price: "Unit",
    facture_unitaire: "Price",
    facture_montant: "Amount",
    facute_solde: "Total due",
    facture_tva_fr: "French VAT 20%",
    order_language_to: "To",
    account_hist_pro_date: "Date of Intervention",
    select_choose: "Choose...",
    tasks_price_tva: "VAT",
    order_no_asser_document_name: "Name of the document",
    menu_label_traductor_asser: "Sworn/certified translation",
    menu_label_traductor_classic: "Regular translation",
    menu_label_interprestation: "Interpretation",
    menu_label_all: "Show all",
    login_caption_title: "Hierø - Translator Account",
    login_caption_subtitle:
      "A software designed by translators for translators.",
    login_main_logo: "Hierø",
    login_main_connect: "Log in",
    login_main_email: "Email",
    login_main_email_placeholder: "Enter your email address",
    login_main_email_help: "Use your email address to log in.",
    login_main_password: "Password",
    login_main_password_placeholder: "Please enter your password.",
    login_main_password_help: "",
    login_main_button: "Log in",
    login_main_to_signup: "Do not have an account? Sign up here!",
    login_main_copyright: "© Hierø - HIERØ 2022",
    login_error_required_field: "Required field",
    login_error_invalid_email: "The email you provided is not valid.",
    login_error_email_not_found: "This email is not registered.",
    signup_caption_subtitle: "Choose Hierø and increase your visibility!",
    signup_main_logo: "Hierø",
    signup_main_title: "Sign up",
    signup_main_email: "Email",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Password",
    signup_main_password_placeholder: "Password",
    signup_main_password_help: "",
    signup_main_confirm: "Confirm your password",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Surname",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Name",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Phone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Sign up!",
    signup_main_to_signup: "Already have an account? Log in",
    signup_main_copyright: "© Hierø - HIERØ 2022",
    signup_error_required_field: "Required field",
    signup_error_invalid_email: "The email you provided is not valid.",
    signup_error_min_password: "8 characters minimum",
    signup_error_max_password: "30 characters maximum",
    signup_error_passwords_do_not_match: "Your passwords do not match",
    login_forgot_password: "Forgot your password?",
    reset_password_main: "Reset your password",
    reset_password_email_title: "Email",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Send",
    reset_password_login: "Log in",
    reset_password_success:
      "Email sent successfully. Please check your email and click the link in order to set a new password.",
    main_left_menu_section_parameters_agency: "My company",
    main_left_menu_section_parameters_services: "My services",
    main_left_menu_section_parameters_user: "My account",
    main_left_menu_section_parameters_support: "Assistance",
    reset_password_error_required: "Required field.",
    reset_password_error_invalid_email: "The email you provided is not valid.",
    reset_password_error_user_not_found: "This email is not registered.",
    main_header_user_menu_logout: "Log out",
    agency_setup_companyname: "Company name",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "The name of the company that will be shown in orders, invoices, etc.",
    agency_setup_address: "Address",
    agency_setup_siret: "Company Registration Number",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help:
      "Enter your SIRET number (or Company Registration Number) if you are a business.",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Start typing your address, then select the correct one in the dropdown menu.",
    agency_setup_addresscompl: "Additional address information",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Building, staircase, code, etc. When your clients pick up their documents, they must have all the necessary information.",
    agency_setup_tel: "Phone number",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "The phone number to use to discuss with your clients.",
    agency_setup_email: "Email",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "The email address to use when corresponding with your clients. Please indicate a new email, should it not be your Hierø registration email.",
    agency_setup_save: "Save",
    agency_setup_error_required_field: "Required field",
    agency_setup_error_invalid_email: "The email you provided is not valid.",
    agency_setup_error_valid_address:
      "You must select a valid address from the dropdown menu.",
    agency_address_not_found: "Your address was not found?",
    agency_address_title: "Manually enter your details",
    agency_address_address: "Address:",
    agency_address_number: "No.",
    agency_address_street: "Street",
    agency_address_code: "Postal code",
    agency_address_city: "City",
    agency_address_country: "Country",
    agency_address_coords: "GPS coordinates:",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Cancel",
    agency_address_validate: "Continue",
    main_left_menu_section_navigation_dashboard: "Dashboard",
    main_menu_left_section_navigation_factures: "My invoices",
    head_facture: "Invoice",
    section_date: "Date",
    section_numero_facture: "Invoice number",
    section_type_prestation: "Type of service",
    section_price: "Total",
    section_print: "Print",
    section_generate_facture: "Generate the invoice in PDF",
    section_consulter: "View",
    prestation: "Service",
    commande_qte: "Quantity",
    commande_produit: "Product",
    commande_desc: "Description",
    total_ht: "Total excl. VAT",
    total_tva: "Total VAT",
    total_ttc: "Total incl. VAT",
    retour_btn: "Go back",
    progress_commande: "Order progression",
    commande_numero: "Order no.",
    capital_sasu: "SASU with a share capital of €7,000",
    rcs_infos: "Trade and Companies Register (RCS) of EVRY B 843 119 728 ",
    rcs_infos_new: "Trade and Companies Register (RCS) of PARIS B 843 119 728 ",
    siret: " Company Registration Number 84311972800023",
    siret_new: " Company Registration Number 84311972800031",
    tva_fr: "VAT number: FR00843119728",
    condition_paragraphe_1: "Late-payment penalties (annual rate): 10%",
    condition_paragraphe_2: "No discount for early payment",
    condition_paragraphe_3:
      "Standard payment rate for recovery fees in the ent of late payment: €40",
    prestataire: "Service provider",
    my_info: "My details",
    geolocation_paragraphe:
      "The software needs to know your location so it can search accurately the closest translator to take your order. ",
    update_password: "Change my password ",
    account_upt: "You can change your password as you wish ",
    send: "Send",
    close_modal: "Close",
    inscription_date: "Registration date:",
    order_translate: "from",
    montant_total_euro: "Total amount in EUR",
    rules_conditions: "TERMS OF PAYMENT",
    rules_conditions_p: "Payment due upon receipt of the invoice",
    section_agency_title: "My company",
    section_account_title: "My account",
    section_support_title: "Assistance",
    notifications_new_task: "You have a new assignment!",
    notifications_task_confirmed:
      "Your assignment has just been confirmed. You can start translating.",
    notifications_task_validated: "Your client validated the assignment.",
    tasks_sent_on: "Sent on",
    tasks_src_country: "Original country",
    tasks_dst_country: "Translate to",
    tasks_state: "Order progress",
    state_cancelled: "Canceled by the client",
    state_waiting_for_translator: "New!",
    state_waiting_for_payment: "Waiting for payment",
    state_refused_by_translator: "Mission refused",
    state_translating: "Translation in progress",
    state_waiting_for_client_validation: "Done, waiting for client approval",
    state_validated: "Approved by client",
    state_validated_pro: "Validated",
    prestation_order_from: "From",
    prestation_order_document_type: "Document type",
    prestation_order_messaging_title: "Chat",
    prestation_order_messaging_send: "Send",
    prestation_order_messaging_client: "Client:",
    prestation_order_messaging_me: "Me:",
    prestation_document_edit: "Document editor",
    prestation_document_edit_expl:
      "The text in the field above is highlighted in the document below. When you modify the text above, the document will automatically be updated. Use the arrows to navigate between phrases in the document.",
    prestation_document_get_browser:
      "You do not see the translated PDF? Please use an up-to-date browser like",
    prestation_document_confirm_oui: "Yes",
    prestation_document_confirm_non: "No",
    account_save: "Save",
    account_surname: "Surname",
    account_surname_placeholder: "",
    account_name: "Name",
    account_name_placeholder: "",
    account_telephone: "Phone number",
    account_telephone_placeholder: "",
    account_adresse: "Address",
    account_adresse_placeholder: "Address",
    account_complement_adr: "Additional address information",
    account_complement_adr_placeholder: "Additional address information",
    account_email: "Email",
    account_email_placeholder: "Email",
    account_password: "Current password",
    account_new_password: "New password",
    account_error_required: "Required",
    agency_expl_1_title: "Business name",
    agency_expl_1_expl:
      "Specify your business name so that your clients can find you.",
    agency_expl_2_title: "Geolocation",
    agency_expl_2_expl:
      "The application needs your coordonates to put you in touch with clients close to you.",
    agency_expl_3_title: "Activate your account",
    agency_expl_3_expl:
      "Your profile will be visible once you have saved the information.",
    services_dst_lang: "To",
    support_title: "Our offices",
    support_company: "Hiero",
    support_addr1: "7 Rue Montespan",
    support_addr1_paris: "83 boulevard de Magenta",
    support_addr2: "91000 Évry-Courcouronnes",
    support_addr2_paris: "75010 Paris",
    support_addr3: "France",
    support_contact: "Contact",
    support_tel: "Tel: (+33) 1 76 38 01 40",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Element not found",
    not_found_subtitle: "The page you requested does not exist",
    not_found_action: "Contact",
    not_found_home: "Home",
    menu_label_sur_place: "On site",
    menu_label_visio: "By video conference",
    consulter_facture: "View my invoices",
    facture_numero: "Invoice no.",
    recap_tva: "VAT SUMMARY",
    recap_taux: "RATE",
    recap_mt_tva: "VAT AMOUNT",
    recap_base_ht: "RATE EXCL. VAT",
    action: "Action",
    hours: "Time",
    join_room: "Enter the room",
    rooms: "Rooms",
    warning: "Warning!",
    conf: "Conference",
    warning_1: "You must pay attention to the correct spelling of each room.",
    interpretation_info: "Rules",
    interpretation_paragraphe_1:
      " To begin the intervention, you need to open two different tabs. You'll be with the interpreter in the first room, and all participants in the second room.",
    interpretation_paragraphe_2:
      " To access the conference room, the name to enter is",
    interpretation_paragraphe_3:
      ". Every participant attending the conference will be in this room, listening.",
    interpretation_paragraphe_4:
      " To access the interpretation room, the name to enter is",
    interpretation_paragraphe_5:
      " . Every speaker attending the conference will be with you in this room. The name of this room is likely to be the name of your client.",
    interpretation_paragraphe_6:
      " To carry out this internvention correctly, this remote conference requires that the speaker and the interpreter do not speak at the same time, in the same room.",
    interpretation_paragraphe_7:
      " That's why there are two different rooms. All participants will only hear your interpretation in the conference room while you'll only hear the speaker in the interpretation room.",
    interpretation_paragraphe_8:
      " For performance reasons, the maximum capacity in a room is 50 participants. You can create additional rooms depending on your audience. Then you can give the name of each room to your interpreter.",
    room_conf: "Conference room",
    traduction_assermentee: "Sworn / Certified Translation",
    traduction_classic: "Regular translation",
    interpretation: "Interpretation",
    AF: "Afghanistan",
    ZA: "South Africa",
    AL: "Albania",
    DZ: "Algeria",
    DE: "Germany",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AN: "Netherlands Antilles",
    SA: "Saudi Arabia",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BR: "Brazil",
    BN: "Brunei",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    EA: "Ceuta and Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Cyprus",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo-Brazzaville",
    KP: "North Korea",
    KR: "South Korea",
    CR: "Costa Rica",
    CI: "Ivory Coast",
    HR: "Croatia",
    CU: "Cuba",
    DK: "Denmark",
    DG: "Diego Garcia",
    DJ: "Djibouti",
    DM: "Dominica",
    EG: "Egypt",
    SV: "El Salvador",
    AE: "United Arab Emirates",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "Spain",
    EE: "Estonia",
    VA: "Vatican",
    FM: "Micronesia",
    US: "United States of America",
    ET: "Ethiopia",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    GS: "South Georgia and the South Sandwich Islands",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GD: "Granada",
    GL: "Greenland",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Equatorial Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    GF: "French Guyana",
    HT: "Haiti",
    HN: "Honduras",
    HU: "Hungary",
    BV: "Bouvet Island",
    CX: "Christmas Island",
    CP: "Clipperton Island",
    AC: "Ascension Island",
    IM: "Isle of Man",
    NF: "Norfolk Island",
    AX: "Åland Islands",
    KY: "Cayman Islands",
    IC: "Canary Islands",
    CC: "Cocos (Keeling) Islands",
    CK: "Cook Islands",
    FO: "Faroe Islands",
    HM: "Heard Island and McDonald Islands",
    FK: "Falkland Islands",
    MP: "Northern Mariana Islands",
    MH: "Marshall Islands",
    UM: "United States Minor Outlying Islands",
    SB: "Solomon Islands",
    TC: "Turks and Caicos Islands",
    VG: "British Virgin Islands",
    VI: "U.S. Virgin Islands",
    IN: "India",
    ID: "Indonesia",
    IQ: "Iraq",
    IR: "Iran",
    IE: "Ireland",
    IS: "Iceland",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesotho",
    LV: "Latvia",
    LB: "Lebanon",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MK: "Macedonia",
    MG: "Madagascar",
    MY: "Malaysia",
    MW: "Malawi",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MA: "Morocco",
    MQ: "Martinique",
    MU: "Mauritius",
    MR: "Mauritania",
    YT: "Mayotte",
    MX: "Mexico",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Norway",
    NC: "New Caledonia",
    NZ: "New Zealand",
    OM: "Oman",
    UG: "Uganda",
    UZ: "Uzbekistan",
    PK: "Pakistan",
    PW: "Palau",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    NL: "Netherlands",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PF: "French Polynesia",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Qatar",
    HK: "Hong Kong",
    MO: "Macao",
    QO: "Remote Oceania",
    CF: "Central African Republic",
    CD: "Democratic Republic of the Congo",
    DO: "Dominican Republic",
    CZ: "Czech Republic",
    RE: "Reunion",
    RO: "Romania",
    GB: "United Kingdom",
    RU: "Russia",
    RW: "Rwanda",
    EH: "Western Sahara",
    BL: "Saint Barthélemy",
    KN: "Saint Kitts and Nevis",
    SM: "San Marino",
    MF: "Saint Martin Island",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    SH: "Saint Helena",
    LC: "Saint Lucia",
    WS: "Samoa",
    AS: "American Samoa",
    ST: "São Tomé and Príncipe",
    SN: "Senegal",
    RS: "Serbia",
    CS: "Serbia and Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SO: "Somalia",
    SD: "Sudan",
    LK: "Sri Lanka",
    SE: "Sweden",
    CH: "Switzerland",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SY: "Syria",
    TJ: "Tajikistan",
    TW: "Taiwan",
    TZ: "Tanzania",
    TD: "Chad",
    TF: "French Southern and Antarctic Lands",
    IO: "British Indian Ocean Territory",
    PS: "Palestinian territories",
    TH: "Thailand",
    TL: "East Timor",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunisia",
    TM: "Turkmenistan",
    TR: "Turkey",
    TV: "Tuvalu",
    UA: "Ukraine",
    EU: "European Union",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis and Futuna",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "Do you require a sworn or certified translation?",
    dashboard_pro_command_company_priceHT: "Price (excl. VAT):",
    new_orders: "Place an order",
    state_pro_wait: "Pending",
    account_expl_pro_cheque: "Please send a check to ...",
    account_expl_pro_viremement: "Wire transfer ",
    account_expl_pro_iban: "IBAN GB 000",
    account_hist_pro_debit: "Debit ",
    account_hist_pro_credit: "Credit ",
    account_hist_pro_way: "Means ",
    account_hist_pro_ref: "Reference ",
    agency_pro_budget_help: "The budget you wish to use for your translations.",
    sub_training: "Team training ",
    sub_search: "Immediate translator / interpreter research ",
    sub_geo: "Translator / interpreter geolocation ",
    sub_dem: "Digital account ",
    sub_save: "File storage and management ",
    sub_recap: "Monthly order recap ",
    sub_proj: "Live project progression ",
    sub_management: "Live budget management ",
    sub_message: "Messaging service",
    sub_send: "Delivery of your translations by post ",
    sub_impl: "Full budget management ",
    sub_account: "Account manager ",
    sub_orientation:
      "Custom orientation, depending on the most requested languages",
    sub_split:
      "Division of the budget within several departments, depending on its users ",
    sub_event: "Delivery of your translations by courier ",
    choose: "Choose",
    sub_function: "Features",
    sub_standard: "Standard",
    sub_essen: "Essential",
    sub_premium: "Premium",
    sub_month: "month",
    sub_associations_label: "Non-Profit Organizations",
    sub_cabinet_label: "Law firms",
    sub_bank_label: "International Mobility",
    sub_academia_label: "Academic institutions",
    sub_who_are_you: "Are you…",
    sub_explanation_academia:
      "We believe a partnership would be better for academic institutions such as universities, higher education institutions, etc. ",
    sub_explanation_systeme: "You can select one of our 2 propositions: ",
    sub_system_one:
      "Using our mobile application if your students cover their own costs.",
    sub_system_two:
      "Negotiating the implementation of a specific account if your students don’t cover their own costs. ",
    sub_credit_card_message:
      "To activate your services, please proceed to the payment of your subscription fee. To do so, go to the 'My Subscription' tab",
    validate_credit_card_message: "Your order has been processed. ",
    subscriber_choose: "Choose a subscription",
    find_out_more: "Find out more",
    menu_label_relecture: "Proofreading",
    signup_error_email_not_found: "This email is not registered.",
    main_left_menu_section_accueil: "Home",
    agency_setup_email_help_pro:
      "The email address to be used when discussing with the Hierø team.",
    main_menu_left_section_navigation_entreprise: "My company",
    main_menu_left_section_navigation_messagerie: "Messaging service",
    main_menu_left_section_navigation_gestion_des_fichiers: "File Manager",
    montant_total: "Total amount",
    single_commande_p1:
      "The translator downloads the document for translation. ",
    single_commande_p2:
      "Once translated, he’ll add the translation to the order so you can validate the translation and confirm the order.",
    load_intervention: "Intervention in process",
    commande_statut: "Order status",
    commande_details: "Order details",
    trad_name: "Name of the translator",
    visioner: "View",
    infos_interv: "Additional information about this intervention",
    intervention_time_date: "Date and time of the intervention",
    loading_order: "My current translation orders",
    view_all_orders: "View all my orders",
    last_payment: "My latest payments",
    view_all_payment: "View all my payments",
    last_send_msg: "Last messages sent",
    state_accept_order: "Order accepted",
    account_expl:
      "Please indicate your personal contact details in the event we need to get in touch.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: " if the problem persists.",
    menu_label_interpretation: "Request an interpreter",
    table_header_statut: "Status",
    head_professionnal:
      "The software needs to know your location so it can find the closest translator for your order.",
    head_change_password: "You can change your password here, if you wish to.",
    address_facturation: "Change my billing address",
    recap_tva_fr: "French VAT 20% ",
    client_particulier_name: "Name",
    client_particulier_email: "Email",
    client_particulier_address: "Postal address",
    client_particulier_code_postal: "Zip code - CITY",
    infos_entrep: "",
    infos_commandes: " ",
    particulier_name: "Name",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
  es: {
    /************** Bloc pour les phrases utilisées que sur le particulier *************/
    modif_profile_failed_pfp: "No pudimos actualizar tu foto de perfil.",
    modif_profile_failed_login_attempts:
      "3er intento: identificación del usuario o contraseña incorrecto. No se pudo actualizar el correo electrónico. Intentar otra vez.",
    agency_support_guide_part: "La guía del usuario",
    agency_setup_title_part: "A propósito",
    finaliser_pay: "Proceder al pago del pedido",
    new_orders_traduction: "Pedir una traducción",
    new_orders_interprestation: "Pedir una interpretación",
    main_header_title_particulier:
      "Solicitar tus traducciones juradas con Hierø ",
    main_left_menu_section_compte: "Cuenta",
    main_menu_left_section_navigation_messages: "Mis mensajes",
    add_file: "Añadir un documento adjunto",
    head_particulier:
      "Puedes encontrar las respuestas a tus preguntas en esta página. En caso contratio, puedes abrir un ticket.",
    partuclier_trad_doc_head1:
      "El traductor no entregó mi documento. ¿Qué debo hacer?",
    partuclier_trad_doc1:
      "Puedes ponerte en contacto con el traductor utilisando la mensajeria o hablar con el soporte técnico si el traductor no responde a tus peticiones. El traductor debe darle una razón valida por la que no pudo entregar su documento en el plazo solicitado.",
    partuclier_trad_doc_head2:
      "Ya tengo la versión digital de la traducción jurada. ¿Porqué necesito el original?",
    partuclier_trad_doc2:
      "La versión digital de la traducción no tiene valor jurídico. Por lo tanto, no puede utilizarse para los procedimientos que requieren una traducción jurada. La versión original de la traduccion es la única aceptada por las autoridades competentes.",
    partuclier_trad_doc_head3:
      "¿Qué ocurre si el traductor que he elegido no responde?",
    partuclier_trad_doc3:
      "En teoría, los traductores que aparecen están disponibles para atender su pedido. Puede occurrir que algunos tarden en responder. Nuestro software dirigirá el pedido a un traductor cercano y disponible para atender su pedido de traducción/interpretación.",
    partuclier_trad_doc_head4: "No puedo recoger mi documento en persona.",
    partuclier_trad_doc4:
      "Si no es posible recoger personalmente el original, el traductor puede enviarte los documentos por correo ordinario.",
    partuclier_trad_doc_head5:
      "La traducción que he recibido parece ser de mala calidad, o incluso errónea o incompleta.",
    partuclier_trad_doc5:
      "Guarde el documento como prueba, póngate en contacto con el traductor para que te dé explicaciones. Si el traductor no quiere realizar cambios, póngata en contacto directamente con el servicio técnico.",
    partuclier_trad_doc_head6:
      "¿Y si el software no detecta ningún traductor cerca?",
    partuclier_trad_doc6:
      "Puede ocurrir que no hay ningún traductor (o cerca de tu posición) para el idioma que necesitas. Póngate en contacto con el servicio de asistencia técnica, o puedes seleccionar un traductor remoto si no hay otro.",
    agency_support_guide_pro: "La guía del usuario",
    delete_account: "Suprimir mi cuenta",
    account_paragraphe1: " ¿Ya no es necesario solicitar traducciones? ",
    account_paragraphe2: " ¿Yo no quieres utilizar nuestros servicios?",
    account_paragraphe3: "Puedes suprimir tu cuenta en cualquier momento.",
    account_delete: "Quiero suprimir mi cuenta",
    message_paragraphe:
      "Puedes hablar con los traductores para obtener más información sobre el pedido en proceso. ",
    discuss_encours: "Conversaciones en curso",
    cdu: "Al hacer clic en 'Registrarse', acepto las ",
    cdu1: "condiciones generales de uso y venta",
    cdu2: "y nuestra política de privacidad",
    cdu3: "así como",
    ticket_numero: "Ticket no.",
    price_unitaire: " Precio unitario sin IVA",
    menu_head_ticket: "Mis tickets",
    table_header_archives: "Archivo de pedidos",
    client_facture_detail: "Cliente",
    salle_de_conference: "Sala de conferencias",
    /********** fin du bloc pour les phrases utilisées que sur le particulier **********/

    /************ Bloc pour les phrases utilisées sur le pro et particulier ************/
    state_translated_order: "Documento traducido",
    newsletter:
      "Acepto recibir por correo electrónico actualizaciones, ofertas especiales y noticias sobre la plataforma y/o otros productos desarrollados por Hierø ",
    online: "quedar conectado",
    login_text:
      "Con Hierø, puedes pedir tus traducciones y selectionar a tu traductor jurado en un solo clic.",
    signup_main_text: "Consigue tus traducciones juradas con HIERØ",
    select: "Elegir",
    traduction_type: "Tipo de traducción",
    find_interpertor: "Buscar un intérprete",
    page_number: "Número de páginas",
    login_caption_title_pro: "Hierø - Interfaz profesional",
    login_main_title_pro: "Interfaz profesional",
    login_main_subtitle_pro: "¡Acceder a su interaz profesional Hierø!",
    signup_caption_title_pro: "Hierø - Interfaz profesional",
    signup_main_subtitle_pro:
      "¡Cree su cuenta, únase a nuestra interfaz y encuentre el traductor más cercano!",
    agency_setup_explanation_pro:
      "Encuentre un traductor jurado rápidamente con nuestra solución. ¡Así podrá traducir tus documentos lo antes posible!",
    main_header_subtitle_pro: "Interfaz profesional",
    main_left_menu_section_navigation_order: "Mis pedidos",
    order_id: "Id",
    order_language_from: "Del",
    order_status: "Estatus",
    status_orders: "Estado del pedido",
    finaliser_commande: "Finalizar el pedido",
    order_traductor_delegate: "Traductores",
    new_order: "Pedir una traducción",
    new_order_ass: "Pedir una traducción jurada",
    section_order_title: "Mis pedidos",
    section_card_title: "Suscripción",
    account_budget: "Presupuesto ",
    account_budget_placeholder: "",
    account_budget_help: "",
    label_origin_lang: "Idioma de origen",
    find_traductors: "Buscar traductores",
    tasks_price_ttc: "Precio (IVA)",
    order_no_asser_query_pdf_page_number:
      "¿Su documento contiene más de una página?",
    order_no_asser_query_pdf_page_number_yes:
      "Indique el número de páginas del documento",
    no_traductor: "¡Aún no hay traductores disponibles! ",
    no_traductor_message: "Cierre la ventana e intente nuevamente",
    loading: "Pendiente ...",
    menu: "Elegir el tipo de traducción",
    alert_form_error_pages_number:
      "El número de páginas no puede ser negativo.",
    urgency: "Plazo de entrega solicitado para esta traducción",
    message_no_budget: "Actualmente tiene un presupuesto de ",
    message_contact: "Póngase en contacto con nosotros para modificarlo.",
    assermente: "Jurado(a)",
    description: "Descripción",
    main_left_menu_title_asser: "Traducción jurada",
    main_left_menu_section_parameters_card: "Mi suscripción",
    main_menu_left_section_navigation_assistance: "Asistencia",
    main_menu_left_section_navigation_ticket: "Crear un tiquete",
    main_menu_left_section_navigation_question: "Preguntas frecuentes",
    date_commande: "Fecha del pedido",
    single_commande_paragraphe:
      "El traductor seleccionado ha aceptado tu pedido. Una vez terminada la traducción, deberás validar y confirmar. Si el traductor seleccionado rechaza el pedido, nuestro software enviará automáticamente tu pedido a otro traductor.",
    commande_receive: "Pedido recibido el",
    doc_a_traduire: " Documento a traducir",
    trad_asser: "Traductor jurado",
    pays_origine: "País de origen",
    visioner_doc: "Ver el documento",
    auth_doc:
      "El traductor verifica la autenticidad del documento antes de comenzar la traducción.",
    doc_bug:
      "En caso de problemas, el traductor puede contactarte directamente por correo electrónico.",
    section_questions_frequentes: "Preguntas frecuentes",
    section_questions_contact:
      "¿Es posible ponerse en contacto con el traductor para hacer preguntas o conocer detalles sobre el servicio?",
    section_questions_paragraphe_six:
      "¡Absolutamente! Tienes acceso al chat cuando accedes a los detalles del pedido, o en la pestaña 'Servicio de mensajes'. También puedes utilizar el servicio de mensajería para concertar un lugar de destino para la entrega de documentos",
    section_questions_traductions:
      "¿Por qué el servicio de traducción clásica es diferente del servicio de traducción jurada?",
    section_questions_paragraphe_seven:
      "Al ser los documentos clásicos más aleatorios, el algoritmo no puede generar un modelo. El cliente transmite el documento, que puede descargarse al acceder a los detalles del pedido. Una vez traducido, el traductor lo carga y lo envía al cliente para que lo valide",
    agency_support: "Asistencia técnica",
    agency_support_service_client: "Número de teléfono - Servicio al cliente",
    agency_support_ouverture:
      "Horario de atención de lunes a viernes, desde las 09:00 hasta las 17:30.",
    agency_support_questions:
      "¿Tienes una pregunta, has detectado algún error? Contáctanos por correo electrónico",
    agency_support_guide: "Guía del usuario profesional",
    agency_support_guide_p:
      "¿Te perdiste una etapa? ¿Quieres saber cómo funciona la plataforma Hierø? Descarga nuestra guía de usuario.",
    agency_support_download_guide: "Descargar nuestra guía",
    agency_ticket_title: "Mis tiquetes antiguos",
    agency_ticket_objet: "Tema",
    agency_ticket_question: "Su pregunta",
    agency_ticket_state: "Estatuto",
    agency_ticket_head:
      "¿Necesitas ayuda? ¿No has encontrado la respuesta que necesitas en las preguntas frecuentes? ¡Realiza todas tus preguntas, te responderemos lo antes posible!",
    consult_orders: "Ver mis pedidos",
    menu_label_intervention: "La intervención debe tener lugar en",
    menu_label_event_name: "Nombre del evento",
    nb_heures_estimee: "Horas estimadas",
    menu_langue: "Idiomas",
    table_header_type_de_document: "Tipo de documento",
    table_header_nb_pages: "Número de páginas",
    table_hader_details: "Detalles",
    joindre_salon: "Entrar en la sala",
    interpretation_paragraphe_9:
      "Para invitar a los participantes, haga clic en el siguiente botón para copiar el enlace de invitación y pegarlo en su mensaje respetando el nombre de la sala ",
    interpretation_paragraphe_10:
      "Haga clic aquí para copiar el enlace de la invitación ",
    interpretation_paragraphe_11:
      "Haga clic aquí para invitar a oradores o participantes ",
    room_inter_pro: "Su nombre de usuario",
    copy_alert: "¡Copiado!",
    commander_interprete: "Pedir una interpretación",
    /********* fin du bloc pour les phrases utilisées sur le pro et particulier ********/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    modal_supp_title: "Confirmar la supresión de tu cuenta",
    facture_part: "Particular",
    tasks_filters: "Filtros",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    modif_profile_success_pwd: "Tu contraseña ha sido actualizada con éxito.",
    modif_profile_failed_pwd:
      "Ocurrió un error al cambiar tu contraseña. Por favor, compruebes que tu contraseña es correcta.",
    modal_modif_title: "Confirmar la modificación de tu correo electrónico",
    modif_profile_failed_login:
      "Correo electrónico o contraseña incorrecta. Intentar otra vez.",
    augmente_budget:
      "Para aumentar tu saldo, puedes enviarnos un cheque o hacer una transferencia",
    no_budget: "No tienes fondos por el momento",
    validation_mail_registration:
      "¡Bievenido a HIERO! Para utilizar nuestros servicios, debes confirmar tu correo electrónico.",
    validation_modif_mail:
      "Tu correo electrónico se ha cambiado con éxito. Para utilizar nuestros servicios, debes confirmar tu correo electrónico otra vez.",
    validation_question:
      "¿No recibiste el correo de confirmación? Haga clic en el botón para enviar de nuevo el correo.",
    validation_mail_resend:
      "Enviar de nuevo el correo electrónico de confirmación",
    message_verification:
      "Por favor, confirmes tu correo electrónico y revises tu buzón de correo.",
    non_valid_address: "Correo electrónico no confirmado",
    validation_mail_success:
      "Correo electrónico de confirmación enviado con éxito.",
    modif_profile_success: "¡Tu perfil has sido actualizado!",
    modif_profile_failed:
      "Se ha producido un error al editar tu perfil. Por favor, comprueba tus datos y inténtalo de nuevo.",
    facture_echeance: "Vencimiento",
    facture_conditions: "Condiciones de pago",
    facture_comptant: "En efeectivo",
    facture_adress: "Enviar a",
    facture_qte: "Cantidad",
    facture_price: "Precio",
    facture_unitaire: "Unitario",
    facture_montant: "Importe",
    facute_solde: "Total",
    facture_tva_fr: "IVA FR 20%",
    order_language_to: "Al",
    account_hist_pro_date: "Fecha de la intervención",
    select_choose: "Elegir...",
    tasks_price_tva: "IVA",
    order_no_asser_document_name: "Nombre del documento",
    menu_label_traductor_asser: "Traducción jurada",
    menu_label_traductor_classic: "Traducción clásica",
    menu_label_interprestation: "Interpretación",
    menu_label_all: "Ver todo",
    login_caption_title: "Hierø - Interfaz traductor",
    login_caption_subtitle:
      "Un programa informático diseñado por traductores para los traductores.",
    login_main_logo: "Hierø",
    login_main_connect: "Conectarse",
    login_main_email: "Dirección electrónica",
    login_main_email_placeholder: "Indicar la dirección electrónica",
    login_main_email_help: "Inicie la sesión con su dirección electrónica",
    login_main_password: "Contraseña",
    login_main_password_placeholder: "Indicar su contraseña",
    login_main_password_help: "",
    login_main_button: "Conectarse",
    login_main_to_signup: "¿No dispone de una cuenta? ¡Registrarse!",
    login_main_copyright: "© Hierø - 2022",
    login_error_required_field: "Campo obligatorio",
    login_error_invalid_email: "La dirección electrónica no es válida.",
    login_error_email_not_found: "La dirección electrónica no existe.",
    signup_caption_subtitle: "¡Elija Hierø y gane en visibilidad!",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscripción",
    signup_main_email: "Dirección electrónica",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Contraseña",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmar vuestra contraseña",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Apellido",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Nombre",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Teléfono",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Suscribir",
    signup_main_to_signup: "¿Dispone de una cuenta? Conectarse",
    signup_main_copyright: "© Hierø - HIERØ 2020",
    signup_error_required_field: "Campo obligatorio",
    signup_error_invalid_email: "La dirección electrónica no es válida.",
    signup_error_min_password: "Al menos 8 caracteres",
    signup_error_max_password: "30 caracteres máximo",
    signup_error_passwords_do_not_match: "Las contraseñas no coinciden",
    login_forgot_password: "¿Has olvidado la contraseña?",
    reset_password_main: "Reiniciar la contraseña ",
    reset_password_email_title: "Dirección electrónica",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Enviar",
    reset_password_login: "Conexión",
    reset_password_success:
      "Se ha enviado un correo a su dirección electrónica. Luego haga clic en el enlace para cambiar la contraseña.",
    reset_password_error_required: "Campo obligatorio",
    reset_password_error_invalid_email: "La dirección electrónica no es válida",
    reset_password_error_user_not_found: "La dirección electrónica no existe",
    main_header_user_menu_logout: "Desconectar",
    main_left_menu_section_parameters_agency: "Mi empresa",
    main_left_menu_section_parameters_services: "Mis servicios",
    main_left_menu_section_parameters_user: "Mi cuenta",
    main_left_menu_section_parameters_support: "Soporte",
    agency_setup_companyname: "Razón social",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "El nombre de la empresa a utilizar para los pedidos, las facturas, etc...",
    agency_setup_address: "Dirección",
    agency_setup_siret: "NIF",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help: "Ingrese su número de siret si es una empresa",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Empieza a escribir la dirección y después selecciona la dirección adecuada en el menú desplegable.",
    agency_setup_addresscompl: "Complementos de dirección",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Edificio, escalera, código de acceso, etc. Cuando tus clientes recogen los documentos, necesitan todas las informaciones.",
    agency_setup_tel: "Teléfono",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "El número de teléfono a utilizar para conversar con tus clientes.",
    agency_setup_email: "Dirección electrónica",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "La dirección electrónica a utilizar para conversar con tus clientes. Por favor indica otra si no es la dirección de registro de Hierø.",
    agency_setup_save: "Guardar",
    agency_setup_error_required_field: "Campo obligatorio",
    agency_setup_error_invalid_email: "La dirección electrónica no es válida.",
    agency_setup_error_valid_address:
      "Por favor escoge una de las direcciones propuestas en el menú desplegable.",
    agency_address_not_found: "¿No se puede encontrar su dirección?",
    agency_address_title: "Introducir manualmente sus datos",
    agency_address_address: "Dirección :",
    agency_address_number: "N.°",
    agency_address_street: "Calle",
    agency_address_code: "Código postal",
    agency_address_city: "Ciudad",
    agency_address_country: "País",
    agency_address_coords: "Coordenadas GPS:",
    agency_address_coords_lat: "Latitud",
    agency_address_coords_long: "Longitud",
    agency_address_cancel: "Cancelar",
    agency_address_validate: "Validar",
    main_left_menu_section_navigation_dashboard: "Panel de control",
    main_menu_left_section_navigation_factures: "Mis facturas",
    head_facture: "Factura",
    section_date: "Fecha",
    section_numero_facture: "Factura",
    section_type_prestation: "Tipo de servicio",
    section_price: "Total",
    section_print: "Imprimir",
    section_generate_facture: "Generar la factura en PDF",
    section_consulter: "Consultar",
    prestation: "Servicio",
    commande_qte: "Cantidad",
    commande_produit: "Producto",
    commande_desc: "Descripción",
    total_ht: "Total antes de impuestos",
    total_tva: "Total con IVA",
    total_ttc: "Total, con IVA incluido",
    retour_btn: "Regresar",
    progress_commande: "Seguimiento del pedido",
    commande_numero: "Nº de pedido°",
    capital_sasu: "SASU con un capital social de 7 000,00 euros",
    rcs_infos: "RCS EVRY B 843 119 728 ",
    rcs_infos_new: "RCS PARIS B 843 119 728 ",
    siret: " NIF 84311972800023",
    siret_new: " NIF 84311972800031",
    tva_fr: "Número de IVA: FR00843119728",
    condition_paragraphe_1: "Penalidad por retraso (tasa anual): 10%",
    condition_paragraphe_2: "No hay descuento por pago anticipado",
    condition_paragraphe_3:
      "Indemnización fija para los costes de cobro en caso de retraso en el pago: 40€",
    prestataire: "Proveedor",
    my_info: "Mi información",
    geolocation_paragraphe:
      "El software necesita conocer tu ubicación para buscar traductores cerca de ti con precisiónn",
    update_password: "Cambiar mi contraseña",
    account_upt: "Si quieres, puedes cambiar tu contraseña.",
    send: "enviado",
    close_modal: "Cerrar",
    inscription_date: "Registrado desde ",
    order_translate: "del ",
    montant_total_euro: "Total en EUR",
    rules_conditions: "CONDICIONES DE PAGO",
    rules_conditions_p: "pago al recibir la factura",
    section_agency_title: "Mi empresa",
    section_account_title: "Mi cuenta",
    section_support_title: "Soporte",
    notifications_new_task: "¡Usted recibió una nueva misión!",
    notifications_task_confirmed:
      "La misión fue confirmada. Usted puede empezar la traducción.",
    notifications_task_validated: "El cliente validó la misión.",
    tasks_sent_on: "Enviado el",
    tasks_src_country: "País de origen",
    tasks_dst_country: "Traducir al",
    tasks_state: "Estado",
    state_cancelled: "Cancelado por el cliente",
    state_waiting_for_translator: "Nuevo",
    state_waiting_for_payment: "Esperando el pago",
    state_refused_by_translator: "Misión rechazada",
    state_translating: "Traducción pendiente",
    state_waiting_for_client_validation:
      "Terminada. Esperando la validación por el cliente",
    state_validated: "Validado por el cliente",
    state_validated_pro: "Validado",
    prestation_order_from: "De",
    prestation_order_document_type: "Tipo de documento",
    prestation_order_messaging_title: "Mensajería electrónica",
    prestation_order_messaging_send: "Enviar",
    prestation_order_messaging_client: "Cliente:",
    prestation_order_messaging_me: "Yo:",
    prestation_document_edit: "Editor de documento",
    prestation_document_edit_expl:
      "El texto en el campo más arriba es subrayado en el documento más abajo. Al entrar el texto traducido en el campo, el documento se actualizará automáticamente. Utilice las flechas para navegar el documento.",
    prestation_document_get_browser:
      "¿Usted no consigue ver el PDF? Por favor utilice un navegador reciente como",
    prestation_document_confirm_oui: "Sí",
    prestation_document_confirm_non: "No",
    account_save: "Guardar",
    account_surname: "Apellido",
    account_surname_placeholder: "",
    account_name: "Nombre",
    account_name_placeholder: "",
    account_telephone: "Teléfono",
    account_telephone_placeholder: "",
    account_adresse: "Dirección",
    account_adresse_placeholder: "Dirección",
    account_complement_adr: "Dirección adicional",
    account_complement_adr_placeholder: "Dirección adicional",
    account_email: "Correo electrónico",
    account_email_placeholder: "Correo electrónico",
    account_password: "Contraseña actual",
    account_new_password: "Nueva contraseña",
    account_confirm: "Confirmar vuestra contraseña",
    account_error_required: "Campo obligatorio",
    account_error_invalid_email: "La dirección electrónica no es válida.",
    account_error_min_password: "Al menos 8 caracteres",
    account_error_passwords_do_not_match: "Las contraseñas no coinciden",
    agency_expl_1_title: "Razón social",
    agency_expl_1_expl:
      "Especifique el nombre de su empresa para que los clientes pueden encontrarlo.",
    agency_expl_2_title: "Localización",
    agency_expl_2_expl:
      "La aplicación necesita su información de contact para ponerlo en  contacto con los clientes más cercano a usted.",
    agency_expl_3_title: "Activar su cuenta",
    agency_expl_3_expl:
      "Vuestro perfil será visible en línea, una vez que guarde sus informaciones de contacto.",
    services_dst_lang: "Al",
    support_title: "Nuestros locales",
    support_company: "Hiero",
    support_addr1: "7 Rue Montespan",
    support_addr1_paris: "83 boulevard de Magenta",
    support_addr2: "91000 Évry-Courcouronnes",
    support_addr2_paris: "75010 Paris",
    support_addr3: "Francia",
    support_contact: "Contacto",
    support_tel: "Tel.: (+33) 1 80 38 30 46",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Elemento no encontrado",
    not_found_subtitle: "La página que solicitó no existe.",
    not_found_action: "Contactar el soporte ",
    not_found_home: "Inicio",
    menu_label_sur_place: "En el lugar",
    menu_label_visio: "Por videoconferencia",
    consulter_facture: "Consultar mis facturas",
    facture_numero: "Factura N°",
    recap_tva: "RESUMEN DEL IVA",
    recap_taux: "TASA",
    recap_mt_tva: "TOTAL DEL IVA",
    recap_base_ht: "BASE SIN IVA",
    action: "Acción",
    hours: "Horas",
    join_room: "Entrar en la sala",
    rooms: "Las salas (salas de reuniones)",
    warning: "¡Atención! ",
    conf: "La conferencia",
    warning_1: "Debe respetarse la ortografía de los nombres de las salas.",
    interpretation_info: "Explicación",
    interpretation_paragraphe_1:
      "Para participar en la misión de interpretación, necesita al menos dos ventanas. En la primera, estarás con el intérprete, en la segunda con todos los participantes.",
    interpretation_paragraphe_2:
      "Para acceder a la sala de conferencias, el nombre de la sala a indicar corresponde a ",
    interpretation_paragraphe_4:
      "Para acceder a la sala de interpretación, el nombre de la sala a indicar corresponde a ",
    interpretation_paragraphe_3:
      ". Aquí se encuentran todos los participantes.",
    interpretation_paragraphe_5:
      ". Aquí se encuentran sus oradores y el intérprete seleccionado.",
    interpretation_paragraphe_6:
      "Para realizar correctamente el trabajo de interpretación de esta conferencia a distancia, es necesario que el orador y el intérprete no hablen al mismo tiempo en la misma sala.",
    interpretation_paragraphe_7:
      "Por eso están en dos salas diferentes. De este modo, los participantes sólo le oirán a usted en la sala de conferencias y usted sólo oirá a los oradores en la sala de interpretación.",
    interpretation_paragraphe_8:
      "Por razones de rendimiento, la capacidad máxima de una sala es de 50 participantes. Puede crear tantas salas correspondientes a su público como desee y comunicar los nombres de estas salas a su intérprete.",
    room_conf: " Sala de conferencias",
    traduction_assermentee: "Traducción jurada",
    traduction_classic: "Traducción clásica",
    interpretation: "Interpretación",
    AF: "Afganistán",
    ZA: "Sudáfrica",
    AL: "Albania",
    DZ: "Argelia",
    DE: "Alemania",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AQ: "Antártida",
    AG: "Antigua y Barbuda",
    AN: "Antillas Neerlandesas",
    SA: "Arabia Saudita",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaiyán",
    BS: "Bahamas",
    BH: "Baréin",
    BD: "Bangladés",
    BB: "Barbados",
    BY: "Belarús",
    BE: "Bélgica",
    BZ: "Belice",
    BJ: "Benín",
    BM: "Bermudas",
    BT: "Bután",
    BO: "Bolivia",
    BA: "Bosnia y Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    BN: "Brunéi",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Camboya",
    CM: "Camerún",
    CA: "Canadá",
    CV: "Cabo Verde",
    EA: "Ceuta y Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Chipre",
    CO: "Colombia",
    KM: "Comoras",
    CG: "República del Congo",
    KP: "Corea del Norte",
    KR: "Corea del Sur",
    CR: "Costa Rica",
    CI: "Costa de Marfil",
    HR: "Croacia",
    CU: "Cuba",
    DK: "Dinamarca",
    DG: "Diego García",
    DJ: "Yibuti",
    DM: "Dominica",
    EG: "Egipto",
    SV: "El Salvador",
    AE: "Emiratos Árabes Unidos",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "España",
    EE: "Estonia",
    VA: "Estado de la Ciudad del Vaticano",
    FM: "Estados Federados de Micronesia",
    US: "Estados Unidos",
    ET: "Etiopia",
    FJ: "Fiyi",
    FI: "Finlandia",
    FR: "Francia",
    GA: "Gabón",
    GM: "Gambia",
    GE: "Georgia",
    GS: "Islas Georgias del Sur y Sándwich del Sur",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grecia",
    GD: "Granada",
    GL: "Groenlandia",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Guinea ecuatorial",
    GW: "Guinea-Bisáu",
    GY: "Guyana",
    GF: "Guayana Francesa",
    HT: "Haití",
    HN: "Honduras",
    HU: "Hungría",
    BV: "Isla Bouvet",
    CX: "Isla de Navidad",
    CP: "Isla Clipperton",
    AC: "Isla Ascensión",
    IM: "Isla de Man",
    NF: "Isla Norfolk",
    AX: "Islas Åland",
    KY: "Islas Caimán",
    IC: "Islas Canarias",
    CC: "Islas Cocos / Islas Keeling",
    CK: "Islas Cook",
    FO: "Islas Feroe",
    HM: "Islas Heard y McDonald",
    FK: "Islas Malvinas",
    MP: "Islas Marianas del Norte",
    MH: "República de las Islas Marshall",
    UM: "Islas Ultramarinas Menores de los Estados Unidos",
    SB: "Islas Salomón",
    TC: "Islas Turcas y Caicos",
    VG: "Islas Vírgenes Británicas",
    VI: "Islas Vírgenes de os Estados Unidos",
    IN: "India",
    ID: "Indonesia",
    IQ: "Irak",
    IR: "Irán",
    IE: "Irlanda",
    IS: "Islandia",
    IL: "Israel",
    IT: "Italia",
    JM: "Jamaica",
    JP: "Japón",
    JE: "Jersey",
    JO: "Jordania",
    KZ: "Kazajistán",
    KE: "Kenia",
    KG: "Kirguistán",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesoto",
    LV: "Letonia",
    LB: "Líbano",
    LR: "Liberia",
    LY: "Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Luxemburgo",
    MK: "Macedonia",
    MG: "Madagascar",
    MY: "Malasia",
    MW: "Malaui",
    MV: "Maldivas",
    ML: "Malí",
    MT: "Malta",
    MA: "Marruecos",
    MQ: "Martinica",
    MU: "Mauricio",
    MR: "Mauritania",
    YT: "Mayotte",
    MX: "México",
    MD: "Moldavia",
    MC: "Mónaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Níger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Noruega",
    NC: "Nueva Caledonia",
    NZ: "Nueva Zelandia",
    OM: "Omán",
    UG: "Uganda",
    UZ: "Uzbekistán",
    PK: "Pakistán",
    PW: "Palaos",
    PA: "Panamá",
    PG: "Papúa Nueva Guinea",
    PY: "Paraguay",
    NL: "Países Bajos",
    PE: "Perú",
    PH: "Filipinas",
    PN: "Islas Pitcairn",
    PL: "Polonia",
    PF: "Polinesia Francesa",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Catar",
    HK: "R.A.E de Hong Kong de la República Popular China",
    MO: "R.A.E. de Macao",
    QO: "Oceanía Lejana",
    CF: "República Centroafricana",
    CD: "República Democrática del Congo",
    DO: "República Dominicana",
    CZ: "República Checa",
    RE: "Reunión",
    RO: "Rumanía",
    GB: "Reino Unido",
    RU: "Rusia",
    RW: "Ruanda",
    EH: "Sahara Occidental",
    BL: "San Bartolomé",
    KN: "San Cristóbal y Nieves",
    SM: "San Marino",
    MF: "Isla San Martín",
    PM: "San Pedro y Miquelón",
    VC: "San Vicente y las Granadinas",
    SH: "Isla Santa Elena",
    LC: "Santa Lucia",
    WS: "Samoa",
    AS: "Samoa Americana",
    ST: "Santo Tomé y Príncipe",
    SN: "Senegal",
    RS: "Serbia",
    CS: "Serbia y Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leona",
    SG: "Singapur",
    SK: "Eslovaquia",
    SI: "Eslovenia",
    SO: "Somalia",
    SD: "Sudán",
    LK: "Sri Lanka",
    SE: "Suecia",
    CH: "Suiza",
    SR: "Surinam",
    SJ: "Svalbard y Jan Mayen",
    SZ: "Suazilandia",
    SY: "Siria",
    TJ: "Tayikistán",
    TW: "Taiwán",
    TZ: "Tanzania",
    TD: "Chad",
    TF: "Tierras Australes y Antárticas Francesas",
    IO: "Territorio Británico del Océano Índico",
    PS: "Territorios Palestinos",
    TH: "Tailandia",
    TL: "Timor Oriental",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad y Tobago",
    TA: "Tristán da Cunha",
    TN: "Túnez",
    TM: "Turkmenistán",
    TR: "Turquía",
    TV: "Tuvalu",
    UA: "Ucrania",
    EU: "Unión Europea",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis y Futuna",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabue",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "¿Necesitas una traducción jurada?",
    dashboard_pro_command_company_priceHT: "Precio (sin IVA):",
    new_orders: "Hacer un nuevo pedido",
    state_pro_wait: "Pendiente",
    account_expl_pro_cheque: "Por favor envíe un cheque a Hiero",
    account_expl_pro_viremement: "Transferencias ",
    account_expl_pro_iban: "IBAN ES 000",
    account_hist_pro_debit: "Débito ",
    account_hist_pro_credit: "Crédito ",
    account_hist_pro_way: "Medio ",
    account_hist_pro_ref: "Referencia ",
    agency_pro_budget_help:
      "El presupuesto que desea asignar a sus traducciones.",
    sub_training: "Formación de equipo ",
    sub_search: "Búsqueda automática de traductor/intérprete ",
    sub_geo: "Geolocalización del traductor y/o del intérprete ",
    sub_dem: "Desmaterialización de todos tus pedidos ",
    sub_save: "Copia de seguridad de tus documentos con gestión de archivos ",
    sub_recap: "Resumen de tus pedidos por mes",
    sub_proj: "Progreso de tus proyectos en tiempo real ",
    sub_management: "Gestión de presupuesto en tiempo real ",
    sub_message: "Mensajería automática",
    sub_send: "Entrega de tus traducciones por correo ",
    sub_impl: "Implementación del presupuesto asignado para 12 meses ",
    sub_account: "Account manager a tu lado ",
    sub_orientation:
      "Orientación personalizada según los idiomas más solicitados",
    sub_split:
      "División del presupuesto en varios departamentos según los usuarios ",
    sub_event: "Entrega de tus traducciones por mensajero ",
    choose: "Elegir",
    sub_function: "Características",
    sub_standard: "Estándar",
    sub_essen: "Esencial",
    sub_premium: "Premium",
    sub_month: "mes",
    sub_associations_label: "Asociaciones",
    sub_cabinet_label: "Despachos de abogados",
    sub_bank_label: "Movilidad internacional",
    sub_academia_label: "Instituciones académicas",
    sub_who_are_you: "¿Eres…?",
    sub_explanation_academia:
      "Queremos trabajar en colaboración con las instituciones académicas, como universidades.",
    sub_explanation_systeme: "Ofrecemos dos sistemas diferentes: ",
    sub_system_one:
      "Utilizar nuestra aplicación móvil s la institución académica no cubre las traducciones.",
    sub_system_two:
      "Negociar la implantación de nuestro espacio profesional si la institución académica cubre las traducciones.",
    sub_credit_card_message:
      " Debes pagar su suscripción para activar tus servicios. En la pestaña",
    validate_credit_card_message: "Tu pedido ha sido procesado.",
    subscriber_choose: "Elija tu suscripción. ",
    find_out_more: "Más información",
    menu_label_relecture: "Relectura",
    signup_error_email_not_found: "La dirección electrónica no existe.",
    main_left_menu_section_accueil: "Inicio",
    agency_setup_email_help_pro:
      "El correo electrónico que se utilizará para corresponder con nuestros traductores y el equipo de Hierø.",
    main_menu_left_section_navigation_entreprise: "Mi empresa",
    main_menu_left_section_navigation_messagerie: "Mis mensajes",
    main_menu_left_section_navigation_gestion_des_fichiers:
      "Gestión de archivos",
    montant_total: "Importe total",
    single_commande_p1: "El traductor descarga el documento para traducirlo. ",
    single_commande_p2:
      "Una vez traducido, lo añade al pedido para que puedes validar la traducción y confirmar el pedido.",
    load_intervention: "Intervención en proceso",
    trad_name: "Nombre del traductor",
    visioner: "Ver",
    infos_interv: "Información adicional sobre la participación",
    intervention_time_date: "Fecha y hora de la intervención",
    loading_order: "Mis pedidos de traducción en curso",
    view_all_orders: "Ver todos los pedidos",
    last_payment: "Mis últimos pagos realizados",
    view_all_payment: "Ver todos mis pagos",
    last_send_msg: "Últimos mensajes enviados",
    state_accept_order: "Pedido aceptado",
    account_expl:
      "Indique sus datos para que podemos contactarle si es necesario.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: "si el problema persiste. ",
    menu_label_interpretation: "Solicitar una interpretación ",
    table_header_statut: "Estatus",
    head_professionnal:
      "El software necesita conocer tu posición para buscar los traductores más cercanos con una mayor precisión",
    head_change_password: "Si lo deseas, puedes cambiar tu contraseña",
    address_facturation: " Cambiar mi dirección de facturación",
    recap_tva_fr: "20% IVA FR",
    client_particulier_name: "Razón social",
    client_particulier_email: "Correo electrónico",
    client_particulier_address: "Dirección ",
    client_particulier_code_postal: "Ciudad",
    infos_entrep: "",
    infos_commandes: "",
    particulier_name: "Nombre del individuo",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
  pt: {
    /************** Bloc pour les phrases utilisées que sur le particulier *************/
    modif_profile_failed_pfp:
      "Não foi possível atualizar a sua foto de perfil.",
    modif_profile_failed_login_attempts:
      "3ª tentativa: dados de início de sessão ou senha incorretos. Não foi possível atualizar seu correio eletrónico. Tente novamente.",
    agency_support_guide_part: "O guia do usuário",
    agency_setup_title_part: "A propósito",
    finaliser_pay: "Proceder ao pagamento da encomenda",
    new_orders_traduction: "Pedir uma tradução",
    new_orders_interprestation: "Pedir uma interpretação",
    main_header_title_particulier:
      "Solicite as suas traduções juramentadas com Hierø",
    main_left_menu_section_compte: "Conta",
    main_menu_left_section_navigation_messages: "Minhas mensagens",
    add_file: "Adicionar um documento anexado",
    head_particulier:
      "As respostas às suas perguntas podem estar nesta página. Caso contrário, não hesite em abrir um bilhete.",
    partuclier_trad_doc_head1:
      "O tradutor não entregou o meu documento. O que fazer?",
    partuclier_trad_doc1:
      "Pode tentar contactar o tradutor usando a mensageria ou através do apoio técnico se o tradutor não responde aos seus pedidos. O tradutor deve dar-lhe uma razão válida para não poder entregar o seu documento dentro do prazo solicitado.",
    partuclier_trad_doc_head2:
      "Já tenho a versão digital da tradução juramentada. Porquê receber o original?",
    partuclier_trad_doc2:
      "A versão digital da tradução não tem valor legal e não pode ser utilizada para procedimentos que requerem uma tradução por um tradutor ajuramentado. A versão original da tradução é a única aceitada pelas autoridades competentes.",
    partuclier_trad_doc_head3:
      "O que sucede se o tradutor que escolhi não responde?",
    partuclier_trad_doc3:
      "Idealmente, os tradutores estão disponíveis para tratar a sua encomenda. Pode acontecer que alguns deles demorem muito tempo a responder. Neste caso, o nosso programa informático irá encaminhá-lo para um tradutor próximo de si que será capaz de tratar o seu pedido de tradução/interpretação.",
    partuclier_trad_doc_head4:
      "Não posso ir ao tradutor para obter o meu documento.",
    partuclier_trad_doc4:
      "Se não conseguir recuperar pessoalmente o documento original, o tradutor pode sempre enviar-lhe os documentos pelo correio.",
    partuclier_trad_doc_head5:
      "A tradução que recebi parece-me ser de má qualidade, ou mesmo errada ou incompleta",
    partuclier_trad_doc5:
      "Guarde o documento como prova, contacte o tradutor para uma explicação. Se o tradutor se rejeitar a fazer alterações, contacte directamente o suporte técnico para intervenção.",
    partuclier_trad_doc_head6:
      "E se o software não detectar um tradutor nas proximidades ?",
    partuclier_trad_doc6:
      "Pode acontecer que não tenhamos um tradutor listado para a língua que lhe interessa, ou que um não esteja por perto. Se for este o caso, contacte o suporte técnico, ou selecionar um tradutor remoto se não tiver sido sugerido nenhum",
    agency_support_guide_pro: "O guia do usuário",
    delete_account: "Cancelar a minha conta",
    account_paragraphe1: "Você não precisa mais solicitar traduções?",
    account_paragraphe2: "Você não deseja mais usar nossos serviços?",
    account_paragraphe3: "Pde cancelar a sua subscrição a qualquer momento",
    account_delete: "Eu desejo cancelar a minha conta",
    message_paragraphe:
      "Fala com os tradutores para qualquer informação sobre o seu pedido",
    discuss_encours: "Conversações em progresso",
    cdu: "Ao clicar em 'Registar', está a concordar com as nossas",
    cdu1: "condições de utilização",
    cdu2: "e a nossa política de privacidade",
    cdu3: "assim como",
    ticket_numero: "Bilhete nº",
    price_unitaire: "Preço unitário excluindo impostos",
    menu_head_ticket: "Mis tickets",
    table_header_archives: "Arquivo de encomendas",
    client_facture_detail: "Cliente",
    salle_de_conference: "Sala de conferencias",
    /********** fin du bloc pour les phrases utilisées que sur le particulier **********/

    /************ Bloc pour les phrases utilisées sur le pro et particulier ************/
    state_translated_order: "Documento traduzido",
    newsletter:
      "Aceito receber por correio eletrónico atualizações, ofertas especiais e novidades da plataforma e/ou outros produtos desenvolvidos por Hierø",
    online: "Manter-se conectado ",
    login_text:
      "Com a interface Hierø, solicite as suas traduções e selecione o seu tradutor ajuramentado em um clique.",
    signup_main_text: "Solicite as suas traduções juramentadas com HIERØ",
    select: "Escolher",
    traduction_type: "Tipo de tradução",
    find_interpertor: "Encontrar um intérprete",
    page_number: "Número de páginas",
    login_caption_title_pro: "Hierø - Interface profissional",
    login_main_title_pro: "Interface profissional",
    login_main_subtitle_pro: "Aceda ao seu espaço profissional Hierø!",
    signup_caption_title_pro: "Hierø - Interface profissional",
    signup_main_subtitle_pro:
      "Crie sua conta, entre na nossa interface e encontre o tradutor mais perto!",
    agency_setup_explanation_pro:
      "Encontre um tradutor juramentado rapidamente graças a nossa solução. Você poderá traduzir seus documentos o mais rápido possível!",
    main_header_subtitle_pro: "Interface profissional",
    main_left_menu_section_navigation_order: "Minhas ordens",
    order_id: "Id",
    order_language_from: "Do",
    order_status: "Estado",
    status_orders: "Status do seu pedido",
    finaliser_commande: "Terminar o pedido",
    order_traductor_delegate: "Tradutores",
    new_order: "Pedir uma tradução",
    new_order_ass: "Pedir uma revisão",
    section_order_title: "Minhas ordens",
    section_card_title: "Subscrição",
    account_budget: "Orçamento ",
    account_budget_placeholder: "",
    account_budget_help: "",
    label_origin_lang: "Língua de origem",
    find_traductors: "Encontrar um tradutore",
    tasks_price_ttc: "Preço (com IVA)",
    order_no_asser_query_pdf_page_number:
      " O seu documento tém mais do que uma página?",
    order_no_asser_query_pdf_page_number_yes:
      "Indicar o número de páginas no documento",
    no_traductor: "Ainda não há tradutores disponíveis! ",
    no_traductor_message: "Feche a janela e tene novamente",
    loading: "Pendente ...",
    menu: "Escolher o tipo de tradução",
    alert_form_error_pages_number: "O número de páginas não pode ser negativo.",
    urgency: "Tempo de entrega desejado para esta tradução",
    message_no_budget: "No momento, você tem um orçamento de ",
    message_contact: "Contacte-nos se quiser mudá-lo.",
    assermente: "Juramentado",
    description: "Descrição",
    main_left_menu_title_asser: "Tradução juramentada",
    main_left_menu_section_parameters_card: "Minha subscrição",
    main_menu_left_section_navigation_assistance: "Suporte técnico",
    main_menu_left_section_navigation_ticket: "Abrir um novo ticket",
    main_menu_left_section_navigation_question: "Preguntas Mais Frequentes",
    date_commande: "Data de encomenda",
    single_commande_paragraphe:
      "O tradutor esclohido aceito a sua encomenda. Quando a tradução estiver pronta, você terá de validar e confirmar o documento.",
    commande_discuss: "Falar diretamente com o tradutor",
    intervention_details: "Detalhes da intervenção",
    commande_receive: "Encomenda recebida em",
    doc_a_traduire: " Documento a traduzir",
    trad_asser: "Tradutor ajuramentado",
    pays_origine: "País de origem",
    visioner_doc: "Ver o documento",
    auth_doc:
      "O tradutor verifica a autenticidade do documento antes de iniciar a tradução.",
    doc_bug:
      "Se tiver algum problema, o tradutor pode contactá-lo diretamente no chat.",
    section_questions_frequentes: "Perguntas mais frequentes",
    section_questions_contact:
      "É possível contactar o tradutor se tiver dúvidas ou para detalhes sobre a encomenda?",
    section_questions_paragraphe_six:
      "Absolutamente! Tem acesso às mensagens quando acede aos detalhes da encomenda, ou no separador 'Serviço de mensagens'. O chat também pode ser utilizado para discutir da entrega de documentos em mão própria.",
    section_questions_traductions:
      "Porque o serviço de tradução 'clássica' é diferente do 'juramentado'?",
    section_questions_paragraphe_seven:
      "Os documentos classicos são todos diferentes, um modelo não pode ser gerado pelo algoritmo. O cliente envia o documento em versão descarregável quando se acede aos detalhes do pedido. Uma vez traduzido, o tradutor carrega e envia a tradução ao cliente para validação.",
    agency_support: "Suporte Técnico",
    agency_support_service_client:
      "Número de telefone - Serviço de Apoio ao Cliente",
    agency_support_ouverture: "Aberto de segunda a sexta feira, das 9h as 17h",
    agency_support_questions:
      "Você tem uma pergunta, você detectou um erro? ? Contate-nos por correio eletrónico.",
    agency_support_guide: "Guia do usuário profissional",
    agency_support_guide_p:
      "Você perdeu uma etapa? Quer saber como funciona a plataforma Hierø? Descarregue o nosso guia do usuário!",
    agency_support_download_guide: "Descarregar o guia",
    agency_ticket_title: "Meus tickets antigos",
    agency_ticket_objet: "Assunto",
    agency_ticket_question: "Sua pergunta",
    agency_ticket_state: "Estado",
    agency_ticket_head:
      "Precisa de ajuda? Não encontrou a resposta nas preguntas mais frequentes? Faça todas as suas perguntas, responderemos o mais rápido possível!",
    consult_orders: "Ver os meus pedidos",
    menu_label_intervention: "A intervenção deve ter lugar em",
    menu_label_event_name: "Nome do evento",
    nb_heures_estimee: "Número estimado de horas",
    menu_langue: "Línguas",
    table_header_type_de_document: "Tipo de documento",
    table_header_nb_pages: "Número de páginas",
    table_hader_details: "Detalhes",
    joindre_salon: "Entrar na sala",
    interpretation_paragraphe_9:
      "Para convidar participantes, clique no botão para copiar o link do convite na mensagem respeitando o nome da sala ",
    interpretation_paragraphe_10: "Clique aqui para copiar o link do convite ",
    interpretation_paragraphe_11:
      "Clique aqui para convidar oradores ou participantes ",
    room_inter_pro: "O seu nome de usúario",
    copy_alert: "Copiado!",
    commander_interprete: "Pedir um intérprete",
    /********* fin du bloc pour les phrases utilisées sur le pro et particulier ********/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    modal_supp_title: "Confirmar a supressão da sua conta",
    facture_part: "Particular",
    tasks_filters: "Filtros",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    modif_profile_success_pwd: "Sua senha foi atualizada com sucesso.",
    modif_profile_failed_pwd:
      "Ocorreu um erro ao alterar sua senha. Por favor, verifique se você indicou a senha correta.",
    modal_modif_title: "Confirmar a modificação do seu correio eletrónico",
    modif_profile_failed_login:
      "Correio eletrónico ou senha incorreta. Tente novamente.",
    augmente_budget:
      "Para aumentar seu saldo, você pode nos enviar um cheque ou fazer uma transferência",
    no_budget: "Você não tem fundos no momento",
    validation_mail_registration:
      "Bem-vindo ao HIERO! Para utilizar os nossos serviços, você deve primeiro confirmar seu correio eletrónico.",
    validation_modif_mail:
      "Seu correio eletrónico foi modificado com sucesso. Para utilizar os nossos serviços, você deve confirmar seu correio eletrónico novamente.",
    validation_question:
      "Não recebeu o correio de confirmação? Clique no botão para enviar novamente o correio de confirmação.",
    validation_mail_resend: "Enviar o correio de confirmação novamente",
    message_verification:
      "Por favor, valide seu correio eletrónico e verifique a sua caixa de entrada.",
    non_valid_address: "Correio eletrónico não confirmado",
    validation_mail_success: "Correio de confirmação enviado com sucesso.",
    account_error_invalid_email: "O correio eletrónico não é válido.",
    account_error_min_password: "8 caracteres mínimo",
    account_error_passwords_do_not_match: "As senhas não correspondem",
    account_confirm: "Confirmar sua senha",
    modif_profile_success: "O seu perfil foi actualizado",
    modif_profile_failed:
      "Ocorreu um erro durante a edição do seu perfil. Por favor, confira a sua informação e tente novamente.",
    facture_echeance: "Data de vencimento",
    facture_conditions: "Pagamento",
    facture_comptant: "A vista",
    facture_adress: "Endereço de facturação",
    facture_qte: "Qtd",
    facture_price: "Preço",
    facture_unitaire: "por Unidade",
    facture_montant: "Quantia",
    facute_solde: "Total",
    facture_tva_fr: "IVA FR 20%",
    order_language_to: "Al",
    account_hist_pro_date: "Data de intervenção",
    select_choose: "Escolher...",
    tasks_price_tva: "IVA",
    order_no_asser_document_name: "Nom do documento",
    menu_label_traductor_asser: "Tradução juramentada",
    menu_label_traductor_classic: "Tradução clássica",
    menu_label_interprestation: "Interpretação",
    menu_label_all: "Ver todo",
    login_caption_title: "Hierø - Interface do tradutor",
    login_caption_subtitle:
      "Um programa informático conceibdo pelo tradutores para tradutores.",
    login_main_logo: "Hierø",
    login_main_connect: "Conexão",
    login_main_email: "Correio eletrónico",
    login_main_email_placeholder: "Entra o correio eletrónico",
    login_main_email_help: "Lançar uma sessão com o correio eletrónico",
    login_main_password: "Senha",
    login_main_password_placeholder: "Entra a sua senha",
    login_main_password_help: "",
    login_main_button: "Conexão",
    login_main_to_signup: "Não tem uma conta? Inscreva-se",
    login_main_copyright: "© Hierø - HIERØ 2022",
    login_error_required_field: "Campo obrigatório",
    login_error_invalid_email: "O correio eletrónico é invalido.",
    login_error_email_not_found: "O correio eletrónico não existe.",
    signup_caption_subtitle: "Escolhe Hierø e torne-se visível!",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscrição",
    signup_main_email: "Correio eletrónico",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Senha",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmar a sua senha",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Sobrenome",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Nome",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Telefone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Subscrever",
    signup_main_to_signup: "Você tem uma conta? Iniciar a sessão.",
    signup_main_copyright: "© Hierø - HIERØ 2022",
    signup_error_required_field: "Campo obrigatório",
    signup_error_invalid_email: "O correio eletrónico é invalido ",
    signup_error_min_password: "8 caracteres mínimo",
    signup_error_max_password: "30 caracteres máximo",
    signup_error_passwords_do_not_match: "As senhas não correspondem",
    login_forgot_password: "Esqueceu-se da sua senha?",
    reset_password_main: "Reiniciar a senha",
    reset_password_email_title: "Correio eletrónico",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Enviar",
    reset_password_login: "Conexão",
    reset_password_success:
      "Um correio eletrónico foi mandado no seu endereço. Clique no link para mudar a senha.",
    main_left_menu_section_parameters_agency: "A minha empresa",
    main_left_menu_section_parameters_services: "Os meus serviços",
    main_left_menu_section_parameters_user: "A minha conta",
    main_left_menu_section_parameters_support: "Soporte",
    reset_password_error_required: "Campo obrigatório",
    reset_password_error_invalid_email: "O correio eletrónico é invalido.",
    reset_password_error_user_not_found: "O correio eletrónico não existe.",
    main_header_user_menu_logout: "Desconectar",
    agency_setup_companyname: "Nome comercial",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "O nome comercial a utilizar para encomendas, faturas, etc.",
    agency_setup_address: "Endereço",
    agency_setup_siret: "NIF/SIRET",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help: "Entra o seu NIF/SIRET se for uma empresa",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Escreve o endereço e, depois, escolhe o endereço apropriado.",
    agency_setup_addresscompl: "Complemento de endereço",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Edificio, escadaria, código de acceso, etc. Quando os seus clientes recolhem os documentos traduzidos, precisam de ter toda a informação.",
    agency_setup_tel: "Telefone",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "O número de teléfono a utilizar para conversar com os clientes.",
    agency_setup_email: "Correio eletrónico",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "O correio eletrónico a utilizar para conversar com os clientes. Por favor, entra otro correio se não for o de registo de Hierø.",
    agency_setup_save: "Guardar",
    agency_setup_error_required_field: "Campo obrigatório",
    agency_setup_error_invalid_email: "O correio eletrónico é invalido.",
    agency_setup_error_valid_address:
      "Por favor, escolhe o endereço apropriado.",
    agency_address_not_found: "Não consegue ver o seu endereço?",
    agency_address_title: "Entrar manualmente os dados",
    agency_address_address: "Endereço:",
    agency_address_number: "N.°",
    agency_address_street: "Estrada",
    agency_address_code: "Código postal",
    agency_address_city: "Cidade",
    agency_address_country: "País",
    agency_address_coords: "Coordenadas GPS:",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Cancelar",
    agency_address_validate: "Validar",
    main_left_menu_section_navigation_dashboard: "Lista de indicadores",
    main_menu_left_section_navigation_factures: "As minhas faturas",
    head_facture: "Fatura",
    section_date: "Data",
    section_numero_facture: "Fatura",
    section_type_prestation: "Tipo de serviço",
    section_price: "Total",
    section_print: "Imprimir",
    section_generate_facture: "Gerar a fatura em PDF",
    section_consulter: "Ver",
    prestation: "Serviço",
    commande_qte: "Quantidade",
    commande_produit: "Produto",
    commande_desc: "Descrição",
    total_ht: "Total sem IVA",
    total_tva: "Total com IVA",
    total_ttc: "Total, impostos incluidos",
    retour_btn: "Voltar",
    progress_commande: "Seguimento das encomendas",
    commande_numero: "N.º de encomenda",
    capital_sasu: "SASU com un capital social de 7 000,00 euros",
    rcs_infos: "RCS PARIS B 843 119 728 ",
    siret: "SIRET 84311972800031",
    tva_fr: "Número de IVA: FR00843119728",
    condition_paragraphe_1:
      "Penalidades por atraso no pagamento (taxa anual): 10%",
    condition_paragraphe_2: "Sem desconto para pagamento antecipado",
    condition_paragraphe_3:
      "Indemnização fixa para despesas de recuperação se tiver atraso de pagamento: 40€",
    prestataire: "Fornecedo",
    my_info: "A minha informação",
    geolocation_paragraphe:
      "O software precisa de saber a sua localização para procurar tradutores perto de si",
    update_password: "Mudar a minha senha",
    account_upt: "Você pode mudar a sua senha.",
    send: "Enviado",
    close_modal: "Feichar",
    inscription_date: "Registado desde ",
    order_translate: "de ",
    montant_total_euro: "Total em EUR",
    rules_conditions: "TERMOS DE PAGAMENTO",
    rules_conditions_p: "Pagamnto a recepção da fatura",
    section_agency_title: "A minha empresa",
    section_account_title: "A minha conta",
    section_support_title: "Soporte",
    notifications_new_task: "Recebeu uma nova missão",
    notifications_task_confirmed:
      "A missão foi confimada. Vôce pode iniciar a tradução.",
    notifications_task_validated: "O cliente validou a missão.",
    tasks_sent_on: "Enviado em",
    tasks_src_country: "País de origem",
    tasks_dst_country: "Traduzir para",
    tasks_state: "Estado",
    state_cancelled: "Cancelado pelo cliente",
    state_waiting_for_translator: "Novo",
    state_waiting_for_payment: "A espera do pagamento",
    state_refused_by_translator: "Missão rejeitada",
    state_translating: "Tradução pendente",
    state_waiting_for_client_validation:
      "Terminada. A espera da validação pelo cliente.",
    state_validated: "Validado pelo cliente",
    state_validated_pro: "Validado",
    prestation_order_from: "De",
    prestation_order_document_type: "Tipo de documento",
    prestation_order_messaging_title: "Chat",
    prestation_order_messaging_send: "Enviar",
    prestation_order_messaging_client: "Cliente:",
    prestation_order_messaging_me: "Eu:",
    prestation_document_edit: "Editor de documento",
    prestation_document_edit_expl:
      "O texo no campo acima está sublinhado no documento abaixo. Quanto entra o texto tradzido, o documento será actualizado automaticamente. Utilize as setas para navegar no documento.",
    prestation_document_get_browser:
      "Você não consegue ver o PDF? Por favor, use um navegador como",
    prestation_document_confirm_oui: "Sim",
    prestation_document_confirm_non: "Não",
    account_save: "Guardar",
    account_surname: "Sobrenome",
    account_surname_placeholder: "",
    account_name: "Nome",
    account_name_placeholder: "",
    account_telephone: "Telefone",
    account_telephone_placeholder: "",
    account_adresse: "Endereço",
    account_adresse_placeholder: "Endereço",
    account_complement_adr: "Complemento de endereço",
    account_complement_adr_placeholder: "Complemento de endereço",
    account_email: "Correio eletrónico",
    account_email_placeholder: "Correio eletrónico",
    account_password: "Senha atual",
    account_new_password: "Nova senha",
    account_error_required: "Campo obrigatório",
    agency_expl_1_title: "Nome comercial",
    agency_expl_1_expl:
      "Entra o nome da sua empresa para que os clientes o possam encontrar.",
    agency_expl_2_title: "Localização",
    agency_expl_2_expl:
      "O software precisa da sua informação para o pôr em contacto com os clientes mais pertos.",
    agency_expl_3_title: "Ativar a sua conta",
    agency_expl_3_expl:
      "O seu perfil será visível on-line, quando guarde a informação de contacto.",
    services_dst_lang: "Ao",
    support_title: "Os nossos escritórios",
    support_company: "Hiero",
    support_addr1: "83 boulevard de Paris",
    support_addr2: "75010 Paris",
    support_addr3: "França",
    support_contact: "Contacto",
    support_tel: "Tel.: (+34) 911 23 88 87",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Elemento não encontrado",
    not_found_subtitle: "A página pedida não existe.",
    not_found_action: "Contactar o soporte ",
    not_found_home: "Inicio",
    menu_label_sur_place: "No sitio",
    menu_label_visio: "Por videoconferência",
    consulter_facture: "Ver as minhas faturas",
    facture_numero: "Fatura n.°",
    recap_tva: "RESUMO DA IVA",
    recap_taux: "TASA",
    recap_mt_tva: "TOTAL COM IVA",
    recap_base_ht: "BASE SEM IVA",
    action: "Acção",
    hours: "Horas",
    join_room: "Entrar na sala",
    rooms: "As salas (salas de reunião)",
    warning: "Atenção! ",
    conf: "A conferência",
    warning_1: "A ortografia dos nomes das salas deve ser respeitadas.",
    interpretation_info: "Explicação",
    interpretation_paragraphe_1:
      "Para participar na missão de interpretação, são necessárias pelo menos duas abas. Primeiro estará com o intérprete, e depois com todos os participantes.",
    interpretation_paragraphe_2:
      "Para aceder a sala de conferência, o nome da sala a entrar corresponde a ",
    interpretation_paragraphe_3: ". Todos os participantes estão nesta sala.",
    interpretation_paragraphe_4:
      "Para aceder a sala de interpretação, o nome da sala a entrar corresponde a ",
    interpretation_paragraphe_5:
      ". Os oradores e o intérprete escolhido estão nesta sala.",
    interpretation_paragraphe_6:
      "Para realizar correctamente a missão de interpretação, é ncessário que o orador e o intérprete não falem ao mesmo tempo na mesma sala.",
    interpretation_paragraphe_7:
      "Por isso, eles estão em duas salas diferentes. Assim, os participantes podem ouvir o intérprete na sala de confêrencia, e vôce pode ouvir os oradores na sala de interpretação.",
    interpretation_paragraphe_8:
      "Por razões de desempenho, a capacidade máxima de uma sala é de 50 participantes. Pode criar tantas salas quantas quiser e comunicar os nomes dessas salas ao seu intérprete.",
    room_conf: " Sala de conferência",
    traduction_assermentee: "Tradução juramentada",
    traduction_classic: "Tradução clássica",
    interpretation: "Interpretação",
    AF: "Afeganistão",
    ZA: "África do Sul",
    AL: "Albânia",
    DZ: "Argélia",
    DE: "Alemanha",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antartica",
    AG: "Antiga e Barbuda",
    AN: "Antilhas Neerlandesas",
    SA: "Arábia Saudita",
    AR: "Argentina",
    AM: "Arménia",
    AW: "Aruba",
    AU: "Austrália",
    AT: "Áustria",
    AZ: "Azerbaijão",
    BS: "Bahamas",
    BH: "Barém",
    BD: "Bangladexe",
    BB: "Barbados",
    BY: "Bielorrússia",
    BE: "Bélgica",
    BZ: "Belize",
    BJ: "Benim",
    BM: "Bermudas",
    BT: "Butão",
    BO: "Bolívia",
    BA: "Bósnia e Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    BN: "Brunei",
    BG: "Bulagária",
    BF: "Burquina Faso",
    BI: "Burúndi",
    KH: "Camboja",
    CM: "Camarões",
    CA: "Canadá",
    CV: "Cabo Verde",
    EA: "Ceuta e Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Chipre",
    CO: "Colômbia",
    KM: "Comores",
    CG: "Congo-Brazzaville",
    KP: "Coreia do Norte",
    KR: "Coreia do Sul",
    CR: "Costa Rica",
    CI: "Costa do Marfim",
    HR: "Croácia",
    CU: "Cuba",
    DK: "Dinamarca",
    DG: "Diego Garcia",
    DJ: "Jibuti",
    DM: "Dominica",
    EG: "Egito",
    SV: "El Salvador",
    AE: "Emirados Árabes Unidos",
    EC: "Equador",
    ER: "Eritreia",
    ES: "Espanha",
    EE: "Estónia",
    VA: "Estado da Cidade do Vaticano",
    FM: "Estados Federados da Micronésia",
    US: "Estados Unidos",
    ET: "Etiópia",
    FJ: "Fiji",
    FI: "Finlândia",
    FR: "França",
    GA: "Gabão",
    GM: "Gâmbia",
    GE: "Geórgia",
    GS: "Ilhas Geórgia do Sul e Sandwich do Sul",
    GH: "Gana",
    GI: "Gibraltar",
    GR: "Grécia",
    GD: "Granada",
    GL: "Gronelândia",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guiné",
    GQ: "Guiné Equatorial",
    GW: "Guiné-Bissau",
    GY: "Guiana",
    GF: "Guiana Francesa",
    HT: "Haiti",
    HN: "Honduras",
    HU: "Hungria",
    BV: "Ilha Bouvet",
    CX: "Ilha Christmas",
    CP: "Ilha Clipperton",
    AC: "Ilha de Ascensão",
    IM: "Ilha de Man",
    NF: "Ilha Norfolk",
    AX: "Ilhas de Aland",
    KY: "Ilhas Caimão",
    IC: "Ilhas Canárias",
    CC: "Ilhas Cocos (Keeling)",
    CK: "Ilhas Cook",
    FO: "Ilhas Feroe",
    HM: "Ilha Heard e Ilhas McDonald",
    FK: "Ilhas Malvinas",
    MP: "Ilhas Marianas Setentrionais",
    MH: "Ilhas Marechal",
    UM: "Ilhas Menores Distantes dos Estados Unidos",
    SB: "Ilhas Salomão",
    TC: "Ilhas Turcas e Caicos",
    VG: "Ilhas Virgens Britânicas",
    VI: "Ilhas Virgens Americanas",
    IN: "India",
    ID: "Indonésia",
    IQ: "Iraque",
    IR: "Irão",
    IE: "Irlanda",
    IS: "Islândia",
    IL: "Israel",
    IT: "Itália",
    JM: "Jamaica",
    JP: "Japão",
    JE: "Jersey",
    JO: "Jordânia",
    KZ: "Cazaquistão",
    KE: "Quénia",
    KG: "Quirguistão",
    KI: "Kiribati",
    KW: "Cuaite",
    LA: "Laus",
    LS: "Lesoto",
    LV: "Letónia",
    LB: "Líbano",
    LR: "Libéria",
    LY: "Líbia",
    LI: "Listenstaine",
    LT: "Lituânia",
    LU: "Luxemburgo",
    MK: "Macedónia",
    MG: "Madagáscar",
    MY: "Malásia",
    MW: "Maláui",
    MV: "Maldivas",
    ML: "Mali",
    MT: "Malta",
    MA: "Marrocos",
    MQ: "Martinica",
    MU: "Maurícia",
    MR: "Mauritânia",
    YT: "Maiote",
    MX: "México",
    MD: "Moldávia",
    MC: "Mónaco",
    MN: "Mongólia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Moçambique",
    MM: "Mianmar",
    NA: "Namíbia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicarágua",
    NE: "Níger",
    NG: "Nigéria",
    NU: "Niue",
    NO: "Noruega",
    NC: "Nova Caledónia",
    NZ: "Nova Zelândia",
    OM: "Omã",
    UG: "Uganda",
    UZ: "Uzbequistão",
    PK: "Paquistão",
    PW: "Palau",
    PA: "Panamá",
    PG: "Papua Nova Guiné",
    PY: "Paraguai",
    NL: "Países Baixos",
    PE: "Peru",
    PH: "Filipinas",
    PN: "Ilhas Pitcairn",
    PL: "Polónia",
    PF: "Polinésia Francesa",
    PR: "Porto Rico",
    PT: "Portugal",
    QA: "Catar",
    HK: "R.A.E. de Hong Kong da República Popular da China",
    MO: "R.A.E. de Macau da República Popular da China",
    QO: "Ocenia Remoto",
    CF: "República Centro-Africana",
    CD: "República Democrática do Congo",
    DO: "República Dominicana",
    CZ: "República Checa",
    RE: "Reunião",
    RO: "Roménia",
    GB: "Reino Unido",
    RU: "Rússia",
    RW: "Ruanda",
    EH: "Saara Ocidental",
    BL: "São Bartolomeu",
    KN: "São Cristóvão e Neves",
    SM: "São Marinho",
    MF: "Ilha de São Martinho",
    PM: "São Pedro e Miquelão",
    VC: "São Vicente e Granadinas",
    SH: "Ilha Santa Helena",
    LC: "Santa Lúcia",
    WS: "Samoa Wúcia",
    AS: "Samoa Americana",
    ST: "São Tomé e Príncipe",
    SN: "Senegal",
    RS: "Sérvia",
    CS: "Sérvia e Montenegro",
    SC: "Seicheles",
    SL: "Serra Leoa",
    SG: "Singapura",
    SK: "Eslóvaquia",
    SI: "Eslóvenia",
    SO: "Somália",
    SD: "Sudã",
    LK: "Sri Lanca",
    SE: "Suécia",
    CH: "Suíça",
    SR: "Suriname",
    SJ: "Svalbard e Jan Mayen",
    SZ: "Suazilândia",
    SY: "Síria",
    TJ: "Tajiquistão",
    TW: "Taiwan",
    TZ: "Tanzânia",
    TD: "Chade",
    TF: "Terras Austrais e Antárticas Francesas",
    IO: "Território Britânico do Oceano Índico",
    PS: "Territórios palestinianos",
    TH: "Tailândia",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidade e Tobago",
    TA: "Tristão da Cunha",
    TN: "Tunísia",
    TM: "Turcomenistão",
    TR: "Turquia",
    TV: "Tuvalu",
    UA: "Ucrânia",
    EU: "União Europeia",
    UY: "Uruguai",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietname",
    WF: "Wallis e Futuna",
    YE: "Iemen",
    ZM: "Zâmbia",
    ZW: "Zimbábue",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "Precisa de uma tradução juramentada",
    dashboard_pro_command_company_priceHT: "Preço sem IVA:",
    new_orders: "Fazer uma nova encomenda",
    state_pro_wait: "Pendente",
    account_expl_pro_cheque: "Por favor mandar um cheque a Hiero",
    account_expl_pro_viremement: "Transferências ",
    account_expl_pro_iban: "IBAN PT 000",
    account_hist_pro_debit: "Débito ",
    account_hist_pro_credit: "Crédito ",
    account_hist_pro_way: "Médio",
    account_hist_pro_ref: "Referência ",
    agency_pro_budget_help:
      "O orçamento que deseja atribuir às suas traduções.",
    sub_training: "Formação de equipa",
    sub_search: "Pesquisa automática deo tradutor/intérprete",
    sub_geo: "Geolocalização do tradutor e/ou intérprete",
    sub_dem: "Desmaterialização das encomendas",
    sub_save: "Cópia de segurança dos documentos com gestão de arquivos",
    sub_recap: "Resumo das encomendas por mês",
    sub_proj: "Progresso dos projetos em tempo real",
    sub_management: "Gestão orçamental em tempo real",
    sub_message: "Chat",
    sub_send: "Entrega das traduções por correio eletrónico",
    sub_impl: "Execução do orçamento atribuído para 12 meses",
    sub_account: "Account manager ao seu lado",
    sub_orientation: "Orientação personalizada com as línguas mais solicitadas",
    sub_split:
      "Divisão do orçamento nos departamentos da empresa, de acordo com os usúarios",
    sub_event: "Entrega das traduções por mensageiro(a)",
    choose: "Escolher",
    sub_function: "Características",
    sub_standard: "Standard",
    sub_essen: "Essencal",
    sub_premium: "Premium",
    sub_month: "mês",
    sub_associations_label: "Associações",
    sub_cabinet_label: "Escritórios de advogados",
    sub_bank_label: "Mobilidade internacional",
    sub_academia_label: "Instituições Académicas",
    sub_who_are_you: "Você é…?",
    sub_explanation_academia:
      "Queremos trabalhar eme colaboração com instituições académicas, tais como universidades.",
    sub_explanation_systeme: "Oferecemos dois sistemas diferentes: ",
    sub_system_one:
      "Utilizar a nossa aplicação móvel se a institução académica não pagar as traduções.",
    sub_system_two:
      "Negociar a implementação da interface profissional se a institução académica paga as traduções.",
    sub_credit_card_message:
      "Deve pagar a sua subscrição para ativar os seus serviços. Na aba",
    validate_credit_card_message: "A sua encomenda foi processada",
    subscriber_choose: "Escolha a sua subscrição. ",
    find_out_more: "Mais informações",
    menu_label_relecture: "Revisão",
    signup_error_email_not_found: "O correio eletrónico não existe.",
    main_left_menu_section_accueil: "Início",
    agency_setup_email_help_pro:
      "O correio eletrónico para corresponder com os tradutores e a equipa Hierø.",
    main_menu_left_section_navigation_entreprise: "A minha empresa",
    main_menu_left_section_navigation_messagerie: "As minhas mensagens",
    main_menu_left_section_navigation_gestion_des_fichiers:
      "Gestão de arquivos",
    montant_total: "Quantia total",
    single_commande_p1: "O tradutor descarrega o documento para tradução. ",
    single_commande_p2:
      "Após a tradução, adiciona-a à encomenda para que possa validar a tradução e confirmar a encomenda.",
    load_intervention: "Intervenção em curso",
    commande_statut: "Estado da encomenda",
    commande_details: "Detalhes da encomenda",
    trad_name: "Nome do tradutor",
    visioner: "Ver",
    infos_interv: "Informação adicional sobre a partcipação",
    intervention_time_date: "Data e hora de intervenção",
    loading_order: "A minhas ecomendas de tradução pendentes",
    view_all_orders: "Ver todas as encomendas",
    last_payment: "Os meus últimos pagamentos realizados",
    view_all_payment: "Ver todos os meus pagamentos",
    last_send_msg: "Últimas mensagens enviadas",
    state_accept_order: "Encomenda aceita",
    account_expl: "Entrar os dados para ser encontrado.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: "se o problema persistir. ",
    menu_label_interpretation: "Pedir uma interpretação ",
    table_header_statut: "Estado",
    head_professionnal:
      "O software precisa de saber a sua localização para procurar tradutores perto de sin",
    head_change_password: "Se desejar, pode mudar a sua senha",
    recap_tva_fr: "FR IVA 20%",
    client_particulier_name: "Nome comercial",
    client_particulier_email: "Correo eletrónico",
    client_particulier_address: "Endereço ",
    client_particulier_code_postal: "Cidade",
    particulier_name: "Nombe do particular",
    infos_entrep: "",
    infos_commandes: "",
    address_facturation: " Mudar o meu endereço de facturação.",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
  de: {
    /************** Bloc pour les phrases utilisées que sur le particulier *************/
    new_orders_traduction: "Einen Übersetzungsauftrag aufgeben",
    new_orders_interprestation: "Einen Dolmetscherauftrag aufgeben",
    main_header_title_particulier:
      "Fordern Sie Ihre beeidigten übersetzungen bei Hierø an",
    main_left_menu_section_compte: "Zugang",
    main_menu_left_section_navigation_messages: "Meine Nachrichten",
    add_file: "Einen Anhang hinzufügen",
    head_particulier:
      "Die Antworten auf Ihre Fragen finden Sie möglicherweise auf dieser Seite. Falls nicht, zögern Sie nicht und öffnen ein Ticket.",
    partuclier_trad_doc_head1:
      "Der Übersetzer hat mein Dokument nicht geliefert. Was ist zu tun?",
    partuclier_trad_doc1:
      "Sie können versuchen ihn über den Chat zu kontaktieren. Falls der Übersetzer Ihnen nicht antwortet, wenden Sie sich an den technischen Support. Der Übersetzer muss einen triftigen Grund nennen, warum er Ihr Dokument nicht innerhalb der geforderten Frist liefern konnte.",
    partuclier_trad_doc_head2:
      " Ich habe bereits die digitale Version der Übersetzung… Warum benötige ich das Original?",
    partuclier_trad_doc2:
      " Die herunterladbare Version der Übersetzung hat keinen Rechtswert und kann daher nicht für Verfahren verwendet werden, die eine Übersetzung durch einen vereidigten Übersetzer erfordern. Die Originalversion der Übersetzung ist die Einzige, die von den zuständigen Behörden akzeptiert wird.",
    partuclier_trad_doc_head3:
      " Was passiert, wenn der ausgewählte Übersetzer nicht antwortet?",
    partuclier_trad_doc3:
      " Theoretisch sind die angezeigten Übersetzer diejenigen, die zur Bearbeitung Ihres Auftrags zur Verfügung stehen. Es kann vorkommen, dass manche zu lange brauchen, um zu antworten. In diesem Fall leitet Sie unsere Software an einen Übersetzer in Ihrer Nähe weiter, der sich um Ihren Auftrag kümmert.",
    partuclier_trad_doc_head4:
      "Ich kann mein Dokument nicht beim Übersetzer abholen.",
    partuclier_trad_doc4:
      "Wenn Sie das Original nicht abholen können, kann Ihnen der Übersetzer das Dokument jederzeit per Post schicken. ",
    partuclier_trad_doc_head5:
      "Die Übersetzung, die ich erhalten habe, scheint von schlechter Qualität, falsch oder unvollständig zu sein.",
    partuclier_trad_doc5:
      "Bewahren Sie das Dokument als Beweis auf und kontaktieren Sie Übersetzer zur Klärung. Weigert sich der Übersetzer Änderungen vorzunehmen, wenden Sie sich bitte an den technischen Support.",
    partuclier_trad_doc_head6:
      "Was tun, falls die Software keinen Übersetzer in der Nähe ausfindig machen kann?",
    partuclier_trad_doc6:
      "Es kann vorkommen, dass für die ausgewählte Sprachen kein sich in Ihrer Nähe befindenden Übersetzer aufgeführt werden. Wenden Sie sich an den technischen Support oder wählen sie einen sich entfernter befindenden Übersetzer aus.",
    agency_support_guide_pro: "Handbuch für Fachkräfte",
    delete_account: "Zugang löschen",
    account_paragraphe1: "Sie haben keinen Bedarf mehr an Übersetzungen?",
    account_paragraphe2:
      " Sie möchten unsere Dienstleistung nicht weiter nutzen?",
    account_paragraphe3: "Sie können sich jeder Zeit abmelden",
    account_delete: "Ich möchte meinen Zugang löschen",
    message_paragraphe:
      "Chatten Sie mit dem Übersetzer, um Informationen über den aktuellen Auftrag zu erhalten.",
    discuss_encours: "Laufende Gespräche",
    cdu: "Indem Sie auf „Abonnieren“ klicken, akzeptieren Sie unsere ",
    cdu1: "Allgemeine Nutzungs- und Verkaufsbedingungen",
    cdu2: "Datenschutzbestimmungen",
    cdu3: "sowie",
    ticket_numero: "Ticketnummer",
    price_unitaire: " Preis(ohne MwSt.)",
    menu_head_ticket: "Meine Tickets",
    table_header_archives: "Archive Ihrer Aufträge",
    client_facture_detail: "Kunde",
    salle_de_conference: "Konferenzraum",
    /********** fin du bloc pour les phrases utilisées que sur le particulier **********/

    /************ Bloc pour les phrases utilisées sur le pro et particulier ************/
    newsletter:
      "Ich stimme zu per E-Mail Update, Sonderangebote und Neuigkeiten auf der Plattform und/oder anderer von Hiero entwickelten Produkten zu erhalten.",
    online: "Verbunden bleiben",
    login_text:
      "Stellen Sie über die Hierø-Benutzeroberfläche Ihre Übersetzung Anfragen und wählen Sie Ihren vereidigten Übersetzer mit einem Klick aus.",
    signup_main_text: "Bestellen Sie eine beglaubigte Übersetzung bei HIERØ ",
    find_interpertor: "Dolmetscher finden",
    login_caption_title_pro: "Hierø - Interface Fachkräfte",
    login_main_title_pro: "Interface Fachkräfte",
    login_main_subtitle_pro: "Ihr Zugang",
    signup_caption_title_pro: "Hierø - Interface Fachkräfte",
    signup_main_subtitle_pro: " Bitte füllen Sie das folgende Formular aus!",
    agency_setup_explanation_pro:
      "Finden Sie dank unserer Lösung schnell einen vereidigten Übersetzer. Lassen Sie Ihre Dokumente schnellstens übersetze!",
    main_header_subtitle_pro: "Interface Fachkräfte",
    main_left_menu_section_navigation_order: "Meine Aufträge",
    order_id: "Benutzer",
    order_language_from: "Von",
    order_status: "Status",
    status_orders: "Auftragsstatus",
    finaliser_commande: "Auftrag abschließen",
    order_traductor_delegate: "Übersetzer",
    new_order: "Übersetzung in Auftrag geben",
    new_order_ass: "Beeidigte Übersetzung in Auftrag geben",
    section_order_title: "Meine Aufträge",
    section_card_title: "Abonnement",
    account_budget: "Budget ",
    account_budget_placeholder: "",
    account_budget_help: "",
    label_origin_lang: "Ausgangssprache",
    find_traductors: "Übersetzer finden",
    tasks_price_ttc: "Preis (ohne MwSt.)",
    order_no_asser_query_pdf_page_number:
      "Enthält Ihr Dokument mehr als eine Seite?",
    order_no_asser_query_pdf_page_number_yes: " Geben Sie die Seitenanzahl an",
    no_traductor: "Momentan sind keine Übersetzer verfügbar! ",
    no_traductor_message: "Fenster schließen und erneut versuchen",
    loading: "In Bearbeitung ...",
    menu: "Aufträge anzeigen von",
    alert_form_error_pages_number: "Die Seitenanzahl kann nicht negativ sein",
    urgency: "Angefragter Liefertermin",
    message_no_budget: "Ihr aktuelles Budget beträgt ",
    message_contact: "Kontaktieren Sie uns, um Ihr Budget zu ändern.",
    assermente: " Vereidigte(r)",
    description: "Beschreibung",
    main_left_menu_title_asser: "Beeidigte Übersetzung",
    main_left_menu_section_parameters_card: "Mein Abonnement",
    main_menu_left_section_navigation_assistance: "Hilfe",
    main_menu_left_section_navigation_ticket: "Erstellen Sie ein Ticket",
    main_menu_left_section_navigation_question: "Fragen?",
    date_commande: "Datum",
    single_commande_paragraphe:
      " Wenn der Übersetzer Ihren Auftrag angenommen hat und die Übersetzung abgeschlossen ist, müssen Sie die Übersetzung lediglich bestätigen. Im Falle einer Ablehnung durch den ausgewählten Übersetzer leitet unsere Software Ihren Auftrag automatisch an einen anderen Übersetzer weiter.",
    commande_discuss: "Diskutieren Sie mit Ihrem Übersetzer / Dolmetscher",
    intervention_details: "Details Ihrer Intervention",
    commande_receive: "Auftrag erhalten am ",
    doc_a_traduire: " Zu übersetzendes Dokument",
    trad_asser: "Vereidigter Übersetzer / Dolmetscher",
    pays_origine: "Ursprungsland",
    visioner_doc: "Dokument ansehen",
    auth_doc:
      " Der Übersetzer kontrolliert die Authentizität des Dokuments vor der Übersetzung.",
    doc_bug:
      " Bei Problemen benachrichtigt Sie der Übersetzer über Instant Messaging.",
    section_questions_frequentes: "Häufig gestellte Fragen",
    section_questions_contact:
      "Ist es möglich, den Übersetzer bei Fragen oder Informationsbedarf zu kontaktieren ?",
    section_questions_paragraphe_six:
      "Absolut! Sie haben Zugriff auf den Chat, wenn Sie auf Bestelldetails oder Nachrichten klicken. Der Chat dient Ihnen ebenfalls zur Vereinbarung der Dokumentübergabe.",
    section_questions_traductions:
      "Warum unterscheidet sich der klassische Übersetzungsdienst von dem beeidigten Übersetzungsdienst?",
    section_questions_paragraphe_seven:
      "Da die klassischen Dokumente eher zufällig sind, kann durch den Algorithmus kein Modell regeneriert werden. Der Kunde sendet sein Dokument, das unter Bestelldetails heruntergeladen werden kann. Nach der Übersetzung lädt der Übersetzer sein Dokument hoch und sendet es an den Kunden zur Bestätigung. ",
    agency_support: "Technische Hilfe",
    agency_support_service_client: "Telefonnummer - Kundenservice",
    agency_support_ouverture:
      "Geöffnet von Montag – Freitag zwischen 9h00 -17h30",
    agency_support_questions:
      " Haben Sie Fragen oder Fehlermeldungen? Senden Sie uns eine E-Mail? ",
    agency_support_guide: "Gebrauchsanleitung Hierø",
    agency_support_guide_p:
      " Finden Sie unsere Gebrauchsanleitung. Haben Sie einen Schritt übersehen. Sie möchten wissen wie unsere Plattform funktioniert?",
    agency_support_download_guide:
      "Laden Sie unsere Gebrauchsanweisung herunter",
    agency_ticket_title: "Mein altes Ticket",
    agency_ticket_objet: "Objekt",
    agency_ticket_question: "Ihre Frage",
    agency_ticket_state: "Status",
    agency_ticket_head:
      "Brauchen Sie Hilfe? Haben Sie keine Antwort in der FAQ? Stellen Sie uns Ihre Fragen. Wir antworten in so schnell wie möglich.",
    consult_orders: "Meine Bestellungen ansehen",
    menu_label_intervention: "Die Intervention soll stattfinden",
    menu_label_event_name: "Name der Intervention",
    nb_heures_estimee: "Geschätzte Dauer in Stunden",
    menu_langue: "Sprachen",
    table_header_type_de_document: "Dokumentenart",
    table_header_nb_pages: "Seitenanzahl",
    table_hader_details: "Details",
    joindre_salon: " In Konferenzraum eintreten",
    interpretation_paragraphe_9:
      " Um Teilnehmer einzuladen, klicken Sie auf die Schaltfläche unten, um den Einladungslink zu kopieren und in Ihre Nachricht einzufügen. Beachten Sie dabei den Namen des Raums ",
    interpretation_paragraphe_10:
      " Klicken Sie hier, um den Link Ihrer Einladung zu kopieren",
    interpretation_paragraphe_11:
      " Klicken Sie hier, um Ihre Teilnehmer einzuladen",
    room_inter_pro: "Ihr Benutzername",
    copy_alert: "Kopiert!",
    commander_interprete: "Dolmetscher beauftragen",
    /********* fin du bloc pour les phrases utilisées sur le pro et particulier ********/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    tasks_filters: "Filter",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    order_language_to: "Nach",
    account_hist_pro_date: "Datum der Mission",
    select_choose: "Wählen Sie...",
    tasks_price_tva: "MwSt.",
    order_no_asser_document_name: "Dokumentenname",
    menu_label_traductor_asser: "Beeidigte Übersetzung",
    menu_label_traductor_classic: "Klassische Übersetzung",
    menu_label_interprestation: "Dolmetscher",
    menu_label_all: "Alle anzeigen",
    login_caption_title: "Hierø - Interface Übersetzer / Dolmetscher",
    login_caption_subtitle:
      "Die Software entwickelt von Übersetzern für Übersetzer.",
    login_main_logo: "Hierø",
    login_main_connect: "Anmelden",
    login_main_email: "E-Mail",
    login_main_email_placeholder: "E-Mail-Adresse einfügen",
    login_main_email_help: "Melden Sie sich mit Ihrer E-Mail-Adresse an.",
    login_main_password: "Password",
    login_main_password_placeholder: "Geben Sie Ihr Passwort ein",
    login_main_password_help: "",
    login_main_button: "Anmelden",
    login_main_to_signup: "Noch nicht registriert? Registrieren Sie sich!",
    login_main_copyright: "© Hierø - 2022",
    login_error_required_field: "Pflichtfeld",
    login_error_invalid_email: "Die eingegebene E-Mail-Adresse ist ungültig.",
    login_error_email_not_found: "Die E-Mail-Adresse existiert nicht.",
    signup_caption_subtitle:
      "Wählen Sie Hierø und erweitern Sie Ihren Horizont!",
    signup_main_logo: "Hierø",
    signup_main_title: "Registrierung",
    signup_main_email: "E-Mail-Adresse",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Passwort",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Bestätigen Sie Ihr Passwort",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Name",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Vorname",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Telefon",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Registrieren",
    signup_main_to_signup: "Bereits registriert? Melden Sie sich an",
    signup_main_copyright: "© Hierø - HIERØ 2020",
    signup_error_required_field: "Pflichtfeld",
    signup_error_invalid_email: "Die eingegebene E-Mail-Adresse ist ungültig.",
    signup_error_min_password: "Mindestens 8 Zeichen",
    signup_error_max_password: "Maximal 30 Zeichen",
    signup_error_passwords_do_not_match: "Die Passwörter stimmen nicht überein",
    login_forgot_password: "Passwort vergessen?",
    reset_password_main: "Passwort wiederherstellen",
    reset_password_email_title: "E-Mail-Adresse",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Gesendet",
    reset_password_login: "Anmelden",
    reset_password_success:
      " Eine Nachricht wurde an Ihre E-Mail-Adresse gesendet. Klicken Sie auf den Link, um Ihr Passwort zu ändern.",
    reset_password_error_required: "Pflichtfeld",
    reset_password_error_invalid_email:
      "Die eingegebene E-Mail-Adresse ist ungültig ",
    reset_password_error_user_not_found: "Die E-Mail-Adresse existiert nicht.",
    main_header_user_menu_logout: "Abmelden",
    main_left_menu_section_parameters_agency: "Mein Unternehmen",
    main_left_menu_section_parameters_services: "Mein Service",
    main_left_menu_section_parameters_user: "Mein Profil",
    main_left_menu_section_parameters_support: "Hilfe",
    agency_setup_companyname: "Name des Unternehmens",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      " Der Name des Unternehmens, der auf Ihren Auftragsbestätigungen, Rechnungen usw. erscheinen soll.",
    agency_setup_address: "Adresse",
    agency_setup_siret: "USt-ID",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help: "Geben Sie Ihre SIRET an ",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Tragen Sie Ihre Adresse ein und wählen Sie die korrekte Adresse aus der erscheinenden Liste.",
    agency_setup_addresscompl: "Adresszusatz",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Gebäude, Treppenhaus, Zugangscode, usw. Falls Ihre Kunden Ihre Übersetzungen vor Ort abholen möchten, werden so viele Informationen wie möglich benötigt.",
    agency_setup_tel: "Telefon",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help: "Die Telefonnummer auf der Sie erreichbar sind",
    agency_setup_email: "E-Mail-Adresse",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      " Die E-Mail-Adresse mit der Sie erreichbar sind für Ihre Kunden. Geben Sie eine andere E-Mail-Adresse an, falls es sich nicht um die E-Mail-Adresse handelt, mit der Sie Ihren Zugang erstellt haben.",
    agency_setup_save: "Speichern",
    agency_setup_error_required_field: "Pflichtfeld",
    agency_setup_error_invalid_email:
      " Die eingegebene E-Mail-Adresse ist ungültig.",
    agency_setup_error_valid_address:
      " Wählen Sie eine vorgeschlagene Adresse aus der Dropdown-Liste.",
    agency_address_not_found: "Ihre Adresse ist nicht gelistet?",
    agency_address_title: "Geben Sie Ihre Adresse ein",
    agency_address_address: "Adresse:",
    agency_address_number: "Nr.",
    agency_address_street: "Straße",
    agency_address_code: "Postleitzahl",
    agency_address_city: "Stadt",
    agency_address_country: "Land",
    agency_address_coords: "GPS-Koordinaten:",
    agency_address_coords_lat: "Breitengrad",
    agency_address_coords_long: "Längengrad",
    agency_address_cancel: "Abbrechen",
    agency_address_validate: "Bestätigen",
    main_left_menu_section_navigation_dashboard: "Dashboard",
    main_menu_left_section_navigation_factures: "Meine Rechnungen",
    head_facture: "Rechnung",
    section_date: "Datum",
    section_numero_facture: "Rechnungsnummer",
    section_type_prestation: "Art der Leistung",
    section_price: "Total (+ MwSt.)",
    section_print: "Ausdrucken",
    section_generate_facture: "PDF-Rechnung erstellen",
    section_consulter: "Konsultieren",
    prestation: "Dienstleistung",
    commande_qte: "Menge",
    commande_produit: "Produkt",
    commande_desc: "Beschreibung",
    total_ht: "Total (+ MwSt.)",
    total_tva: "Total (+ MwSt.)",
    total_ttc: "Total (+ MwSt.)",
    retour_btn: "Zurück",
    progress_commande: "Auftragsfortschritt",
    commande_numero: "Auftragsnummer",
    capital_sasu: " SASU mit Stammkapital von 7 000,00 €",
    rcs_infos: "RCS EVRY 843119728 ",
    siret: " ",
    tva_fr: " USt-IdNr: FR00843119728",
    condition_paragraphe_1: "Verzugszinsen (Jahresrate): 10%",
    condition_paragraphe_2: "Kein Skonto möglich",
    condition_paragraphe_3:
      " Pauschaler Schadensersatz für Beitreibungskosten bei Zahlungsverzug: 40€",
    prestataire: "Leistungserbringer",
    my_info: "Meine Daten",
    geolocation_paragraphe:
      " Die Softaware benötigt Ihre Position, um Ihnen die nächstgelegenen Übersetzer anzuzeigen.",
    update_password: "Passwort ändern",
    account_upt: "Falls Sie es wünschen, können Sie Ihr Passwort ändern",
    send: "Senden",
    close_modal: "Schliessen",
    inscription_date: "Mitglied seit ",
    order_translate: "von ",
    montant_total_euro: "Gesamtpreis in EURO",
    rules_conditions: "Zahlungsbedingungen",
    rules_conditions_p: "Zahlung bei Rechungseingang",
    section_agency_title: "Mein Unternehmen",
    section_account_title: "Mein Profil",
    section_support_title: "Hilfe",
    notifications_new_task: "Sie haben einen neuen Auftrag!",
    notifications_task_confirmed:
      " Der Auftrag wurde bestätigt. Sie können die Übersetzung beginnen.",
    notifications_task_validated:
      " Der Auftrag wurde von Ihrem Kunden bestätigt.",
    tasks_sent_on: "Gesendet am",
    tasks_src_country: "Ursprungsland",
    tasks_dst_country: "Zielsprache",
    tasks_state: "Status",
    state_cancelled: "storniert vom Kunden",
    state_waiting_for_translator: "Neu",
    state_waiting_for_payment: "Warten auf die Zahlung",
    state_refused_by_translator: "Auftrag abgelehnt",
    state_translating: "Übersetzung in Arbeit",
    state_waiting_for_client_validation:
      "Fertig. Warten auf die Bestätigung des Kunden.",
    state_validated: "Bestätigt vom Kunden",
    state_validated_pro: "Bestätigt",
    prestation_order_from: "Von",
    prestation_order_document_type: "Dokumentenart",
    prestation_order_messaging_title: "Nachrichten",
    prestation_order_messaging_send: "Gesendet",
    prestation_order_messaging_client: "Kunden:",
    prestation_order_messaging_me: "Ich:",
    prestation_document_edit: "Dokument-Editor",
    prestation_document_edit_expl:
      " Der Text im obigen Feld ist im folgenden Dokument unterstrichen. Durch die Eingabe des übersetzten Textes in das Feld wird das Dokument automatisch aktualisiert. Verwenden Sie die Pfeile, um sich durch das Dokument zu navigieren.",
    prestation_document_get_browser:
      " Sie sehen keine PDF-Datei? Verwenden Sie den aktuellsten Webbrowser wie",
    prestation_document_confirm_oui: "Ja",
    prestation_document_confirm_non: "Nein",
    account_save: "Speichern",
    account_surname: "Name",
    account_surname_placeholder: "",
    account_name: "Vorname",
    account_name_placeholder: "",
    account_telephone: "Telefon",
    account_telephone_placeholder: "",
    account_adresse: "Adresse",
    account_adresse_placeholder: "Adresse",
    account_complement_adr: "Adresszusatz",
    account_complement_adr_placeholder: " Adresszusatz ",
    account_email: "E-Mail-Adresse",
    account_email_placeholder: " E-Mail-Adresse ",
    account_password: "Aktuelles Passwort",
    account_new_password: "Neues Passwort",
    account_confirm: "Bestätigen Sie Ihr Passwort",
    account_error_required: "Pflichtfeld",
    account_error_invalid_email: "Die eingegebene E-Mail-Adresse ist ungültig.",
    account_error_min_password: "Mindestens 8 Zeichen",
    account_error_passwords_do_not_match:
      "Die Passwörter stimmen nicht überein",
    agency_expl_1_title: "Firmennamen",
    agency_expl_1_expl:
      " Geben Sie exakt den Namen Ihres Unternehmens an, damit Sie besser von Ihren Kunden gefunden werden können.",
    agency_expl_2_title: "Geolokalisierung",
    agency_expl_2_expl:
      " Die Software benötigt Ihre Kontaktdaten, damit Sie an die nächstgelegenen Kunden vermittelt werden können.",
    agency_expl_3_title: "Zugangsaktivierung",
    agency_expl_3_expl:
      " Ihr Profil wird sichtbar durch die Speicherung Ihrer Informationen.",
    services_dst_lang: "Zielsprache",
    support_title: "Unser Büro",
    support_company: "Hiero",
    support_addr1: "86 Rue de Paris",
    support_addr2: "91400 Orsay",
    support_addr3: "Frankreich",
    support_contact: "Kontakt",
    support_tel: "Tel: (+33) 01 76 38 01 40",
    support_email:
      "Mail: <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a>",
    not_found_title: "Element kann nicht gefunden werden",
    not_found_subtitle: " Die gewünschte Seite existiert nicht ",
    not_found_action: "Kontaktieren Sie",
    not_found_home: "Startseite",
    menu_label_sur_place: "Vor Ort",
    menu_label_visio: "Via Videokonferenz",
    consulter_facture: "Meine Rechnungen ansehen",
    facture_numero: "Rechnungsnummer",
    recap_tva: " ZUSAMMENFASSUNG DER MWST",
    recap_taux: "RATE",
    recap_mt_tva: "BETRAG DER MWST",
    recap_base_ht: "BASIS OHNE MWST",
    action: "Aktion",
    hours: "Uhrzeit",
    join_room: "Konferenzraum betreten ",
    rooms: "Räume",
    warning: "Achtung! ",
    conf: "Konferenz",
    warning_1: " Beachten Sie die Schreibweise des Namens des Konferenzraums.",
    interpretation_info: "Erläuterung",
    interpretation_paragraphe_1:
      " Um den Dolmetscher-Service wahrzunehmen, benötigen Sie mindestens zwei Fenster. Das erste Fenster dient zum Kontakt mit dem Dolmetscher. Das zweite Fenster dient zum Kontakt mit allen anderen Teilnehmern.",
    interpretation_paragraphe_2:
      " Um den Konferenzraum zu betreten, der Name des Konferenzraums ist ",
    interpretation_paragraphe_4:
      " Um den Dolmetscherraum zu betreten, der Name des Dolmetscherraums ist ",
    interpretation_paragraphe_3: ". Dort befinden sich alle Teilnehmer.",
    interpretation_paragraphe_5:
      ". Dort befinden sich Ihre Teilnehmer und Ihr Dolmetscher.",
    interpretation_paragraphe_6:
      " Zur korrekten Durchführung des Dolmetschereinsatz dieser Fernkonferenz ist es erforderlich, dass Redner und Dolmetscher nicht gleichzeitig im selben Raum sprechen.",
    interpretation_paragraphe_7:
      " Aus diesem Grund befinden Sie sich in zwei verschiedenen Räumen. Auf diese Weise hören die Teilnehmer nur Sie im Konferenzraum und Sie hören nur die Redner im Dolmetscherraum.",
    interpretation_paragraphe_8:
      " Aus Leistungsgründen beträgt die maximale Kapazität eines Raumes 50 Teilnehmer. Sie können beliebig viele Räume entsprechend Ihrer Zuhörerschaft erstellen und die Namen dieser Räume Ihrem Dolmetscher mitteilen.",
    room_conf: " Konferenzraum",
    traduction_assermentee: "Beglaubigte Übersetzung",
    traduction_classic: "Klassische Übersetzung",
    interpretation: "Dolmetschermission",
    AF: "Afghanistan",
    ZA: "Südafrika",
    AL: "Albanien",
    DZ: "Algerien",
    DE: "Deutschland",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarktis",
    AG: "Antigua und Barbuda",
    AN: "Niederländische Antillen",
    SA: "Saudi-Arabien",
    AR: "Argentinien",
    AM: "Armenien",
    AW: "Aruba",
    AU: "Australien",
    AT: "Österreich",
    AZ: "Aserbaidschan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesch",
    BB: "Barbados",
    BY: "Weißrussland",
    BE: "Belgien",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivien",
    BA: "Bosnien und Herzegowina",
    BW: "Botswana",
    BR: "Brasilien",
    BN: "Brunei Darussalam",
    BG: "Bulgarien",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Kambodscha",
    CM: "Kamerun",
    CA: "Kanada",
    CV: "Kap Verde",
    EA: "Ceuta und Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Zypern",
    CO: "Kolumbien",
    KM: "Komoren",
    CG: "Kongo-Brazzaville",
    KP: "Nordkorea",
    KR: "Südkorea",
    CR: "Costa Rica",
    CI: "Elfenbeinküste",
    HR: "Kroatien",
    CU: "Kuba",
    DK: "Dänemark",
    DG: "Diego Garcia",
    DJ: "Dschibuti",
    DM: "Dominique",
    EG: "Ägypten",
    SV: "El Salvador",
    AE: "Vereinigte Arabische Emirate",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "Spanien",
    EE: "Estland",
    VA: "Staat der Vatikanstadt",
    FM: "Föderierte Staaten von Mikronesien",
    US: "Vereinigte Staaten",
    ET: "Äthiopien",
    FJ: "Fidschi",
    FI: "Finnland",
    FR: "Frankreich",
    GA: "Gabun",
    GM: "Gambia",
    GE: "Georgien",
    GS: "Südgeorgien und die Südlichen Sandwichinseln",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Griechenland",
    GD: "Granate",
    GL: "Grönland",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Äquatorialguinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    GF: "Französisch-Guyana",
    HT: "Haiti",
    HN: "Honduras",
    HU: "Ungarn",
    BV: "Île Bouvet",
    CX: "Weihnachtsinsel",
    CP: "Clipperton-Insel",
    AC: "Insel der Himmelfahrt",
    IM: "Isle of Man",
    NF: "Norfolkinsel",
    AX: "Åland-Inseln",
    KY: "Kaimaninseln",
    IC: "Kanarische Inseln",
    CC: "Kokos - Keelinginseln",
    CK: "Kochinseln",
    FO: "Färöer-Inseln",
    HM: "Heard and MacDonald Islands",
    FK: "Falklandinseln",
    MP: "Nördliche Marianen",
    MH: "Marshall-Inseln",
    UM: "Minor Outlying Islands of the United States",
    SB: "Salomonen",
    TC: "Turks- und Caicosinseln",
    VG: "Britische Jungferninseln",
    VI: "Jungferninseln der Vereinigten Staaten",
    ID: "Indonesien",
    IQ: "Irak",
    IR: "Iran",
    IE: "Irland",
    IS: "Island",
    IL: "Israel",
    IT: "Italien",
    JM: "Jamaika",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordanien",
    KZ: "Kasachstan",
    KE: "Kenia",
    KG: "Kirgisistan",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesotho",
    LV: "Lettland",
    LB: "Libanon",
    LR: "Liberia",
    LY: "Libyen",
    LI: "Liechtenstein",
    LT: "Litauen",
    LU: "Luxemburg",
    MK: "Mazedonien",
    MG: "Madagaskar",
    MY: "Malaysia",
    MW: "Malawi",
    MV: "Malediven",
    ML: "Mali",
    MT: "Malta",
    MA: "Marokko",
    MQ: "Martinique",
    MU: "Maurice",
    MR: "Mauretanien",
    YT: "Mayotte",
    MX: "Mexiko",
    MD: "Moldawien",
    MC: "Monaco",
    MN: "Mongolei",
    ME: "Serbien und Montenegro",
    MS: "Montserrat",
    MZ: "Mosambik",
    MM: "Myanmar",
    NA: "Namibie",
    NR: "Suriname",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Norwegen",
    NC: "Neukaledonien",
    NZ: "Neuseeland",
    OM: "Oman",
    UG: "Uganda",
    UZ: "Usbekistan",
    PK: "Pakistan",
    PW: "Palau",
    PA: "Panama",
    PG: "Papua-Neuguinea",
    PY: "Paraguay",
    NL: "Niederlande",
    PE: "Peru",
    PH: "Philippinen",
    PN: "Pitcairn",
    PL: "Polen",
    PF: "Französisch-Polynesien",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Katar",
    HK: "Chinesische Sonderverwaltungszone Hongkong",
    MO: "China SAR Macau",
    QO: "Abgelegene Regionen Ozeaniens",
    CF: "Zentralafrikanische Republik",
    CD: "Demokratische Republik Kongo",
    DO: "Dominikanische Republik",
    CZ: "Tschechische Republik",
    RE: "Besprechung",
    RO: "Rumänien",
    GB: "Großbritannien",
    RU: "Russland",
    RW: "Ruanda",
    EH: "Westsahara",
    BL: "Saint-Barthélemy",
    KN: "St.-Kitts-und-Nevis",
    SM: "San Marino",
    MF: "Saint-Martin",
    PM: "Saint-Pierre-et-Miquelon",
    VC: "St. Vincent und die Grenadinen",
    SH: "Heilige Helena",
    LC: "Heilige Lucia",
    WS: "Samoa",
    AS: "Amerikanisch-Samoa",
    ST: "Sao Tome und Principe",
    SN: "Senegal",
    RS: "Serbien",
    SC: "Seychellen",
    SL: "Sierra Leone",
    SG: "Singapur",
    SK: "Slowakei",
    SI: "Slowenien",
    SO: "Somalia",
    SD: "Sudan",
    LK: "Sri Lanka",
    SE: "Schweden",
    CH: "Schweiz",
    SR: "Suriname",
    SJ: "Svalbard und Jan Mayen Island",
    SZ: "Swasiland",
    SY: "Syrien",
    TJ: "Tadschikistan",
    TW: "Taiwan",
    TZ: "Tansania",
    TD: "Tschad",
    TF: "Französische Süd- und Antarktisgebiete",
    IO: "Britisches Territorium im Indischen Ozean",
    PS: "Palästinensisches Territorium",
    TH: "Thailand",
    TL: "Ost-Timor",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad und Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunesien",
    TM: "Turkmenistan",
    TR: "Türkei",
    TV: "Tuvalu",
    UA: "Ukraine",
    EU: "Europäische Union",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis und Futuna",
    YE: "Jemen",
    ZM: "Sambia",
    ZW: "Simbabwe",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "Handelt es sich um eine beglaubigte Übersetzung?",
    new_orders: "Eine Bestellung aufgeben",
    state_pro_wait: "Warten",
    account_expl_pro_cheque: "Schicken Sie uns einen Scheck",
    account_expl_pro_viremement: "Überweisung",
    account_expl_pro_iban: "IBAN DE 000",
    account_hist_pro_debit: "Lastschrift",
    account_hist_pro_credit: "Gutschrift",
    account_hist_pro_way: "Zahlungsmittel ",
    account_hist_pro_ref: "Referenz ",
    agency_pro_budget_help: "Budget für Ihre Übersetzungen.",
    sub_training: "Schulung Ihrer Mitarbeiter ",
    sub_search: "Automatische Suche eines Übersetzers / Dolmetschers ",
    sub_geo: "Geolokalisierung des Übersetzers / Dolmetschers",
    sub_dem: "Digitalisierung Ihrer Aufträge ",
    sub_save: "Speicherung Ihrer Dokumente mit dem Datei Manager",
    sub_recap: "Zusammenfassung der monatlichen Aufträge ",
    sub_proj: "Bearbeitungsstatus Ihrer Aufträge in Echtzeit ",
    sub_management: "Budgetverwaltung in Echtzeit ",
    sub_message: "Instant Messaging",
    sub_send: "Lieferung Ihrer Übersetzung per Post ",
    sub_impl: "12 Monate Budget ",
    sub_account: "persönlicher Account Manager ",
    sub_orientation:
      "Personalisierte Ausrichtung nach den am häufigsten angefragten Sprachen ",
    sub_split:
      "Aufteilung des Budgets in mehrere Abteilungen (nach Übersetzer) ",
    sub_event: "Abholung durch Kurierdienst ",
    choose: "Wahlen Sie",
    sub_function: "Inhalt ",
    sub_standard: "Standard",
    sub_essen: "Essentiell",
    sub_premium: "Premium",
    sub_month: "Monat",
    sub_associations_label: "Assoziationen",
    sub_cabinet_label: "Anwaltskanzleien",
    sub_bank_label: "Internationale Mobilitä",
    sub_academia_label: "Akademische Einrichtungen",
    sub_who_are_you: "Sie sind?",
    sub_explanation_academia:
      " Wir arbeiten bevorzugt in Form von Partnerschaften mit akademischen Einrichtungen wie Universitäten, Internationalen Schulen ...",
    sub_explanation_systeme: "2 mögliche Systeme: ",
    sub_system_one: "Nutzung der mobilen HIERO App.",
    sub_system_two: "Nutzung des Interfaces für Fachkräfte.",
    sub_credit_card_message:
      "Aktivieren Sie unsere Dienstleistungen durch die Zahlung Ihres Abonnements ",
    validate_credit_card_message: "Ihr Bestellung wurde berücksichtigt",
    subscriber_choose: "Wählen Sie Ihr Abonnement",
    find_out_more: "Erfahren Sie mehr",
    menu_label_relecture: "Korrekturlesen ",
    signup_error_email_not_found: "Die E-Mail-Adresse existiert nicht.",
    main_left_menu_section_accueil: "Startseite",
    agency_setup_email_help_pro:
      " Die E-Mail-Adresse mit der Sie erreichbar sind für HIERO und andere Übersetzer / Dolmetscher.",
    main_menu_left_section_navigation_entreprise: "Mein Unternehmen",
    main_menu_left_section_navigation_messagerie: "Instant-Messaging",
    main_menu_left_section_navigation_gestion_des_fichiers: "Ordner",
    montant_total: "Total (+ MwSt.)",
    single_commande_p1:
      " Der Übersetzer lädt das zu übersetzende Dokument herunter ",
    single_commande_p2:
      " Nach der Fertigstellung wird die Übersetzung hinzugefügt, damit sie von Ihnen bestätigt werden kann.",
    load_intervention: "Intervention laufend",
    commande_statut: "Auftragsstatus",
    commande_details: "Auftragsdetails",
    trad_name: "Name des Übersetzers",
    visioner: "Ansehen",
    infos_interv: "Zusätzliche Informationen zur Intervention",
    intervention_time_date: "Datum und Uhrzeit",
    loading_order: "Meine aktuellen Übersetzungsanfragen",
    view_all_orders: "Alle Aufträge anzeigen",
    last_payment: "Meine letzten Zahlungen",
    view_all_payment: "Alle meine Zahlungen anzeigen",
    last_send_msg: "Letzte gesendete Nachrichten",
    state_accept_order: "Auftrag akzeptiert",
    account_expl:
      " Geben Sie Ihre Kontaktdaten an, um Sie bei Bedarf kontaktieren zu können.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: "Das Problem besteht weiterhin. ",
    menu_label_interpretation: "Dolmetscher beauftragen",
    table_header_statut: "Status",
    head_professionnal:
      " Die Software benötigt Ihre Position um Sie an die nächstgelegenen Übersetzer / Dolmetschers vermitteln zu können ",
    head_change_password: "Falls gewünscht, können Sie Ihr Passwort ändern",
    address_facturation: "Rechnungsadresse ändern",
    recap_tva_fr: "19% MWTS DE",
    client_particulier_name: "Name",
    client_particulier_email: "E-Mail-Adresse",
    client_particulier_address: "Adresse ",
    client_particulier_code_postal: "Stadt",
    infos_entrep: "",
    infos_commandes: "",
    particulier_name: "Name",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
};
