<div *ngFor="let ticket of loadTicket">
  <p class="entete"> {{'menu_head_ticket' | localise }} / {{'ticket_numero' | localise }} {{ ticket.numero }}</p>
  <div class="row container-fluid">
    <div class="col-md-7 mt-3 border">
      <form [formGroup]="msgForm">
        <p> {{'agency_ticket_objet' | localise }}: {{ ticket.objet }}</p>
        <p class="text-left message" style="color: #1b1f24" [innerHTML]="msgForm.value.message"></p>
        <div class="text-right">
          <p>{{ ticket.questions }}</p>
        </div>
        <div class="msg d-sm-inline-flex">
          <label>
            <input style="width: 520px;" type="text" name="message" formControlName="message" placeholder="Tapez votre message ici..." class="message">
            <!--  <span style="color: #1b1f24;">{{ filename }}</span> -->
          </label>
          <div class="d-flex justify-content-sm-around">
            <label for="file-upload" class="custom-file-upload">
              <i class="fa fa-paperclip"></i>
              <input id="file-upload" type="file"  (change)="onUploadFile($event)" name="file"/>
            </label>
            <button style="border: none;" [disabled]="msgForm.invalid" (submit)="onSendMsg()">
              <i class="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-4 mt-3">
      <div class="col-md-3">
        <div class="card" style="width: 32.2rem;">
          <div class="card-body">
            <h5 class="card-title">{{ 'agency_support' | localise}}</h5>
            <div class="text-right">
              <h6 class="card-subtitle mb-2 txt">{{ 'agency_support_service_client' | localise}}</h6>
              <p class="card-text"><strong> {{ 'support_tel' | localise }}</strong></p>
              <p>{{ 'agency_support_ouverture' | localise}}</p>
            </div>
            <p>{{ 'agency_support_questions' | localise }}  <a href="mailto:contact@hiero-solution.com">{{ 'support_email' | localise }}</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-3">
        <div class="card" style="width: 32.2rem;">
          <div class="card-body">
            <h5 class="card-title">{{ 'agency_support_guide' | localise}}</h5>
            <!-- <h6 class="card-subtitle mb-2 ">Numéro de téléphone- Services Clients</h6> -->
            <div class="text-right text-justify">
              <p class="card-text text-justify">{{ 'agency_support_guide_p' | localise}}</p>
              <p> <a href="#">{{ 'agency_support_guide_pro' | localise}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
