import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { Subscription } from "rxjs/Subscription";
import { EnumPrestationState } from "../../../../../common/src/bdd/interfaces/IPrestation";
import { IUserProfile } from "../../../../../common/src/bdd/interfaces/IUserProfile";
import { IPrestationNotification } from "../../../../../common/src/bdd/traducteur/TraducteurPrestations";
import { User } from "../../../../../common/src/bdd/user/User";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import { HieroBDD } from "../../../services/hierobdd.service";
import {
  ITitleInfo,
  TitleExtractor,
} from "../../../../../common/src/utility/title_extractor.class";
import { IsValidProfile } from "../../verify/profile/profilecheck.component";
import { IParticulier } from "../../../../../common/src/bdd/interfaces/IProfessionnel";
import { Particulier } from "../../../../../common/src/bdd/professionnel/Professionnel";
import * as firebase from "firebase";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import swal from "sweetalert";

@Component({
  templateUrl: "./appbase.component.html",
  styleUrls: ["./appbase.component.scss"],
})
export class AppBaseComponent implements OnInit, OnDestroy {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;

  @ViewChild("sidebar") sidebar: ElementRef;

  private _titleExtractor: TitleExtractor;
  titles: ITitleInfo[] = [];
  public photo: any;

  sideBarOpen: boolean;

  user: User;
  profile: IUserProfile;
  particulier: IParticulier;

  url: string;

  largeur: string;

  private _subscription: Subscription;
  private _eventSubs: Subscription;
  private _routerSubs: Subscription;
  private _particulierSub: Subscription;

  private _particulierProfile: Subscription;

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private zone: NgZone,
    private localisation: LocalisationService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    this._titleExtractor = new TitleExtractor(
      route,
      (key: string) => {
        // Replace strings here if necessary
        return "";
      },
      (titles: ITitleInfo[]) => {
        this.titles = titles;
      }
    );

    this._eventSubs = hiero.WatchPrestationEvent({
      next: (event: IPrestationNotification) => {
        let msg = null;
        switch (event.to) {
          case EnumPrestationState.WaitingForTranslator:
            msg = localisation.localise("notifications_new_task");
            break;
          case EnumPrestationState.Translating:
            msg = localisation.localise("notifications_task_confirmed");
            break;
          case EnumPrestationState.Validated:
            msg = localisation.localise("notifications_task_validated");
            break;
        }

        if (msg) {
          this.notifier.notify("success", msg);
        }
      },
    });

    // Session expiration
    // sets an idle timeout of 15 min, for testing purposes.
    idle.setIdle(900);
    // sets a timeout period of 2 min. after 17 min of inactivity, the user will be considered timed out.
    idle.setTimeout(120);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      console.log(this.idleState);
      this.router
        .navigate(["/compte", "connexion"])
        .then((value) => this.hiero.Auth.logout())
        .then((value) => {
          swal({
            title: "Votre session a expiré.",
            text: "Veuillez vous reconnecter pour pouvoir passer vos demandes.",
          });
        });
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "Vous êtes inactif";
      console.log(this.idleState);
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "Votre session expire dans " + countdown + " secondes!";
      console.log(this.idleState);
      swal({
        title:
          this.hiero.Auth.User.Profile.givenName +
          " " +
          "vous êtes toujours là?",
        text: this.idleState,
        icon: "warning",
        timer: countdown * 1000,
        buttons: ["Ok!", "Je suis là"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.reset();
        }
      });
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    if (this.hiero.Auth) {
      idle.watch();
      this.timedOut = false;
    } else {
      idle.stop();
    }
  }

  getTitle(): string {
    if (this.titles && this.titles.length > 0) {
      const titleKey = this.titles[this.titles.length - 1].title;
      return this.localisation.localise(titleKey);
    } else {
      return "";
    }
  }

  ngOnInit() {
    this.photo = firebase.auth().currentUser.photoURL;

    this._subscription = this.hiero.Auth.WatchUser({
      next: (user: User) => {
        this.user = user;

        if (this.user) {
          this.user.WatchProfile({
            next: (profile: IUserProfile) => {
              this.profile = profile;
              this.url =
                this.profile.partenaire === "science-acceuil"
                  ? "../../../../assets/img/logo/logosacceuil.png"
                  : "../../../../assets/img/logo/logoexpat.png";
            },
          });
        }
      },
    });

    // ESSENTIAL: PROFESSIONNEL DETAILS
    this._particulierProfile = this.hiero.WatchParticulierProfile({
      next: (profile: IParticulier) => {
        if (profile) {
          this.particulier = profile;

          if (
            firebase.auth().currentUser.emailVerified === true ||
            new Date(
              firebase.auth().currentUser.metadata.creationTime
            ).getTime() < 1644847200000
          ) {
            if (!IsValidProfile(profile)) {
              this.router.navigate(["verify"]);
            } else {
              // Check if we have already defined some services.
              this._particulierSub = this.hiero.WatchParticulier({
                next: async (particulier: Particulier) => {
                  const hasServices = await particulier.hasServicesOrders();
                  this._particulierSub.unsubscribe();
                },
              });
            }
          } else if (
            firebase.auth().currentUser.emailVerified === false &&
            new Date(
              firebase.auth().currentUser.metadata.creationTime
            ).getTime() >= 1644847200000
          ) {
            this.router.navigate(["compte", "connexion"]);
          }

          /* if (!IsValidProfile(profile)) {
            this.router.navigate(["verify"]);
          } else {
            // Check if we have already defined some services.
            this._particulierSub = this.hiero.WatchParticulier({
              next: async (particulier: Particulier) => {
                const hasServices = await particulier.hasServicesOrders();
                this._particulierSub.unsubscribe();
              },
            });
          } */
        }
      },
    });

    this._titleExtractor.update();
    this._routerSubs = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._titleExtractor.update();
      }
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this._eventSubs.unsubscribe();
    this._particulierProfile.unsubscribe();
    this._routerSubs.unsubscribe();
    if (this._particulierSub) {
      this._particulierSub.unsubscribe();
    }
  }

  async logout() {
    await this.hiero.Auth.logout();
    if (this.profile.partenaire === "science-acceuil") {
      this.router.navigate(["compte", "connect"]);
    } else {
      this.router.navigate(["compte", "connexion"]);
    }
  }

  toggleSidebar() {
    this.sidebar.nativeElement.style = "width: 30px";
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
}
