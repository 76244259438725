<div class="row ml-0 border">
  <div class="col-md-6 pl-0 m-3">
    <p [innerHTML]="'agency_setup_explanation_pro' | localise"></p>
    <form [formGroup]="profileForm.Group" novalidate>
      <div class="d-flex">
        <div class=" form-group mr-2">
          <app-form-input [parameters]="profileForm.GetInputElement('businessName')"></app-form-input>
        </div>
        <div class="form-group">
          <app-form-input [parameters]="profileForm.GetInputElement('siret')"></app-form-input>
        </div>
      </div>


      <app-form-typeahead class="verify-address" [parameters]="profileForm.GetInputElement('fulladdr')"
        [search]="search" [formatter]="formatter" (selected)='onAddressSelected($event)'></app-form-typeahead>
      <small><a class="d-block text-primary clickable mb-3"
          (click)="manualAddress()">{{ 'agency_address_not_found' | localise }}</a></small>


      <app-form-input [parameters]="profileForm.GetInputElement('extra')"></app-form-input>
      <div class="d-flex">
        <div class="form-group mr-2">
          <app-form-input [parameters]="profileForm.GetInputElement('email')"></app-form-input>
        </div>
        <div class="form-group">
          <app-form-input [parameters]="profileForm.GetInputElement('telephone')"></app-form-input>
        </div>

      </div>
      <app-form-input [parameters]="profileForm.GetInputElement('budget')"></app-form-input>
      <!-- <app-form-input [parameters]="profileForm.GetInputElement('subscription')"></app-form-input> -->


    </form>
    <button type="button" class="btn btn-info" (click)='profileForm.Submit()'
      [disabled]="!profileForm.Valid">{{'agency_setup_save' | localise}}</button>
    <app-wait [show]='busy'></app-wait>
  </div>
  <!--
    <div class="col-md-4">
      <h4><i class="fa fa-check fa-check text-muted"></i> {{'agency_expl_1_title' | localise}}</h4>
      <ul class="nav nav-list">
        <li>{{'agency_expl_1_expl' | localise}} </li>
      </ul>

      <h4 class="mt-5"><i class="fa fa-globe fa-fw text-muted"></i> {{'agency_expl_2_title' | localise}}</h4>
      <ul class="nav nav-list">
        <li>{{'agency_expl_2_expl' | localise}}</li>
      </ul>
      <h4 class="mt-5"><i class="fa fa-adjust fa-fw text-muted"></i> {{'agency_expl_3_title' | localise}}</h4>
      <ul class="nav nav-list">
        <li>{{'agency_expl_3_expl' | localise}}</li>
      </ul>


      <app-wait [show]='busy'></app-wait>
    </div>
  -->
  <!-- <div class="col-md-4">
    <div class="d-flex mt-5">
      <div class="ml-5">
        <img src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="logo" class="img-fluid"
        style="width : 150px; height : 150px;border: none;
          -moz-border-radius : 75px;
          -webkit-border-radius : 75px;
          border-radius : 75px;
          margin: auto;"
        >
      </div>
      <div class="pull-right">
        <h6>NOM DE L'ENTREPRISE</h6>
      </div>
    </div>
  </div> -->
</div>
