<div dropdown class="language-dropdown-main">
  <a
    href="javascript:;"
    dropdownToggle
    class="dropdown-toggle"
    data-toggle="dropdown"
  >
    <span class="d-md-inline">{{ current }}</span>
  </a>
  <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
    <!--
      <a [routerLink]="['traducteur']" class="dropdown-item">Éditer mon profil</a>
      <a href="javascript:;" class="dropdown-item">Règles de confidentialité & Conditions d'utilisation</a>
      <div class="dropdown-divider"></div>
    -->
    <a
      *ngFor="let lang of languages"
      href="javascript:;"
      (click)="setLanguage(lang)"
      class="dropdown-item"
    >
      {{ lang.name }}</a
    >
  </div>
</div>
