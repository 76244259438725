import { Pipe, PipeTransform } from '@angular/core';
import { EnumPrestationState } from '../../../common/src/bdd/interfaces/IPrestation';
import { LocalisationService } from '../../../common/src/modules/localisation/localisation.service';


@Pipe({ name: 'prestationStateName' })
export class PrestationStatePipe implements PipeTransform {

  constructor(
    private localisation: LocalisationService
  ) {
  }

  transform(code: string): string {
    switch (code) {
      case EnumPrestationState.Defining: return '';
      case EnumPrestationState.CancelledByClient: return this.localisation.localise('state_cancelled');
      case EnumPrestationState.WaitingForTranslator: return this.localisation.localise('state_waiting_for_translator');
      case EnumPrestationState.WaitingForPayment: return this.localisation.localise('state_waiting_for_payment');
      case EnumPrestationState.RefusedByTranslator: return this.localisation.localise('state_refused_by_translator');
      case EnumPrestationState.Translating: return this.localisation.localise('state_translating');
      case EnumPrestationState.WaitingForValidationFromClient: return this.localisation.localise('state_waiting_for_client_validation');
      case EnumPrestationState.Validated: return this.localisation.localise('state_validated');
      case EnumPrestationState.ValidatedPro: return this.localisation.localise('state_validated_pro');
    }
  }
}
