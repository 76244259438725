import { Logger } from "../../common/src/utility/logger.class";
//
import { environment } from "../environments/environment";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
registerLocaleData(localeFr, "fr");
// Set logging level
Logger.LogLevel = environment.logLevel;
const ɵ0 = environment;
export class AppModule {
}
export { ɵ0 };
