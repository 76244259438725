var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators, } from "@angular/forms";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { GoogleGeo } from "../../../services/google.services";
import { HieroBDD } from "../../../services/hierobdd.service";
import { SubmitForm, } from "../../../../../common/src/utility/forms/submitform.class";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppModalManualAddressComponent } from "../../../utility/components/modal-manual-address/modal-manual-address.component";
// brin import
import { EmptyParticulierAddress, EmptyParticulierCoords, EmptyParticulierProfile, } from "../../../../../common/src/bdd/interfaces/IProfessionnel";
export function haveValideAddressValidator(id) {
    return (control) => {
        let noddress = false;
        if (control.parent) {
            const par = control.parent;
            const coords = par.get("coords").value;
            const address = par.get("address").value;
            noddress =
                !coords ||
                    !coords.latitude ||
                    !coords.longitude ||
                    !address ||
                    !address.formatted;
        }
        return noddress ? { validAddress: true } : null;
    };
}
export function IsValidProfile(profile) {
    return !(!profile.coords ||
        !profile.coords.latitude ||
        !profile.coords.longitude ||
        !profile.address ||
        !profile.address.formatted);
}
export class ProfileCheckComponent {
    constructor(fb, hiero, router, geo, modalService, localisation) {
        this.fb = fb;
        this.hiero = hiero;
        this.router = router;
        this.geo = geo;
        this.modalService = modalService;
        this.localisation = localisation;
        this.busy = true;
        this.formatter = (loc) => loc && loc.address ? loc.address.formatted : "";
        this.search = (text$) => text$.pipe(debounceTime(100), distinctUntilChanged(), switchMap((term) => (term.length < 3 ? [] : this.geo.geocode(term))));
        this.createForm(this.profile || EmptyParticulierProfile);
    }
    ngOnInit() {
        this.tradSubs = this.hiero.WatchParticulier({
            next: (particulier) => {
                this.profile = null;
                // We have a user, save a reference
                this.particulier = particulier;
                if (this.particulier) {
                    // Set up a listener for the translator profile
                    this.profSubs = this.particulier.WatchProfile({
                        next: (profile) => {
                            this.profile = profile;
                            if (!IsValidProfile(this.profile)) {
                                console.log(this.profile);
                                if (!this.profile.email) {
                                    this.profile.email = this.particulier.User.Email;
                                }
                                if (!this.profile.telephone) {
                                    this.profile.telephone =
                                        this.particulier.User.Profile.telephone;
                                }
                                if (!this.profile.address) {
                                    this.profile.address = EmptyParticulierAddress;
                                }
                                if (!this.profile.coords) {
                                    this.profile.coords = EmptyParticulierCoords;
                                }
                                // pas de subcription ni de budget pour particulier
                                /*
                                if (!this.profile.registration) {
                                  this.profile.registration = '';
                                }
                                if (!this.profile.budget) {
                                  this.profile.budget = this.profileForm.GetValue('budget');
                                  console.log('Budget =>', this.profileForm.GetValue('budget'))
                                }
                                if (!this.profile.subscription) {
                                  this.profile.subscription = this.profileForm.GetValue('subscription');
                                  console.log('subscriptison =>', this.profileForm.GetValue('subscription'))
                                }
                               */
                                this.createForm(this.profile);
                                this.busy = false;
                            }
                            else {
                                // Profile OK! Continut
                                this.router.navigate(["app"]);
                            }
                        },
                    });
                }
            },
        });
    }
    ngOnDestroy() {
        if (this.tradSubs) {
            this.tradSubs.unsubscribe();
        }
        if (this.profSubs) {
            this.profSubs.unsubscribe();
        }
    }
    createForm(profile) {
        console.log(profile);
        let compliment = "";
        if (profile && profile.address && profile.address.extra) {
            compliment = profile.address.extra;
        }
        /*
        if (profile.subscription === undefined) {
          // profile.subscription ==
        }
         */
        const fullAddr = {
            address: profile.address,
            coords: profile.coords,
        };
        this.profileForm = new SubmitForm(this.fb, [
            {
                name: "fulladdr",
                value: fullAddr,
                validators: [haveValideAddressValidator("fulladdr")],
                type: "text",
                title: this.localisation.localise("agency_setup_address"),
                autocomplete: "",
                placeholder: this.localisation.localise("agency_setup_address_placeholder"),
                help: this.localisation.localise("agency_setup_address_help"),
                errors: [
                    {
                        code: "required",
                        message: this.localisation.localise("agency_setup_error_required_field"),
                    },
                    {
                        code: "validAddress",
                        message: this.localisation.localise("agency_setup_error_valid_address"),
                    },
                ],
            },
            {
                name: "extra",
                value: compliment,
                validators: [],
                type: "text",
                title: this.localisation.localise("agency_setup_addresscompl"),
                autocomplete: "",
                placeholder: this.localisation.localise("agency_setup_addresscompl_placeholder"),
                help: this.localisation.localise("agency_setup_addresscompl_help"),
                errors: [],
            },
            {
                name: "telephone",
                value: profile.telephone,
                validators: [Validators.required],
                type: "number",
                title: this.localisation.localise("agency_setup_tel"),
                autocomplete: "",
                placeholder: this.localisation.localise("agency_setup_tel_placeholder"),
                help: this.localisation.localise("agency_setup_tel_help"),
                errors: [
                    {
                        code: "required",
                        message: this.localisation.localise("agency_setup_error_required_field"),
                    },
                ],
            },
            {
                name: "email",
                value: profile.email,
                validators: [Validators.required, Validators.email],
                type: "email",
                title: this.localisation.localise("agency_setup_email"),
                autocomplete: "",
                placeholder: this.localisation.localise("agency_setup_email_placeholder"),
                help: this.localisation.localise("agency_setup_email_help"),
                errors: [
                    {
                        code: "required",
                        message: this.localisation.localise("agency_setup_error_required_field"),
                    },
                    {
                        code: "email",
                        message: this.localisation.localise("agency_setup_error_invalid_email"),
                    },
                ],
            },
            {
                name: "coords",
                value: profile.coords,
                validators: [Validators.required],
                type: "text",
                title: "",
                autocomplete: "",
                placeholder: "",
                help: "",
                errors: [],
            },
            {
                name: "address",
                value: profile.address,
                validators: [Validators.required],
                type: "text",
                title: "",
                autocomplete: "",
                placeholder: "",
                help: "",
                errors: [],
            },
        ], 
        // Submit callback
        (changes) => {
            this.busy = true;
            const particulier = this.updateData();
            return this.particulier.UpdateProfile(particulier);
        }, 
        // Success callback
        () => {
            this.busy = false;
        }, 
        // Fail callback
        (err) => {
            // What to do with login failuer
            console.error(err);
            this.busy = false;
        }, 
        // Changes callback
        null);
    }
    onAddressSelected(address) {
        const oldAddress = this.profileForm.GetValue("address");
        if (oldAddress && oldAddress.extra) {
            address.address.extra = oldAddress.extra;
        }
        this.profileForm.SetValue("coords", address.coords);
        this.profileForm.SetValue("address", address.address);
    }
    disconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            this.busy = true;
            if (this.hiero.Auth.User.Profile.partenaire === "science-acceuil") {
                yield this.hiero.Auth.logout();
                this.router.navigate(["compte", "connect"]);
            }
            else {
                yield this.hiero.Auth.logout();
                this.router.navigate(["compte", "connexion"]);
            }
        });
    }
    updateData() {
        const particulier = {
            uid: this.profile.uid,
            email: this.profileForm.GetValue("email"),
            telephone: this.profileForm.GetValue("telephone"),
            address: this.profileForm.GetValue("address"),
            coords: this.profileForm.GetValue("coords"),
            // @ts-ignore
            createdAt: Date.now().toString(),
            partenaire: "",
        };
        particulier.address.extra = this.profileForm.GetValue("extra");
        return particulier;
    }
    manualAddress() {
        return __awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modalService.open(AppModalManualAddressComponent, {
                centered: true,
                backdrop: "static",
                keyboard: false,
            });
            modalRef.componentInstance.address = this.profileForm.GetValue("address");
            modalRef.componentInstance.coords = this.profileForm.GetValue("coords");
            let result = false;
            try {
                result = yield modalRef.result;
            }
            catch (_a) { }
            if (result) {
                this.busy = true;
                try {
                    const trad = this.updateData();
                    const extra = trad.address.extra;
                    trad.address = result.address;
                    trad.address.extra = extra;
                    trad.coords = result.coords;
                    this.createForm(trad);
                    this.profileForm.CheckValidity();
                }
                catch (err) {
                    console.error(err.message);
                }
                finally {
                    this.busy = false;
                }
            }
        });
    }
}
