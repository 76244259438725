import { Component, OnDestroy } from "@angular/core";
import {
  LocalisationService,
  ILocalisedLanguage,
} from "../../../../../common/src/modules/localisation/localisation.service";
import { Subscription } from "rxjs/Subscription";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-language-dropdown",
  templateUrl: "./language-dropdown.component.html",
  styleUrls: ["./language-dropdown.component.scss"],
})
export class LanguageDropdownComponent implements OnDestroy {
  private _subs: Subscription;

  languages: ILocalisedLanguage[] = [];
  current: string;

  constructor(private localisation: LocalisationService) {
    this._subs = localisation.WatchAvailableLanguages({
      next: (langs: ILocalisedLanguage[]) => {
        this.languages = langs;

        this.languages.forEach((lang) => {
          if (lang.current) {
            this.current = lang.iso639.toUpperCase();
          }
        });
      },
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  setLanguage(lang: ILocalisedLanguage) {
    this.localisation.changeLanguage(lang.iso639);
  }
}
