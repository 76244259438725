import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommandeComponent } from "./commande/commande.component";
import { HieroBDD } from "../../../services/hierobdd.service";
import { InterpreteComponent } from "./interprete/interprete.component";
import * as firebase from "firebase";
import { TutoSalonComponent } from "../tuto-salon/tuto-salon.component";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Router } from "@angular/router";
import {log} from "firebase-functions/lib/logger";
/** not used
  import { Particulier } from '../../../../../common/src/bdd/professionnel/Professionnel';
  import { ParticulierServiceList } from '../../../../../common/src/bdd/professionnel/ProfessionnelService';
*/

const URL = "";

@Component({
  selector: "app-commandes",
  templateUrl: "./commandes.component.html",
  styleUrls: ["./commandes.component.scss"],
})
export class CommandesComponent implements OnInit {
  /** not used
    tabClassic: firebase.firestore.DocumentData = [];
    commandesListClassic = new BehaviorSubject<firebase.firestore.DocumentData[]>([]);
    isEndingBudget: boolean
    desc = true;
    docId: string;
    commandeId: string;
    newParticulier = true;
    budget: number;
  */

  commandesListInterpretations = Array();
  commandesList = new BehaviorSubject<firebase.firestore.DocumentData[]>([]);
  assermentationList: any;
  classicList: any;
  page = 1;
  pageAsser = 1;
  pageInter = 1;
  particulierData: any;
  hasBudget = true;
  pageSize = 10;

  constructor(
    private modalService: NgbModal,
    private hiero: HieroBDD,
    private router: Router
  ) {}

  ngOnInit() {
    this.getInterpretationsCommandes().then((array) => {
      this.commandesListInterpretations = array;
    });
    this.getTraductionsCommandes().then((array) => {
      this.assermentationList = array.filter(
        (el) => el.prestationInfo.srcCountryCode != ""
      );
      this.classicList = array.filter(
        (el) => el.prestationInfo.srcCountryCode === ""
      );
    });
    //this.getCommandesListClassic().then(array => this.classicList = array);
  }

  getRooms() {
    this.modalService.open(TutoSalonComponent); // *****created the component for tutorial's video***
    // window.open(URL);
  }

  getInterpretationsCommandes() {
    const currentUser = this.hiero.Auth.User;
    const docRef = this.hiero.DB.collection("particuliers").where(
      "uid",
      "==",
      currentUser.Id
    );
    return docRef.get().then((snapshot) => {
      const particulierId = snapshot.docs[0].id;
      this.particulierData = snapshot.docs[0].data();
      const request = this.hiero.DB.collection("particuliers_commandes")
        .where("particulierId", "==", particulierId)
        .where("is_interpretation", "==", true);
      // .orderBy('missions.date', 'desc');

      return request.get().then((snapshot) => {
        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      });
    });
  }

  getTraductionsCommandes() {
    const currentUser = this.hiero.Auth.User;
    const docRef = this.hiero.DB.collection("particuliers").where(
      "uid",
      "==",
      currentUser.Id
    );
    return docRef.get().then((snapshot) => {
      const particulierId = snapshot.docs[0].id;
      this.particulierData = snapshot.docs[0].data();

      const request = this.hiero.DB.collection("particuliers_commandes")
        .where("particulierId", "==", particulierId)
        .orderBy("createdAt", "desc");

      return request.get().then(
        // tslint:disable-next-line:no-shadowed-variable
        (snapshot) => {
          return snapshot.docs.map((doc) => {
            return doc.data();
          });
        }
      );
    });
  }

  onPageChange(pageNumber: number, switchnmbr: number) {
    switch (switchnmbr) {
      case 0:
        this.pageAsser = pageNumber;
        // console.log('pageCOm n:', this.pageCom)
        break;
      case 1:
        this.page = pageNumber;
        // console.log('pagePre n:', this.pagePre)
        break;
      case 2:
        this.pageInter = pageNumber;
        // console.log('pageUser n:', this.pageUser)
        break;
      /*case 3:
        this.pageTrad = pageNumber
        //console.log('pageUser n:', this.pageTrad)
        break; */
      default:
        break;
    }
  }

  showDataDashboard(selected: number) {
    const showOrdersPro = document.getElementById("orders_pro");
    const showOrders = document.getElementById("orders");
    const showInterpretation = document.getElementById("interpretation_list");
    // const showUsers = document.getElementById('users_list')
    switch (selected) {
      case 0:
        showOrdersPro.classList.remove("showBloc");
        showOrders.classList.add("showBloc");
        showInterpretation.classList.add("showBloc");
        // showUsers.classList.add("showBloc")
        break;
      case 1:
        showOrdersPro.classList.add("showBloc");
        showOrders.classList.remove("showBloc");
        showInterpretation.classList.add("showBloc");
        // showUsers.classList.add("showBloc")
        break;
      case 2:
        showOrdersPro.classList.add("showBloc");
        showOrders.classList.add("showBloc");
        showInterpretation.classList.remove("showBloc");
        // showUsers.classList.add("showBloc")
        break;
      case 3:
        showOrdersPro.classList.remove("showBloc");
        showOrders.classList.remove("showBloc");
        showInterpretation.classList.remove("showBloc");
        // showUsers.classList.remove("showBloc")
        break;
      default:
        break;
    }
  }

  /**
   * this function show a selected modal
   * @param selected
   */
  showFormModal(selected: number) {
    switch (selected) {
      // asser Forms
      /* case 0:
        this.modalService.open(CommandeAssermenteComponent);
        break; */
      case 1:
        this.modalService.open(CommandeComponent, { size: "lg" });
        break;
      case 2:
        this.modalService.open(InterpreteComponent, { size: "lg" });
        break;
      default:
        break;
    }
  }

  detailCommande(commande) {
    this.router.navigate(["app", "commandes", commande.prestationId]);
  }

  /** not used yet
    isValideBudget() {
      if (this.particulierData.budget > 0) {
        // console.log('this.hasBudget', this.hasBudget)
        this.hasBudget = false;
      }
    }
  */

  /** not used
    getCommandesListClassic(): Promise<firebase.firestore.DocumentData[]> {
      const currentUser = this.hiero.Auth.User;
      const docRef = this.hiero.DB.collection('particuliers').where('uid', '==', currentUser.Id);
      return docRef.get().then(
        (snapshot) => {
          const particulierId = snapshot.docs[0].id;
          this.particulierData = snapshot.docs[0].data();
          // this.budget = this.professionelData.budget;
          // function orderby don't work with this query improve it
          const docRefCommandeClassic = this.hiero.DB.collection('particuliers_commandes').where('particulierId', '==', particulierId).where('originLanguage', '==', 'classic').orderBy('createdAt', 'desc');
          // this.isValideBudget();
          return docRefCommandeClassic.get().then(
            // tslint:disable-next-line:no-shadowed-variable
            (snapshot) => {
              return snapshot.docs.map(doc => {
                this.docId = doc.id;
                // console.log(this.docId)
                // console.log('data classic => ', doc.data());
                // return doc.data();
                // if (doc.data().originLanguage === 'classic') {
                return doc.data();
                // }
              });
            }
          );
        }
      );
    }

    async observeMyAssermentationCommande() {
      const currentUser = this.hiero.Auth.User;
      const docRef = this.hiero.DB.collection('particuliers').where('uid', '==', currentUser.Id);
      const data = docRef.get().then(
        (snapshot) => {
          const particulierId = snapshot.docs[0].id;
          this.particulierData = snapshot.docs[0].data();
          // this.budget = this.particulierData.budget;
          const docRefCommande = this.hiero.DB.collection('particuliers_commandes')
            .where('particulierId', '==', particulierId)
            .orderBy('createdAt', 'desc');
          // this.isValideBudget();
          return docRefCommande.get().then(
            // tslint:disable-next-line:no-shadowed-variable
            (snapshot) => {
              return snapshot.docs.map(doc => {
                //this.docId = doc.id;
                // console.log('Particuliers =>', doc.data());
                return doc.data();
              });
            }
          );
        }
      );
      // console.log('data', data);
      this.commandesList.next(await data);
      this.commandesList.subscribe((observer) => {
        // tslint:disable-next-line:triple-equals
        this.assermentationList = observer.filter(el => el.prestationInfo.isProfessionalPrestation == true);
        this.classicList = observer.filter(el => el.prestationInfo.isProfessionalPrestation == false);
      });
    }

    openMissions(commande) {
      const link = ['app/commandes', commande.prestationId];
      this.router.navigate(link);
    }

    openMissionsInterpretation(commande) {
      const link = ['app/commandes', commande.id ];
      // console.log(link);
      this.router.navigate(link);
    }
   */

  // Found commented
  /*
    openModal(event) {
      if (event === 1) {
        this.modalService.open(CommandeComponent, { size: 'lg' });
      } else if (event === 2) {
        this.modalService.open(InterpreteComponent, { size: 'lg' });
      }
      // assert forms
      // else if (event === 0) {
      //  this.modalService.open(CommandeAssermenteComponent);
      //}
    }
  */
}
