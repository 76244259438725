var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { HieroBDD } from '../../../services/hierobdd.service';
import * as firebase from 'firebase';
export class CardComponent {
    constructor(hiero) {
        this.hiero = hiero;
    }
    ngOnInit() {
        this.commandWaitingPaiement().then(value => {
            this.command = value.filter(doc => doc.prestationInfo.state === "waiting-for-payment");
        });
    }
    commandWaitingPaiement() {
        return __awaiter(this, void 0, void 0, function* () {
            const currentUser = this.hiero.Auth.User;
            const docRef = this.hiero.DB.collection("particuliers").where("uid", "==", currentUser.Id);
            return docRef.get().then((snapshot) => {
                const particulierId = snapshot.docs[0].id;
                this.particulierData = snapshot.docs[0].data();
                const request = this.hiero.DB.collection("particuliers_commandes")
                    .where("particulierId", "==", particulierId)
                    .orderBy("createdAt", "desc");
                return request.get().then(
                // tslint:disable-next-line:no-shadowed-variable
                (snapshot) => {
                    return snapshot.docs.map((doc) => {
                        return doc.data();
                    });
                });
            });
        });
    }
    paid(prix) {
        return __awaiter(this, void 0, void 0, function* () {
            let db = firebase.firestore();
            const docRef = yield db
                .collection('customers')
                .doc(firebase.auth().currentUser.uid)
                .collection('checkout_sessions')
                .add({
                line_items: [
                    {
                        price_data: {
                            currency: 'eur',
                            product_data: {
                                name: 'T-shirt',
                                description: 'grrrrrrrrrrrrrrr'
                            },
                            unit_amount: prix,
                        },
                        quantity: 1,
                    },
                ],
                mode: "payment",
                success_url: window.location.origin,
                cancel_url: window.location.origin,
            });
            // Wait for the CheckoutSession to get attached by the extension
            docRef.onSnapshot((snap) => {
                const { error, url } = snap.data();
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`An error occured: ${error.message}`);
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                }
            });
        });
    }
}
