<div class="modal-body">
  <div class="container">
    <div class="mb-3">
      <h3>{{ "commander_interprete" | localise }}</h3>
    </div>
    <form [formGroup]="interForm" (ngSubmit)="onSubmitForm()">
      <!-- Type d'intervention-->
      <div class="form-group">
        <label class="col-form-label" for="intervention">
          {{ "menu_label_intervention" | localise }}
        </label>
        <select
          class="form-group custom-select my-1 mr-sm-2"
          formControlName="intervention"
          id="intervention"
        >
          <option value="" disabled>
            {{ "select_choose" | localise }}
          </option>
          <option *ngFor="let inter of typeIntervention" [ngValue]="inter">
            {{ inter.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label class="col-form-label" for="room">
          {{ "menu_label_event_name" | localise }}
        </label>
        <input
          type="text"
          class="form-control"
          id="room"
          formControlName="room"
        />
      </div>

      <div class="form-group row">
        <div class="col-sm">
          <label class="col-form-label" for="srcLang">
            {{ "label_origin_lang" | localise }}
          </label>
          <select
            class="form-group custom-select"
            id="srcLang"
            formControlName="srcLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option *ngFor="let l of lang" [ngValue]="l">
              {{ l.iso639 | iso639ToNative }}
            </option>
          </select>
        </div>

        <div class="col-sm">
          <label class="col-form-label" for="destLang">
            {{ "order_language_to" | localise }}
          </label>
          <select
            class="form-group custom-select"
            id="destLang"
            formControlName="destLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option *ngFor="let lang of destFiltered" [ngValue]="lang">
              {{ lang | iso639ToNative }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-form-label" for="address">
          {{ "agency_setup_address" | localise }}
        </label>
        <input
          type="text"
          class="form-control"
          id="address"
          formControlName="address"
        />
      </div>

      <div class="form-group row">
        <div class="col-sm">
          <label class="col-form-label" for="date">
            {{ "account_hist_pro_date" | localise }}
          </label>
          <input
            class="form-control"
            type="date"
            id="date"
            formControlName="date"
          />
        </div>

        <div class="col-sm">
          <label class="col-form-label" for="time">
            {{ "hours" | localise }}
          </label>
          <input
            class="form-control"
            type="time"
            id="time"
            formControlName="time"
          />
        </div>
      </div>

      <div class="form-group row justify-content-between">
        <label class="col-form-label" for="extHours">
          {{ "nb_heures_estimee" | localise }}
        </label>
        <input
          class="form-control col-md-5 mr-3"
          type="time"
          id="extHours"
          formControlName="extHours"
        />
      </div>

      <!--
      <div class="form-group">
        <label class="my-1 mr-2" for="contactDeplacement" disabled> Déplacement </label>
        <input type="checkbox" id="contactDeplacement" formControlName="contactDeplacement" value="Deplacement" disabled>
      </div>
      <div class="pull-rigth">
        <label class="my-1 mr-2" for="contactTel"> Par téléphone </label>
        <input type="checkbox" id="contactTel" formControlName="contactTel" value="telephone">
      </div>
     -->

      <div class="form-group">
        <label class="my-1 mr-2" for="context">
          {{ " table_hader_details " | localise }}
        </label>
        <textarea
          class="form-control"
          id="context"
          formControlName="context"
          rows="10"
          cols="35"
        ></textarea>
      </div>

      <div>
        <div class="mb-3 d-flex justify-content-end">
          <button class="btn my-color" [disabled]="interForm.invalid">
            {{ "find_interpertor" | localise }}
          </button>
        </div>
      </div>
    </form>
    <table class="table table-responsive w-auto" *ngIf="show">
      <thead>
        <tr>
          <th>{{ "order_traductor_delegate" | localise }}</th>
          <th>{{ "agency_address_city" | localise }}</th>
          <th>{{ "tasks_price_ttc" | localise }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="translatorList?.length == 0">
          {{
            "no_traductor" | localise
          }}
          <i>{{ "no_traductor_message" | localise }}</i>
        </tr>
        <tr *ngFor="let traducteur of translatorsCopies">
          <td nowrap>{{ traducteur.traducteur.businessName }}</td>
          <td nowrap>
            {{ traducteur.traducteur.address.city }}
          </td>
          <td>{{ traducteur.priceTTC.toFixed(2) }} €</td>
          <td>
            <button
              class="btn my-color"
              (click)="
                getTranslatorNameOnClickButton(
                  traducteur.traducteur.businessName
                )
              "
            >
              {{ "select" | localise }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div class="load-wrapp" *ngIf="showLoading">
      <div class="load-5">
        <p>En cours ...</p>
        <div class="ring-2">
          <div class="ball-holder">
            <div class="ball"></div>
          </div>
        </div>
     </div>
  </div> -->
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Close
  </button>
</div>
