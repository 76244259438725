import * as firebase from 'firebase';
import * as i0 from "@angular/core";
export class TicketServiceService {
    constructor() { }
    uploadFile(file) {
        return new Promise((resolve, reject) => {
            const uniqueId = Date.now().toString();
            const fileName = uniqueId + file.name;
            const upload = firebase.storage().ref().child('Ticket/' + fileName).put(file);
            upload.on(firebase.storage.TaskEvent.STATE_CHANGED, () => {
                console.log('Chargement !!!!');
            }, (error) => {
                console.log(error);
                reject(error);
            }, () => {
                upload.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                    resolve(downloadUrl);
                });
            });
        });
    }
}
TicketServiceService.ngInjectableDef = i0.defineInjectable({ factory: function TicketServiceService_Factory() { return new TicketServiceService(); }, token: TicketServiceService, providedIn: "root" });
