<div *ngIf="busy" class="row h-100">
  <div class="col d-flex align-items-center justify-content-center">
    <img src="../../../../assets/img/logo/origami.png" height="80px" alt="" />
  </div>
</div>

<div *ngIf="!busy" class="row">
  <div class="col d-flex align-items-center justify-content-center">
    <div class="container">
      <div class="d-flex p-20 flex-row-reverse">
        <app-language-dropdown></app-language-dropdown>
      </div>
      <div class="row">
        <div class="col d-flex flex-column align-items-center">
          <img
            src="../../../../assets/img/logo/origami.png"
            height="60px"
            alt=""
          />
          <h1>{{ "agency_setup_title_part" | localise }}</h1>
          <p
            class="text-center"
            [innerHTML]="'agency_setup_explanation_pro' | localise"
          ></p>
        </div>
      </div>

      <div class="row">
        <div class="col d-flex flex-column align-items-center">
          <form
            [formGroup]="profileForm.Group"
            novalidate
            style="max-width: 550px"
          >
            <app-form-typeahead
              class="verify-address"
              [parameters]="profileForm.GetInputElement('fulladdr')"
              [search]="search"
              [formatter]="formatter"
              (selected)="onAddressSelected($event)"
            ></app-form-typeahead>
            <small
              ><a
                class="d-block text-primary clickable mb-3"
                (click)="manualAddress()"
                >{{ "agency_address_not_found" | localise }}</a
              ></small
            >

            <app-form-input
              [parameters]="profileForm.GetInputElement('extra')"
            ></app-form-input>
            <app-form-input
              [parameters]="profileForm.GetInputElement('telephone')"
            ></app-form-input>
            <app-form-input
              [parameters]="profileForm.GetInputElement('email')"
            ></app-form-input>
            <!--<app-form-input [parameters]="profileForm.GetInputElement('budget')"></app-form-input> -->
            <!--<app-form-input [parameters]="profileForm.GetInputElement('subscriptions')"></app-form-input> -->
          </form>
          <button
            type="button"
            class="btn my-btn"
            (click)="profileForm.Submit()"
            [disabled]="!profileForm.Valid"
          >
            {{ "agency_setup_save" | localise }}
          </button>
          <button class="btn btn-link mt-3" (click)="disconnect()">
            {{ "main_header_user_menu_logout" | localise }}
          </button>
          <app-wait [show]="busy"></app-wait>
        </div>
      </div>
    </div>
  </div>
</div>
