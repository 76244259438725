var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EnumPrestationState } from '../interfaces/IPrestation';
import { Prestation } from '../prestation/Prestation';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs';
const FIREBASE_PRESTATION_COLLECTION = 'particuliers_commandes';
export class ParticulierOrderParam {
}
/**
 * Use this class to list, filter, order and monitor the orders for a specific professionnal.
 * Note: this class will recover ALL records for this professisonal, filter and order them client side.
 * This will most likely need to be optimised once we know more about the general usage of this class.
 * Remember to call cleanup before destroying this object to avoid memory leaks.
 */
export class ParticulierOrders {
    constructor(particulier, query, initSnap, watch, states, order) {
        this._totalFilteredUnpagedCount = 0;
        this._page = 0;
        this._pageSize = 10;
        this._states = states;
        this._order = order;
        this._particulier = particulier;
        this.reconstruct(initSnap);
        this._listSubject = new BehaviorSubject(this.All);
        this._prestationChangeStateSubject = new Subject();
        if (watch) {
            this._stopListening = query.onSnapshot({
                next: (snapshot) => {
                    // Go through all changes
                    snapshot.docChanges().forEach((change) => {
                        // Get a corresponding old one to be able to see if it exists or not
                        const old = this._prestationMap.get(change.doc.id);
                        const prestation = change.doc.data();
                        // SIGNAL NEWLY ADDED
                        if (change.type === 'added' && !old) {
                            if (prestation.state === EnumPrestationState.WaitingForTranslator) {
                                this._prestationChangeStateSubject.next({
                                    prestation: prestation,
                                    from: EnumPrestationState.Defining,
                                    to: prestation.state
                                });
                            }
                        }
                        else if (change.type === 'modified') {
                            // SIGNAL MODIFIED
                            if (old) {
                                if (prestation.state !== old.Data.state) {
                                    this._prestationChangeStateSubject.next({
                                        prestation: prestation,
                                        from: old.Data.state,
                                        to: prestation.state
                                    });
                                }
                            }
                        }
                    });
                    this.reconstruct(snapshot);
                },
                error: (err) => {
                }
            });
        }
    }
    get Count() {
        return this._filteredArr.length;
    }
    /**
     * Gets the number of filtered items before paging
     */
    get TotalCount() {
        return this._totalFilteredUnpagedCount;
    }
    /**
     * Get a copy of your query
     */
    get All() {
        return [...this._filteredArr];
    }
    /**
     * Update your query here, it will not redownload the data, but will fire the subscription with re-ordered data (if there is one in place).
     * Otherwise get the data using All.
     * @param states
     * @param order
     * @param page
     * @param pageSize
     */
    updateQuery(states, order, page, pageSize) {
        this._states = states;
        this._order = order;
        this._page = page;
        this._pageSize = pageSize;
        // Filter and order
        this._filteredArr = this.getWithQuery(this._states, this._order, page, pageSize);
        if (this._listSubject) {
            this._listSubject.next(this.All);
        }
    }
    getWithQuery(states, order, page = 0, pageSize = 10) {
        // Filter by selected states
        let result = [];
        if (states && states.length > 0) {
            const stateSet = new Set();
            states.forEach((state) => {
                stateSet.add(state);
            });
            result = this._prestationArr.filter((prestation) => {
                return stateSet.has(prestation.Data.state);
            });
        }
        else {
            result = this._prestationArr;
        }
        // Order
        if (order && order.length > 0) {
            for (let i = order.length - 1; i >= 0; --i) {
                const orderParam = order[i];
                const sorted = result.sort((a, b) => {
                    let res = 0;
                    if (typeof (a.Data[orderParam.column]) === 'string') {
                        res = a.Data[orderParam.column].localeCompare(b.Data[orderParam.column]);
                    }
                    else {
                        res = a.Data[orderParam.column] - b.Data[orderParam.column];
                    }
                    if (orderParam.desc) {
                        res = res * -1;
                    }
                    return res;
                });
                result = sorted;
            }
        }
        // Save the total filtered count
        this._totalFilteredUnpagedCount = result.length;
        // Paging
        const startPage = page * pageSize;
        result = result.slice(startPage, startPage + pageSize);
        return result;
    }
    /**
     * Get a specific order, given its Id
     * @param id
     */
    getPrestation(id) {
        return this._prestationMap.get(id);
    }
    reconstruct(snap) {
        this._prestationMap = new Map();
        this._prestationArr = [];
        snap.forEach((doc) => {
            const prestRaw = doc.data();
            const prestation = new Prestation(this._particulier.User, true, doc.ref, doc.id, prestRaw);
            this._prestationMap.set(doc.id, prestation);
            this._prestationArr.push(prestation);
        });
        // Filter and order
        this._filteredArr = this.getWithQuery(this._states, this._order);
        if (this._listSubject) {
            this._listSubject.next(this.All);
        }
    }
    /**
     * Subscribe for changes to this list
     * @param observer
     */
    WatchList(observer) {
        return this._listSubject.subscribe(observer);
    }
    WatchEvents(observer) {
        return this._prestationChangeStateSubject.subscribe(observer);
    }
    /**
     * Make sure you call this function to avoid memory leaks.
     */
    cleanup() {
        if (this._stopListening) {
            this._stopListening();
            this._stopListening = null;
        }
    }
    /**
     * Create the list object. This will get the initial list and setup the watcher if required.
     * @param particulier
     * @param watch If true, a watch will be placed on this list, and automatic updates will be transmitted via the Watch* subscriptions
     * @param states A list of states that you want to filter the list by
     * @param order The order in which you wish to display the results
     */
    static Init(particulier, watch, states, order) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = particulier.User.DB.collection(FIREBASE_PRESTATION_COLLECTION)
                .where('particulierId', '==', particulier.Id);
            // TODO: A way to filter these results to reduce query time !
            // .where('archived', '==', (archived ? true : false));
            const snapshot = yield query.get();
            return new ParticulierOrders(particulier, query, snapshot, watch, states, order);
        });
    }
}
