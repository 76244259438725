<div>
  <article style="text-align: start">
    <div>
      <h4>We&rsquo;ll be back soon!</h4>
      <p>
        Sorry for the inconvenience but we&rsquo;re performing some maintenance
        at the moment. If you need to you can always
        <a href="mailto:contact@hiero-support.com">contact us</a>, otherwise
        we&rsquo;ll be back online shortly!
      </p>
      <p>&mdash; The Team Hiero</p>
    </div>
    <div class="grid">
      <img src="../assets/img/logo/origami.png" height="60px" />
    </div>
    <div>
      <img src="../assets/img/WebMaintenance.webp" height="300px" />
    </div>
  </article>
</div>
