var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { UserProfileSchema } from '../interfaces/IUserProfile';
export var ERoleType;
(function (ERoleType) {
    ERoleType["client"] = "client";
    ERoleType["traducteur"] = "traducteur";
    ERoleType["admin"] = "admin";
    ERoleType["particulier"] = "particulier";
})(ERoleType || (ERoleType = {}));
var ERoleAccess;
(function (ERoleAccess) {
    ERoleAccess["granted"] = "granted";
    ERoleAccess["denied"] = "denied";
})(ERoleAccess || (ERoleAccess = {}));
var EAsyncResult;
(function (EAsyncResult) {
    EAsyncResult[EAsyncResult["DoNothing"] = 0] = "DoNothing";
    EAsyncResult[EAsyncResult["Negative"] = 1] = "Negative";
    EAsyncResult[EAsyncResult["Positive"] = 2] = "Positive";
})(EAsyncResult || (EAsyncResult = {}));
/**
 * The User class represents a user in the Hiero system, and is generally identified by an email address.
 * A user may be a translator AND a paying customer, in both cases he/she will have the same ID address.
 * For this reason, during sign-up or login, we must specify which ROLE we are interested in.
 *
 * This class will automatically monitor for changes to the user profile, and roles through the Watch* methods.
 */
export class User {
    /**
     * The user id as in the Firestore database
     */
    get Id() {
        return this._fbUser.uid;
    }
    /**
     * The user email address
     */
    get Email() {
        return this._fbUser.email;
    }
    /**
     * Reference to the firestore database.
     */
    get DB() {
        return this._db;
    }
    constructor(fbUser, db, uid, role, profile) {
        this._fbUser = fbUser;
        this._db = db;
        this._profileSubject = new BehaviorSubject(profile);
        this._role = new BehaviorSubject(role);
        this._authorized = new BehaviorSubject(role.access === ERoleAccess.granted);
        this._docRef = db.collection('users').doc(uid);
        // Subscribe to the snapshot
        this._docRef.onSnapshot((snapshot) => {
            const snapProfile = snapshot.get('profile');
            this._profileSubject.next(snapProfile);
            const roles = snapshot.get('roles');
            const foundRole = roles.find((oneRole) => {
                return (oneRole.type === this._role.value.type);
            });
            if (foundRole) {
                if (this._role.value.type !== foundRole.type || this._role.value.access !== foundRole.access) {
                    this._role.next(foundRole);
                }
                if (this._role.value.access !== foundRole.access) {
                    this._authorized.next(foundRole.access === ERoleAccess.granted);
                }
            }
            else {
                // If role is removed on the server, deny
                this._authorized.next(false);
            }
        }, (err) => {
            this._profileSubject.next(null);
            this._authorized.next(false);
        }, () => {
        });
    }
    get Profile() {
        return this._profileSubject.value;
    }
    /**
     * Subscribe to get updates to the user profile.
     * @param observer
     */
    WatchProfile(observer) {
        return this._profileSubject.subscribe(observer);
    }
    /**
     * Subscribe to get updates to the user roles
     * @param observer
     */
    WatchRole(observer) {
        return this._role.subscribe(observer);
    }
    /**
     * Updates the user profile.
     * The passed data is first validated, then sent to the server. It will result in an update to the user profile,
     * so all subscriptions should automatically update.
     * @param profile The user profile structure that you wish to update
     * @throws ValidationError
     */
    UpdateProfile(profile) {
        return __awaiter(this, void 0, void 0, function* () {
            let validatedProfile = null;
            // Validate input
            try {
                validatedProfile = yield UserProfileSchema.validate(profile, {
                    strict: false,
                    abortEarly: false,
                    stripUnknown: true
                });
            }
            catch (err) {
                return Promise.reject(err);
            }
            yield this._docRef.update({
                profile: validatedProfile
            });
        });
    }
    UpdateEmail(email) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._fbUser.updateEmail(email);
        });
    }
    UpdateFCMToken(token) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                console.log('User doc: ' + this._docRef.id);
                console.log('Updating fcm token to: ' + token);
                yield this._docRef.update({
                    fcm: token
                });
            }
            catch (err) {
                console.warn(err.message);
            }
        });
    }
    UpdateDisplayLanguage(langCode) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this._docRef.update({
                    displayLang: langCode
                });
            }
            catch (err) {
                console.warn(err.message);
            }
        });
    }
    GetIDToken() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this._fbUser.getIdToken(true);
        });
    }
    /**
     * Set this id to allow to get the User object
     */
    ToSetMyId(id) {
        if (this.Id === undefined) {
            // this._fbUser.uid = id;
            console.log('toto');
        }
    }
    /// ---------------------------- FACTORY METHODS ---------------------------- ///
    /**
     * Factory method for creating a user. This method will try find the user, create the profile if necessary and verify the user roles,
     * before creating an instance of the user.
     * @param fbUser The firebase user object
     * @param db The firestore database object
     * @param role The role we are logging in for
     * @param iProfile
     */
    // tslint:disable-next-line: member-ordering
    static Init(fbUser, db, role, iProfile) {
        return __awaiter(this, void 0, void 0, function* () {
            // Set up document reference
            const profileDocRef = db.collection('users').doc(fbUser.uid);
            let unsubscribe = null;
            let profile = null;
            let success = false;
            // Start with unknown role
            const roleStruct = {
                access: ERoleAccess.denied,
                type: role
            };
            yield new Promise((resolve, reject) => {
                // Subscribe to the snapshot listener
                unsubscribe = profileDocRef.onSnapshot((snapshot) => {
                    // Case 1: No profile exists for this user, create one
                    if (!snapshot.exists) {
                        if (iProfile) {
                            // There is a profile, set it up as default one
                            // This is async, but will not stop and wait for it, as we are expecting the snapshot to update
                            this._setupProfile(profileDocRef, iProfile)
                                .catch((err) => {
                                // If there was a problem, snapshot won't update so should reject
                                console.log('Error setting up profile');
                                reject(err);
                            });
                        }
                        else {
                            // No profile, are we signing in instead of signing-up ?
                            reject('No profile was found and none was provided!');
                        }
                    }
                    else {
                        // Snapshot received, get profile
                        profile = snapshot.get('profile');
                        // Check the user role for this platform
                        this._checkRoles(role, snapshot)
                            .then((result) => {
                            if (result === EAsyncResult.Negative) {
                                // Negative response, do not have permission to continue with this user
                                roleStruct.access = ERoleAccess.denied;
                                resolve(false);
                            }
                            else if (result === EAsyncResult.Positive) {
                                // Have permission !
                                roleStruct.access = ERoleAccess.granted;
                                success = true;
                                resolve(true);
                            }
                        })
                            .catch((err) => {
                            console.log(err);
                            reject('Error creating or validating roles!');
                        });
                    }
                }, () => {
                    // .log('Error reading user snapshot.. TODO: should this fail the init process?');
                    // NOT SURE IF I SHOULD PUT A REJECT HERE?
                });
                // Start listening
                profileDocRef.get({ source: 'server' })
                    .catch((err) => {
                    console.log(err);
                });
            })
                .catch((err) => {
                console.log(err);
            });
            // In all cases, unsubscribe
            if (unsubscribe) {
                unsubscribe();
            }
            if (success) {
                return new User(fbUser, db, fbUser.uid, roleStruct, profile);
            }
            else {
                return null;
            }
        });
    }
    // tslint:disable-next-line: member-ordering
    static _setupProfile(profileDocRef, profile) {
        return __awaiter(this, void 0, void 0, function* () {
            yield profileDocRef.set({
                profile: profile
            });
            return true;
        });
    }
    static _createRole(requestedRole, snapshot) {
        return __awaiter(this, void 0, void 0, function* () {
            const role = {
                type: requestedRole,
                access: ERoleAccess.granted
            };
            // NOTE: MAY THROW
            yield snapshot.ref.update({
                roles: [role]
            });
        });
    }
    static _checkRoles(requestedRole, snapshot) {
        return __awaiter(this, void 0, void 0, function* () {
            const roles = snapshot.get('roles');
            if (!roles || roles.length === 0) {
                // No roles yet, add this person as a translator
                // May throw
                yield this._createRole(requestedRole, snapshot);
                // The above will result in a recall of the callback, so return here
                return EAsyncResult.DoNothing;
            }
            // Have roles, go through
            const foundRole = roles.find((role) => {
                return (role.type === requestedRole);
            });
            if (!foundRole) {
                // No roles yet, add this person as a translator
                // May throw
                yield this._createRole(requestedRole, snapshot);
                // The above will result in a recall of the callback, so return here
                return EAsyncResult.DoNothing;
            }
            else {
                // Have the role, is it granted or denied ?
                if (foundRole.access !== ERoleAccess.granted) {
                    // Refuse, was deliberately denied by adming
                    return EAsyncResult.Negative;
                }
                else {
                    return EAsyncResult.Positive;
                }
            }
        });
    }
}
