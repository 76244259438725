/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./messages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/src/modules/localisation/localise.pipe";
import * as i3 from "../../../../../common/src/modules/localisation/localisation.service";
import * as i4 from "./messages.component";
var styles_MessagesComponent = [i0.styles];
var RenderType_MessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessagesComponent, data: {} });
export { RenderType_MessagesComponent as RenderType_MessagesComponent };
export function View_MessagesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LocalisePipe, [i3.LocalisationService]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h5", [["class", "d-inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "d-inline ml-3 my-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "row border mt-3 mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h5", [["class", "ml-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "row border mr-1 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "col-4 my-older-discuss border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "message mr-1 col-8 border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "receive-msg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [["class", "send-msg"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), "main_menu_left_section_navigation_messagerie")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), "message_paragraphe")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), "discuss_encours")); _ck(_v, 9, 0, currVal_2); }); }
export function View_MessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-messages", [], null, null, null, View_MessagesComponent_0, RenderType_MessagesComponent)), i1.ɵdid(1, 114688, null, 0, i4.MessagesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessagesComponentNgFactory = i1.ɵccf("app-messages", i4.MessagesComponent, View_MessagesComponent_Host_0, {}, {}, []);
export { MessagesComponentNgFactory as MessagesComponentNgFactory };
