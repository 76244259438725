import { Component } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Config } from "../../../../../../common/src/services/config.service";
import { ESignupType } from "../../../../../../common/src/bdd/user/AuthService";
import { HieroBDD } from "../../../../services/hierobdd.service";
import {
  ISubmitFormElement,
  ISubmitFormInputErrors,
  SubmitForm,
} from "../../../../../../common/src/utility/forms/submitform.class";
import { LocalisationService } from "../../../../../../common/src/modules/localisation/localisation.service";
export function passwordsMatchValidator(id: string): ValidationErrors | null {
  return (control: AbstractControl): { [key: string]: any } => {
    let nomatch = false;
    if (control.parent) {
      const par = control.parent;
      const pass = par.get("password").value;
      const conf = par.get("confirm").value;
      nomatch = pass !== conf;
    }
    return nomatch ? { passwordsMatch: true } : null;
  };
}
@Component({
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent {
  busy: boolean;
  signupForm: SubmitForm;
  sub: any;
  partenaire: string;

  constructor(
    private fb: FormBuilder,
    private hiero: HieroBDD,
    private config: Config,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private localisation: LocalisationService
  ) {
    this.hiero.Auth.logout();
    // console.log('params =>', this.activeRoute.snapshot.params.id);
    this.createForm();

    this.partenaire = this.router.url.startsWith("/compte/registration")
      ? "science-acceuil"
      : "";
  }

  createForm() {
    this.signupForm = new SubmitForm(
      this.fb,
      [
        {
          name: "email",
          value: "",
          validators: [Validators.required, Validators.email],
          type: "email",
          title: this.localisation.localise("signup_main_email"),
          autocomplete: "email",
          placeholder: this.localisation.localise(
            "signup_main_email_placeholder"
          ),
          help: this.localisation.localise("signup_main_email_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
            <ISubmitFormInputErrors>{
              code: "email",
              message: this.localisation.localise("signup_error_invalid_email"),
            },
            <ISubmitFormInputErrors>{
              code: "isEmail",
              message: this.localisation.localise("signup_error_invalid_email"),
            },
          ],
        },
        {
          name: "password",
          value: "",
          validators: [Validators.required, Validators.minLength(8)],
          type: "password",
          title: this.localisation.localise("signup_main_password"),
          autocomplete: "off",
          placeholder: this.localisation.localise(
            "signup_main_password_placeholder"
          ),
          help: this.localisation.localise("signup_main_password_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
            <ISubmitFormInputErrors>{
              code: "minlength",
              message: this.localisation.localise("signup_error_min_password"),
            },
            <ISubmitFormInputErrors>{
              code: "maxlength",
              message: this.localisation.localise("signup_error_max_password"),
            },
          ],
        },
        <ISubmitFormElement>{
          name: "confirm",
          value: "",
          validators: [Validators.required, passwordsMatchValidator("confirm")],
          type: "password",
          title: this.localisation.localise("signup_main_confirm"),
          autocomplete: "off",
          placeholder: this.localisation.localise(
            "signup_main_confirm_placeholder"
          ),
          help: this.localisation.localise("signup_main_confirm_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "passwordsMatch",
              message: this.localisation.localise(
                "signup_error_passwords_do_not_match"
              ),
            },
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
          ],
        },
        {
          name: "givenName",
          value: "",
          validators: [Validators.required],
          type: "text",
          title: this.localisation.localise("signup_main_name"),
          autocomplete: "given-name",
          placeholder: this.localisation.localise(
            "signup_main_name_placeholder"
          ),
          help: this.localisation.localise("signup_main_name_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
          ],
        },
        {
          name: "familyName",
          value: "",
          validators: [Validators.required],
          type: "text",
          title: this.localisation.localise("signup_main_surname"),
          autocomplete: "family-name",
          placeholder: this.localisation.localise(
            "signup_main_surname_placeholder"
          ),
          help: this.localisation.localise("signup_main_surname_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
          ],
        },
        {
          name: "telephone",
          value: "",
          validators: [],
          type: "number",
          title: this.localisation.localise("signup_main_telephone"),
          autocomplete: "tel",
          placeholder: this.localisation.localise(
            "signup_main_telephone_placeholder"
          ),
          help: this.localisation.localise("signup_main_telephone_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
          ],
        },
      ],
      // Submit callback
      (data) => {
        this.busy = true;
        const prom = this.hiero.Auth.signup(
          {
            email: data.email,
            password: data.password,
            type: ESignupType.EmailPassword,
          },
          {
            email: data.email,
            givenName: data.givenName,
            familyName: data.familyName,
            telephone: data.telephone,
            createdAt: Date.now().toString(),
            partenaire: this.partenaire,
          },
          {
            url: this.config.AppFullPath + "/app",
            bundleId: this.config.Environment.appBundleId,
            minimumVersion: this.config.Environment.appMinimumVersion,
          }
        );
        console.log("data =>", data);
        console.log(" prom =>", prom);
        // throw "toto"
        return prom;
      },
      // Success callback
      () => {
        // What to do with login success ?
        this.router.navigate(["compte", "emailVerify", "1"]);
      },
      // Fail callback
      (err) => {
        // What to do with login failuer
        this.busy = false;
      },
      // Changes callback
      null
    );
  }
}
