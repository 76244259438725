var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
// import by brin
import { Particulier } from "../../../common/src/bdd/professionnel/Professionnel";
import { AuthService } from "../../../common/src/bdd/user/AuthService";
import { ERoleType } from "../../../common/src/bdd/user/User";
import { Config } from "../../../common/src/services/config.service";
import { GoogleGeo } from "./google.services";
import { MessageNotifications } from "../../../common/src/bdd/messaging/MessageNotifications";
import { ParticulierOrders } from "../../../common/src/bdd/professionnel/ProfessionnelOrders";
export class HieroBDD {
    constructor(config, google) {
        this.config = config;
        this.google = google;
        // Initialize Firebase
        firebase.initializeApp(this.config.Environment.firebase);
        // Create the database object
        this._db = firebase.firestore();
        this._auth = firebase.auth();
        this._storage = firebase.storage();
        // this._traducteurSubject = new BehaviorSubject(null);
        // this._traducteurPresentSubject = new Subject<Traducteur>();
        // this._traducteurProfileSubject = new BehaviorSubject(null);
        this._particulierPresentSubject = new Subject();
        this._particulierProfileSubject = new BehaviorSubject(null);
        this._particulierSubject = new BehaviorSubject(null);
        this._traducteurPrestationsSubject =
            new BehaviorSubject(null);
        this._prestationEventSubject = new Subject();
        this._notifierMap = new Map();
        this._notifierSubscriptionMap = new Map();
        this._prestationMessageNotifiersSubject = new BehaviorSubject(this._notifierMap);
        // Setup the service provider
        this._authService = new AuthService(this._auth, this._db, ERoleType.particulier);
        // Setup extra login steps:
        // 1/ Login automatically as a translator
        this._authService.extraLoginSteps.push((user) => __awaiter(this, void 0, void 0, function* () {
            try {
                // const idtoken = await user.GetIDToken();
                // console.log(idtoken);
                const particulier = yield Particulier.Init(user);
                this._particulierSubject.next(particulier);
                this._particulierPresentSubject.next(particulier);
                // GLOBAL SUBSCRIBE TO PROFILE
                particulier.WatchProfile({
                    next: (profile) => {
                        this._particulierProfileSubject.next(profile);
                    },
                });
                // GLOBAL SUBSCRIBE TO PRESTATIONS
                ParticulierOrders.Init(particulier, true, [], []).then((prestations) => {
                    if (this._particulierPrestationsSubject)
                        this._particulierPrestationsSubject.next(prestations);
                    // Subscribe to the filtered prestation list (that is shown)
                    // This will create subscriptions on each shown prestation for the message queues that may exist
                    // on each prestation
                    /*
                      this._prestationListSubscription = prestations.WatchList({
                        next: (newPrestations: Prestation[]) => {
                          this.handleNotifierUpdates(newPrestations);
                        }
                      });
                      */
                    // Unsubscribe the old one to prevent memory leaks
                    if (this._prestationEventSubscription) {
                        this._prestationEventSubscription.unsubscribe();
                    }
                    // Subscribe this one
                    this._prestationEventSubscription = prestations.WatchEvents({
                        next: (event) => {
                            this._prestationEventSubject.next(event);
                        },
                    });
                });
            }
            catch (err) {
                this._particulierSubject.next(null);
                this._particulierPresentSubject.next(null);
                throw err;
            }
        }));
        // Set up a watch on user, if user becomes null, so does traducteur
        this._authService.WatchUser({
            next: (user) => {
                if (user == null) {
                    // User logs out
                    this.handleNotifierUpdates([]);
                    /*
                    const prestationList =  this._professionnelPrestationsSubject.value;
                    if (prestationList) {
                      prestationList.cleanup();
                    }
                    this._professionnelPrestationsSubject.next(null);
          
                    // Unsubscribe listener for prestation list
          
                    if (this._prestationListSubscription) {
                      this._prestationListSubscription.unsubscribe();
                      this._prestationListSubscription = null;
                    }*/
                    // Unsubscribe the old one to prevent memory leaks
                    if (this._prestationEventSubscription) {
                        this._prestationEventSubscription.unsubscribe();
                    }
                    this._particulierSubject.next(null);
                    this._particulierPresentSubject.next(null);
                }
            },
        });
        // Start listener
        this.Auth.Listen();
    }
    get DB() {
        return this._db;
    }
    get Auth() {
        return this._authService;
    }
    get Storage() {
        return this._storage;
    }
    // watch professionnel
    WatchParticulier(observer) {
        return this._particulierSubject.subscribe(observer);
    }
    WatchParticulierPresent(observer) {
        return this._particulierPresentSubject.subscribe(observer);
    }
    get Particulier() {
        return this._particulierSubject.value;
    }
    WatchParticulierProfile(observer) {
        return this._particulierProfileSubject.subscribe(observer);
    }
    WatchParticulierOrders(observer) {
        return this._particulierPrestationsSubject.subscribe(observer);
    }
    // end watch professionnel
    // public WatchTraducteur(observer: PartialObserver<Traducteur>): Subscription {
    //   return this._traducteurSubject.subscribe(observer);
    // }
    // public WatchTraducteurPresent(observer: PartialObserver<Traducteur>): Subscription {
    //   return this._traducteurPresentSubject.subscribe(observer);
    // }
    // public get Traducteur(): Traducteur|null {
    //   return this._traducteurSubject.value;
    // }
    // public WatchTraducteurProfile(observer: PartialObserver<ITraducteur>): Subscription {
    //   return this._traducteurProfileSubject.subscribe(observer);
    // }
    WatchTraducteurPrestations(observer) {
        return this._traducteurPrestationsSubject.subscribe(observer);
    }
    WatchPrestationEvent(observer) {
        return this._prestationEventSubject.subscribe(observer);
    }
    /////////// MESSAGE NOTIFICATIONS
    handleNotifierUpdates(prestations) {
        if (!this._particulierSubject.value) {
            return;
        }
        const notSet = new Set();
        prestations.forEach((prestation) => {
            notSet.add(prestation.Id);
            if (!this._notifierMap.has(prestation.Id)) {
                // Set to null, to mark the place
                this._notifierMap.set(prestation.Id, null);
                this._notifierSubscriptionMap.set(prestation.Id, null);
                MessageNotifications.InitForParticulierPrestation(this._particulierSubject.value, prestation.Id).then((not) => {
                    this._notifierMap.set(prestation.Id, not);
                    this._notifierSubscriptionMap.set(prestation.Id, not.WatchNotifications({
                        next: (newNotification) => {
                            this._prestationMessageNotifiersSubject.next(this._notifierMap);
                        },
                    }));
                });
            }
        });
        const toRemove = [];
        this._notifierMap.forEach((msgNot, key) => {
            if (!notSet.has(key)) {
                // Signal for removal
                toRemove.push(key);
            }
        });
        toRemove.forEach((id) => {
            const msgNot = this._notifierMap.get(id);
            if (msgNot) {
                msgNot.cleanup();
            }
            const sub = this._notifierSubscriptionMap.get(id);
            if (sub) {
                sub.unsubscribe();
            }
            this._notifierMap.delete(id);
            this._notifierSubscriptionMap.delete(id);
        });
    }
    SetSeen(prestationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const not = this._notifierMap.get(prestationId);
            if (not) {
                try {
                    yield not.SetSeen();
                }
                catch (err) {
                    console.log(err);
                }
            }
        });
    }
    GetNotifierFor(prestationId) {
        return this._notifierMap.get(prestationId);
    }
    WatchPrestationMessageNotifiers(observer) {
        return this._prestationMessageNotifiersSubject.subscribe(observer);
    }
}
