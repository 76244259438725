
import * as yup from 'yup';

// Parts of the user profile required for user signup
export interface ISignupUserProfile {
  givenName: string;
  familyName: string;
  telephone: string;
  createdAt?: string;
  complement_address?: string;
  email: string;
  partenaire?: string;
}


export const ParticulierProfileSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string(),
    createAt: yup.string(),
    telephone: yup.string()
});



export interface IUserProfile extends ISignupUserProfile {
  // TODO: Add fields here for generic user profile
  // These fields are ones that are not required for signup
  address?: string;
}




export const UserProfileSchema = yup.object({
  givenName: yup.string().required(),
  familyName: yup.string().required(),
  telephone: yup.string().required(),
  // subscription: yup.string(),
  // poste: yup.string().required(),
  // service: yup.string().required(),
  createAt: yup.string(),
  email: yup.string().email(),
  partenaire: yup.string().required(),

});

export const EmptyUserProfile = UserProfileSchema.cast({});
// particulier
export const EmptyParticulierProfile = ParticulierProfileSchema.cast({});
