/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./base.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./base.component";
import * as i3 from "../../services/hierobdd.service";
import * as i4 from "@angular/router";
var styles_BaseComponent = [i0.styles];
var RenderType_BaseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BaseComponent, data: {} });
export { RenderType_BaseComponent as RenderType_BaseComponent };
export function View_BaseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-fluid h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["height", "80px"], ["src", "../assets/img/logo/origami.png"]], null, null, null, null, null))], null, null); }
export function View_BaseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BaseComponent_0, RenderType_BaseComponent)), i1.ɵdid(1, 245760, null, 0, i2.BaseComponent, [i3.HieroBDD, i4.Router, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BaseComponentNgFactory = i1.ɵccf("ng-component", i2.BaseComponent, View_BaseComponent_Host_0, {}, {}, []);
export { BaseComponentNgFactory as BaseComponentNgFactory };
