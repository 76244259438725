var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef, NgZone, OnDestroy, OnInit, } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { EnumPrestationState } from "../../../../../common/src/bdd/interfaces/IPrestation";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import { HieroBDD } from "../../../services/hierobdd.service";
import { TitleExtractor, } from "../../../../../common/src/utility/title_extractor.class";
import { IsValidProfile } from "../../verify/profile/profilecheck.component";
import * as firebase from "firebase";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import swal from "sweetalert";
export class AppBaseComponent {
    constructor(hiero, router, route, notifier, zone, localisation, idle, keepalive) {
        this.hiero = hiero;
        this.router = router;
        this.route = route;
        this.notifier = notifier;
        this.zone = zone;
        this.localisation = localisation;
        this.idle = idle;
        this.keepalive = keepalive;
        this.idleState = "Not started.";
        this.timedOut = false;
        this.lastPing = null;
        this.titles = [];
        this._titleExtractor = new TitleExtractor(route, (key) => {
            // Replace strings here if necessary
            return "";
        }, (titles) => {
            this.titles = titles;
        });
        this._eventSubs = hiero.WatchPrestationEvent({
            next: (event) => {
                let msg = null;
                switch (event.to) {
                    case EnumPrestationState.WaitingForTranslator:
                        msg = localisation.localise("notifications_new_task");
                        break;
                    case EnumPrestationState.Translating:
                        msg = localisation.localise("notifications_task_confirmed");
                        break;
                    case EnumPrestationState.Validated:
                        msg = localisation.localise("notifications_task_validated");
                        break;
                }
                if (msg) {
                    this.notifier.notify("success", msg);
                }
            },
        });
        // Session expiration
        // sets an idle timeout of 15 min, for testing purposes.
        idle.setIdle(900);
        // sets a timeout period of 2 min. after 17 min of inactivity, the user will be considered timed out.
        idle.setTimeout(120);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        idle.onIdleEnd.subscribe(() => {
            this.idleState = "No longer idle.";
            console.log(this.idleState);
            this.reset();
        });
        idle.onTimeout.subscribe(() => {
            this.idleState = "Timed out!";
            this.timedOut = true;
            console.log(this.idleState);
            this.router
                .navigate(["/compte", "connexion"])
                .then((value) => this.hiero.Auth.logout())
                .then((value) => {
                swal({
                    title: "Votre session a expiré.",
                    text: "Veuillez vous reconnecter pour pouvoir passer vos demandes.",
                });
            });
        });
        idle.onIdleStart.subscribe(() => {
            this.idleState = "Vous êtes inactif";
            console.log(this.idleState);
        });
        idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = "Votre session expire dans " + countdown + " secondes!";
            console.log(this.idleState);
            swal({
                title: this.hiero.Auth.User.Profile.givenName +
                    " " +
                    "vous êtes toujours là?",
                text: this.idleState,
                icon: "warning",
                timer: countdown * 1000,
                buttons: ["Ok!", "Je suis là"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.reset();
                }
            });
        });
        // sets the ping interval to 15 seconds
        keepalive.interval(15);
        keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
        if (this.hiero.Auth) {
            idle.watch();
            this.timedOut = false;
        }
        else {
            idle.stop();
        }
    }
    getTitle() {
        if (this.titles && this.titles.length > 0) {
            const titleKey = this.titles[this.titles.length - 1].title;
            return this.localisation.localise(titleKey);
        }
        else {
            return "";
        }
    }
    ngOnInit() {
        this.photo = firebase.auth().currentUser.photoURL;
        this._subscription = this.hiero.Auth.WatchUser({
            next: (user) => {
                this.user = user;
                if (this.user) {
                    this.user.WatchProfile({
                        next: (profile) => {
                            this.profile = profile;
                            this.url =
                                this.profile.partenaire === "science-acceuil"
                                    ? "../../../../assets/img/logo/logosacceuil.png"
                                    : "../../../../assets/img/logo/logoexpat.png";
                        },
                    });
                }
            },
        });
        // ESSENTIAL: PROFESSIONNEL DETAILS
        this._particulierProfile = this.hiero.WatchParticulierProfile({
            next: (profile) => {
                if (profile) {
                    this.particulier = profile;
                    if (firebase.auth().currentUser.emailVerified === true ||
                        new Date(firebase.auth().currentUser.metadata.creationTime).getTime() < 1644847200000) {
                        if (!IsValidProfile(profile)) {
                            this.router.navigate(["verify"]);
                        }
                        else {
                            // Check if we have already defined some services.
                            this._particulierSub = this.hiero.WatchParticulier({
                                next: (particulier) => __awaiter(this, void 0, void 0, function* () {
                                    const hasServices = yield particulier.hasServicesOrders();
                                    this._particulierSub.unsubscribe();
                                }),
                            });
                        }
                    }
                    else if (firebase.auth().currentUser.emailVerified === false &&
                        new Date(firebase.auth().currentUser.metadata.creationTime).getTime() >= 1644847200000) {
                        this.router.navigate(["compte", "connexion"]);
                    }
                    /* if (!IsValidProfile(profile)) {
                      this.router.navigate(["verify"]);
                    } else {
                      // Check if we have already defined some services.
                      this._particulierSub = this.hiero.WatchParticulier({
                        next: async (particulier: Particulier) => {
                          const hasServices = await particulier.hasServicesOrders();
                          this._particulierSub.unsubscribe();
                        },
                      });
                    } */
                }
            },
        });
        this._titleExtractor.update();
        this._routerSubs = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._titleExtractor.update();
            }
        });
    }
    ngOnDestroy() {
        this._subscription.unsubscribe();
        this._eventSubs.unsubscribe();
        this._particulierProfile.unsubscribe();
        this._routerSubs.unsubscribe();
        if (this._particulierSub) {
            this._particulierSub.unsubscribe();
        }
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.hiero.Auth.logout();
            if (this.profile.partenaire === "science-acceuil") {
                this.router.navigate(["compte", "connect"]);
            }
            else {
                this.router.navigate(["compte", "connexion"]);
            }
        });
    }
    toggleSidebar() {
        this.sidebar.nativeElement.style = "width: 30px";
    }
    reset() {
        this.idle.watch();
        this.idleState = "Started.";
        this.timedOut = false;
    }
}
