<div class="container-fluid">
  <div *ngIf="facture">
    <div class="border my-first">
      <p class="m-2 clickable" [routerLink]="['/app/factures']">
        {{ "main_menu_left_section_navigation_factures" | localise }} /
        <span class="my-p"
          >{{ "facture_numero" | localise }} {{ facture.numero }}</span
        >
      </p>
    </div>

    <div id="print-me">
      <div class="border mt-3" id="htmlDATA">
        <div>
          <p class="m-2 p1">
            {{ "commande_numero" | localise }} {{ facture.prestation }}
            {{ "order_translate" | localise }}
            {{ facture.date.toDate() | date: "dd/MM/yyyy" }}
          </p>
        </div>
        <hr />

        <div class="row">
          <div class="col-md-3">
            <p class="ml-3 my-p">{{ "prestataire" | localise | uppercase }}</p>
            <div class="d-block ml-3">
              <p>HIERØ</p>
              <p class="mt-n2">{{ "support_addr1" | localise }}</p>
              <p class="mt-n2">{{ "support_addr2" | localise }}</p>
              <p class="mt-n2">{{ "support_tel" | localise }}</p>
              <p class="mt-n2">{{ "support_email" | localise }}</p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="clientData">
            <p class="my-p">
              {{ "client_facture_detail" | localise | uppercase }}
            </p>
            <div class="d-block" *ngIf="fullName">
              <p>
                {{ fullName.profile.familyName }}
                {{ fullName.profile.givenName }}
              </p>
              <p class="mt-n2">
                {{ clientData.address.number }} {{ clientData.address.street }}
              </p>
              <p class="mt-n2">
                {{ clientData.address.code }} {{ clientData.address.city }}
                {{ clientData.address.country }}
              </p>
              <p class="mt-n2">{{ clientData.telephone }}</p>
              <p class="mt-n2">{{ clientData.email }}</p>
            </div>
          </div>
          <div class="col-md-6">
            <h5 class="text-right mr-3">
              {{ "facture_numero" | localise }} {{ facture.numero }}
            </h5>
            <h5 class="text-right mr-3">
              {{ "account_hist_pro_date" | localise }} :
              {{ facture.date.toDate() | date: "dd/MM/yyyy" }}
            </h5>

            <div class="d-block">
              <p class="text-right mr-3 my-p">
                {{ "prestation" | localise | uppercase }}
              </p>
              <p class="text-right mr-3">
                {{ "section_type_prestation" | localise }} :
                {{
                  facture.typePrestation === "Traduction classique"
                    ? ("traduction_classic" | localise)
                    : facture.typePrestation === "Traduction assermentée"
                    ? ("traduction_assermentee" | localise)
                    : facture.typePrestation === "Interprétation"
                    ? ("interpretation" | localise)
                    : facture.typePrestation
                }}
              </p>
              <p class="text-right mr-3">
                {{ "date_commande" | localise }} :
                {{ facture.date.toDate() | date: "dd/MM/yyyy" }}
              </p>
            </div>
          </div>
        </div>

        <table class="table table-striped mt-3">
          <thead>
            <tr>
              <th scope="col">{{ "commande_qte" | localise }}</th>
              <th scope="col">{{ "commande_produit" | localise }}</th>
              <th scope="col">{{ "description" | localise }}</th>
              <th scope="col">{{ "total_tva" | localise }}</th>
              <th scope="col">{{ "price_unitaire" | localise }}</th>
              <th scope="col">{{ "section_price" | localise }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <span *ngIf="facture.pagesNumber">{{
                  facture.pagesNumber
                }}</span>
                <span *ngIf="!facture.pagesNumber">1</span>
              </th>

              <td
                *ngIf="facture.typePrestation === 'Traduction classique'"
                class="align-middle"
              >
                {{ "traduction_classic" | localise }}
              </td>
              <td
                *ngIf="facture.typePrestation === 'Traduction assermentée'"
                class="align-middle"
              >
                {{ "traduction_assermentee" | localise }}
              </td>
              <td
                *ngIf="facture.typePrestation === 'Interprétation'"
                class="align-middle"
              >
                {{ "interpretation" | localise }}
              </td>
              <td
                *ngIf="
                  facture.typePrestation != 'Interprétation' &&
                  facture.typePrestation != 'Traduction assermentée' &&
                  facture.typePrestation != 'Traduction classique'
                "
                class="align-middle"
              >
                {{ facture.typePrestation }}
              </td>

              <td *ngIf="facture.typePrestation === 'Traduction assermentée'">
                {{ "prestation_order_from" | localise }}
                {{ facture.srcLang | iso639ToNative }}
                {{ "	order_language_to" | localise }}
                {{ facture.destLang.iso639 | iso639ToNative }}
              </td>
              <td *ngIf="facture.typePrestation === 'Traduction classique'">
                {{ "prestation_order_from" | localise }}
                {{ facture.srcLang | iso639ToNative }}
                {{ "	order_language_to" | localise }}
                {{ facture.destLang | iso639ToNative }}
              </td>
              <td *ngIf="facture.typePrestation === 'Interprétation'">
                {{ "prestation_order_from" | localise }}
                {{ facture.srcLang | iso639ToNative }}
                {{ "	order_language_to" | localise }}
                {{ facture.destLang | iso639ToNative }}
              </td>

              <td>{{ facture.priceTVAVal }}</td>
              <td>{{ facture.priceTTC }}</td>
              <td>{{ facture.priceTTC }}</td>
            </tr>
          </tbody>
        </table>
        <div class="row mt-3">
          <div class="col-md-8 mt-5">
            <p class="ml-3 my-p">
              {{ "rules_conditions" | localise | uppercase }} :
              {{ "rules_conditions_p" | localise }}
            </p>
            <p class="ml-3 p">{{ "condition_paragraphe_1" | localise }}</p>
            <p class="ml-3 p">{{ "condition_paragraphe_2" | localise }}</p>
            <p class="ml-3 p">{{ "condition_paragraphe_3" | localise }}</p>
          </div>
          <div class="col-md-4 mt-2">
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between ml-5 mr-5">
                <p class="p-2">{{ "total_ht" | localise }} :</p>
                <p>{{ facture.priceHT }}</p>
              </div>
              <div class="d-flex justify-content-between ml-5 mr-5">
                <p class="p-2">{{ "total_tva" | localise }} :</p>
                <p>{{ facture.priceTVAVal }}</p>
              </div>
              <div class="d-flex justify-content-between ml-5 mr-5">
                <p class="p-2 font-weight-bold h5">
                  {{ "montant_total_euro" | localise }} :
                </p>
                <p class="font-weight-bold h5 mt-2">{{ facture.priceTTC }} €</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-nowrap my-footer mt-3">
              <p class="p-2">{{ "capital_sasu" | localise }} - N</p>
              <p class="p-2">{{ "rcs_infos" | localise }} - N</p>
              <p class="p-2">{{ "siret" | localise }} - N</p>
              <p class="p-2">{{ "tva_fr" | localise }} - N</p>
              <p class="p-2">{{ "facture_numero" | localise }}</p>
              <p class="p-2">{{ facture.numero }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--end div border mt-3 -->
    </div>
  </div>
  <!--end div print-me -->
</div>
<!--end div container -->

<div class="d-flex mt-5">
  <div class="mr-auto p-2">
    <button [routerLink]="['/app/factures']" class="btn">
      <i class="fa fa-undo"></i> {{ "retour_btn" | localise }}
    </button>
  </div>
  <div class="p-2">
    <button
      style="background-color: #2d4054; color: white"
      class="btn mr-1"
      (click)="download(2)"
    >
      <i class="fa fa-download"></i> {{ "section_generate_facture" | localise }}
    </button>
    <button
      class="btn mr-1"
      style="background-color: black; color: white"
      (click)="download(1)"
    >
      <i class="fa fa-print"></i> {{ "section_print" | localise }}
    </button>
  </div>
</div>
