import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HieroBDD } from "../../../../services/hierobdd.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GoogleGeo } from "../../../../services/google.services";
import { ITraducteur } from "../../../../../../common/src/bdd/interfaces/ITraducteur";
import Swal from "sweetalert2";
import { LocalisationService } from "../../../../../../common/src/modules/localisation/localisation.service";
import { ITraducteurCopie } from "../commande/commande.component";
import { IParticulier } from "../../../../../../common/src/bdd/interfaces/IParticulier";
import * as firebase from "firebase";

export const ROOM_LINK =
  "https://video-app-6852-1089-dev.twil.io?passcode=03052368521089";

export enum EnumPrestationState {
  Defining = "defining",
  WaitingForTranslator = "waiting-for-translator",
  CancelledByClient = "cancelled-by-client",
  WaitingForPayment = "waiting-for-payment",
  RefusedByTranslator = "refused-by-translator",
  Translating = "translating",
  WaitingForValidationFromClient = "waiting-for-client-to-validate",
  Validated = "validated",
}

@Component({
  selector: "app-interprete",
  templateUrl: "./interprete.component.html",
  styleUrls: ["./interprete.component.scss"],
})

/**
 * TOTO:In the urgency I make a copy paste from commandComponent functions but it is necessary to rewrite the functions
 *
 */
export class InterpreteComponent implements OnInit {
  public interForm: FormGroup;
  public lang = [];
  public destFiltered = [];
  public translatorsCopies: ITraducteurCopie[];
  private translatorList: ITraducteur[] = [];
  private formValue: any;
  private state = EnumPrestationState.WaitingForTranslator;
  public show = false;
  private particulierlId: string;
  private particulierData: IParticulier;
  showLoading = false;
  private TVA: number = 1.2;

  public typeIntervention = [
    {
      id: 1,
      name: this.localisation.localise("menu_label_sur_place"),
    },
    /* {
      id: 2,
      name: 'Par téléphone'
    },*/
    {
      id: 3,
      name: this.localisation.localise("menu_label_visio"),
    },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private hiero: HieroBDD,
    private geo: GoogleGeo,
    private localisation: LocalisationService
  ) {}

  ngOnInit() {
    this.createForm();
    this.interForm
      .get("intervention")
      .valueChanges.subscribe((intervention) => {
        if (intervention.id === 1) this.interForm.get("address").enable();
        else {
          this.interForm.get("address").setValue("");
          this.interForm.get("address").disable();
        }
      });
    this.getTranslatorServices();
    this.interForm.get("srcLang").valueChanges.subscribe((lang) => {
      this.getDestLang(lang.iso639);
    });
    this.getPartData();
  }

  private createForm(): void {
    this.interForm = this.formBuilder.group({
      srcLang: ["", Validators.required],
      destLang: ["", Validators.required],
      room: ["", Validators.required],
      date: ["", Validators.required],
      time: ["", Validators.required],
      extHours: [
        "00:30",
        [
          Validators.required,
          Validators.pattern(
            /^(00:[3-5][0-9])|((0[1-9]|1[0-9]|2[0-3]):[0-5][0-9])$/
          ),
        ],
      ],
      contactDeplacement: [""],
      contactTel: [""],
      intervention: ["", Validators.required],
      address: [{ value: "", disabled: true }, Validators.required],
      context: ["", Validators.required],
    });
  }

  public getTranslatorServices() {
    const docRef = this.hiero.DB.collection("app_indexes").doc("services");

    return docRef
      .get()
      .then((snapshot) => {
        this.lang = snapshot.get("languages");
      })
      .catch();
  }

  public async getDestLang(lang) {
    this.interForm.get("destLang").setValue("");
    const traducteurDestListRef = await this.hiero.DB.collection(
      "traducteur_services"
    )
      .where("srcLanguageIso639", "==", lang)
      .get();
    const temp = traducteurDestListRef.docs.map((doc) =>
      doc.get("destLanguageIso639")
    );
    this.destFiltered = await temp.filter((v, i) => temp.indexOf(v) === i);
  }

  private async getPartData() {
    const currentUser = await this.hiero.Auth.User;
    const docRef = await this.hiero.DB.collection("particuliers").where(
      "uid",
      "==",
      currentUser.Id
    );
    return docRef.get().then((snapshot) => {
      return snapshot.docs.map((doc) => {
        this.particulierlId = snapshot.docs[0].id;
        this.particulierData = snapshot.docs[0].data() as IParticulier;
      });
    });
  }

  public onSubmitForm() {
    this.formValue = this.interForm.value;
    this.translatorList = [];

    this.getTraducteurs({
      srcName: this.formValue.srcLang.iso639,
      destName: this.formValue.destLang,
    });

    this.show = true;
  }

  private getTraducteurs({
    srcName,
    destName,
  }: {
    srcName: string;
    destName: string;
  }) {
    const traducteurServiceRef = this.hiero.DB.collection("traducteur_services")
      .where("srcLanguageIso639", "==", srcName)
      .where("destLanguageIso639", "==", destName)
      .get();

    traducteurServiceRef.then((doc) => {
      return doc.docs.map((doc) => {
        return this.hiero.DB.collection("traducteurs")
          .where("interpreter", "==", "true")
          .get()
          .then((snap) => {
            snap.docs.map((element) => {
              // don't show the ANR & Seine Maritime translator
              if (
                element.data().uid !== "f5UaBV5xlEavReS3NRAO2WuPleS2" &&
                element.data().uid !== "FDgWJPpMJaMV8of0zrMXhNALPz92"
              ) {
                let translator = element.data() as ITraducteur;

                if (element.id === doc.data().traducteurId) {
                  console.log(translator);

                  this.translatorList.push(translator);
                  //this.traducteurList[this.traducteurList.length ++] = element.data() as ITraducteur;
                  return element.data();
                }
              }
            });
            this.calculPrice(this.translatorList);
          });
      });
    });
  }

  private calculPrice(traducteurs: ITraducteur[]) {
    this.translatorsCopies = traducteurs
      // filter à supprimer une fois le prix est fixé
      .filter((element) => {
        if (element.interpretationPrice) return element;
      })
      .map((element) => {
        if (element) {
          console.log("element => ", element);
          console.log("exception => ", element.defaultException);
          let d = this.formValue.extHours;
          let a = d.split(":"); // split it at the colons
          let time = +a[0] + +a[1] / 60;

          // calcul du prix par rapport au nombre d'heure de l'intervention
          const priceHT = time * +element.interpretationPrice;

          const TVAval = (priceHT / 100) * 20;
          const priceTTC = priceHT + TVAval;
          const copie: ITraducteurCopie = {
            traducteur: element,
            priceHT: priceHT,
            tvaValue: TVAval,
            priceTTC: priceTTC,
          };
          return copie;
        }
      });
  }

  public getTranslatorNameOnClickButton(translatorName: string) {
    if (translatorName != null) {
      this.sendCommand(translatorName);
    }
  }

  private async sendCommand(translatorName: string) {
    this.showLoading = true;

    const isSelectedTrad = this.translatorsCopies.filter(
      (el) => el.traducteur.businessName === translatorName
    );

    await this.hiero.DB.collection("particuliers_commandes").add({
      is_interpretation: true,
      missions: this.formValue,
      state: this.state,
      traducteur: translatorName,
      particulier: this.particulierData,
      particulierId: this.particulierlId,
      room: this.formValue.room,
      roomLink: ROOM_LINK,
      TVA: isSelectedTrad[0].tvaValue,
      price: isSelectedTrad[0].priceHT,
      priceTTC: isSelectedTrad[0].priceTTC,
    });
    await this.hiero.DB.collection("facture_particuliers").add({
      numero: Math.floor(Math.random() * 1000000 + 1),
      room: this.formValue.room,
      date: new Date(),
      srcLang: this.formValue.srcLang.iso639,
      destLang: this.formValue.destLang,
      priceTTC: isSelectedTrad[0].priceTTC,
      priceHT: isSelectedTrad[0].priceHT,
      priceTVA: isSelectedTrad[0].tvaValue,
      priceTVAVal: isSelectedTrad[0].tvaValue,
      traducteur: isSelectedTrad[0].traducteur,
      traducteurName: translatorName,
      pagesNumber: "1",
      prestation: Math.floor(Math.random() * 1000000 + 1),
      particulierId: this.particulierlId,
      typePrestation: "Interprétation",
    });

    this.loadingFile();
  }

  public loadingFile() {
    this.show = false;
    this.showLoading = true;
    Swal.fire({
      title: this.localisation.localise("reset_password_send"),
    }).then(() => {
      this.activeModal.close();
    });
  }

  // Pour geolocaliser l'adresse au moment de la saisie de l'interpretation
  /**
   * async getLocalisation(addr: string) {
    await this.geo.geocode(addr);
  }
   * */
}
