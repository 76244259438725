<div class="modal-body">
  <div class="container">
    <div class="mb-3">
      <h3>{{ "new_order" | localise }}</h3>
    </div>
    <form [formGroup]="commandeForm" (ngSubmit)="onSubmitForm()">
      <!-- choose type of command (asser or classic) -->
      <div class="form-group">
        <label class="col-form-label" for="type">{{
          "traduction_type" | localise
        }}</label>
        <select
          class="form-group custom-select my-1 mr-sm-2"
          id="type"
          formControlName="type"
        >
          <option value="" disabled>
            {{ "select_choose" | localise }}
          </option>
          <option value="classic">{{ "traduction_classic" | localise }}</option>
          <option value="asser">
            {{ "traduction_assermentee" | localise }}
          </option>
        </select>
      </div>
      <div class="form-group row">
        <!-- choose document type/name -->
        <div class="col-sm">
          <label class="col-form-label" for="doctype">
            {{ "order_no_asser_document_name" | localise }}
          </label>
          <input
            class="form-control"
            type="text"
            id="doctype"
            formControlName="doctype"
          />
        </div>

        <!-- choose document source language-->
        <div class="col-sm">
          <label class="col-form-label" for="srcLang">{{
            "label_origin_lang" | localise
          }}</label>
          <select
            class="form-control custom-select"
            id="srcLang"
            formControlName="srcLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option
              *ngFor="let srcLang of srcLangList"
              [value]="srcLang.iso639"
            >
              {{ srcLang.iso639 | iso639ToNative }}
            </option>
          </select>
        </div>
        <!--  <select class="form-group custom-select my-1 mr-sm-2"  id="doctype" formControlName="doctype" (ngModelChange)="getSelectedListDocument($event)" >
            <option value="" disabled>{{ 'select_choose' | localise}}</option>
              <option *ngFor="let lang of documentFiltered" [ngValue]="lang">
                {{ lang.documentTypeId }}
            </option>
          </select> -->
      </div>

      <div class="form-group row">
        <!-- choose document destination language-->
        <div class="col-sm">
          <label class="col-form-label" for="destLang">{{
            "tasks_dst_country" | localise
          }}</label>
          <select
            class="form-control custom-select"
            id="destLang"
            formControlName="destLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option *ngFor="let destLang of destLangList" [value]="destLang">
              {{ destLang | iso639ToNative }}
            </option>
          </select>
        </div>

        <!-- choose document country language-->
        <div class="col-sm">
          <label class="col-form-label" for="originLang">{{
            "tasks_src_country" | localise
          }}</label>
          <select
            class="form-control custom-select"
            id="originLang"
            formControlName="originLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option
              *ngFor="let srcCountry of srcCountryList"
              [value]="srcCountry.code"
            >
              {{ srcCountry.code | country }}
            </option>
          </select>
        </div>
      </div>
      <!-- description -->
      <div class="form-group">
        <label class="my-1 mr-2" for="description">
          {{ "commande_desc" | localise }}
        </label>
        <textarea
          class="form-control"
          id="description"
          rows="3"
          formControlName="description"
        ></textarea>
      </div>
      <!-- end -->
      <!-- urgency -->
      <div class="form-group row justify-content-between">
        <label class="col-form-label" for="urgency">
          {{ "urgency" | localise }}
        </label>
        <input
          type="date"
          class="form-control col-md-5 mr-3"
          id="urgency"
          formControlName="urgency"
          data-date-format="DD MMMM YYYY"
        />
      </div>

      <!-- document pages numbers check -->
      <div class="form-group d-flex align-items-center">
        <label class="col-form-label" for="isNumberPage"
          >{{ "order_no_asser_query_pdf_page_number" | localise }}
        </label>
        <input
          type="checkbox"
          class="ml-3 form-check-input-reverse"
          id="isNumberPage"
          formControlName="isNumberPage"
        />
      </div>
      <!-- number of pages -->
      <div *ngIf="isChecked" class="form-group row justify-content-between">
        <label class="col-form-label" for="docFilePageNumber">
          {{ "order_no_asser_query_pdf_page_number_yes" | localise }}
        </label>
        <input
          class="form-control col-md-5 mr-3"
          type="number"
          id="docFilePageNumber"
          formControlName="docFilePageNumber"
          min="0"
        />
        <div
          class="alert text-danger pull-right"
          *ngIf="commandeForm.controls['docFilePageNumber'].hasError('min')"
        >
          {{ "alert_form_error_pages_number" | localise }}
        </div>
      </div>

      <!-- the documents to translate -->
      <div class="form-group">
        <input
          id="input_file"
          class="form-control-file"
          type="file"
          formControlName="docFile"
          multiple
          (change)="fileUpload($event.target.files)"
        />
      </div>

      <!-- button to get traslators -->
      <div class="mb-3 d-flex justify-content-end">
        <button class="btn my-color" [disabled]="commandeForm.invalid">
          {{ "find_traductors" | localise }}
        </button>
      </div>
    </form>
    <table class="table table-responsive w-auto" *ngIf="show">
      <thead>
        <tr>
          <th>{{ "order_traductor_delegate" | localise }}</th>
          <th>{{ "agency_address_city" | localise }}</th>
          <th>{{ "tasks_price_ttc" | localise }}</th>
          <th>{{ "assermente" | localise }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="translatorList?.length == 0">
          {{
            "no_traductor" | localise
          }}
          <i>{{ "no_traductor_message" | localise }}</i>
        </tr>
        <tr *ngFor="let traducteur of translatorsCopies">
          <td nowrap>
            {{ traducteur.traducteur.businessName }}
          </td>
          <td nowrap>
            {{ traducteur.traducteur.address.city }}
          </td>
          <td>{{ traducteur.priceTTC }} €</td>
          <td>
            {{
              traducteur.traducteur.assermentation === "true" ||
              traducteur.traducteur.assermentation == true
                ? ("prestation_document_confirm_oui" | localise)
                : ("prestation_document_confirm_non" | localise)
            }}
          </td>
          <td>
            <button
              class="btn my-color"
              (click)="
                getTranslatorNameOnClickButton(
                  traducteur.traducteur.businessName
                )
              "
            >
              {{ "select" | localise }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="load-wrapp" *ngIf="showLoading">
      <div class="load-5">
        <p>{{ "loading" | localise }} - {{ progress }} %</p>

        <div class="progress">
          <div
            class="progress-bar w-{{ progress }}"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    {{ "close_modal" | localise }}
  </button>
</div>
