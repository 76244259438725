<h5 class="d-inline">{{'main_menu_left_section_navigation_messagerie' | localise }}</h5>
<span class="d-inline ml-3 my-txt">{{'message_paragraphe' | localise }}</span>
<div class="row border mt-3 mr-1">
  <h5 class="ml-3">{{'discuss_encours' | localise }}</h5>
</div>
<div class="row border mr-1 mb-3">
  <div class="col-4 my-older-discuss border">
    <!--<p>okkk</p> -->
  </div>
  <div class="message mr-1 col-8 border">
    <div class="message">
      <div class="receive-msg">

      </div>
      <div class="send-msg">

      </div>
    </div>
  </div>
</div>
