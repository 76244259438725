import { User } from '../user/User';
import { BehaviorSubject, PartialObserver } from 'rxjs';
import { IMessageNotification } from '../interfaces/IMessaging';
import { Traducteur } from '../traducteur/Traducteur';
import { Subscription } from 'rxjs/Subscription';
import { Particulier } from '../professionnel/Professionnel';

const FIREBASE_MESSAGE_NOTIFICATIONS_CLIENT_COLLECTION = 'client_message_notifications';
const FIREBASE_MESSAGE_NOTIFICATIONS_TRANSLATOR_COLLECTION = 'traducteur_message_notifications';
const FIREBASE_MESSAGE_NOTIFICATIONS_PROFESSIONNEL_COLLECTION = 'partoculier_message_notifications';

export class MessageNotifications {

  private _doc: firebase.firestore.DocumentReference;

  private _stopListeningMessages;

  private _notificationSubject: BehaviorSubject<IMessageNotification>;


  constructor(doc: firebase.firestore.DocumentReference, snapshot: firebase.firestore.DocumentSnapshot) {
    this._doc = doc;

    let not: IMessageNotification = {
      stored: 0,
      seen: 0
    };
    if (snapshot.exists) {
      not = snapshot.data() as IMessageNotification;
    }

    this._notificationSubject = new BehaviorSubject(not);

    this._stopListeningMessages = doc.onSnapshot(
      (newSnap: firebase.firestore.DocumentSnapshot) => {
        const notif: IMessageNotification = newSnap.data() as IMessageNotification;
        this._notificationSubject.next(notif);
      }
    );

  }

  public get Id() {
    return this._doc.id;
  }

  public get Unseen() {
    const data = this._notificationSubject.value;
    return Math.max(data.stored - data.seen, 0);
  }

  public cleanup() {
    if (this._stopListeningMessages ) {
      this._stopListeningMessages();
      this._stopListeningMessages = null;
    }
  }

  public async SetSeen() {
    const not: IMessageNotification = this._notificationSubject.value;
    await this._doc.update(
      {
        seen: not.stored
      }
    );
  }

  public WatchNotifications(observer: PartialObserver<IMessageNotification>): Subscription {
    return this._notificationSubject.subscribe(observer);
  }

  private static async getOrAdd(doc: firebase.firestore.DocumentReference): Promise<firebase.firestore.DocumentSnapshot> {
    let snapshot = await doc.get();

    if (!snapshot.exists) {
      const not: IMessageNotification = {
        stored: 0,
        seen: 0
      };

      await doc.set(not);
      snapshot = await doc.get();
    }
    return snapshot;
  }


  public static async InitForClientPrestation(user: User, prestationId: string) {
    const doc = user.DB.collection(FIREBASE_MESSAGE_NOTIFICATIONS_CLIENT_COLLECTION).doc(prestationId);
    const snapshot = await MessageNotifications.getOrAdd(doc);
    return new MessageNotifications(doc, snapshot);

  }

  public static async InitForTranslatorPrestation(translator: Traducteur, prestationId: string) {
    const doc = translator.User.DB.collection(FIREBASE_MESSAGE_NOTIFICATIONS_TRANSLATOR_COLLECTION).doc(prestationId);
    const snapshot = await MessageNotifications.getOrAdd(doc);
    return new MessageNotifications(doc, snapshot);
  }

  public static async InitForParticulierPrestation(particulier: Particulier, prestationId: string) {
    const doc = particulier.User.DB.collection(FIREBASE_MESSAGE_NOTIFICATIONS_PROFESSIONNEL_COLLECTION).doc(prestationId);
    const snapshot = await MessageNotifications.getOrAdd(doc);
    return new MessageNotifications(doc, snapshot);
  }
}
