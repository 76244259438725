<h4 class="ml-3">
  {{ "main_menu_left_section_navigation_question" | localise }}
  <small>{{ "head_particulier" | localise }}</small>
</h4>
<div class="d-flex">
  <div class="col-md-8 mt-3 myDiv mb-3">
    <h4 class="mt-3">{{ "section_questions_frequentes" | localise }}</h4>
    <hr />
    <div>
      <h6>
        <i class="fa fa-question-circle"></i>
        {{ "partuclier_trad_doc_head1" | localise }}
      </h6>
      <p>{{ "partuclier_trad_doc1" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6>
        <i class="fa fa-question-circle"></i>
        {{ "partuclier_trad_doc_head2" | localise }}
      </h6>
      <p>{{ "partuclier_trad_doc2" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6>
        <i class="fa fa-question-circle"></i>
        {{ "partuclier_trad_doc_head3" | localise }}
      </h6>
      <p>{{ "partuclier_trad_doc3" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6>
        <i class="fa fa-question-circle"></i>
        {{ "partuclier_trad_doc_head4" | localise }}
      </h6>
      <p>{{ "partuclier_trad_doc4" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6>
        <i class="fa fa-question-circle"></i>
        {{ "partuclier_trad_doc_head5" | localise }}
      </h6>
      <p>{{ "partuclier_trad_doc5" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6>
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_contact" | localise }}
      </h6>
      <p>{{ "section_questions_paragraphe_six" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6>
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_traductions" | localise }}
      </h6>
      <p>
        {{ "section_questions_paragraphe_seven" | localise }}
      </p>
    </div>
    <hr />
    <div>
      <h6>
        <i class="fa fa-question-circle"></i>
        {{ "partuclier_trad_doc_head6" | localise }}
      </h6>
      <p>{{ "partuclier_trad_doc6" | localise }}</p>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card mt-3" style="width: 25rem">
      <div class="card-body">
        <h5 class="card-title">{{ "agency_support" | localise }}</h5>
        <div class="text-right">
          <h6 class="card-subtitle mb-2 txt">
            {{ "agency_support_service_client" | localise }}
          </h6>
          <p class="card-text">
            <strong> {{ "support_tel" | localise }}</strong>
          </p>
          <p>{{ "agency_support_ouverture" | localise }}</p>
        </div>
        <p>
          {{ "agency_support_questions" | localise }}
          <a href="mailto:contact@hiero-support.com"
            >contact@hiero-support.com</a
          >
        </p>
      </div>
    </div>
    <div class="card mt-5" style="width: 25rem">
      <div class="card-body">
        <h5 class="card-title">{{ "agency_support_guide_part" | localise }}</h5>
        <!-- <h6 class="card-subtitle mb-2 ">Numéro de téléphone- Services Clients</h6> -->
        <div class="text-right text-justify">
          <p class="card-text">{{ "agency_support_guide_p" | localise }}</p>

          <p>
            <a href="#"> {{ "agency_support_download_guide" | localise }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
