<div class="row">
  <p class="ml-3 h4">{{ "consult_orders" | localise }}</p>
  <!--<span class="d-inline">Accedez à vos commandes en détail, validez et confirmez les traductions reçues, discutez avec votre traducteur</span> -->
  <div class="col d-flex justify-content-end">
    <!-- Le bouton Afficher les commandes de -->
    <div ngbDropdown class="d-inline-block">
      <button class="btn my-color mb-3" id="dropdownBasic1" ngbDropdownToggle>
        {{ "menu" | localise }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="showDataDashboard(0)">
          {{ "menu_label_traductor_asser" | localise }}
        </button>
        <button ngbDropdownItem (click)="showDataDashboard(1)">
          {{ "menu_label_traductor_classic" | localise }}
        </button>
        <button ngbDropdownItem (click)="showDataDashboard(2)">
          {{ "menu_label_interprestation" | localise }}
        </button>
        <button ngbDropdownItem (click)="showDataDashboard(3)">
          {{ "menu_label_all" | localise }}
        </button>
      </div>
    </div>

    <!-- les 2 boutons pour ajouter une nouvelle commande (trad ou interpretation) -->
    <div>
      <button
        class="btn my-color mb-3 ml-2"
        id="dropdownBasic2"
        (click)="showFormModal(1)"
      >
        {{ "new_orders_traduction" | localise }}
      </button>
      <button
        class="btn my-color mb-3 ml-2"
        id="dropdownBasic"
        (click)="showFormModal(2)"
      >
        {{ "new_orders_interprestation" | localise }}
      </button>
      <!-- [disabled]="hasBudget" -->
      <!-- <div ngbDropdownMenu aria-labelledby="dropdownBasic">
        <button ngbDropdownItem   (click)="showFormModal(0)"> {{ 'menu_label_traductor_asser' | localise}} </button>
        <button ngbDropdownItem  (click)="showFormModal(1)"> {{ 'menu_label_traductor_classic' | localise}} </button>
        <button ngbDropdownItem   (click)="showFormModal(2)"> {{ 'menu_label_interprestation' | localise}} </button>
      </div> -->
    </div>

    <!--<button class="btn my-color mb-3 ml-2">{{'table_header_archives' | localise }}</button> -->
  </div>
  <!--
       <div class="mr-1">
      <button class="btn my-color white pull-right" (click)="openModal(0)"
              [disabled]="hasBudget">{{ 'new_order_ass' | localise}}
      </button>
    </div>
    <div class="mr-1">
      <button class="btn my-color white pull-right" (click)="openModal(1)"
              [disabled]="hasBudget">{{ 'new_order' | localise}}
      </button>
    </div>
      <div class="mr-3">
        <button class="btn my-color white pull-right" (click)="openModal(2)"
                [disabled]="hasBudget">Commander un interprète
        </button>
      </div>
    -->
</div>

<hr />
<!--
<div *ngIf="hasBudget">
  <span>
    <h4>
      {{ 'message_no_budget' | localise }}
      <span *ngIf="budget == 0 || budget == null; else elseBlock">
        0 (€)
      </span>
      <ng-template #elseBlock>
        {{ budget }} (€)
      </ng-template>
      {{ 'message_contact' | localise }}
    </h4>
  </span>
  <hr />
</div>
-->

<!-- liste des commandes assermentées -->
<div id="orders_pro">
  <!-- <ng-template #popFilterContent>
    <a *ngFor="let filter of filterUI" class="badge clickable mr-1"
       [ngClass]="{'badge-info':filter.active, 'badge-secondary':!filter.active}"
       (click)="toggleState(filter)">{{ filter.state | prestationStateName }}</a>
  </ng-template>
  <ng-template #popFilterTitle>
    {{ 'tasks_filters'| localise }}
  </ng-template> -->
  <!-- assermentation bloc -->

  <h2 class="pull-left">{{ "main_left_menu_title_asser" | localise }}</h2>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" class="align-middle">
          {{ "tasks_sent_on" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "prestation_order_document_type" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_language_from" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "pays_origine" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_language_to" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "table_header_nb_pages" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_status" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "tasks_price_ttc" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_traductor_delegate" | localise }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let commande of assermentationList
            | slice
              : (pageAsser - 1) * pageSize
              : (pageAsser - 1) * pageSize + pageSize
        "
        class="clickable"
        (click)="detailCommande(commande)"
      >
        <!--  -->

        <td
          class="align-middle"
          style="background-color: #2d4054; width: 25px; color: white"
        >
          {{ commande.createdAt.toDate() | date: "dd/MM/yyyy " }}
        </td>
        <td class="align-middle">{{ commande.documentType }}</td>
        <td class="align-middle">
          {{ commande.srcLanguageIso639 | iso639ToNative }}
        </td>
        <td class="align-middle">
          {{ commande.prestationInfo.srcCountryCode | country }}
        </td>
        <td class="align-middle">
          {{ commande.destLanguageIso639 | iso639ToNative }}
        </td>
        <td class="align-middle">
          <span *ngIf="commande.pagesNumber">{{ commande.pagesNumber }}</span>
          <span *ngIf="!commande.pagesNumber">1</span>
        </td>
        <td class="align-middle">
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'waiting-for-translator'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #257f25"
            *ngIf="commande.prestationInfo.state == 'translating'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #257f25"
            *ngIf="commande.prestationInfo.state == 'validated-pro'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="
              commande.prestationInfo.state == 'waiting-for-client-to-validate'
            "
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'refused-by-translator'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'cancelled-by-client'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'validated'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'waiting-for-payment'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
        </td>
        <td class="align-middle">{{ commande.prestationInfo.price.ttc }}</td>
        <td class="align-middle">{{ commande.traducteurName }}</td>
      </tr>
    </tbody>
  </table>
  <ngb-pagination
    *ngIf="!!assermentationList"
    [collectionSize]="assermentationList.length"
    [page]="pageAsser"
    [pageSize]="pageSize"
    (pageChange)="onPageChange($event, 0)"
    aria-label="Default pagination"
  ></ngb-pagination>
</div>
<!-- end bloc assermentée-->

<!-- list des commandes classics -->
<div id="orders">
  <!--class="showBloc"-->
  <h3 class="pull-left">{{ "menu_label_traductor_classic" | localise }}</h3>
  <table class="table table-striped">
    <thead>
      <tr>
        <!-- <th scope="col" class="align-middle">
      <a *ngIf="desc" class="badge badge-info menu_badge text-white clickable" (click)="toggleOrder()" ><i class="fa fa-angle-down"></i></a>
      <a *ngIf="!desc" class="badge badge-info menu_badge text-white clickable" (click)="toggleOrder()"><i class="fa fa-angle-up"></i></a>
      {{ 'order_id' | localise }}
    </th>-->
        <th scope="col" class="align-middle">
          {{ "tasks_sent_on" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "prestation_order_document_type" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_language_from" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_language_to" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "table_header_nb_pages" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_status" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "tasks_price_ttc" | localise }}
        </th>
        <!--<th scope="col" class="align-middle">
      <a class="badge badge-info menu_badge text-white clickable" [ngbPopover]="popFilterContent" [popoverTitle]="popFilterTitle" [placement]="['bottom']"><i class="fa fa-filter"></i></a>
      {{ 'tasks_state' | localise }}
    </th>-->
        <th scope="col" class="align-middle">
          {{ "order_traductor_delegate" | localise }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let commande of classicList
            | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
        "
        class="clickable"
        (click)="detailCommande(commande)"
      >
        <!--   -->

        <td
          class="align-middle"
          style="background-color: #2d4054; width: 25px; color: white"
        >
          {{ commande.createdAt.toDate() | date: "dd/MM/yyyy " }}
        </td>
        <td class="align-middle">{{ commande.documentType }}</td>
        <td class="align-middle">
          {{ commande.srcLanguageIso639 | iso639ToNative }}
        </td>
        <td class="align-middle">
          {{ commande.destLanguageIso639 | iso639ToNative }}
        </td>
        <td class="align-middle">
          <span *ngIf="commande.pagesNumber">{{ commande.pagesNumber }}</span>
          <span *ngIf="!commande.pagesNumber">1</span>
        </td>
        <td class="align-middle">
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'waiting-for-translator'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #257f25"
            *ngIf="commande.prestationInfo.state == 'translating'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #257f25"
            *ngIf="commande.prestationInfo.state == 'validated-pro'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="
              commande.prestationInfo.state == 'waiting-for-client-to-validate'
            "
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'refused-by-translator'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'cancelled-by-client'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'validated'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
          <span
            style="color: #935e10"
            *ngIf="commande.prestationInfo.state == 'waiting-for-payment'"
            >{{ commande.prestationInfo.state | prestationStateName }}</span
          >
        </td>
        <td class="align-middle">{{ commande.prestationInfo.price.ttc }}</td>
        <td class="align-middle">{{ commande.traducteurName }}</td>
      </tr>
    </tbody>
  </table>
  <ngb-pagination
    *ngIf="!!classicList"
    [collectionSize]="classicList.length"
    [page]="page"
    [pageSize]="pageSize"
    (pageChange)="onPageChange($event, 1)"
    aria-label="Default pagination"
  ></ngb-pagination>
</div>
<!-- end classic -->

<!-- liste des commandes d'interpetation -->
<div id="interpretation_list">
  <h2 class="pull-left">{{ "menu_label_interprestation" | localise }}</h2>
  <!-- <div >
    <button class="btn my-color white pull-right"  (click)="openModal(2)" [disabled]="hasBudget" >
      Commander un interprète </button>
  </div> -->
  <!-- interpetation bloc -->
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" class="align-middle">
          {{ "account_hist_pro_date" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_language_from" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_language_to" | localise }}
        </th>
        <!--<th scope="col" class="align-middle">{{ 'order_status' | localise }}</th> -->
        <th scope="col" class="align-middle">
          {{ "tasks_price_ttc" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_traductor_delegate" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "order_status" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "salle_de_conference" | localise }}
        </th>
        <th scope="col" class="align-middle">{{ "action" | localise }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let commandes of commandesListInterpretations
            | sortDateInterpretation: commandesListInterpretations
            | slice
              : (pageInter - 1) * pageSize
              : (pageInter - 1) * pageSize + pageSize
        "
      >
        <td
          class="align-middle"
          style="background-color: #2d4054; width: 25px; color: white"
        >
          {{ commandes.missions.date | date: "dd/MM/yyyy" }}
        </td>
        <td class="align-middle">
          {{ commandes.missions.srcLang.iso639 | iso639ToNative }}
        </td>
        <td class="align-middle">
          {{ commandes.missions.destLang | iso639ToNative }}
        </td>
        <!--<td class="align-middle"> {{ commandes.interpretationData.state | prestationStateName }}</td> -->
        <td class="align-middle">{{ commandes.price }}</td>
        <td class="align-middle">{{ commandes.traducteur }}</td>
        <td class="align-middle">
          <span
            style="color: #935e10"
            *ngIf="commandes.state == 'waiting-for-translator'"
            >{{ commandes.state | prestationStateName }}</span
          >
          <span
            style="color: #257f25"
            *ngIf="commandes.state == 'translating'"
            >{{ commandes.state | prestationStateName }}</span
          >
          <span style="color: #257f25" *ngIf="commandes.state == 'validated'">{{
            commandes.state | prestationStateName
          }}</span>
          <span
            style="color: #935e10"
            *ngIf="commandes.state == 'waiting-for-client-to-validate'"
            >{{ commandes.state | prestationStateName }}</span
          >
        </td>
        <td class="align-middle">{{ commandes.missions.room }}</td>
        <td class="align-middle">
          <button
            class="btn my-color white pull-right"
            (click)="getRooms()"
            [disabled]="commandes.missions.address != ''"
          >
            {{ "joindre_salon" | localise }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ngb-pagination
    *ngIf="!!commandesListInterpretations"
    [collectionSize]="commandesListInterpretations.length"
    [page]="pageInter"
    [pageSize]="pageSize"
    (pageChange)="onPageChange($event, 2)"
    aria-label="Default pagination"
  ></ngb-pagination>
</div>
<!-- end interpretation -->

<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  Launch demo modal
</button> -->
<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" *ngIf="">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> -->
