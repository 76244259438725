<div class="list-group">
  <div href="#" class="list-group-item list-group-item-action flex-column align-items-start bg-light" *ngFor="let commande of command">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">{{commande.documentType}}</h5>
      <button class="btn btn-primary" (click)="paid(commande.prestationInfo.price.ttc * 100)">Payer ma commande</button>
    </div>
    <p class="mb-1">{{commande.description}}</p>
    <small>Donec id elit non mi porta.</small>
  </div>
  
</div>