<!-- begin login -->
<div class="login login-with-news-feed" style="min-height: 100vh">
  <!-- begin news-feed -->
  <div class="news-feed">
    <div
      class="news-image"
      style="background-image: url(../assets/img/login-bg/expat-bg.jpg)"
    ></div>

    <!-- <div class="news-caption">
      <h4 class="caption-title">{{ "login_caption_title" | localise }}</h4>
      <p>
        {{ "login_caption_subtitle" | localise }}
      </p>
    </div> -->
  </div>
  <!-- end news-feed -->
  <!-- begin right-content -->
  <div class="right-content">
    <div class="d-flex p-20 flex-row-reverse">
      <app-language-dropdown></app-language-dropdown>
    </div>

    <!-- begin login-header -->
    <div class="login-header">
      <div class="brand">
        <img
          src="../assets/img/logo/origami.png"
          height="60px"
          style="margin-right: 10px"
        />
        {{ "login_main_logo" | localise }}
      </div>
      <div class="icon">
        <i class="fa fa-sign-in"></i>
      </div>
    </div>

    <!-- end login-header -->
    <!-- begin login-content -->
    <div class="login-content">
      <form
        [formGroup]="resetPassForm.Group"
        novalidate
        class="margin-bottom-0"
        data-parsley-validate="true"
      >
        <h2 class="register-header">
          {{ "reset_password_main" | localise }}
        </h2>
        <app-form-input
          [parameters]="resetPassForm.GetInputElement('email')"
        ></app-form-input>
        <app-form-general-error
          [submitForm]="resetPassForm"
        ></app-form-general-error>
        <!--<div class="checkbox checkbox-css m-b-30">
          <input type="checkbox" id="remember_me_checkbox" value="" />
          <label for="remember_me_checkbox">
          Rester connecté
          </label>
        </div>
        -->
        <div class="login-buttons">
          <button
            (click)="resetPassForm.Submit()"
            [disabled]="!resetPassForm.Valid"
            class="btn btn-block btn-lg"
          >
            {{ "reset_password_send" | localise }}
          </button>
        </div>

        <div *ngIf="sentOk" class="alert alert-success mt-3">
          {{ "reset_password_success" | localise }}
        </div>

        <div class="m-t-20 m-b-20 p-b-20 text-inverse">
          <a [routerLink]="['..', 'connexion']" class="text-success">{{
            "reset_password_login" | localise
          }}</a>
        </div>

        <hr />
        <p class="text-center text-grey-darker">
          {{ "login_main_copyright" | localise }}
        </p>
      </form>
    </div>
    <!-- end login-content -->
  </div>
  <!-- end right-container -->
  <app-wait [show]="busy"></app-wait>
</div>
<!-- end login -->
