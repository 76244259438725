var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { HieroBDD } from "../../../services/hierobdd.service";
import { GoogleGeo } from "../../../services/google.services";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import * as firebase from "firebase";
import { LoginModalComponent } from "./login-modal/login-modal.component";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
export class ProfileComponent {
    constructor(hiero, formBuilder, geo, router, modalService) {
        this.hiero = hiero;
        this.formBuilder = formBuilder;
        this.geo = geo;
        this.router = router;
        this.modalService = modalService;
        this.updated = false;
        this.updatedPwd = false;
        this.error = false;
        this.errorLogin = false;
        this.errorPwd = false;
        this.errorImg = false;
        this.active = false;
        this.search = (text$) => text$.pipe(debounceTime(100), distinctUntilChanged(), switchMap((term) => (term.length < 2 ? [] : this.geo.geocode(term))));
        this.formatter = (loc) => loc && loc.address ? loc.address.formatted : "";
    }
    ngOnInit() {
        this.getUserData();
        this.createForm();
        setTimeout(() => {
            this.profileForm.valueChanges.subscribe((val) => {
                this.active = true;
            });
        }, 2000);
        this.createFormPwd();
    }
    getUserData() {
        this.currentUser = this.hiero.Auth.User;
        this.profileData = this.currentUser.Profile;
        this.user = firebase.auth().currentUser;
        this.photo = this.user.photoURL;
        const docRef = this.hiero.DB.collection("particuliers").where("uid", "==", this.currentUser.Id);
        docRef.get().then((snapshot) => {
            this.particulierData = snapshot.docs[0].data();
            this.particulierId = snapshot.docs[0].id;
            this.geo
                .geocode(this.particulierData.address.formatted)
                .then((x) => this.profileForm.get("address").setValue(x[0]));
            this.profileForm
                .get("address2")
                .setValue(this.particulierData.address.extra);
        });
    }
    createForm() {
        this.profileForm = this.formBuilder.group({
            familyName: [this.profileData.familyName, Validators.required],
            givenName: [this.profileData.givenName, Validators.required],
            address: ["", Validators.required],
            address2: [""],
            email: [this.user.email, [Validators.required, Validators.email]],
            tel: [this.profileData.telephone, Validators.required],
        });
    }
    createFormPwd() {
        this.passwordForm = this.formBuilder.group({
            password: ["", Validators.required],
            newPassword: ["", [Validators.required, Validators.minLength(8)]],
            newPassword2: ["", Validators.required],
        }, {
            validator: this.mustMatch("newPassword", "newPassword2"),
        });
    }
    onFileSelected(event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.errorImg = false;
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                // store the image in the firebase storage at images/profile
                let storage = this.hiero.Storage;
                const fileRef = storage.ref("images/profile/").child(this.user.uid);
                const result = yield fileRef.put(file);
                // get the image stored and update the profile picture
                yield storage
                    .ref(result.ref.fullPath)
                    .getDownloadURL()
                    .then((photo) => {
                    this.user
                        .updateProfile({
                        photoURL: photo,
                    })
                        .then(() => {
                        this.photo = this.user.photoURL;
                        //window.location.reload();
                    })
                        .catch((error) => (this.errorImg = true));
                });
            }
        });
    }
    submit() {
        this.updated = false;
        this.errorLogin = false;
        this.error = false;
        let formValue = this.profileForm.value;
        formValue.address.address.extra = formValue.address2;
        if (formValue.email != this.user.email) {
            const modal = this.modalService.open(LoginModalComponent);
            modal.componentInstance.type = "modif";
            modal.result.then((x) => {
                if (x === true) {
                    this.user
                        .updateEmail(formValue.email)
                        .then(() => {
                        this.updateProfileInfo(formValue);
                        this.user.sendEmailVerification();
                        this.router.navigate(["compte", "emailVerify", "2"]);
                    })
                        .catch((error) => {
                        // TO DO : what to do if update email failed?
                        console.log("update email failed");
                    });
                }
                // TO DO : what to do if login failed?
                if (x === false) {
                    this.errorLogin = true;
                }
            });
        }
        else {
            this.updateProfileInfo(formValue);
        }
    }
    updateProfileInfo(formValue) {
        let typePartenaire = " ";
        this.profileData.partenaire
            ? (typePartenaire = this.profileData.partenaire)
            : " ";
        this.currentUser
            .UpdateProfile({
            familyName: formValue.familyName,
            givenName: formValue.givenName,
            telephone: formValue.tel,
            email: formValue.email,
            partenaire: typePartenaire,
        })
            .then(() => {
            this.hiero.DB.collection("particuliers")
                .doc(this.particulierId)
                .update({
                address: formValue.address.address,
                coords: formValue.address.coords,
            });
            this.updated = true;
            this.profileData.familyName = formValue.familyName;
            this.profileData.givenName = formValue.givenName;
        })
            .catch((error) => {
            this.error = true;
        });
    }
    submitPwd() {
        this.errorPwd = false;
        this.updatedPwd = false;
        const credentials = firebase.auth.EmailAuthProvider.credential(this.user.email, this.passwordForm.value.password);
        this.user
            .reauthenticateWithCredential(credentials)
            .then(() => {
            this.user
                .updatePassword(this.passwordForm.value.newPassword)
                .then(() => {
                this.updatedPwd = true;
            })
                .catch((error) => {
                this.errorPwd = true;
            });
        })
            .catch((error) => (this.errorPwd = true));
    }
    mustMatch(controlName, matchingControlName) {
        return (formGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            }
            else {
                matchingControl.setErrors(null);
            }
        };
    }
    deleteAccount() {
        const modal = this.modalService.open(LoginModalComponent);
        modal.componentInstance.type = "delete";
        modal.result.then((x) => {
            if (x === true) {
                this.user
                    .delete()
                    .then(() => {
                    this.router.navigate(["compte", "connexion"]);
                })
                    .catch((error) => {
                    console.log("delete error");
                });
            }
            else {
                console.log("delete error");
            }
        });
    }
}
