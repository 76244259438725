var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as jsPDF from "jspdf";
import { HieroBDD } from "../../../services/hierobdd.service";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import { Language } from "../../../../../common/src/bdd/utility/Language";
let img = new Image();
img.src = "../assets/img/logo/fact.png";
var doc = new jsPDF();
var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
export class FactureDetailComponent {
    constructor(activatedRoute, router, hiero, localisation) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.hiero = hiero;
        this.localisation = localisation;
    }
    download(param) {
        var doc = new jsPDF();
        // ####### Adresse de Hiero
        doc.setFontSize(12);
        doc.setFontType("bold");
        doc.text(this.localisation.localise("support_company"), 10, 10);
        doc.setFontSize(10);
        doc.setFontType("normal");
        doc.text(this.localisation.localise("support_addr1"), 10, 15);
        doc.text(this.localisation.localise("support_addr2"), 10, 20);
        doc.text(this.localisation.localise("support_tel"), 10, 25);
        doc.text(this.localisation.localise("support_email"), 10, 30);
        //##### Logo
        doc.addImage(img, "png", 150, 10, 50, 50);
        // ##### Titre facture
        doc.setTextColor("#2d4054");
        doc.setFontSize(20);
        doc.setFontType("bold");
        doc.text(this.localisation.localise("head_facture").toUpperCase(), 10, 70);
        doc.setFontType("normal");
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(30);
        // Infos sous le titre de la facture:
        doc.setTextColor("black");
        doc.setFontSize(10);
        doc.setFontType("bold");
        doc.text(this.localisation.localise("facture_numero").toUpperCase(), 20, 80);
        doc.text(this.localisation.localise("section_date").toUpperCase(), 20, 85);
        doc.text(this.localisation.localise("facture_echeance").toUpperCase(), 20, 90);
        doc.text(this.localisation.localise("facture_conditions").toUpperCase(), 20, 95);
        doc.setFontType("normal");
        doc.text(`${this.facture.numero}`, 50, 80);
        doc.text(new Date(this.facture.date.seconds * 1000).toLocaleDateString("fr-FR"), 50, 85);
        doc.text(new Date(this.facture.date.seconds * 1000).toLocaleDateString("fr-FR"), 50, 90);
        doc.text(this.localisation.localise("facture_comptant"), 50, 95);
        // ADRESSE DE FACTURATION
        doc.setFontType("bold");
        doc.text(this.localisation.localise("facture_adress").toUpperCase(), 130, 80);
        doc.setFontType("normal");
        doc.text(this.fullName.profile.familyName + " " + this.fullName.profile.givenName, 130, 87);
        doc.text(this.localisation.localise("facture_part"), 130, 92);
        doc.text(this.clientData.address.number + " " + this.clientData.address.street, 130, 97);
        doc.text(this.clientData.address.code + " " + this.clientData.address.city, 130, 102);
        doc.text(this.clientData.address.country, 130, 107);
        // Ligne horizontal
        doc.setDrawColor("#2d4054");
        doc.line(pageWidth - 20, 120, 20, 120);
        // doc.line(190, 151, 30, 151);
        // ####### Le tableau
        // # En tête
        doc.setFillColor(45, 64, 84);
        doc.rect(20, 125, pageWidth - 40, 10, "F");
        doc.setTextColor("#FFFFFF");
        doc.text(this.localisation.localise("facture_qte").toUpperCase(), 21, 131);
        doc.text(this.localisation.localise("commande_produit").toUpperCase(), 33, 131);
        doc.text(this.localisation.localise("commande_desc").toUpperCase(), 73, 131);
        doc.text(this.localisation.localise("tasks_price_tva").toUpperCase(), 127, 131);
        doc.text(this.localisation.localise("facture_price").toUpperCase(), 147, 129);
        doc.text(this.localisation.localise("facture_unitaire").toUpperCase(), 147, 133);
        doc.text(this.localisation.localise("facture_montant").toUpperCase(), 171, 131);
        // # La data
        doc.setTextColor("black");
        doc.text(`${this.facture.pagesNumber ? this.facture.pagesNumber : 1}`, 21, 143); //  QTE
        doc.text(this.product, 33, 143); //  Product
        let type = "";
        if (this.facture.typePrestation === "Interprétation")
            type = this.localisation.localise("interpretation");
        if (this.facture.typePrestation === "Traduction assermentée")
            type = this.localisation.localise("traduction_assermentee");
        if (this.facture.typePrestation === "Traduction classique")
            type = this.localisation.localise("traduction_classic");
        doc.text(type +
            " " +
            this.localisation.localise("prestation_order_from").toLowerCase() +
            "\n " +
            Language.GetNameFromISO639(this.facture.srcLang).toLowerCase() +
            " " +
            this.localisation.localise("order_language_to").toLowerCase() +
            " " +
            Language.GetNameFromISO639(this.facture.destLang).toLowerCase(), 73, 143); // Description (faire la gestion de l'espace direcetement avec substr() et \n)
        doc.text("20 %", 127, 143); // TVA
        doc.text((this.facture.priceHT.toFixed(2) /
            (this.facture.pagesNumber ? this.facture.pagesNumber : 1)).toFixed(2), 152, 143); // Question par rapport au Prix Unitaire
        doc.text(this.facture.priceHT.toFixed(2), pageWidth - 30, 143); // Montant total
        doc.setDrawColor("#2d4054");
        // doc.line(190, 185, 30, 185);
        doc.line(pageWidth - 20, 155, 20, 155);
        // ##### Table des totaux
        //# Col 1
        doc.text(this.localisation.localise("total_ht").toUpperCase(), 127, 165);
        doc.text(this.localisation.localise("total_tva").toUpperCase(), 127, 175);
        doc.text(this.localisation.localise("total_ttc").toUpperCase(), 127, 185);
        doc.text(this.localisation.localise("facute_solde").toUpperCase(), 127, 195);
        // # Col 2
        doc.text(this.facture.priceHT.toFixed(2), pageWidth - 30, 165);
        doc.text(this.facture.priceTVAVal.toFixed(2), pageWidth - 30, 175);
        doc.text(this.facture.priceTTC.toFixed(2), pageWidth - 30, 185);
        doc.setTextColor("#2d4054");
        doc.setFontSize(15);
        doc.setFontType("bold");
        doc.text(this.facture.priceTTC.toFixed(2) + " " + "EUR", 165, 195);
        doc.setFontType("normal");
        // Recap TVA
        doc.setFontSize(12);
        doc.setTextColor("black");
        doc.text(this.localisation.localise("recap_tva").toUpperCase(), 20, 208);
        doc.setFontSize(10);
        // doc.text('MONTANT DE TVA', )
        // doc.text('BASE HT', )
        // doc.text()
        doc.setTextColor("black");
        // Lignes inférieures
        // doc.setDrawColor('#702963')
        // doc.line(30, 230, 190, 230)
        // doc.line(20, 201, pageWidth-20, 201)
        doc.setFillColor(45, 64, 84);
        doc.rect(20, 211, pageWidth - 40, 10, "F");
        doc.setTextColor("#FFFFFF");
        doc.text(this.localisation.localise("recap_taux").toUpperCase(), 30, 217);
        doc.text(this.localisation.localise("recap_mt_tva").toUpperCase(), 90, 217);
        doc.text(this.localisation.localise("recap_base_ht").toUpperCase(), 170, 217);
        doc.setTextColor("black");
        doc.text(this.localisation.localise("facture_tva_fr").toUpperCase(), 25, 227);
        doc.text(this.facture.priceTVAVal.toFixed(2), 100, 227);
        doc.text(this.facture.priceHT.toFixed(2), 179, 227);
        // Pénalité et etc...
        doc.setFontType("normal");
        doc.setFontSize(10);
        doc.setTextColor("black");
        doc.text(this.localisation.localise("condition_paragraphe_1"), pageWidth / 2, 245, {
            align: "center",
        });
        doc.text(this.localisation.localise("condition_paragraphe_2"), pageWidth / 2, 250, {
            align: "center",
        });
        doc.text(this.localisation.localise("condition_paragraphe_3"), pageWidth / 2, 255, { align: "center" });
        // Footer
        doc.setDrawColor("#2d4054");
        doc.line(0, pageHeight - 16, pageWidth, pageHeight - 16);
        doc.setFontSize(8);
        doc.text(this.localisation.localise("capital_sasu") +
            " - " +
            this.localisation.localise("agency_address_number") +
            " " +
            this.localisation.localise("rcs_infos") +
            " - " +
            this.localisation.localise("agency_address_number") +
            " " +
            this.localisation.localise("siret") +
            " - " +
            this.localisation.localise("agency_address_number") +
            " " +
            this.localisation.localise("tva_fr"), pageWidth / 2, pageHeight - 10, { align: "center" });
        if (param === 1) {
            doc.autoPrint();
            //This is a key for printing
            doc.output("dataurlnewwindow");
        }
        else {
            //Save the PDF
            doc.save("Facture_" + this.facture.numero + ".pdf");
        }
    }
    ngOnInit() {
        this.getClientData().then((array) => (this.clientData = array[0]));
        const id = this.activatedRoute.snapshot.paramMap.get("id");
        //this.getTraducteurData(id);
        this.activatedRoute.paramMap.subscribe((paramMap) => __awaiter(this, void 0, void 0, function* () {
            if (!paramMap.has("id")) {
                yield this.router.navigate(["/facture"]);
            }
            const docID = paramMap.get("id");
            this.facture = yield this.getSingleFacture(docID);
            if (this.facture.room) {
                this.product = this.facture.room;
            }
            else {
                const docRef = this.hiero.DB.collection("particuliers_commandes")
                    .where("prestationId", "==", this.facture.prestation)
                    .get();
                docRef.then((snapshot) => {
                    snapshot.docs.map((doc) => {
                        console.log(doc.data());
                        this.product = doc.data().documentType;
                    });
                });
            }
        }));
        this.getFullName();
    }
    getSingleFacture(docID) {
        return __awaiter(this, void 0, void 0, function* () {
            const db = this.hiero.DB.collection("facture_particuliers").doc(docID);
            const data = yield db.get();
            return data.data();
        });
    }
    getClientData() {
        return __awaiter(this, void 0, void 0, function* () {
            const currentUser = this.hiero.Auth.User;
            const docRef = this.hiero.DB.collection("particuliers").where("uid", "==", currentUser.Id);
            const snapshot = yield docRef.get();
            return snapshot.docs.map((doc) => {
                return doc.data();
            });
        });
    }
    // not used anymore, we show HIERO's information in prestataire
    /*  getTraducteurData(factureId: string) {
      const docRef = this.hiero.DB.collection("facture_particuliers")
        .doc(factureId)
        .get();
  
      return docRef.then((doc) => {
        return this.hiero.DB.collection("traducteurs")
          .get()
          .then((snap) => {
            return snap.docs.map((el) => {
              if (el.data().businessName === doc.data().traducteurName) {
                this.traducteurData = el.data();
                return el.data(); // el.data();
              }
            });
          });
      });
    } */
    getFullName() {
        const currentUser = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection("particuliers").where("uid", "==", currentUser.Id);
        return docRef.get().then((snaphot) => {
            return snaphot.docs.map((doc) => {
                return this.hiero.DB.collection("users")
                    .get()
                    .then((snap) => {
                    return snap.docs.map((el) => {
                        if (el.id === doc.data().uid) {
                            this.fullName = el.data();
                            // console.log('users => ',  this.loadFullName);
                        }
                        return this.fullName;
                    });
                });
            });
        });
    }
}
