import {Particulier} from './Professionnel';
import {IParticulierService } from '../interfaces/IProfessionnelService';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { PartialObserver, Subscription } from 'rxjs';
import { IPrestation } from '../interfaces/IPrestation';


const FIREBASE_COLLECTION = 'particuliers_commandes';

export class ParticulierService {
  private _particulier: Particulier;
  private _docId: string;
  private _data: IParticulierService;

  constructor(particulier: Particulier, docId: string, data: IParticulierService) {
    this._particulier = particulier;
    this._docId = docId;
    this._data = data;
  }

  get Id(): string {
    return this._docId;
  }

  get data(): IParticulierService {
    return this._data;
  }

  get Particulier(): Particulier {
    return this._particulier;
  }
}

export class ParticulierServiceList {

  constructor(particulier: Particulier) {

    this._particulier = particulier;
    this._services = [];
    this._servicesSubject = new BehaviorSubject<ParticulierService[]>(this._services);

    const query = particulier.User.DB.collection(FIREBASE_COLLECTION).where('particulierId', '==', particulier.Id);
    query.onSnapshot({
      next: (snapshort: firebase.firestore.QuerySnapshot) => {

        this._services = [];

        snapshort.forEach(
          (result: firebase.firestore.QueryDocumentSnapshot) => {
            const data: IParticulierService = result.data() as IParticulierService;
            const cmd: ParticulierService = new ParticulierService(particulier, result.id, data);

            this._services.push(cmd);
          }
        );

      }

    });
    // tslint:disable-next-line:label-position no-unused-expression
    error: (error: Error) => {

    };

    try {
      query.get();
    } catch (err) {
      console.warn(err);
    }
  }
  private _particulier: Particulier;
  private _services: ParticulierService[];
  private _servicesSubject: BehaviorSubject<ParticulierService[]>;
  // list commande
  public WatchParticulierServiceList(observer: PartialObserver<ParticulierService[]>): Subscription {
    return this._servicesSubject.subscribe(observer);
  }
  // Add commande
  public async addService({
    srcLangISO639,
    originLangISO369,
    destLangISO369,
    documentType,
    docFile,
    tradName,
    tradId,
    date,
    prestaId,
    presta,
    pagesNumber,
    urgency,
    description
  }: {
    srcLangISO639: string; originLangISO369: string; destLangISO369: string; documentType: string;
    docFile: Array<any>; tradName: string; tradId: string; date: Date; prestaId: string; presta: IPrestation;
    pagesNumber?: number; urgency?: string; description?: string;
  }) {


    const commande: IParticulierService = {
      particulierId: this._particulier.Id,
      srcLanguageIso639: srcLangISO639,
      originLanguage: originLangISO369,
      destLanguageIso639: destLangISO369,
      documentType: documentType,
      docFile: docFile,
      traducteurName: tradName,
      traducteurId: tradId,
      createdAt: date,
      prestationId: prestaId,
      prestationInfo: presta,
      pagesNumber: pagesNumber,
      urgency: urgency,
      description: description,
    };

    const exist = this._services.find(
      (service) => {
        return (
          service.data.srcLanguageIso639 === srcLangISO639 &&
          service.data.destLanguageIso639 === destLangISO369 &&
          service.data.originLanguage === originLangISO369 &&
          service.data.documentType === documentType &&
          service.data.docFile === docFile &&
          service.data.traducteurName === tradName);

      }
    );
    if (exist) {
      throw Error('This order service already exist');
    }
    await this._particulier.User.DB.collection(FIREBASE_COLLECTION).add(commande);

  }

  public async removeService(prestationId: string) {
    await this._particulier.User.DB.collection(FIREBASE_COLLECTION).doc(prestationId).delete();
  }

  public getSrcLanguages(particulier: Particulier): any {
    const documentTypeRef = particulier.User.DB.collection('app_indexes').doc('services');
    documentTypeRef.get().then(
      (snapshot) => {
        const lang = snapshot.get('languages');
        // console.log(lang);

        /*
        for(const key in data){
          const value = data[key];
          return (value['iso639']);
        }
        */

      }
    ).catch();
  }

  // brin test
  public async getList(particulier: Particulier): Promise<boolean> {
    {
      const query = particulier.User.DB.collection(FIREBASE_COLLECTION).where('particulierId', '==', particulier.Id);
      const results: firebase.firestore.QuerySnapshot = await query.get();
      if (results) {
        return (results.size > 0);
      } else {
        return false;
      }
    }
  }

  public static async Init(particulier: Particulier): Promise<ParticulierServiceList> {
    return Promise.resolve(new ParticulierServiceList(particulier));
  }

  public static async HasService(particulier: Particulier): Promise<boolean> {
    const query = particulier.User.DB.collection(FIREBASE_COLLECTION).where('particulierId', '==', particulier.Id);
    const results: firebase.firestore.QuerySnapshot = await query.get();
    if (results) {
      return (results.size > 0);
    } else {
      return false;
    }
  }


}
