import { Component, NgZone } from "@angular/core";
import {
  FormBuilder,
  Validators,
  ValidationErrors,
  AbstractControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ESignupType } from "../../../../../common/src/bdd/user/AuthService";
import { HieroBDD } from "../../../services/hierobdd.service";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import * as firebase from "firebase/app";
import { Config } from "../../../../../common/src/services/config.service";
import {
  ISubmitFormElement,
  ISubmitFormInputErrors,
  SubmitForm,
} from "../../../../../common/src/utility/forms/submitform.class";

// signup
export function passwordsMatchValidator(id: string): ValidationErrors | null {
  return (control: AbstractControl): { [key: string]: any } => {
    let nomatch = false;
    if (control.parent) {
      const par = control.parent;
      const pass = par.get("password").value;
      const conf = par.get("confirm").value;
      nomatch = pass !== conf;
    }
    return nomatch ? { passwordsMatch: true } : null;
  };
}

@Component({
  templateUrl: "./defaultLogin.component.html",
  styleUrls: ["./defaultLogin.component.scss"],
})
export class DefaultLoginComponent {
  busy: boolean;
  signupForm: SubmitForm;
  sub: any;

  loginForm: SubmitForm;
  constructor(
    private fb: FormBuilder,
    private hiero: HieroBDD,
    private router: Router,
    private zone: NgZone,
    private localisation: LocalisationService,
    private config: Config,
    private activeRoute: ActivatedRoute
  ) {
    // this.router.navigate(['maintenance'])
    this.createForm();
  }

  createForm() {
    this.loginForm = new SubmitForm(
      this.fb,
      [
        {
          name: "email",
          value: "",
          validators: [Validators.required, Validators.email],
          type: "email",
          title: this.localisation.localise("login_main_email"),
          autocomplete: "email",
          placeholder: this.localisation.localise(
            "login_main_email_placeholder"
          ),
          help: this.localisation.localise("login_main_email_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise("login_error_required_field"),
            },
            <ISubmitFormInputErrors>{
              code: "email",
              message: this.localisation.localise("login_error_invalid_email"),
            },
            <ISubmitFormInputErrors>{
              code: "isEmail",
              message: this.localisation.localise("login_error_invalid_email"),
            },
            <ISubmitFormInputErrors>{
              code: "notFound",
              message: this.localisation.localise(
                "login_error_email_not_found"
              ),
            },
          ],
        },
        {
          name: "password",
          value: "",
          validators: [Validators.required],
          type: "password",
          title: this.localisation.localise("login_main_password"),
          autocomplete: "off",
          placeholder: this.localisation.localise(
            "login_main_password_placeholder"
          ),
          help: this.localisation.localise("login_main_password_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise("login_error_required_field"),
            },
          ],
        },
      ],
      // Submit callback
      (data) => {
        this.busy = true;
        return this.hiero.Auth.login({
          email: data.email,
          password: data.password,
          type: ESignupType.EmailPassword,
        });
      },
      // Success callback
      () => {
        // What to do with login success ?
        if (
          firebase.auth().currentUser.emailVerified === true ||
          new Date(
            firebase.auth().currentUser.metadata.creationTime
          ).getTime() < 1644847200000
        ) {
          this.router.navigate(["/", "app"]);
        } else {
          this.router.navigate(["compte/connexion"]);
          window.alert(this.localisation.localise("message_verification"));
        }
      },
      // Fail callback
      (err) => {
        // What to do with login failuer
        this.busy = false;
      },
      // Changes callback
      null
    );

    this.signupForm = new SubmitForm(
      this.fb,
      [
        {
          name: "email",
          value: "",
          validators: [Validators.required, Validators.email],
          type: "email",
          title: this.localisation.localise("login_main_email"),
          autocomplete: "email",
          placeholder: this.localisation.localise(
            "signup_main_email_placeholder"
          ),
          help: this.localisation.localise("signup_main_email_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
            <ISubmitFormInputErrors>{
              code: "email",
              message: this.localisation.localise("signup_error_invalid_email"),
            },
            <ISubmitFormInputErrors>{
              code: "isEmail",
              message: this.localisation.localise("signup_error_invalid_email"),
            },
          ],
        },
        {
          name: "password",
          value: "",
          validators: [Validators.required, Validators.minLength(8)],
          type: "password",
          title: this.localisation.localise("signup_main_password"),
          autocomplete: "off",
          placeholder: this.localisation.localise(
            "signup_main_password_placeholder"
          ),
          help: this.localisation.localise("signup_main_password_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
            <ISubmitFormInputErrors>{
              code: "minlength",
              message: this.localisation.localise("signup_error_min_password"),
            },
            <ISubmitFormInputErrors>{
              code: "maxlength",
              message: this.localisation.localise("signup_error_max_password"),
            },
          ],
        },
        <ISubmitFormElement>{
          name: "confirm",
          value: "",
          validators: [Validators.required, passwordsMatchValidator("confirm")],
          type: "password",
          title: this.localisation.localise("signup_main_confirm"),
          autocomplete: "off",
          placeholder: this.localisation.localise(
            "signup_main_confirm_placeholder"
          ),
          help: this.localisation.localise("signup_main_confirm_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "passwordsMatch",
              message: this.localisation.localise(
                "signup_error_passwords_do_not_match"
              ),
            },
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
          ],
        },
        {
          name: "givenName",
          value: "",
          validators: [Validators.required],
          type: "text",
          title: this.localisation.localise("signup_main_name"),
          autocomplete: "given-name",
          placeholder: this.localisation.localise(
            "signup_main_name_placeholder"
          ),
          help: this.localisation.localise("signup_main_name_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
          ],
        },
        {
          name: "familyName",
          value: "",
          validators: [Validators.required],
          type: "text",
          title: this.localisation.localise("signup_main_surname"),
          autocomplete: "family-name",
          placeholder: this.localisation.localise(
            "signup_main_surname_placeholder"
          ),
          help: this.localisation.localise("signup_main_surname_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
          ],
        },
        {
          name: "telephone",
          value: "",
          validators: [Validators.required],
          type: "number",
          title: this.localisation.localise("signup_main_telephone"),
          autocomplete: "tel",
          placeholder: this.localisation.localise(
            "signup_main_telephone_placeholder"
          ),
          help: this.localisation.localise("signup_main_telephone_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise(
                "signup_error_required_field"
              ),
            },
          ],
        },
      ],
      // Submit callback
      (data) => {
        this.busy = true;
        const prom = this.hiero.Auth.signup(
          {
            email: data.email,
            password: data.password,
            type: ESignupType.EmailPassword,
          },
          {
            email: data.email,
            givenName: data.givenName,
            familyName: data.familyName,
            telephone: data.telephone,
            createdAt: Date.now().toString(),
            // partenaire: this.activeRoute.snapshot.params.id,
          },
          {
            url: this.config.AppFullPath + "/app",
            bundleId: this.config.Environment.appBundleId,
            minimumVersion: this.config.Environment.appMinimumVersion,
          }
        );

        return prom;
      },
      // Success callback
      () => {
        // What to do with login success ?
        /* this.router.navigate(['/compte/connexion']); */
        this.router.navigate(["compte", "emailVerify", "1"]);
      },
      // Fail callback
      (err) => {
        // What to do with login failuer
        this.busy = false;
      },
      // Changes callback
      null
    );
  }
}
