import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as firebase from 'firebase';
import {IParticulier} from '../../../../../common/src/bdd/interfaces/IProfessionnel';
import {ITicket, status} from '../../../../../common/src/bdd/interfaces/ITicket';
import {TicketServiceService} from '../ticket-service.service';
import {string} from 'yup';


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  sendTicket: FormGroup;
  tickets: ITicket[] = [];
  fileUploading = false;
  fileUploaded = false;
  fileAdded: any[] = [];
  filename: string;
  pipe = new DatePipe('fr-FR');
  now = Date.now();
  myFormattedDate = this.pipe.transform(this.now, 'shortDate');
  status: status;
  idPro: any[] = [];
  Prof: any[] = [];

  ticketList = Array();
  ticketId = Array();
  ticketData = Array();


  constructor(private formBuilder: FormBuilder, private ticketService: TicketServiceService) { }

  ngOnInit() {
    // tslint:disable-next-line:no-shadowed-variable
    this.getCurrentUserData().then(array => this.Prof = array);
    this.initSendTicket();
    this.getAllTicket().then(array => this.ticketList = array);
    // console.log(this.ticketList);
  }

  initSendTicket() {
    this.sendTicket = this.formBuilder.group({
      objet: ['', Validators.required],
      questions: ['', Validators.required]
    });
  }

  // get the current user data
  getCurrentUserData() {
    const currentUser = firebase.auth().currentUser;
    const docRef = firebase.firestore().collection('particuliers').where('uid', '==', currentUser.uid);
    return docRef.get().then((snapshot) => {
      return snapshot.docs.map(doc => {
          this.idPro.push(doc.id);
         // console.log(this.idPro);
          this.Prof.push(<IParticulier>doc.data());
         // console.log(this.Prof);
          return this.Prof;
      });
    });
  }


  onSendTicket() {
    const currentUser = firebase.auth().currentUser;
    const newTicket: ITicket = this.sendTicket.value;
    newTicket.objet = this.sendTicket.get('objet').value;
    newTicket.questions = this.sendTicket.get('questions').value;
    newTicket.today = this.myFormattedDate;
    newTicket.numero = Math.floor((Math.random() * 1000000) + 1);
    newTicket.uid = currentUser.uid;
    // newTicket.state = this.status.t;
    // @ts-ignore return undefined
    // newTicket.state = this.status.encours;
    // convert the array into an object so that firebase accepts it
    newTicket.user = this.Prof.map((obj) => {
      return Object.assign({}, obj);
    });
    // console.log(newTicket.user);

    firebase.firestore().collection('tickets').add(newTicket);
    this.sendTicket.reset();
  }

  onUploadFile(event) {
    this.filename = event.srcElement.files[0].name;
    this.fileUploading = true;
    this.ticketService.uploadFile(event.target.files[0]).then(
      (url: string) => {
        this.fileAdded.push(url);
        this.fileUploading = false;
        this.fileUploaded = true;
        setTimeout(() => {
          this.fileUploaded = false;
        }, 5000);
      }
    );
  }

  async getAllTicket() {
    const db = firebase.firestore().collection('tickets').orderBy('today', 'desc');
    const snapshot = await db.get();
    return snapshot.docs.map(doc => {
      return {
        id: doc.id,
        data: doc.data()
      };
    });
  }
}
