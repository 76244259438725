var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, Validators } from "@angular/forms";
import { HieroBDD } from "../../../../services/hierobdd.service";
import { Particulier } from "../../../../../../common/src/bdd/professionnel/Professionnel";
import { ParticulierServiceList } from "../../../../../../common/src/bdd/professionnel/ProfessionnelService";
import { Prestation } from "../../../../../../common/src/bdd/prestation/Prestation";
import Swal from "sweetalert2";
import * as firebase from "firebase";
import { LocalisationService } from "../../../../../../common/src/modules/localisation/localisation.service";
import { EmptyTraducteurProfile, } from "../../../../../../common/src/bdd/interfaces/ITraducteur";
export var EnumPrestationState;
(function (EnumPrestationState) {
    EnumPrestationState["Defining"] = "defining";
    EnumPrestationState["WaitingForTranslator"] = "waiting-for-translator";
    EnumPrestationState["CancelledByClient"] = "cancelled-by-client";
    EnumPrestationState["WaitingForPayment"] = "waiting-for-payment";
    EnumPrestationState["RefusedByTranslator"] = "refused-by-translator";
    EnumPrestationState["Translating"] = "translating";
    EnumPrestationState["WaitingForValidationFromClient"] = "waiting-for-client-to-validate";
    EnumPrestationState["Validated"] = "validated";
})(EnumPrestationState || (EnumPrestationState = {}));
export class CommandeComponent {
    constructor(activeModal, formBuilder, hiero, localisation) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.hiero = hiero;
        this.localisation = localisation;
        this.EmptyPrestation = {
            uid: "",
            state: EnumPrestationState.WaitingForTranslator,
            srcLanguageIso639: "",
            destLanguageIso639: "",
            srcCountryCode: "",
            documents: [],
            traducteurId: null,
            traducteur: null,
            price: {
                traducteurHT: 0,
                hieroMarginPercent: 0,
                hieroMarginValue: 0,
                subtotalHT: 0,
                tva: 0,
                tvaValue: 0,
                ttc: 0,
            },
            deviceStorageId: "",
            sentToTranslatorAt: 0,
            cancelledByClientAt: 0,
            acceptedByTranslatorAt: 0,
            refusedByTranslatorAt: 0,
            dueAt: 0,
            paidAt: 0,
            completedAt: 0,
            validatedByClientAt: 0,
            lastModifiedAt: 0,
            createdAt: 0,
            isProfessionalPrestation: false,
            partenaire: "",
        };
        this.EmptyDocument = {
            documentTypeId: "",
            deviceStorageId: "",
            original: [],
            translated: [],
        };
        this.EmptyIFile = {
            name: "",
            deviceStorageId: "",
            downloadURL: "",
        };
        this.EmptyTraducteurTampon = {
            traducteur: EmptyTraducteurProfile,
            priceHT: 0,
            priceTTC: 0,
            tvaValue: 0,
        };
        this.srcLangList = [];
        this.destLangList = [];
        this.srcCountryList = [];
        this.isChecked = false;
        this.translatorList = [];
        this.fileLength = 0;
        this.tab = [];
        this.documentsFileUpload = [];
        this.FORFAIT = 32.5;
        this.TVA = 1.2;
        this.show = false;
        this.showLoading = false;
        this.progress = 0;
        this.pagesNumber = 1;
    }
    ngOnInit() {
        this.storage = this.hiero.Storage;
        this.createForm();
        this.getSrcLang();
        this.commandeForm.get("type").valueChanges.subscribe((type) => {
            if (type === "asser")
                this.commandeForm.get("originLang").enable();
            else {
                this.commandeForm.get("originLang").setValue("");
                this.commandeForm.get("originLang").disable();
            }
        });
        this.commandeForm.get("srcLang").valueChanges.subscribe((lang) => {
            this.getDestLang(lang);
        });
        this.commandeForm
            .get("isNumberPage")
            .valueChanges.subscribe((isChecked) => {
            this.isChecked = isChecked;
            if (isChecked) {
                this.commandeForm.get("docFile").setValue([]);
                this.selectedFiles = null;
                document.getElementById("input_file").removeAttribute("multiple");
            }
            else {
                this.commandeForm.get("docFile").setValue([]);
                this.selectedFiles = null;
                document
                    .getElementById("input_file")
                    .setAttribute("multiple", "true");
            }
        });
        this.commandeForm
            .get("docFilePageNumber")
            .valueChanges.subscribe((number) => (this.pagesNumber = number));
        //this.getTranslatorServices();
    }
    createForm() {
        this.commandeForm = this.formBuilder.group({
            type: ["", Validators.required],
            doctype: ["", Validators.required],
            srcLang: ["", Validators.required],
            destLang: ["", Validators.required],
            originLang: [{ value: "", disabled: true }, Validators.required],
            description: [""],
            urgency: ["", Validators.required],
            isNumberPage: [""],
            docFilePageNumber: ["", Validators.min(0)],
            docFile: [[], Validators.required],
        });
    }
    getSrcLang() {
        return __awaiter(this, void 0, void 0, function* () {
            const docRef = this.hiero.DB.collection("app_indexes").doc("services");
            return docRef
                .get()
                .then((snapshot) => {
                this.srcLangList = snapshot.get("languages");
            })
                .catch();
        });
    }
    getDestLang(lang) {
        return __awaiter(this, void 0, void 0, function* () {
            this.commandeForm.get("destLang").setValue("");
            this.commandeForm.get("originLang").setValue("");
            const traducteurDestListRef = yield this.hiero.DB.collection("traducteur_services")
                .where("srcLanguageIso639", "==", lang)
                .get();
            const temp = traducteurDestListRef.docs.map((doc) => doc.get("destLanguageIso639"));
            this.destLangList = yield temp.filter((v, i) => temp.indexOf(v) === i);
            this.srcCountryList = this.srcLangList
                .filter((l) => l.iso639 == lang)
                .map((_) => _.countries)
                .reduce((acc, val) => acc.concat(val), []);
        });
    }
    fileUpload(files) {
        this.fileLength = files.length;
        if (this.fileLength > 0)
            this.selectedFiles = files;
    }
    onSubmitForm() {
        return __awaiter(this, void 0, void 0, function* () {
            this.formValue = this.commandeForm.value;
            this.translatorList = [];
            // get traslators
            const traducteurListFilter = this.getTraducteurs({
                srcName: this.formValue.srcLang,
                destName: this.formValue.destLang,
            });
            // get traslators Id
            const tradIds = yield traducteurListFilter.then((data) => data.map((_) => {
                return _.traducteurId;
            }));
            // get Traslators' infos
            const traducteurRef = yield this.hiero.DB.collection("traducteurs").get();
            traducteurRef.docs.map((_) => {
                if (_.data().uid !== "f5UaBV5xlEavReS3NRAO2WuPleS2" &&
                    _.data().uid !== "FDgWJPpMJaMV8of0zrMXhNALPz92") {
                    tradIds.forEach((element) => {
                        if (element === _.id) {
                            if (this.formValue.type === "asser") {
                                if (_.data().assermentation === "true" ||
                                    _.data().assermentation === true) {
                                    this.translatorList.push(_.data());
                                }
                            }
                            else {
                                this.translatorList.push(_.data());
                            }
                            //calcul tva priceHT and priceTTC foreach trad
                            if (this.isChecked &&
                                this.pagesNumber != null &&
                                this.pagesNumber > 0) {
                                this.calculPrice(this.pagesNumber, this.translatorList);
                            }
                            else {
                                this.calculPrice(this.fileLength, this.translatorList);
                            }
                        }
                    });
                }
            });
            this.show = true;
        });
    }
    getTraducteurs({ srcName, destName, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const traducteurServiceRef = yield this.hiero.DB.collection("traducteur_services")
                .where("srcLanguageIso639", "==", srcName)
                .where("destLanguageIso639", "==", destName)
                .get();
            const datas = traducteurServiceRef.docs.map((doc) => doc.data());
            return datas;
        });
    }
    // get the traslator's id
    getTraducteurId(traslatorName) {
        const docRefTrad = this.hiero.DB.collection("traducteurs").where("businessName", "==", traslatorName);
        return docRefTrad.get().then((snapshot) => {
            this.tradId = snapshot.docs[0].id;
            snapshot.docs.find((elt) => elt.id === this.tradId).data();
            return this.tradId;
        });
    }
    sendCommande(translatorName) {
        return __awaiter(this, void 0, void 0, function* () {
            this.show = false;
            this.showLoading = true;
            this.progress = 0;
            const part = new Particulier(this.currentUser, this.particulierId);
            const commands = new ParticulierServiceList(part);
            this.EmptyDocument.documentTypeId = this.formValue.doctype;
            this.isSelectedTrad = this.translatorsCopies.filter((el) => el.traducteur.businessName === translatorName);
            // send data files to firestorage
            Object.keys(this.selectedFiles).map((obj) => {
                const EmptyIFiles = {
                    name: "",
                    deviceStorageId: "",
                    downloadURL: "",
                };
                EmptyIFiles.deviceStorageId = this.selectedFiles[obj].name;
                const id = this.currentUser.Id +
                    "/" +
                    this.currentUser.Id /*this.traducteur.uid*/ +
                    "/" +
                    this.currentUser
                        .Id /*this.traducteur.uid this.traducteurName.replace(' ', '_')*/ +
                    "/" +
                    EmptyIFiles.deviceStorageId;
                // this.storageRef = this.hiero.Storage.ref().child(this.currentUser.Id).child(this.currentUser.Id).child(this.traducteurName.replace(' ', '_'))
                this.storageRef = this.hiero.Storage.ref(id);
                this.storageRef.put(this.selectedFiles[obj]).on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                    const float = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.progress = +float.toFixed();
                    console.log("Upload is " + this.progress + "% done");
                    this.storageRef.getDownloadURL().then((snap) => {
                        EmptyIFiles.downloadURL = snap;
                    });
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED:
                            console.log("Upload is paused");
                            break;
                        case firebase.storage.TaskState.RUNNING:
                            console.log("Upload is running");
                            break;
                    }
                }, (error) => { }, () => {
                    this.loadingFile();
                });
                EmptyIFiles.name = this.selectedFiles[obj].name;
                EmptyIFiles.type = this.selectedFiles[obj].type;
                EmptyIFiles.deviceStorageId.replace(" ", "_") + obj;
                this.tab.push(EmptyIFiles);
            });
            this.EmptyDocument.original = this.tab;
            const arrayDocuments = [];
            arrayDocuments[0] = this.EmptyDocument;
            this.hiero.Auth.User.Profile.partenaire === "science-acceuil"
                ? (this.EmptyPrestation.partenaire = "science-acceuil")
                : null;
            this.EmptyPrestation.srcLanguageIso639 = this.formValue.srcLang;
            this.EmptyPrestation.price.ttc = this.isSelectedTrad[0].priceTTC;
            this.EmptyPrestation.price.traducteurHT = this.isSelectedTrad[0].priceHT;
            this.EmptyPrestation.price.tva = this.TVA;
            this.EmptyPrestation.price.tvaValue = this.isSelectedTrad[0].tvaValue;
            if (this.formValue.type === "asser")
                this.EmptyPrestation.srcCountryCode = this.formValue.originLang;
            else
                this.EmptyPrestation.srcCountryCode = "";
            this.EmptyPrestation.destLanguageIso639 = this.formValue.destLang;
            this.EmptyPrestation.traducteurId = translatorName;
            this.EmptyPrestation.documents = arrayDocuments;
            this.EmptyPrestation.traducteurId = this.tradId;
            this.EmptyPrestation.uid = this.tradId; // Id traductor
            this.EmptyPrestation.deviceStorageId = this.currentUser.Id; // id prestation device
            this.EmptyPrestation.traducteur = this.isSelectedTrad[0].traducteur;
            this.EmptyPrestation.isProfessionalPrestation = false;
            const newPrestation = yield Prestation.Create(this.currentUser, this.EmptyPrestation);
            let typePrestation;
            if (this.formValue.type === "asser")
                typePrestation = "Traduction assermentée";
            else
                typePrestation = "Traduction classique";
            // data of facture
            yield this.hiero.DB.collection("facture_particuliers").add({
                numero: Math.floor(Math.random() * 1000000 + 1),
                date: new Date(),
                srcLang: this.formValue.srcLang,
                destLang: this.formValue.destLang,
                priceTTC: this.isSelectedTrad[0].priceTTC,
                priceHT: this.isSelectedTrad[0].priceHT,
                priceTVA: this.TVA,
                priceTVAVal: this.isSelectedTrad[0].tvaValue,
                traducteur: this.tradId,
                traducteurName: translatorName,
                pagesNumber: this.pagesNumber,
                prestation: newPrestation.Id,
                particulierId: this.particulierId,
                typePrestation: typePrestation,
            });
            let originLangISO369;
            if (this.formValue.type === "asser")
                originLangISO369 = this.formValue.originLang;
            else
                originLangISO369 = "classic";
            // add information form particulier command
            yield commands.addService({
                srcLangISO639: this.formValue.srcLang,
                originLangISO369: originLangISO369,
                destLangISO369: this.formValue.destLang,
                documentType: this.formValue.doctype,
                docFile: this.documentsFileUpload,
                tradName: translatorName,
                tradId: this.tradId,
                date: new Date(),
                prestaId: newPrestation.Id,
                presta: this.EmptyPrestation,
                pagesNumber: this.pagesNumber,
                urgency: this.formValue.urgency,
                description: this.formValue.description,
            });
            yield firebase
                .firestore()
                .collection("mail")
                .add({
                bcc: [
                    "jw.johnson@hiero-solution.com",
                    "B.balsomi@hiero-solution.com",
                    "a.luis@hiero-solution.com",
                    "w.moingt@hiero-solution.com",
                    "n.ziani@hiero-solution.com"
                ],
                message: {
                    subject: "Nouvelle prestation de particulier",
                    text: "Nouvelle commande",
                    html: `  <div style=" font-family: "Helvetica Neue", sans-serif; font-size: 20px;"> <div style="font-weight: bold"> <img src="https://firebasestorage.googleapis.com/v0/b/hiero-prod.appspot.com/o/Logos%2Fbird.png?alt=media&token=fa72f85d-1c09-48c1-ab47-ee7cde0eb80b" alt="" width="50" /> Hierø Solution </div> <hr /> <h3 style="text-align: center">Nouvelle commande de particulier</h3> <p>Bonjour,</p><p style="line-height: 2; text-align:justify"> Nous avons une nouvelle commande dans notre système du particulier ${this.hiero.Auth.User.Profile.givenName} ${this.hiero.Auth.User.Profile.familyName} . Le numéro de la prestation est ${newPrestation.Id}. Retrouve les détails de cette prestation dans ton espace admin. <p>Bien cordialement,</p> <p>L\'équipe Hierø</p> <hr /><div><h3 style= "text-align:center">Description de la commande</h3><p > ${this.formValue.description}</p></div> </div> `,
                },
            })
                .then(() => console.log("Queued email for delivery!"));
        });
    }
    getTranslatorNameOnClickButton(translatorName) {
        this.currentUser = this.hiero.Auth.User;
        this.getTraducteurId(translatorName);
        const docRef = this.hiero.DB.collection("particuliers").where("uid", "==", this.currentUser.Id);
        docRef
            .get()
            .then((snapshot) => {
            this.particulierId = snapshot.docs[0].id;
            this.particulierData = snapshot.docs[0].data();
            if (translatorName != null) {
                if (this.fileLength > 0) {
                    for (const indexe in this.selectedFiles) {
                        this.EmptyDocument.deviceStorageId = this.currentUser.Id;
                        if (indexe === "length") {
                            this.sendCommande(translatorName);
                        }
                    }
                }
            }
        })
            .catch();
    }
    calculPrice(pagesNumber, traducteurs) {
        this.translatorsCopies = traducteurs.map((element) => {
            if (element.defaultException) {
                const priceHT = pagesNumber * +element.defaultException;
                const priceMarge = (priceHT / 100) * 18;
                const TVAval = ((priceHT + priceMarge) / 100) * 20;
                const priceTTC = +(priceHT + TVAval + priceMarge).toFixed(2);
                const copie = {
                    traducteur: element,
                    priceHT: priceHT,
                    tvaValue: TVAval,
                    priceTTC: priceTTC,
                };
                return copie;
            }
            if (!element.defaultException) {
                const priceHT = pagesNumber * this.FORFAIT;
                const TVAvaleur = (priceHT / 100) * 20;
                const priceMarge = (priceHT / 100) * 33;
                const priceTTC = +(priceHT + TVAvaleur + priceMarge).toFixed(2);
                const copie = {
                    traducteur: element,
                    priceHT: priceHT,
                    tvaValue: TVAvaleur,
                    priceTTC: priceTTC,
                };
                return copie;
            }
        });
    }
    loadingFile() {
        this.show = false;
        this.showLoading = true;
        Swal.fire({
            title: this.localisation.localise("reset_password_send"),
        }).then(() => {
            this.activeModal.close();
        });
    }
}
