import * as yup from 'yup';

export interface IParticulier {
    uid: string;
    businessName: string;
    address: IAddress;
    coords: IGeoCoords;
    telephone: string;
    email: string;
    // registration: string; // SIRET, SIREN, ...
    // budget?: number;
    // subscription?: string;
    createdAt?: Date;
    // service?: string;
    // siret?: string;
    // poste?: string;
    partenaire: string;
}


export interface IAddress {
    formatted: string;
    number: string;
    street: string;
    city: string;
    country: string;
    code: string;
    extra: string;
}

export interface IGeoCoords {
    latitude: number;
    longitude: number;
}

export const ParticulierAddressSchema = yup.object({
    formatted: yup.string().default(''),
    number: yup.string().default(''),
    street: yup.string().default(''),
    city: yup.string().default(''),
    country: yup.string().default(''),
    code: yup.string().default(''),
    extra: yup.string().default(''),
});

export const ParticulierCoordsSchema = yup.object({
    latitude: yup.number().default(0),
    longitude: yup.number().default(0)
});

export const ParticulierProfileSchema = yup.object({
    uid: yup.string(),
    businessName: yup.string(),
    address: ParticulierAddressSchema,
    coords: ParticulierCoordsSchema,
    telephone: yup.string(),
    email: yup.string().email(),
    partenaire: yup.string(),
});


export const EmptyParticulierProfile = ParticulierProfileSchema.cast({});
export const EmptyParticulierAddress = ParticulierAddressSchema.cast({});
export const EmptyParticulierCoords = ParticulierCoordsSchema.cast({});
